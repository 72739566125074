import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import UIRoute from "core/presentation/components/UIRoute";
import { useMemo } from "react";
import getHours from "date-fns/getHours";
import { useCurrentUser } from "features/login/presentation/hooks/useUser";
import dashboardEmptyImage from "assets/img/dashboard_empty.svg";
import { Box } from "@material-ui/core";

export interface DashboardRouteProps extends RouteComponentProps {}

export function getGreetingByHour(hour: number) {
  // for empty content...maybe find a better solution when it is no prototype anymore
  if (hour === -1) {
    return "";
  }

  if (hour < 5) {
    return "general";
  } else if (hour < 11) {
    return "morning";
  } else if (hour < 18) {
    return "day";
  } else if (hour < 22) {
    return "eve";
  } else {
    return "general";
  }
}

function DashboardRoute(props: DashboardRouteProps) {
  const hour: number = getHours(new Date());
  const currentUserQuery = useCurrentUser();

  const fullname = useMemo(() => {
    if (currentUserQuery.data) {
      const firstname = currentUserQuery.data.firstname;
      const lastname = currentUserQuery.data.lastname;
      return `${firstname} ${lastname}`;
    } else {
      return "";
    }
  }, [currentUserQuery.data]);

  const greeting = useMemo(() => {
    switch (getGreetingByHour(hour)) {
      case "general":
        return "Hallo";

      case "morning":
        return "Guten Morgen";

      case "day":
        return "Guten Tag";

      case "eve":
        return "Guten Abend";

      default:
        return "";
    }
  }, [hour]);

  return (
    <UIRoute testID="dashboard" {...props} title="Überblick">
      <Box display="flex" height="100%" justifyContent="center" alignItems="center" flexDirection="column">
        <img src={dashboardEmptyImage} style={{ height: 280 }} />
        <Typography variant="h5" style={{ fontWeight: "bold" }}>
          {greeting}, {fullname}!
        </Typography>
      </Box>
    </UIRoute>
  );
}

export default DashboardRoute;
