import { CircularProgress, FormControl, FormHelperText, makeStyles, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { FormikContextType, useFormikContext } from "formik";
import React, { useState } from "react";

interface SelectOption {
  value: string;
  label: string;
}

interface ControlledAutoCompleteProps {
  label: string;
  name: string;
  initialValue?: string;
  onSelectOption: (value: string) => void;
  onChangeInput?: (value: string) => void;
  options: SelectOption[];
  loading?: boolean;
  disabled?: boolean;
  variant?: "standard" | "outlined";
  className?: string;
  "data-testid"?: string;
  getTestIdForOption?: (option: any) => string;
}

const useStyles = makeStyles((theme) => ({
  errorText: { marginLeft: "14px", color: "#f44336" },
}));

const ControlledAutocomplete: React.FC<ControlledAutoCompleteProps> = ({
  initialValue,
  loading = false,
  onChangeInput,
  onSelectOption,
  options,
  disabled,
  label,
  name,
  className,
  getTestIdForOption,
  ...props
}) => {
  const classes = useStyles();

  const [inputValue, setInputValue] = useState<string>(initialValue || "");
  const form: FormikContextType<any> | undefined = useFormikContext<any>();

  const testId = props["data-testid"];

  return (
    <FormControl error={form?.touched[name] && !!form.errors[name]}>
      <Autocomplete
        data-testid={testId}
        className={className}
        freeSolo
        disabled={disabled}
        value={{
          value: inputValue,
          label: initialValue && inputValue === initialValue ? options.find((option) => option.value === inputValue)?.label : inputValue,
        }}
        // it's always of type { value: string, label: string } | null, never string
        onChange={(_event, option: any) => onSelectOption(option?.value || "")}
        inputValue={inputValue}
        onInputChange={(_event, value) => {
          if (onChangeInput) {
            onChangeInput(value);
          }
          setInputValue(value);
        }}
        options={options}
        getOptionSelected={(option, selected) => option.value === selected.value}
        getOptionLabel={(option) => option.label || ""}
        renderOption={(option) => (
          <Typography data-testid={`${testId}-${getTestIdForOption ? getTestIdForOption(option) : option.value}`} noWrap>
            {option.label}
          </Typography>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth
            error={form?.touched[name] && !!form.errors[name]}
            onBlur={() => form?.setFieldTouched(name, true)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        {...props}
      />
      {form?.touched[name] && !!form.errors[name] && <FormHelperText className={classes.errorText}>{form.errors[name]}</FormHelperText>}
    </FormControl>
  );
};

export default ControlledAutocomplete;
