import React from "react";
import SubTabHeader from "template/presentation/components/nav/SubTabHeader";

interface Props {
  path: string;
  onTabChange: (path: string) => void;
}

const AssignmentsSubHeader: React.FC<Props> = ({ path, onTabChange }) => (
  <SubTabHeader
    currentPath={path}
    onTabChange={onTabChange}
    tabs={[
      { value: "/assignments", label: "Alle" },
      { value: "/assignments/todo", label: "Offen" },
      { value: "/assignments/scheduling", label: "Neu zu terminieren" },
      { value: "/assignments/done", label: "Abgeschlossen" },
    ]}
  />
);

export default AssignmentsSubHeader;
