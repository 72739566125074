import React, { useCallback, useState } from "react";
import { queryCache } from "react-query";
import useService from "core/di/useService";
import { RouteComponentProps } from "react-router-dom";
import UIRoute from "core/presentation/components/UIRoute";
import ClientService from "../../domain/services/ClientService";
import { ValidationMessageError } from "core/data/errors";
import CreateClientOnIndustrySoftwareForm, {
  CreateClientOnIndustrySoftwareFormValues,
} from "../components/CreateClientOnIndustrySoftwareForm";

export interface CreateClientOnIndustrySoftwareRouteProps extends RouteComponentProps {}

const CreateClientOnIndustrySoftwareRoute = (props: CreateClientOnIndustrySoftwareRouteProps) => {
  const clientService = useService(ClientService);

  const [error, setError] = useState<string | null>(null);

  const onSubmit = useCallback(
    (values: CreateClientOnIndustrySoftwareFormValues) => {
      return clientService
        .createNewClientOnIndustrySoftware(values)
        .then(() => {
          setError(null);
          return queryCache.invalidateQueries("clientList");
        })
        .then(() => {
          props.history.push("/clients/b2c");
        })
        .catch((e) => {
          if (e instanceof ValidationMessageError) {
            switch (e.code) {
              case "CLIENT_CREATION_FAILED":
                setError("Kunde konnte auf Branchensoftware nicht angelegt werden.");
                return;
              default:
                setError(`Ein unbekannter Fehler ist aufgetreten. Code: ${e.code}`);
                return;
            }
          } else {
            setError("Ein unbekannter Fehler ist aufgetreten");
          }
        });
    },
    [clientService, props.history]
  );

  return (
    <UIRoute {...props} title={"Neuen Endkunden anlegen (inkl. Branchensoftware)"}>
      <CreateClientOnIndustrySoftwareForm error={error} onSubmit={onSubmit} />
    </UIRoute>
  );
};

export default CreateClientOnIndustrySoftwareRoute;
