import React, { useCallback, useState } from "react";
import { useDebounce } from "use-debounce";
import { useCurrentUserInGroup } from "../../../login/presentation/hooks/useUser";
import MessagesTable from "../components/MessagesTable";
import MessageService from "../../domain/services/MessageService";
import { useQuery, useQueryCache } from "react-query";
import { useService } from "core/di";
import UIRoute from "core/presentation/components/UIRoute";
import { RouteComponentProps } from "react-router-dom";
import { BadRequestError, ElementNotFoundError, UnauthorizedError } from "core/data/errors";
import { usePageAndPageSizeAndAnyQueryFromRouteProps } from "core/utils";
import UserService from "features/users/domain/services/UserService";
import AssignmentService from "features/assignments/domain/services/AssignmentService";
import { SelectOption } from "features/assignments/domain/entities/Assignment";

export interface MessagesRouteProps extends RouteComponentProps {}

const MessagesRoute = (props: MessagesRouteProps) => {
  const { page, pageSize, searchQueries } = usePageAndPageSizeAndAnyQueryFromRouteProps(props);
  const [sortedBy, setSortedBy] = useState<{ field: string; order: "asc" | "desc" }>({ field: "date", order: "desc" });
  const [recipientQuery, setRecipientQuery] = useState<string>("");
  const [recipientQueryDebounced] = useDebounce(recipientQuery, 500);
  const [assignmentQuery, setAssignmentQuery] = useState<string>("");
  const [assignmentQueryDebounced] = useDebounce(assignmentQuery, 500);
  const [, isNotB2BBackoffice] = useCurrentUserInGroup("b2bBackoffice");

  const userService = useService(UserService);
  const assignmentService = useService(AssignmentService);

  const { data: recipientOptions } = useQuery([`recipientQuery${recipientQueryDebounced}`], () => {
    return userService.getTypeAheadOptions(recipientQueryDebounced).then((r): SelectOption[] => {
      return r.map((recipient) => {
        return {
          label: `${recipient.firstname} ${recipient.lastname} (${
            recipient.employeeNumber || recipient.b2bNumber || recipient.b2cClient?.clientNumber || "Keine Nummer"
          }) [${recipient.groups.pop()?.toUpperCase()}]`,
          // @ts-ignore
          value: recipient._id,
          number: recipient.employeeNumber || recipient.b2bNumber || recipient.email,
        };
      });
    });
  });

  const { data: assignmentOptions } = useQuery(
    [`assignmentQuery${assignmentQueryDebounced}`],
    () => {
      return assignmentService
        .getTypeAheadOptions(assignmentQueryDebounced)
        .then((r): SelectOption[] =>
          r.map((assignment) => ({
            label: `${assignment.assignmentNumber}`,
            value: assignment.id,
          }))
        )
        .catch((err) => {
          if (err.response.status === 401) {
            return;
          }
          throw err;
        });
    },
    { enabled: isNotB2BBackoffice }
  );

  const onHandleQuery = useCallback(
    (page, pageSize, searchQueries) => {
      let query = "/messages?page=" + page;
      if (pageSize) {
        query += "&page_size=" + pageSize;
      }
      if (searchQueries) {
        for (const [key, value] of Object.entries(searchQueries)) {
          if (value) {
            query += `&${key}=${value}`;
          }
        }
      }
      props.history.push(query);
    },
    [props.history]
  );

  const onSortChange = useCallback((sortBy: string, sortOrder: "asc" | "desc") => {
    setSortedBy({ field: sortBy, order: sortOrder });
  }, []);

  const onRecipientQueryChange = useCallback((query: string) => {
    setRecipientQuery(query);
  }, []);

  const onAssignmentQueryChange = useCallback((query: string) => {
    setAssignmentQuery(query);
  }, []);

  const messageService = useService(MessageService);
  const [error, setError] = useState<string | null>(null);
  const queryCache = useQueryCache();

  const { data, isLoading, refetch, isFetching } = useQuery(["messageList", { searchQueries, page, pageSize, sortedBy }], () =>
    messageService.getMessageList(searchQueries, page, pageSize, sortedBy)
  );

  const onDeleteMessage = (id: string) => {
    messageService
      .deleteById(id)
      .then(() => refetch())
      .then(() => {
        return queryCache.invalidateQueries(["message", id]);
      })
      .catch((e) => {
        if (e instanceof BadRequestError || e instanceof UnauthorizedError || e instanceof ElementNotFoundError) {
          setError(e.message);
        } else {
          setError("Ein unbekannter Fehler ist aufgetreten");
        }
      });
  };

  return (
    <UIRoute {...props} title="Mitteilungen">
      <MessagesTable
        isLoading={isLoading || isFetching}
        onReload={refetch}
        recipientTypeAhead={{ options: recipientOptions || [], query: onRecipientQueryChange }}
        assignmentTypeAhead={{ options: assignmentOptions || [], query: onAssignmentQueryChange }}
        onSearch={(searchValue) => {
          onHandleQuery(1, pageSize, searchValue);
        }}
        onAdd={() => {
          props.history.push("/message/new");
        }}
        messages={data?.data || null}
        page={page}
        pageSize={pageSize}
        totalCount={data?.totalCount}
        onPageChange={(newPage: number) => {
          onHandleQuery(newPage, pageSize, searchQueries);
        }}
        onPageSizeChange={(newPageSize: number) => {
          onHandleQuery(page, newPageSize, searchQueries);
        }}
        sortedBy={sortedBy}
        onSortChange={onSortChange}
        onDeleteMessage={onDeleteMessage}
        error={error}
      />
    </UIRoute>
  );
};

export default MessagesRoute;
