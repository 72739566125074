import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import UsersRoute from "features/users/presentation/routes/UsersRoute";
import CreateUserRoute from "features/users/presentation/routes/CreateUserRoute";
import DashboardRoute from "features/dashboard/presentation/routes/DashboardRoute";
import BaseScreenLayout from "template/presentation/components/layout/BaseScreenLayout";
import { useNavigationCategories } from "../hooks/useNavigationCategories";
import { APP_NAME_DRAWER } from "core/config";
import EditUserRoute from "features/users/presentation/routes/EditUserRoute";
import ClientsRoute from "features/clients/presentation/routes/ClientsRoute";
import CreateClientRoute from "features/clients/presentation/routes/CreateClientRoute";
import EditClientRoute from "features/clients/presentation/routes/EditClientRoute";
import ManageAreaRoute from "features/b2b/presentation/routes/ManageAreaRoute";
import EditAreaRoute from "features/b2b/presentation/routes/EditAreaRoute";
import MessagesRoute from "features/messages/presentation/routes/MessagesRoute";
import CreateMessageRoute from "features/messages/presentation/routes/CreateMessageRoute";
import ReadMessageRoute from "features/messages/presentation/routes/ReadMessageRoute";
import AssignmentsRoute from "features/assignments/presentation/routes/AssignmentsRoute";
import EditAssignmentsRoute from "features/assignments/presentation/routes/EditAssignmentsRoute";
import InternalPoolsRoute from "features/pools/presentation/routes/InternalPoolsRoute";
import CreatePoolRoute from "features/pools/presentation/routes/CreatePoolRoute";
import UpdatePoolRoute from "features/pools/presentation/routes/UpdatePoolRoute";
import PoolAssignmentsRoute from "features/pools/presentation/routes/PoolAssignmentsRoute";
import PoolAssignmentDetailsRoute from "features/pools/presentation/routes/PoolAssignmentDetailsRoute";
import AdaptersRoute from "features/adapter/presentation/routes/AdaptersRoute";
import AdapterDetailRoute from "features/adapter/presentation/routes/AdapterDetailRoute";
import BranchesRoute from "features/branches/presentation/routes/BranchesRoute";
import EditBranchRoute from "features/branches/presentation/routes/EditBranchRoute";
import CreateBranchRoute from "features/branches/presentation/routes/CreateBranchRoute";
import ClassificationsRoute from "features/classifications/presentation/routes/ClassicifationRoute";
import CreateClassificationRoute from "features/classifications/presentation/routes/CreateClassificationRoute";
import EditClassificationRoute from "features/classifications/presentation/routes/EditClassificationRoute";
import ListProductsRoute from "features/products/presentation/routes/ListProductsRoute";
import ImportProductsRoute from "features/products/presentation/routes/ImportProductsRoute";
import ListB2CRecruitmentsRoute from "features/recruitment/presentation/routes/ListB2CRecruitmentsRoute";
import B2BPoolsRoute from "features/pools/presentation/routes/B2BPoolsRoute";
import CreateAssignmentRoute from "features/assignments/presentation/routes/CreateAssignmentRoute";
import CasesRoute from "features/cases/presentation/routes/CasesRoute";
import CreateCaseRoute from "features/cases/presentation/routes/CreateCaseRoute";
import ChangePasswordRoute from "features/profile/presentation/routes/ChangePasswordRoute";
import CreateClientOnIndustrySoftwareRoute from "features/clients/presentation/routes/CreateClientOnIndustrySoftwareRoute";
import CalendarRoute from "features/assignments-calendar/routes/CalendarRoute";
import SignatureformsRoute from "features/signatureforms/presentation/routes/SignatureformsRoute";
import CreateSignatureformsRoute from "features/signatureforms/presentation/routes/CreateSignatureformsRoute";
import EditSignatureformsRoute from "features/signatureforms/presentation/routes/EditSignatureformsRoute";
import CreateSignatureAssignmentRoute from "features/signatureforms/presentation/routes/CreateSignatureAssignmentRoute";
import EditCaseRoute from "features/cases/presentation/routes/EditCaseRoute";
import RehaItemsRoute from "features/rehaItems/presentation/routes/RehaItemsRoute";
import FormularListRoute from "features/formular-center/presentation/routes/FormularListRoute";
import CreateFormularRoute from "features/formular-center/presentation/routes/CreateFormularRoute";
import EditFormularRoute from "features/formular-center/presentation/routes/EditFormularRoute";

export interface BaseScreenProps extends RouteComponentProps {}

function BaseScreen(props: BaseScreenProps) {
  const navigationCategories = useNavigationCategories();

  return (
    <BaseScreenLayout
      title={APP_NAME_DRAWER}
      navigationCategories={navigationCategories}
      path={props.location.pathname}
      handleRouteChange={props.history.push}
    >
      <Switch>
        <Route exact path="/" component={DashboardRoute} />

        <Route path="/changePassword" component={ChangePasswordRoute} />

        <Route exact path="/users/create" component={CreateUserRoute} />
        <Route exact path="/users/edit/:id" component={EditUserRoute} />
        <Route path="/users/:group?" component={UsersRoute} />

        <Route path="/clients/createOnIndustrySoftware" component={CreateClientOnIndustrySoftwareRoute} />
        <Route path="/clients/create/:group?" component={CreateClientRoute} />
        <Route path="/clients/edit/:id/:group?" component={EditClientRoute} />
        <Route path="/clients/:group?" component={ClientsRoute} />

        <Route path="/area/manage/:id" component={ManageAreaRoute} />
        <Route path="/area/editArea/:clientNumber/:areaNumber" component={EditAreaRoute} />

        <Route path="/branches/:type" component={BranchesRoute} />
        <Route path="/branch/edit/:id/:type" component={EditBranchRoute} />
        <Route path="/branch/create/:type" component={CreateBranchRoute} />

        <Route path="/message/new" component={CreateMessageRoute} />
        <Route path="/message/:id?" component={ReadMessageRoute} />
        <Route path="/messages/" component={MessagesRoute} />

        <Route path="/assignments-calendar" component={CalendarRoute} />
        <Route path="/assignments/create" component={CreateAssignmentRoute} />
        <Route path="/assignments/:status?" component={AssignmentsRoute} />
        <Route path="/assignment/edit/:id?" component={EditAssignmentsRoute} />

        <Route exact path="/signatureforms" component={SignatureformsRoute} />
        <Route path="/signatureforms/create" component={CreateSignatureformsRoute} />
        <Route path="/signatureforms/assignment/:id" component={CreateSignatureAssignmentRoute} />
        <Route path="/signatureforms/:id" component={EditSignatureformsRoute} />

        <Route path="/rehaItems/:id" component={RehaItemsRoute} />

        <Route exact path="/pools/create/:type" component={CreatePoolRoute} />
        <Route exact path="/pools/edit/:id" component={UpdatePoolRoute} />
        <Route exact path="/pools/:id/assignments/:type/:status" component={PoolAssignmentsRoute} />
        <Route exact path="/pools/assignments/:status" component={PoolAssignmentsRoute} />
        <Route path="/pools/assignments/:id/work" component={PoolAssignmentDetailsRoute} />
        <Route path="/pools/internal" component={InternalPoolsRoute} />
        <Route path="/pools/b2b" component={B2BPoolsRoute} />

        <Route path="/classifications" component={ClassificationsRoute} />
        <Route path="/classification/create" component={CreateClassificationRoute} />
        <Route path="/classification/edit/:id" component={EditClassificationRoute} />

        <Route path="/adapter/:id" component={AdapterDetailRoute} />
        <Route path="/adapter" component={AdaptersRoute} />

        <Route path="/products/import" component={ImportProductsRoute} />
        <Route path="/products" component={ListProductsRoute} />

        <Route path="/b2c-recruitments" component={ListB2CRecruitmentsRoute} />

        <Route path="/cases/create" component={CreateCaseRoute} />
        <Route path="/cases/:type?" component={CasesRoute} />
        <Route path="/case/edit/:caseNumber" component={EditCaseRoute} />

        <Route path="/formular-center/edit/:id" component={EditFormularRoute} />
        <Route path="/formular-center/create" component={CreateFormularRoute} />
        <Route path="/formular-center/" component={FormularListRoute} />
      </Switch>
    </BaseScreenLayout>
  );
}

export default BaseScreen;
