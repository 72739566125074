import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "formik-material-ui";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import PasswordField from "template/presentation/components/form/PasswordField";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "80%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  field: {
    marginTop: theme.spacing(5),
  },
  submit: {
    margin: theme.spacing(8, 0, 2),
  },
  title: {
    fontWeight: "bold",
    textAlign: "left",
    marginTop: theme.spacing(5),
  },
}));

export interface LoginFormValues {
  username: string;
  password: string;
}

interface LoginFormProps {
  handleSubmit: (values: LoginFormValues) => Promise<void>;
  error: string | null;
}

const loginSchema = Yup.object().shape({
  username: Yup.string().email("Keine gültige E-Mail Adresse").required("Benötigt"),
  password: Yup.string().min(8, "Mindestens 8 Zeichen erforderlich").required("Benötigt"),
});

export default function LoginForm({ handleSubmit, error }: LoginFormProps) {
  const classes = useStyles();

  return (
    <div className={classes.form}>
      <Typography variant="h6" gutterBottom className={classes.title}>
        Anmelden
      </Typography>
      <Formik
        initialValues={{
          username: "",
          password: "",
        }}
        validationSchema={loginSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await handleSubmit(values);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <>
            <Form id="login-form">
              <Field
                component={TextField}
                name="username"
                type="email"
                label="E-Mail"
                fullWidth
                variant="outlined"
                margin="normal"
                autoComplete="email"
                className={classes.field}
              />
              <Field
                component={PasswordField}
                name="password"
                label="Passwort"
                fullWidth
                variant="outlined"
                margin="normal"
                autoComplete="current-password"
                className={classes.field}
              />
              {error && (
                <Alert data-testid="signin-error" severity="error">
                  {error}
                </Alert>
              )}
              <Button
                fullWidth
                data-testid="signin-button"
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isSubmitting}
                onClick={submitForm}
              >
                Login
              </Button>
              <input type="submit" hidden />
            </Form>
          </>
        )}
      </Formik>
      <Grid container>
        <Grid item xs>
          <Link href="#" to={"/resetPassword"} style={{ textDecoration: "none" }}>
            Passwort vergessen?
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}
