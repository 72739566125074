import { PoolAssignmentAnnotation } from "features/pools/domain/entities/PoolAssignment";
import React from "react";
import ContentSection from "template/presentation/components/content/ContentSection";
import LabeledValue from "template/presentation/components/content/LabeledValue";
import ValueGrid from "template/presentation/components/content/ValueGrid";

interface Props {
  annotation: PoolAssignmentAnnotation;
}

export const AnnotationSubSection: React.FC<Props> = ({ annotation }) => {
  if (annotation.changeOfAddress || annotation.changeOfName || annotation.deceased || annotation.seniorHome || annotation.custom) {
    return (
      <ContentSection label="Anmerkungen">
        <ValueGrid>
          {annotation.changeOfAddress && <LabeledValue label="Neue Adresse" value={annotation.newAddress} />}
          {annotation.changeOfName && <LabeledValue label="Namensänderung" value={annotation.newName} />}
          {annotation.deceased && <LabeledValue label="Verstorben?" value={annotation.deceased ? "Ja" : "-"} />}
          {annotation.seniorHome && <LabeledValue label="In Seniorenheim?" value={annotation.seniorHome ? "Ja" : "-"} />}
          {annotation.custom && <LabeledValue label="Zusatz" value={annotation.custom} />}
        </ValueGrid>
      </ContentSection>
    );
  } else {
    return null;
  }
};
