import React, { useCallback, useMemo, useState } from "react";
import { queryCache, useQuery } from "react-query";
import useService from "core/di/useService";
import { RouteComponentProps } from "react-router-dom";
import UIRoute from "core/presentation/components/UIRoute";
import AssignmentService from "../../domain/services/AssignmentService";
import CreateOrUpdateAssignmentForm, { CreateOrUpdateAssignmentFormValues } from "../components/CreateOrUpdateAssignmentForm";
import { ClientNotFound, ValidationMessageError } from "core/data/errors";
import CaseService from "features/cases/domain/services/CaseService";
import useUrlQueryParams from "core/presentation/hooks/useUrlQueryParams";
import { formatISO, parse } from "date-fns";
import UserService from "features/users/domain/services/UserService";
import { renderUserName } from "features/clients/presentation/utils";
import RehaItemService from "features/rehaItems/domain/services/RehaItemService";
import RehaItemConverter from "features/rehaItems/domain/converter/RehaItemConverter";
import SelectOption from "core/interfaces/SelectOption";

export interface CreateAssignmentRouteProps extends RouteComponentProps {}

const CreateAssignmentRoute = (props: CreateAssignmentRouteProps) => {
  const urlQueryParams = useUrlQueryParams();
  const caseNumber = urlQueryParams.get("caseNumber");
  const clientNumber = urlQueryParams.get("clientNumber");

  const assignmentService = useService(AssignmentService);
  const caseService = useService(CaseService);
  const userService = useService(UserService);
  const rehaItemService = useService(RehaItemService);

  const [error, setError] = useState<string | null>(null);
  const [rehaItems, setRehaItems] = useState<SelectOption[] | undefined>(undefined);
  const [caseNumberQuery, setCaseNumberQuery] = useState<string | null>("");

  const { data: queriedCaseOptions, isLoading: casesLoading } = useQuery(
    ["cases", { caseNumberQuery, pageSize: 10 }],
    () =>
      caseService.get({ caseNumberQuery: caseNumberQuery || undefined, pageSize: 10 }).then(({ data }) => {
        return data.map((kase) => ({
          value: kase.caseNumber,
          label: kase.caseNumber,
        }));
      }),
    { enabled: caseNumberQuery && caseNumberQuery.length >= 1 }
  );

  const { data: employeeOptionsForCase } = useQuery(["allAvailableEmployeesForAssignment"], () =>
    userService.getEmployees({ groups: ["employee"] }).then((users) =>
      users.data.map((user) => ({
        value: user.employeeNumber || "",
        label: `${renderUserName(user)} (${user.employeeNumber})`,
      }))
    )
  );
  const queryParameterCaseNumberWins = useCallback(
    () => caseNumber && caseNumber !== "" && (!caseNumberQuery || caseNumberQuery.length === 0),
    [caseNumber, caseNumberQuery]
  );

  const caseOptions = useMemo(() => {
    if (queryParameterCaseNumberWins()) {
      return [{ value: caseNumber || "", label: caseNumber || "" }];
    } else {
      return queriedCaseOptions;
    }
  }, [caseNumber, queriedCaseOptions, queryParameterCaseNumberWins]);

  const onSubmit = useCallback(
    (values: CreateOrUpdateAssignmentFormValues, setFieldError) => {
      return assignmentService
        .create({
          ...values,
          date: values.date && formatISO(parse(values.date, "yyyy-MM-dd", new Date())),
          timeFrom: values.timeFrom && formatISO(parse(values.timeFrom, "yyyy-MM-dd'T'HH:mm", new Date())),
          timeUntil: values.timeUntil && formatISO(parse(values.timeUntil, "yyyy-MM-dd'T'HH:mm", new Date())),
          // TODO rename employeeNumber attribute in values object
          employeeNumber: employeeOptionsForCase?.find((employee) => values.employeeNumber === employee.label)?.value,
          rehaItems: values?.rehaItems?.map((i) => i.value),
        })
        .then(async ({ id }) => {
          await queryCache.invalidateQueries("assignmentList");
          setError("");
          props.history.goBack();
        })
        .catch((e) => {
          if (e instanceof ValidationMessageError) {
            switch (e.code) {
              case "EMPLOYEE_NOT_FOUND":
                setFieldError("employeeNumber", "Unbekannten Mitarbeiter angegeben");
                return;
              case "CLIENT_NOT_FOUND":
                setFieldError("clientNumber", "Unbekannten Kunden angegeben");
                return;
              case "CASE_NOT_FOUND":
                setFieldError("caseNumber", "Unbekannten Vorgang angegeben");
                return;
              case "WRONG_DATE_RANGE":
                if (e.data?.field === "timeFrom") {
                  setFieldError("timeFrom", "Ungültige Startzeit angegeben");
                  return;
                }
                if (e.data?.field === "timeUntil") {
                  setFieldError("timeUntil", "Ungültige Endzeit angegeben");
                  return;
                }
                setFieldError("date", "Ein unbekannter Fehler beim Validieren des Datums ist aufgetreten.");
                return;
              default:
                setError(`Ein unbekannter Fehler ist aufgetreten. Code: ${e.code}`);
                return;
            }
          } else {
            setError("Ein unbekannter Fehler ist aufgetreten");
          }
        });
    },
    [assignmentService, props.history, employeeOptionsForCase]
  );

  const onLoadRehaItems = useCallback(
    async (clientNumber: string) => {
      try {
        const rehaItems = await rehaItemService.getByClientNumber(clientNumber);
        setRehaItems(RehaItemConverter.fromEntityToSelectOptions(rehaItems));
        setError("");
      } catch (e) {
        if (e instanceof ClientNotFound) {
          setError("Fehler beim Laden der Rehamittel. Kunde existiert nicht.");
        } else {
          setError("Ein unbekannter Fehler ist aufgetreten");
        }
      }
    },
    [rehaItemService]
  );

  return (
    <UIRoute {...props} title={"Neuen Auftrag erstellen"}>
      <div data-testid="assignment-form-create">
        <CreateOrUpdateAssignmentForm
          error={error}
          onSubmit={onSubmit}
          statusOptions={assignmentService.getStatusOptions()}
          typeOptions={assignmentService.getTypeOptions()}
          caseNumber={caseNumber || ""}
          clientNumber={clientNumber || ""}
          setCaseNumberQuery={setCaseNumberQuery}
          caseOptions={caseOptions || []}
          caseOptionsLoading={casesLoading}
          employeeOptions={employeeOptionsForCase || []}
          onLoadRehaItems={onLoadRehaItems}
          rehaItems={rehaItems}
        />
      </div>
    </UIRoute>
  );
};

export default CreateAssignmentRoute;
