import BranchConverter from "features/branches/domain/converters/BranchConverter";
import ClassificationConverter from "features/classifications/domain/converters/ClassificationConverter";
import ClientConverter from "features/clients/domain/converter/ClientConverter";
import UserConverter from "features/users/domain/converter/UserConverter";
import CaseCreateOrUpdateModel from "../data/CaseCreateOrUpdateModel";
import CaseReponseModel, { CaseHistoryEntryResponseModel } from "../data/CaseModel";
import Case, { CaseHistoryEntry } from "../domain/entities/Case";
import { CreateOrUpdateCaseFormValues } from "../presentation/components/CreateOrUpdateCaseForm";

export default class CaseConverter {
  public static fromResponseModelToEntity(model: CaseReponseModel): Case {
    return {
      id: model._id,
      caseNumber: model.caseNumber,
      client: model.client ? ClientConverter.fromResponseModelToClient(model.client) : undefined,
      date: model.date ? new Date(model.date) : undefined,
      subject: model.subject ? model.subject : model.description ? model.description : "",
      agreement: model.agreement,
      branch: model.branch && BranchConverter.fromResponseModelToEntity(model.branch),
      classification: model.classification && ClassificationConverter.fromResponseModelToEntity(model.classification),
      status: model.status,
      history: model.history.map((entry) => CaseConverter.fromHistoryEntryResponseModelToEntity(entry)),
      appointmentFrom: model.appointmentFrom ? new Date(model.appointmentFrom) : undefined,
      appointmentUntil: model.appointmentUntil ? new Date(model.appointmentUntil) : undefined,
      employee: model.employee ? UserConverter.fromModelToEntity(model.employee) : undefined,
      prescribers: model.prescribers,
    };
  }

  public static fromCreateOrUpdateFormToModel(formInput: CreateOrUpdateCaseFormValues): CaseCreateOrUpdateModel {
    return {
      clientNumber: formInput.clientNumber ? formInput.clientNumber : undefined,
      subject: formInput.subject,
      agreement: formInput.agreement,
      branchNumber: formInput.branchNumber,
      classificationNumber: formInput.classificationNumber,
      appointmentFrom: formInput.appointmentFrom,
      appointmentUntil: formInput.appointmentUntil,
    };
  }

  public static fromHistoryEntryResponseModelToEntity(model: CaseHistoryEntryResponseModel): CaseHistoryEntry {
    return {
      ...model,
      date: new Date(model.date),
    };
  }
}
