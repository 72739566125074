import React, { useCallback, useState } from "react";
import { useQuery } from "react-query";
import useService from "core/di/useService";
import UserService from "../../domain/services/UserService";
import UsersTable from "../components/UsersTable";
import { RouteComponentProps, useParams } from "react-router-dom";
import UsersSubHeader from "../components/UsersSubHeader";
import UIRoute from "core/presentation/components/UIRoute";
import { usePageAndPageSizeAndQueryFromRouteProps } from "core/utils";

export interface UsersRouteProps extends RouteComponentProps {}

export default function UsersRoute(props: UsersRouteProps) {
  const { page, pageSize, searchQuery } = usePageAndPageSizeAndQueryFromRouteProps(props);
  const [sortedBy, setSortedBy] = useState<{ field: string; order: "asc" | "desc" }>({ field: "lastname", order: "asc" });
  const { group } = useParams<any>();

  const userService = useService(UserService);

  let queryUri: string;

  if (group) {
    queryUri = `/users/${group}?page=`;
  } else {
    queryUri = `/users?page=`;
  }

  const onHandleQuery = useCallback(
    (page, pageSize, searchQuery) => {
      let query = queryUri + page;
      if (pageSize) {
        query += "&page_size=" + pageSize;
      }
      if (searchQuery) {
        query += "&searchQuery=" + searchQuery;
      }
      props.history.push(query);
    },
    [props.history, queryUri]
  );

  const onSortChange = useCallback((sortBy: string, sortOrder: "asc" | "desc") => {
    setSortedBy({ field: sortBy, order: sortOrder });
  }, []);

  const { data, isLoading, refetch, isFetching } = useQuery(["usersList", { searchQuery, page, pageSize, group, sortedBy }], () =>
    userService.get(searchQuery, group, page, pageSize, sortedBy)
  );

  return (
    <UIRoute
      {...props}
      title="Benutzer"
      renderSubHeader={() => <UsersSubHeader path={props.location.pathname} onTabChange={props.history.push} />}
    >
      <UsersTable
        group={group}
        users={data?.data || null}
        isLoading={isLoading || isFetching}
        onReload={refetch}
        searchQuery={searchQuery}
        onSearch={(searchValue) => {
          onHandleQuery(1, pageSize, searchValue);
        }}
        onAdd={() => {
          props.history.push(`/users/create?group=${group ?? ""}`);
        }}
        page={page}
        pageSize={pageSize}
        totalCount={data?.totalCount}
        onPageChange={(newPage: number) => {
          onHandleQuery(newPage, pageSize, searchQuery);
        }}
        onPageSizeChange={(newPageSize: number) => {
          onHandleQuery(page, newPageSize, searchQuery);
        }}
        sortedBy={sortedBy}
        onSortChange={onSortChange}
      />
    </UIRoute>
  );
}
