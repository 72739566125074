import { makeStyles } from "@material-ui/core";
import React from "react";
import DeformedEgg from "./components/DeformedEgg";
import SmartphoneWithApp from "./components/SmartphoneWithApp";
import WebUI from "./components/WebUI";

const useStyles = makeStyles((theme) => ({
  egg: {
    position: "absolute",
    top: "5%",
  },
  webUI: {
    position: "absolute",
    top: "20%",
  },
  smartphone: {
    position: "absolute",
    right: "3%",
    top: "25%",
  },
}));

interface Props {
  className?: string;
}

const Showcase: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <div className={props.className ? props.className : ""}>
      <DeformedEgg className={classes.egg} />
      <WebUI className={classes.webUI} />
      <SmartphoneWithApp className={classes.smartphone} />
    </div>
  );
};

export default Showcase;
