import React, { useCallback, useMemo, useState } from "react";
import { RouteComponentProps, useParams } from "react-router-dom";
import { useQuery, useQueryCache } from "react-query";
import useService from "core/di/useService";
import UIRoute from "core/presentation/components/UIRoute";
import BranchService from "../../domain/services/BranchService";
import CreateOrUpdateBranchForm, { CreateOrUpdateBranchFormValues } from "../components/CreateOrUpdateBranchForm";
import { ValidationMessageError } from "core/data/errors";
import { AllocatedArea } from "features/branches/domain/entities/BranchEntity";

import B2BService from "features/b2b/domain/services/B2BService";

export interface CreateBranchRouteProps extends RouteComponentProps {}

export default function CreateBranchRoute(props: CreateBranchRouteProps) {
  const { type } = useParams<{ type: "internal" | "b2b" }>();

  const branchService = useService(BranchService);
  const b2bService = useService(B2BService);

  const queryCache = useQueryCache();

  const [error, setError] = useState<string | null>(null);
  const [selectedB2BClient, setSelectedB2BClient] = useState<string | undefined>();
  const { data: b2bPartnerOptions } = useQuery("b2bPartner", () => b2bService.getB2BPartners(), { enabled: type === "b2b" });

  const { data: defaultBranch } = useQuery(
    ["defaultBranch", { type, selectedB2BClient }],
    () => (type === "internal" ? branchService.getDefault() : branchService.getDefault(selectedB2BClient)),
    { enabled: type === "internal" || (type === "b2b" && selectedB2BClient) }
  );

  const b2bPartner = useMemo(() => {
    if (b2bPartnerOptions) {
      return b2bPartnerOptions.map((partner) => ({
        value: partner.id,
        label: `${partner.name} (${partner.b2bNumber}) [${partner.type === "Intermediary" ? "Vermittler" : "Verordner"}] `,
        id: partner.id,
      }));
    }
    return [];
  }, [b2bPartnerOptions]);

  const onZipSearch = useCallback(
    (zip: string, allocatedAreas: AllocatedArea[]): Promise<void | string[]> => {
      return branchService
        .getCityByZip(zip, allocatedAreas)
        .then((r) => {
          setError(null);
          return r;
        })
        .catch((e) => {
          if (e instanceof ValidationMessageError) {
            switch (e.code) {
              case "NO_CITY_FOR_ZIPCODE":
                setError("Zur angegebenen Postleitzahl konnte keine Stadt gefunden werden.");
                return;
              case "NOT_WELL_FORMATTED_RANGE":
                setError("Der angegebene Postleitzahlenbereich ist nicht passend.");
                return;
              case "ALREADY_ALLOCATED_TO_THIS_BRANCH":
                setError("Diese Postleitzahl, oder Postleitzahlenbereich ist bereits dieser Niederlassung zugeordnet.");
                return;
              default:
                setError(`Ein unbekannter Fehler ist aufgetreten. Code: ${e.code || "VMSG400"}`);
                return;
            }
          } else {
            setError(`Ein unbekannter Fehler ist aufgetreten`);
          }
        });
    },
    [branchService]
  );

  const onSubmit = useCallback(
    (values: CreateOrUpdateBranchFormValues) => {
      return branchService
        .create(values)
        .then(() => {
          setError(null);
        })
        .then(() => {
          queryCache.invalidateQueries("branchesList");
        })
        .then(() => {
          props.history.goBack();
        })
        .catch((e) => {
          if (e instanceof ValidationMessageError) {
            switch (e.code) {
              case "DUPLICATE_BRANCH_NUMBER":
                setError("Die Niederlassungsnummer ist bereits Vorhanden.");
                return;
              default:
                setError(`Ein unbekannter Fehler ist aufgetreten. Code: ${e.code || "VMSG400"}`);
                return;
            }
          } else {
            setError(`Ein unbekannter Fehler ist aufgetreten`);
          }
        });
    },
    [props.history, queryCache, branchService]
  );
  return (
    <UIRoute {...props} title="Niederlassung erstellen">
      <CreateOrUpdateBranchForm
        b2bPartner={b2bPartner}
        setSelectedB2BClient={(client) => setSelectedB2BClient(client)}
        selectedB2BClient={selectedB2BClient}
        isB2B={type === "b2b"}
        error={error}
        onSubmit={onSubmit}
        defaultBranch={defaultBranch}
        onZipSearch={onZipSearch}
      />
    </UIRoute>
  );
}
