import { ValidationMessageError } from "core/data/errors";
import { useService } from "core/di";
import { useCurrentUserInGroup } from "features/login/presentation/hooks/useUser";
import PoolAssignment from "features/pools/domain/entities/PoolAssignment";
import {
  ClassifyModel,
  ChangeBranchModel,
  HandOverModel,
  SelectOption,
  ChangePoolModel,
  TransferToB2BPartnerModel,
  TransferToInternalModel,
} from "features/pools/domain/entities/PoolEntity";
import PoolService from "features/pools/domain/services/PoolService";
import React, { useCallback } from "react";
import { queryCache } from "react-query";
import FormSection from "template/presentation/components/form/FormSection";
import ControlledAccordions from "template/presentation/components/menues/ControlledAccordions";
import { TransferToB2BPartnerSection } from "./TransferToB2BClientSection";
import { ChangeBranchSection } from "./ChangeBranchSection";
import { ChangePoolSection } from "./ChangePoolSection";
import { ClassificationSection } from "./ClassificationSection";
import { HandOverAssignmentSection } from "./HandOverAssignmentSection";
import { TransferToInternalSection } from "./TransferToInternalSection";

interface Props {
  assignment: PoolAssignment;
  userOptions: SelectOption[] | undefined;
  classificationOptions: SelectOption[] | undefined;
  branchOptions: SelectOption[] | undefined;
  onActionSuccessful: () => void;
  setActionError: (error: string) => void;
  b2bPoolOptions?: SelectOption[];
  b2bPartnerOptions?: SelectOption[];
}

export const ActionSection: React.FC<Props> = ({
  assignment,
  userOptions,
  classificationOptions,
  branchOptions,
  onActionSuccessful,
  setActionError,
  b2bPoolOptions,
  b2bPartnerOptions,
}) => {
  const poolService = useService(PoolService);
  const [isAdmin] = useCurrentUserInGroup("admin");
  const [isBackoffice] = useCurrentUserInGroup("backoffice");
  const [isB2BBackoffice] = useCurrentUserInGroup("b2bBackoffice");

  const onClassify = useCallback(
    (values: ClassifyModel) => {
      return poolService
        .setClassification(values)
        .then(() => queryCache.invalidateQueries(["/pools/assignments/", values.assignmentId]))
        .then(() => onActionSuccessful())
        .catch((e) => {
          if (e instanceof ValidationMessageError) {
            switch (e.code) {
              case "ASSIGNMENT_DOES_NOT_EXIST":
                setActionError(`Vorgang mit ID ${values.assignmentId} existiert nicht`);
                return;
              case "NOTFOUND_CLASSIFICATION":
                setActionError("Die ausgewählte Klassifizierung konnte nicht gefunden werden.");
                return;
              case "NOTFOUND_POOL_FOR_CLASSIFICATION":
                setActionError("Für die ausgewählte Klassifizierung ist kein Pool vorhanden.");
                return;
              default:
                setActionError(`Ein unbekannter Fehler ist aufgetreten. Code: ${e.code || "VMSG400"}`);
                return;
            }
          }
        });
    },
    [onActionSuccessful, poolService, setActionError]
  );

  const onChangeBranch = useCallback(
    (model: ChangeBranchModel) => {
      return poolService
        .changeBranch(model)
        .then(() => queryCache.invalidateQueries(["/pools/assignments/", model.assignmentId]))
        .then(() => onActionSuccessful())
        .catch((e) => {
          if (e instanceof ValidationMessageError) {
            switch (e.code) {
              case "BRANCH_DOES_NOT_EXIST":
                setActionError(`Die Niederlassung mit dem Code ${model.branchCode} existiert nicht.`);
                return;
              case "ASSIGNMENT_DOES_NOT_EXIST":
                setActionError(`Der Vorgang mit ID ${model.assignmentId} existiert nicht.`);
                return;
              case "ASSIGNMENT_ALREADY_IN_BRANCH":
                setActionError(
                  `Der Vorgang mit der ID ${model.assignmentId} ist bereits der Niederlassung mit dem Code ${model.branchCode} zugeordnet.`
                );
                return;
              default:
                setActionError(`Ein unbekannter Fehler ist aufgetreten. Code: ${e.code || "VMSG400"}`);
                return;
            }
          }
        });
    },
    [onActionSuccessful, poolService, setActionError]
  );

  const onHandOver = useCallback(
    (model: HandOverModel) => {
      poolService
        .handOverAssignment(model)
        .then(() => queryCache.invalidateQueries(["/pools/assignments/", model.assignmentId]))
        .then(() => onActionSuccessful())
        .catch((e) => {
          if (e instanceof ValidationMessageError) {
            switch (e.code) {
              case "ASSIGNMENT_DOES_NOT_EXIST":
                setActionError(`Vorgang mit ID ${model.assignmentId} existiert nicht`);
                return;
              case "FOREIGN_ASSIGNMENT":
                setActionError(`Vorgang mit ID ${model.assignmentId} wird nicht von Ihnen bearbeitet.`);
                return;
              case "NOTFOUND_USER":
                setActionError(`Mitarbeiter mit der Nummer ${model.employeeNumber} konnte nicht gefunden werden.`);
                return;
              case "ALREADY_CLAIMED_BY_EMPLOYEE":
                setActionError(
                  `Der Vorgang mit ID ${model.assignmentId} wird bereits vom Mitarbeiter mit der Nummer ${model.employeeNumber} bearbeitet.`
                );
                return;
              default:
                setActionError(`Ein unbekannter Fehler ist aufgetreten. Code: ${e.code || "VMSG400"}`);
                return;
            }
          }
        });
    },
    [onActionSuccessful, poolService, setActionError]
  );

  const onRelease = useCallback(
    (assignmentId: string) => {
      poolService
        .releaseAssignment(assignmentId)
        .then(() => queryCache.invalidateQueries(["/pools/assignments/", assignmentId]))
        .then(() => onActionSuccessful())
        .catch((e) => {
          if (e instanceof ValidationMessageError) {
            switch (e.code) {
              case "ASSIGNMENT_DOES_NOT_EXIST":
                setActionError(`Vorgang mit ID ${assignmentId} existiert nicht`);
                return;
              case "FOREIGN_ASSIGNMENT":
                setActionError(`Vorgang mit ID ${assignmentId} wird nicht von Ihnen bearbeitet.`);
                return;
              default:
                setActionError(`Ein unbekannter Fehler ist aufgetreten. Code: ${e.code || "VMSG400"}`);
                return;
            }
          }
        });
    },
    [onActionSuccessful, poolService, setActionError]
  );

  const onChangePool = useCallback(
    (model: ChangePoolModel) => {
      return poolService
        .changePoolForAssignment(model)
        .then(() => queryCache.invalidateQueries(["/pools/assignments/", model.assignmentId]))
        .then(() => onActionSuccessful())
        .catch((e) => {
          if (e instanceof ValidationMessageError) {
            switch (e.code) {
              case "ASSIGNMENT_DOES_NOT_EXIST":
                setActionError(`Der Vorgang mit ID ${model.assignmentId} existiert nicht.`);
                return;
              default:
                setActionError(`Ein unbekannter Fehler ist aufgetreten. Code: ${e.code || "VMSG400"}`);
                return;
            }
          }
        });
    },
    [onActionSuccessful, poolService, setActionError]
  );

  const onAssignB2BPartner = useCallback(
    (model: TransferToB2BPartnerModel) => {
      return poolService
        .transferAssignmentToB2BPartner(model)
        .then(() => queryCache.invalidateQueries(["/pools/assignments/", model.assignmentId]))
        .then(() => onActionSuccessful())
        .catch((e) => {
          if (e instanceof ValidationMessageError) {
            switch (e.code) {
              case "ASSIGNMENT_DOES_NOT_EXIST":
                setActionError(`Der Vorgang mit ID ${model.assignmentId} existiert nicht.`);
                return;
              default:
                setActionError(`Ein unbekannter Fehler ist aufgetreten. Code: ${e.code || "VMSG400"}`);
                return;
            }
          } else {
            setActionError(
              `Der Auftrag konnte nicht übergeben werden. Stellen Sie sicher, dass der B2B-Partner eine Default-Niederlassung und einen Default-Pool hat.`
            );
            return;
          }
        });
    },
    [onActionSuccessful, poolService, setActionError]
  );

  const onTransferToInternal = useCallback(
    (model: TransferToInternalModel) => {
      return poolService
        .transferB2BAssignmentToInternal(model)
        .then(() => queryCache.invalidateQueries(["/pools/assignments/", model.assignmentId]))
        .then(() => onActionSuccessful())
        .catch((e) => {
          if (e instanceof ValidationMessageError) {
            switch (e.code) {
              case "ASSIGNMENT_DOES_NOT_EXIST":
                setActionError(`Der Vorgang mit ID ${model.assignmentId} existiert nicht.`);
                return;
              default:
                setActionError(`Ein unbekannter Fehler ist aufgetreten. Code: ${e.code || "VMSG400"}`);
                return;
            }
          }
        });
    },
    [onActionSuccessful, poolService, setActionError]
  );

  return (
    <FormSection label="Aktionen">
      {assignment.assignmentState.status !== "DONE" ? (
        <>
          <ControlledAccordions
            accordions={[
              isBackoffice || isAdmin
                ? {
                    key: "classify",
                    title: "Klassifizieren",
                    details: (
                      <ClassificationSection
                        assignment={assignment}
                        classificationOptions={classificationOptions}
                        onClassify={onClassify}
                      />
                    ),
                  }
                : null,
              isAdmin || isBackoffice || isB2BBackoffice
                ? {
                    key: "transferToB2BPartner",
                    title: "Einem B2B-Partner übergeben",
                    details: (
                      <TransferToB2BPartnerSection
                        assignment={assignment}
                        b2bPartnerOptions={b2bPartnerOptions}
                        onAssignB2BPartner={onAssignB2BPartner}
                      />
                    ),
                  }
                : null,
              isAdmin || isB2BBackoffice || isBackoffice
                ? {
                    key: "changeBranch",
                    title: "Anderer Niederlassung übergeben",
                    details: <ChangeBranchSection assignment={assignment} branchOptions={branchOptions} onChangeBranch={onChangeBranch} />,
                  }
                : null,
              isB2BBackoffice
                ? {
                    key: "changePool",
                    title: "Anderem Pool zuweisen",
                    details: <ChangePoolSection assignment={assignment} poolOptions={b2bPoolOptions} onChangePool={onChangePool} />,
                  }
                : null,
              isB2BBackoffice
                ? {
                    key: "transferToInternal",
                    title: "Dem Sanitätshaus übergeben",
                    details: <TransferToInternalSection assignment={assignment} onTransferToInternal={onTransferToInternal} />,
                  }
                : null,
              {
                key: "releaseAssignment",
                title: "Freigeben",
                details: (
                  <HandOverAssignmentSection
                    assignmentId={assignment.assignmentId}
                    canChangeEmployee={assignment.canChangeEmployee}
                    userOptions={userOptions}
                    onHandOver={onHandOver}
                    onRelease={onRelease}
                  />
                ),
              },
            ]}
          />
        </>
      ) : (
        <div>Keine Aktionen mehr möglich, der Vorgang wurde abgeschlossen.</div>
      )}
    </FormSection>
  );
};
