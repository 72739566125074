import React, { useCallback, useState } from "react";
import { useService } from "core/di";
import UIRoute from "core/presentation/components/UIRoute";
import { usePageAndPageSizeAndAnyQueryFromRouteProps } from "core/utils";
import FormularService from "features/formular-center/domain/services/FormularService";
import { useQuery } from "react-query";
import { RouteComponentProps } from "react-router-dom";
import FormularTable from "../components/FormularTable";
import { useDebounce } from "use-debounce";
import AssignmentService from "../../../assignments/domain/services/AssignmentService";
import SelectOption from "../../../../core/interfaces/SelectOption";
import UserService from "../../../users/domain/services/UserService";
import CaseService from "../../../cases/domain/services/CaseService";
import qs from "query-string";
import ClientService from "../../../clients/domain/services/ClientService";

export interface FormularListRouteProps extends RouteComponentProps {}

const FormularListRoute = (props: FormularListRouteProps) => {
  const formularService = useService(FormularService);
  const assignmentService = useService(AssignmentService);
  const caseService = useService(CaseService);
  const userService = useService(UserService);
  const clientService = useService(ClientService);
  const { page, pageSize = 1, searchQueries } = usePageAndPageSizeAndAnyQueryFromRouteProps(props);

  const [assignmentQuery, setAssignmentQuery] = useState("");
  const [assignmentQueryLoading, setAssignmentQueryLoading] = useState(false);
  const [assignmentQueryDebounced] = useDebounce(assignmentQuery, 500);
  const { data: assignmentsSearched } = useQuery(["assignments", assignmentQueryDebounced], () =>
    assignmentService.getTypeAheadOptions(assignmentQueryDebounced).then(
      (assignments): Array<SelectOption> => {
        setAssignmentQueryLoading(false);
        return assignments.map((assignment) => ({
          label: assignment.assignmentNumber,
          value: assignment.id,
          number: assignment.assignmentNumber,
        }));
      }
    )
  );

  const [caseQuery, setCaseQuery] = useState("");
  const [caseQueryLoading, setCaseQueryLoading] = useState(false);
  const [caseQueryDebounced] = useDebounce(caseQuery, 500);
  const { data: casesSearched } = useQuery(["cases", caseQueryDebounced], () =>
    caseService.getTypeAheadOptions(caseQueryDebounced).then(
      (cases): Array<SelectOption> => {
        setCaseQueryLoading(false);
        return cases.map((kase) => ({
          label: kase.caseNumber,
          value: kase.id,
          number: kase.caseNumber,
        }));
      }
    )
  );

  const [customerQuery, setCustomerQuery] = useState("");
  const [customerQueryLoading, setCustomerQueryLoading] = useState(false);
  const [customerQueryDebounced] = useDebounce(customerQuery, 500);
  const { data: customersSearched } = useQuery(["customers", customerQueryDebounced], () =>
    clientService.getTypeAheadOptions(customerQueryDebounced).then(
      (clients): Array<SelectOption> => {
        setCustomerQueryLoading(false);
        return clients.map((client) => ({
          label: [client.firstname, client.lastname, client.clientNumber && `(${client.clientNumber})`].join(" "),
          value: client.id,
          number: client.clientNumber,
        }));
      }
    )
  );

  const onHandleQuery = useCallback(
    (page, pageSize, searchQueries) => {
      const cleanedSearchQuery: any = Object.fromEntries(Object.entries(searchQueries ?? {}).filter(([, value]) => !!value));
      props.history.push({ search: qs.stringify(cleanedSearchQuery) });
    },
    [props.history]
  );

  const { data: formularList, refetch, isLoading } = useQuery(["formularList", searchQueries], () => formularService.get(searchQueries));

  return (
    <UIRoute {...props} title="Formular Center">
      <div>
        <FormularTable
          onSearchSubmit={(values) => onHandleQuery(1, pageSize, values)}
          filterItems={[
            {
              name: "Kunde",
              searchField: "client",
              setTypeAheadQuery: (value) => {
                setCustomerQueryLoading(true);
                setCustomerQuery(value);
              },
              typeAheadOptions: customersSearched ?? [],
              typeAheadTestProperty: "number",
              isLoading: customerQueryLoading,
            },
            {
              name: "Auftrag",
              searchField: "relatedAssignment",
              setTypeAheadQuery: (value) => {
                setAssignmentQueryLoading(true);
                setAssignmentQuery(value);
              },
              typeAheadOptions: assignmentsSearched ?? [],
              typeAheadTestProperty: "number",
              isLoading: assignmentQueryLoading,
            },
            {
              name: "Vorgang",
              searchField: "relatedCase",
              setTypeAheadQuery: (value) => {
                setCaseQueryLoading(true);
                setCaseQuery(value);
              },
              typeAheadOptions: casesSearched ?? [],
              typeAheadTestProperty: "number",
              isLoading: caseQueryLoading,
            },
          ]}
          onSearch={() => {}}
          addButtonText="Neues Formular"
          onAdd={() => {
            props.history.push("/formular-center/create");
          }}
          formularList={formularList?.data}
          onReload={refetch}
          isLoading={isLoading}
          page={page}
          pageSize={pageSize}
          totalCount={formularList?.totalCount}
          onPageChange={(newPage: number) => {}}
          onPageSizeChange={(newPageSize: number) => {}}
        />
      </div>
    </UIRoute>
  );
};
export default FormularListRoute;
