import React, { useMemo } from "react";
import { Formik } from "formik";
import PoolAssignment from "features/pools/domain/entities/PoolAssignment";
import { ClassifyModel, SelectOption } from "features/pools/domain/entities/PoolEntity";
import { Button, makeStyles } from "@material-ui/core";
import LabeledValue from "template/presentation/components/content/LabeledValue";
import { useCurrentUserInGroup } from "features/login/presentation/hooks/useUser";
import { ClassificationResponseModel } from "features/classifications/data/ClassificationResponseModel";
import ControlledAutocomplete from "template/presentation/components/form/ControlledAutocomplete";
import * as Yup from "yup";

interface Props {
  assignment: PoolAssignment;
  classificationOptions: SelectOption[] | undefined;
  onClassify: (values: ClassifyModel) => Promise<void>;
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    width: "50%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "200%",
    justifyContent: "center",
  },
}));

const validationSchema = Yup.object().shape({
  classificationNumber: Yup.string().required("Sie müssen eine Option auswählen"),
});

export const ClassificationSection: React.FC<Props> = ({ assignment, classificationOptions, onClassify }) => {
  const classes = useStyles();
  const [isAdmin] = useCurrentUserInGroup("admin");
  const allowClassify = (
    isAdmin: boolean,
    classification: ClassificationResponseModel | undefined,
    canChangeClassification: boolean
  ): boolean => {
    if (isAdmin) {
      return true;
    } else if (canChangeClassification) {
      return true;
    } else if (!classification) {
      return true;
    }
    return false;
  };

  const initValues = useMemo(() => {
    if (assignment.classification) {
      return {
        classificationNumber: assignment.classification.number,
        assignmentId: assignment.assignmentId,
      };
    } else {
      return {
        classificationNumber: "",
        assignmentId: assignment.assignmentId,
      };
    }
  }, [assignment]);

  let headline: string = " ";
  let content: string = " ";

  if (isAdmin || !assignment.classification || assignment.canChangeClassification) {
    headline = "Klassifizieren";
  } else if (assignment.classification && !assignment.canChangeClassification) {
    headline = "Klassifizierung";
    content = `${assignment.classification.name} (${assignment.classification.number})`;
  }

  return (
    <div>
      {headline === "Klassifizierung" && <LabeledValue label={headline} value={content} />}
      {allowClassify(isAdmin, assignment.classification, assignment.canChangeClassification) ? (
        <Formik
          initialValues={initValues}
          onSubmit={async (values: ClassifyModel) => {
            await onClassify(values);
          }}
          validationSchema={validationSchema}
        >
          {({ submitForm, handleChange, values }) => {
            return (
              <>
                {classificationOptions ? (
                  <div className={classes.container}>
                    <ControlledAutocomplete
                      data-testid="field-classifyassignment"
                      label="Klassifizierung"
                      name="classificationNumber"
                      initialValue={values.classificationNumber}
                      options={classificationOptions}
                      onSelectOption={handleChange("classificationNumber")}
                    />
                    <Button
                      className={classes.button}
                      color="primary"
                      variant="contained"
                      data-testid="button-classify"
                      onClick={submitForm}
                    >
                      Klassifizierung ändern
                    </Button>
                  </div>
                ) : (
                  <div data-testid="no-classify">Es kann keine Klassifizierung vorgenommen werden.</div>
                )}
              </>
            );
          }}
        </Formik>
      ) : (
        <div data-testid="no-classify" />
      )}
    </div>
  );
};
