import { PoolAssignmentReportRepair } from "features/pools/domain/entities/PoolAssignment";
import { RehaItem } from "features/rehaItems/domain/entities/RehaItem";
import React, { useMemo } from "react";
import ContentContainer from "template/presentation/components/content/ContentContainer";
import ContentSection from "template/presentation/components/content/ContentSection";
import LabeledValue from "template/presentation/components/content/LabeledValue";
import ValueGrid from "template/presentation/components/content/ValueGrid";
import { RehaItemsPoolView } from "features/pools/presentation/components/RehaItemsPoolView";
import { AnnotationSubSection } from "./AnnotationSubSection";

interface Props {
  report: PoolAssignmentReportRepair;
  rehaItems?: RehaItem[];
}

export const RepairInfoSection: React.FC<Props> = ({ report, rehaItems }) => {
  const status = useMemo(() => {
    switch (report.result) {
      case "delivered":
        return "Erfolgreich durchgeführt";
      case "notEncountered":
        return "Niemanden angetroffen";
      case "unfeasible":
        return "Nicht durchführbar";
      default:
        return "Unbekannter Status";
    }
  }, [report.result]);

  return (
    <ContentSection label="Details">
      <ContentContainer>{report.annotation && <AnnotationSubSection annotation={report.annotation} />}</ContentContainer>
      <ValueGrid>
        <LabeledValue data-testid="status" label="Status" value={status} />
        <LabeledValue data-testid="comment" label="Kommentar" value={report.comment} />
      </ValueGrid>
      <div style={{ marginBottom: 5 }}></div>
      <RehaItemsPoolView rehaItems={rehaItems} />
    </ContentSection>
  );
};
