import React, { useCallback, useMemo, useState } from "react";
import { RouteComponentProps, useParams } from "react-router-dom";
import { useQuery, useQueryCache } from "react-query";
import useService from "core/di/useService";
import UIRoute from "core/presentation/components/UIRoute";
import CreateOrUpdatePoolForm, { CreateOrUpdatePoolFormValues } from "../components/CreateOrUpdatePoolForm";
import { convertAxiosErrorToValidationError, ValidationMessageError } from "core/data/errors";
import PoolService from "features/pools/domain/services/PoolService";
import BranchService from "features/branches/domain/services/BranchService";
import ClassificationService from "features/classifications/domain/services/ClassificationService";
import UserService from "features/users/domain/services/UserService";
import { InternalPoolsStore } from "../stores";
import B2BService from "features/b2b/domain/services/B2BService";

export interface CreatePoolRouteProps extends RouteComponentProps {}

export default function CreatePoolRoute(props: CreatePoolRouteProps) {
  const { type } = useParams<{ type: "internal" | "b2b" }>();
  const isB2B = useMemo(() => type === "b2b", [type]);

  const poolService = useService(PoolService);
  const branchService = useService(BranchService);
  const classificationService = useService(ClassificationService);
  const userService = useService(UserService);
  const b2bService = useService(B2BService);

  const queryCache = useQueryCache();

  const [error, setError] = useState<string | null>(null);
  const [selectedBranch, setSelectedBranch] = useState<string>("");
  const [selectedB2BPartner, setSelectedB2BPartner] = useState<string>("");

  const { data: defaultPool } = useQuery(
    ["/defaultPoolForBranch/" + selectedBranch, selectedBranch],
    () => poolService.getDefaultForBranch(selectedBranch),
    {
      enabled: selectedBranch !== "",
    }
  );

  const { data: branches } = useQuery(
    [isB2B ? `allB2BBranches/${selectedB2BPartner}` : "allInternalBranches"],
    () => (isB2B ? branchService.get(undefined, true, selectedB2BPartner) : branchService.get()),
    { enabled: !isB2B || (isB2B && selectedB2BPartner !== "") }
  );
  const { data: employees } = useQuery(
    ["allBackofficeEmployees"],
    () => userService.get(undefined, isB2B ? "b2bBackoffice" : "backoffice", 0, 0, { field: "lastname", order: "asc" }, selectedBranch),
    { enabled: selectedBranch !== "" }
  );
  const { data: fieldstaffEmployees } = useQuery(
    ["allAvailableFieldstaffEmployees", selectedBranch],
    () => poolService.getAvailableFieldstaffEmployeesFromBranch(selectedBranch),
    { enabled: !isB2B && selectedBranch !== "" }
  );
  const { data: classifications } = useQuery(
    ["allAvailableClassifications", selectedBranch],
    () => classificationService.getAvailableNotInBranch(selectedBranch),
    { enabled: !isB2B && selectedBranch !== "" }
  );

  const { data: b2bPartner } = useQuery("b2bPartnerWithBranches", () => b2bService.getB2BPartnerWithBranches(), { enabled: isB2B });

  const onSubmit = useCallback(
    (values: CreateOrUpdatePoolFormValues) => {
      return poolService
        .create(values)
        .then(() => {
          if (!isB2B) {
            InternalPoolsStore.update((s) => {
              s.currentBranch = selectedBranch;
            });
          }
          return setError(null);
        })
        .then(() => {
          queryCache.invalidateQueries(["/pools/default-for-branch/" + values.branch]);
          return queryCache.invalidateQueries(["/pools?branch=" + values.branch]);
        })
        .then(() => {
          return props.history.goBack();
        })
        .catch((e) => {
          const err = convertAxiosErrorToValidationError(e);
          if (err instanceof ValidationMessageError) {
            switch (err.code) {
              case "NOTFOUND_BRANCH":
                setError(`Angegebene Niederlassung existiert nicht (${err.message})`);
                return;
              case "NOTFOUND_CLASSIFICATION":
                setError(`Angegebene Klassifizierung existiert nicht (${err.message})`);
                return;
              case "NOTFOUND_USER":
                setError(`Angegebener Mitarbeiter existiert nicht (${err.message})`);
                return;
              case "DUPLICATE_NAME":
                setError(`Ein Pool mit der Bezeichnung "${values.name}" existiert bereits`);
                return;
              default:
                setError(`Ein unbekannter Fehler ist aufgetreten (${err.message})`);
                return;
            }
          } else {
            setError(`Ein unbekannter Fehler ist aufgetreten (${err.message})`);
          }
        });
    },
    [props.history, queryCache, poolService, selectedBranch, isB2B]
  );

  return (
    <UIRoute {...props} title="Neuen Pool erstellen">
      <CreateOrUpdatePoolForm
        b2bPartner={b2bPartner || []}
        setSelectedB2BPartner={setSelectedB2BPartner}
        branches={branches || []}
        setSelectedBranch={setSelectedBranch}
        classifications={classifications || []}
        employees={employees?.data || []}
        fieldstaffEmployees={fieldstaffEmployees || []}
        defaultPool={defaultPool}
        error={error}
        onSubmit={onSubmit}
        isB2B={isB2B}
      />
    </UIRoute>
  );
}
