import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import useService from "core/di/useService";
import { useQuery } from "react-query";
import FormularService from "features/formular-center/domain/services/FormularService";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Alert, AlertTitle } from "@material-ui/lab";

interface ViewAndDownloadDialogProps {
  open: boolean;
  onDismiss: (open: boolean) => void;
  formularId?: string;
}

const ViewAndDownloadDialog: React.FC<ViewAndDownloadDialogProps> = ({ open, formularId, onDismiss }) => {
  const formularService = useService(FormularService);

  const { data: fileData, isLoading, error } = useQuery(["fileData", formularId], () => formularService.getFile(formularId!), {
    enabled: formularId !== undefined && open === true,
  });

  return (
    <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{"Druckansicht"}</DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            Darstellungsfehler. Dienst nicht erreichbar.
          </Alert>
        )}
        {isLoading && (
          <div style={{ display: "flex" }}>
            <CircularProgress />
          </div>
        )}
        {fileData && (
          <div data-testid={"Lieferschein"} style={{ display: "flex" }}>
            <iframe
              title={"Lieferschein"}
              key={`Lieferschein_${formularId}`}
              src={`data:application/pdf;base64,${fileData}`}
              id="pdf"
              width="1200px"
              height="800px"
              style={{ overflow: "auto" }}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onDismiss(!open)}>Schließen</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewAndDownloadDialog;
