import React, { useCallback } from "react";
import { useQuery, useQueryCache } from "react-query";
import useService from "core/di/useService";
import SignatureformsService from "../../domain/services/SignatureformsService";
import SignatureformsTable from "../components/SignatureformsTable";
import { RouteComponentProps } from "react-router-dom";
import UIRoute from "core/presentation/components/UIRoute";

export interface SignatureformsRouteProps extends RouteComponentProps {}

export default function SignatureformsRoute(props: SignatureformsRouteProps) {
  const signatureformsService = useService(SignatureformsService);
  const queryCache = useQueryCache();

  const { data, isLoading, refetch, isFetching } = useQuery(["signatureformsList"], () => signatureformsService.getSignatureformsList());

  const onDeleteSignatureform = useCallback(
    (id: string) => {
      signatureformsService.delete(id).then(() => queryCache.invalidateQueries(["signatureformsList"]));
    },
    [signatureformsService, queryCache]
  );

  return (
    <UIRoute {...props} title="Unterschriftenformulare">
      <SignatureformsTable
        signatureforms={data || null}
        isLoading={isLoading || isFetching}
        onReload={refetch}
        onAdd={() => {
          props.history.push("/signatureforms/create");
        }}
        onDelete={onDeleteSignatureform}
      />
    </UIRoute>
  );
}
