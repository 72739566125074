import React, { useMemo } from "react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import { makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import FormSection from "template/presentation/components/form/FormSection";
import FormContainer from "template/presentation/components/form/FormContainer";
import SelectField from "template/presentation/components/form/SelectField";
import MultiSelectField from "template/presentation/components/form/MutiSelectField";
import FormSubmitButton from "template/presentation/components/form/FormSubmitButton";
import { DefaultPool, Pool } from "features/pools/domain/entities/PoolEntity";
import { Branch } from "features/branches/domain/entities/BranchEntity";
import User from "features/users/domain/entities/UserEntity";
import { Classification } from "features/classifications/domain/entities/ClassificationEntity";
import PoolService from "features/pools/domain/services/PoolService";
import { useService } from "core/di";
import { B2BPartner } from "features/clients/domain/entities/Client";

export interface CreateOrUpdatePoolFormValues {
  name: string;
  branch: { value: string; label: string } | null | string;
  classifications: Array<{ value: string; label: string }>;
  employees: Array<{ value: string; label: string }>;
  fieldstaffEmployees: Array<{ value: string; label: string }>;
  default: boolean;
  canChangeClassification: boolean;
  canChangeEmployee: boolean;
  isB2B: boolean;
  b2bClient?: string;
}

const createOrUpdateUserSchema = Yup.object().shape({
  name: Yup.string().required("Benötigt").min(1, "Mindestens ein Zeichen"),
  branch: Yup.mixed().required("Benötigt"),
  classifications: Yup.array(),
  employees: Yup.array(),
});

export interface InstanceFormProps {
  onSubmit: (values: CreateOrUpdatePoolFormValues) => Promise<void>;
  error: string | null;
  branches: Branch[];
  setSelectedBranch: (branchId: string) => void;
  classifications: Classification[];
  employees: User[];
  fieldstaffEmployees: User[];
  pool?: Pool;
  defaultPool?: DefaultPool;
  isB2B: boolean;
  b2bPartner?: B2BPartner[];
  setSelectedB2BPartner?: (partner: string) => void;
}

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(1),
  },
}));

export default function CreateOrUpdatePoolForm(props: InstanceFormProps) {
  const classes = useStyles();
  const {
    branches,
    setSelectedBranch,
    classifications,
    employees,
    fieldstaffEmployees,
    pool,
    defaultPool,
    onSubmit,
    error,
    isB2B,
    b2bPartner,
    setSelectedB2BPartner,
  } = props;
  const poolService = useService(PoolService);

  const branchOptions = useMemo(
    () =>
      branches.map((branch) => ({
        value: branch.id,
        code: branch.code,
        label: `${branch.name} (${branch.code})`,
      })),
    [branches]
  );

  const classificationOptions = useMemo(
    () =>
      classifications.map((classification) => ({
        value: classification.id,
        number: classification.number,
        label: `${classification.name} (${classification.number})`,
      })),
    [classifications]
  );

  const employeeOptions = useMemo(
    () =>
      employees.map((employee) => ({
        value: employee.id,
        number: isB2B ? employee.b2bNumber : employee.employeeNumber,
        label: `${employee.firstname} ${employee.lastname} (${
          (isB2B ? "B2B-Nummer: " + employee.b2bNumber : employee.employeeNumber) || "Keine Mitarbeiternummer"
        })`,
      })),
    [employees, isB2B]
  );

  const fieldstaffEmployeeOptions = useMemo(
    () =>
      fieldstaffEmployees.map((employee) => ({
        value: employee.id,
        number: employee.employeeNumber,
        label: `${employee.firstname} ${employee.lastname} (${employee.employeeNumber || "Keine Mitarbeiternummer"})`,
      })),
    [fieldstaffEmployees]
  );

  const initialValuesFromProps = useMemo(() => {
    if (pool === undefined) {
      const values: CreateOrUpdatePoolFormValues = {
        name: "",
        branch: null,
        classifications: [],
        employees: [],
        fieldstaffEmployees: [],
        default: false,
        canChangeClassification: false,
        canChangeEmployee: false,
        isB2B,
      };
      return values;
    } else {
      const values: CreateOrUpdatePoolFormValues = {
        name: pool.name,
        branch: pool.branch.id,
        classifications: pool.classifications
          ? classificationOptions.filter(
              (option) => pool.classifications.findIndex((classification) => classification.id === option.value) >= 0
            )
          : [],
        employees: pool.employees
          ? employeeOptions.filter((option) => pool.employees && pool.employees.findIndex((employee) => employee.id === option.value) >= 0)
          : [],
        fieldstaffEmployees: pool.fieldstaffEmployees
          ? fieldstaffEmployeeOptions.filter(
              (option) => pool.fieldstaffEmployees && pool.fieldstaffEmployees.findIndex((employee) => employee.id === option.value) >= 0
            )
          : [],
        default: pool.default || false,
        canChangeClassification: pool.canChangeClassification || false,
        canChangeEmployee: pool.canChangeEmployee || false,
        isB2B,
        b2bClient: pool.branch.b2bOwner?.id || pool.branch.b2bOwner,
      };

      return values;
    }
  }, [pool, classificationOptions, employeeOptions, fieldstaffEmployeeOptions, isB2B]);

  return (
    <Formik<CreateOrUpdatePoolFormValues>
      initialValues={initialValuesFromProps}
      validationSchema={createOrUpdateUserSchema}
      enableReinitialize={true}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ submitForm, isSubmitting, values, setFieldValue }) => (
        <FormContainer>
          <FormSection label="Name des Pools">
            <Field
              data-testid="field-name"
              className={classes.field}
              component={TextField}
              variant="outlined"
              name="name"
              type="text"
              label="Bezeichnung"
            />
          </FormSection>
          <FormSection label={isB2B ? "B2B-Kunde und Niederlassung wählen" : "Für welche Niederlassung soll der Pool erstellt werden?"}>
            {isB2B && (
              <Field
                data-testid="field-b2bClient"
                className={classes.field}
                name="b2bClient"
                component={SelectField}
                legend={"B2B-Kunde"}
                disabled={pool !== undefined}
                options={b2bPartner?.map((p) => ({ value: p.id, label: p.name, number: p.b2bNumber }))}
                onChange={
                  setSelectedB2BPartner
                    ? (e: any) => {
                        setFieldValue("b2bClient", e.target.value);
                        setFieldValue("branch", null);
                        setSelectedB2BPartner(e.target.value);
                      }
                    : undefined
                }
                getTestIdForOption={(option: any) => option.number}
              />
            )}
            <Field
              data-testid="field-branch"
              className={classes.field}
              name="branch"
              component={SelectField}
              legend={isB2B && !values.b2bClient ? "Bitte zuerst einen Kunden auswählen" : "Niederlassung"}
              disabled={pool !== undefined || (isB2B && !values.b2bClient)}
              options={branchOptions}
              onChange={(e: any) => {
                setFieldValue("branch", e.target.value);
                setFieldValue("classifications", []);
                setSelectedBranch(e.target.value);
              }}
              getTestIdForOption={(option: any) => option.code}
            />
          </FormSection>
          {!isB2B && (
            <FormSection label="Klassifizierungen zuweisen">
              <Field
                data-testid="field-classifications"
                name="classifications"
                legend={values.branch ? "Klassifizierungen" : "Bitte zuerst eine Niederlassung auswählen"}
                disabled={!values.branch}
                component={MultiSelectField}
                options={classificationOptions}
                getTestIdForOption={(option: any) => option.number}
              />
            </FormSection>
          )}
          {!isB2B && (
            <FormSection label="Außendienstmitarbeiter zuweisen (Aufträge dieser Mitarbeiter werden immer diesem Pool zugewiesen)">
              <Field
                data-testid="field-fieldstaffEmployees"
                name="fieldstaffEmployees"
                legend={values.branch ? "Zugewiesene Außendienstmitarbeiter" : "Bitte zuerst eine Niederlassung auswählen"}
                disabled={!values.branch}
                component={MultiSelectField}
                options={fieldstaffEmployeeOptions}
                getTestIdForOption={(option: any) => option.number}
              />
            </FormSection>
          )}
          <FormSection label="Innendienstmitarbeiter zuweisen">
            <Field
              data-testid={isB2B ? "field-b2b_backoffice" : "field-employees"}
              name="employees"
              legend={!values.branch ? "Bitte zuerst eine Niederlassung auswählen" : "Zugewiesene Innendienstmitarbeiter"}
              disabled={!values.branch}
              component={MultiSelectField}
              options={employeeOptions}
              getTestIdForOption={(option: any) => option.number}
            />
          </FormSection>
          <FormSection label="Optionen">
            <Field
              data-testid="field-default"
              component={CheckboxWithLabel}
              type="checkbox"
              name="default"
              disabled={poolService.disableDefault(defaultPool, { id: pool?.id, ...values })}
              Label={{ label: "Default" }}
              data-test-id="field-default"
            />
            {!isB2B && (
              <Field
                data-testid="field-canChangeClassification"
                name="canChangeClassification"
                Label={{ label: "Vorgangsklassifizierungen können nachträglich geändert werden" }}
                component={CheckboxWithLabel}
                type="checkbox"
              />
            )}
            <Field
              data-testid="field-canChangeEmployee"
              name="canChangeEmployee"
              Label={{ label: "Mitarbeiter können Vorgänge untereinander verschieben" }}
              component={CheckboxWithLabel}
              type="checkbox"
            />
          </FormSection>
          <FormSection>
            {error && (
              <Alert className={classes.field} data-testid="create-pool-error" severity="error">
                {error}
              </Alert>
            )}

            <FormSubmitButton disabled={isSubmitting} onClick={submitForm}>
              {pool ? "Speichern" : "Erstellen"}
            </FormSubmitButton>
          </FormSection>
        </FormContainer>
      )}
    </Formik>
  );
}
