import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

interface DeleteClientInAreaDialogProps {
  open: boolean;
  onAgree: () => void;
  onDismiss: () => void;
  type: string;
}

const DeleteClientInAreaDialog: React.FC<DeleteClientInAreaDialogProps> = ({ open, onAgree, onDismiss, type }) => {
  return (
    <div>
      <Dialog open={open} onClose={onDismiss} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{`Entfernen von ${type}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sind Sie sicher dass Sie den <span>{type}</span> aus dem Wohnbereich entfernen wollen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button data-testid="delete-dismiss" onClick={onDismiss}>
            Abbrechen
          </Button>
          <Button data-testid="delete-agree" onClick={() => onAgree()} color="secondary" autoFocus>
            {type} Entfernen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteClientInAreaDialog;
