import DeleteIcon from "@material-ui/icons/Delete";
import React, { CSSProperties, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { DataTableCell } from "template/presentation/components/table/DataTable";
import { PoolAssignmentPreview } from "features/pools/domain/entities/PoolAssignment";
import DataContainer from "template/presentation/components/table/DataContainerExtendedSearch";
import { format } from "date-fns";
import { useCurrentUser, useCurrentUserInGroup } from "features/login/presentation/hooks/useUser";
import PoolAssignmentConverter from "../../domain/converters/PoolAssignmentConverter";
import DataTablePaginatedAndSortable from "template/presentation/components/table/DataTablePaginatedAndSortable";
import { SelectOption } from "features/assignments/domain/entities/Assignment";
import { useService } from "core/di";
import PoolService from "features/pools/domain/services/PoolService";
import { useQueryCache } from "react-query";
import { Tooltip } from "@material-ui/core";
import { renderClientLabel } from "features/clients/presentation/utils";
import { EmptyState } from "core/presentation/components/EmptyState";

export interface PoolAssignmentsTableProps {
  deleteAssignment: (id: string) => void;
  assignmentPreviews: PoolAssignmentPreview[];
  assignmentPreviewsLoading: boolean;
  onReload: () => void;
  onSearch: (query: string | undefined) => void;
  page: number;
  pageSize: number;
  totalCount: number;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
  onSortChange: (sortBy: string, sortOrder: "asc" | "desc") => void;
  sortedBy: { field: string; order: "asc" | "desc" };
  poolAssignmentTypeSelectOptions: SelectOption[];
  clientTypeAhead: { options: SelectOption[]; query: (query: string) => void };
  type?: "internal" | "b2b";
}

const renderAssignmentState = (options: {
  email: string;
  assignment: PoolAssignmentPreview;
  userWithPrivilege: boolean;
  index: number;
  onFinishAssignment?: (assignmentId: string) => void;
}) => {
  const { assignment, email, userWithPrivilege, index, onFinishAssignment } = options;
  if ((assignment.type === "CASE_CREATED" || assignment.type === "CASE_CHANGED") && onFinishAssignment) {
    if (assignment.assignmentState.status !== "DONE") {
      return (
        <button
          style={{
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
            textDecoration: "underline",
            display: "inline",
            margin: 0,
            padding: 0,
          }}
          onClick={() => onFinishAssignment(assignment.assignmentId)}
        >
          Zur Kenntnis genommen
        </button>
      );
    }

    return (
      <span
        data-testid={`link-process-done-row-${index}`}
      >{`Abgeschlossen (von ${assignment.assignmentState.claimedByEmployee.firstname} ${assignment.assignmentState.claimedByEmployee.lastname})`}</span>
    );
  }
  if (assignment.assignmentState.status === "IN_PROGRESS") {
    if (assignment.assignmentState.claimedByEmployee.email === email) {
      return (
        <Link data-testid={`link-process-inprogressyo-row-${index}`} to={`/pools/assignments/${assignment.assignmentId}/work`}>
          Weiter bearbeiten
        </Link>
      );
    } else {
      if (userWithPrivilege) {
        return (
          <Link data-testid={`link-process-inprogressother-row-${index}`} to={`/pools/assignments/${assignment.assignmentId}/work`}>
            In Bearbeitung (von {assignment.assignmentState.claimedByEmployee.firstname}{" "}
            {assignment.assignmentState.claimedByEmployee.lastname})
          </Link>
        );
      } else {
        return (
          <span
            data-testid={`link-process-inprogressother-row-${index}`}
          >{`In Bearbeitung (von ${assignment.assignmentState.claimedByEmployee.firstname} ${assignment.assignmentState.claimedByEmployee.lastname})`}</span>
        );
      }
    }
  } else if (assignment.assignmentState.status === "DONE") {
    if (userWithPrivilege) {
      return (
        <Link data-testid={`link-process-done-row-${index}`} to={`/pools/assignments/${assignment.assignmentId}/work`}>
          Abgeschlossen (von {assignment.assignmentState.claimedByEmployee.firstname}{" "}
          {assignment.assignmentState.claimedByEmployee.lastname})
        </Link>
      );
    } else {
      return (
        <span
          data-testid={`link-process-done-row-${index}`}
        >{`Abgeschlossen (von ${assignment.assignmentState.claimedByEmployee.firstname} ${assignment.assignmentState.claimedByEmployee.lastname})`}</span>
      );
    }
  } else {
    return (
      <Link data-testid={`link-process-todo-row-${index}`} to={`/pools/assignments/${assignment.assignmentId}/work`}>
        Zu Bearbeiten
      </Link>
    );
  }
};

export default function PoolAssignmentsTable(props: PoolAssignmentsTableProps) {
  const {
    deleteAssignment,
    assignmentPreviews,
    assignmentPreviewsLoading,
    onReload,
    onSearch,
    page,
    pageSize,
    onPageChange,
    onPageSizeChange,
    totalCount,
    onSortChange,
    sortedBy,
    poolAssignmentTypeSelectOptions,
    clientTypeAhead,
    type = "internal",
  } = props;
  const poolService = useService(PoolService);
  const queryCache = useQueryCache();
  const [isB2BBackofficeUser] = useCurrentUserInGroup("b2bBackoffice");
  const { data: user } = useCurrentUser();
  const [isAdmin] = useCurrentUserInGroup("admin");
  const onFinishAssignment = useCallback(
    (assignmentId: string) => {
      poolService.finishAssignment(assignmentId).then(() => queryCache.invalidateQueries(["/pools/assignments/my"]));
    },
    [poolService, queryCache]
  );

  const columns = useMemo(() => {
    return [
      { name: "Auftragsnummer" },
      { name: "Vorgang" },
      { name: "Typ" },
      { name: "Kunde" },
      type === "internal" && { name: "Klassifizierung" },
      { name: "Erstelldatum", sortField: "date" },
      { name: "Bearbeitungsstatus" },
      isAdmin && { name: "Aktionen" },
    ].filter((e) => !!e) as Array<{ name: string }>;
  }, [isAdmin, type]);

  const filterItems = useMemo(() => {
    if (isB2BBackofficeUser) {
      return [
        { name: "Auftragsnummer", searchField: "assignmentNumber" },
        { name: "Typ", searchField: "type", selectOptions: poolAssignmentTypeSelectOptions },
        {
          name: "Kundennummer",
          searchField: "client",
          setTypeAheadQuery: clientTypeAhead.query,
          typeAheadOptions: clientTypeAhead.options,
          typeAheadTestProperty: "clientNumber",
        },
      ];
    }
    return [
      { name: "Auftragsnummer", searchField: "assignmentNumber" },
      { name: "Typ", searchField: "type", selectOptions: poolAssignmentTypeSelectOptions },
      {
        name: "Kundennummer",
        searchField: "client",
        setTypeAheadQuery: clientTypeAhead.query,
        typeAheadOptions: clientTypeAhead.options,
        typeAheadTestProperty: "clientNumber",
      },
      { name: "Neukunden", searchField: "newClientName" },
    ];
  }, [clientTypeAhead.query, clientTypeAhead.options, poolAssignmentTypeSelectOptions, isB2BBackofficeUser]);

  const makeStyleEllipsisMaxCharacters = (characters: number): CSSProperties => ({
    whiteSpace: "pre-wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline-block",
    maxWidth: `${characters}ch`,
  });

  const tooltipWithMaxCharacters = (title: string, characters: number) => (
    <Tooltip title={title} placement="top">
      <p style={makeStyleEllipsisMaxCharacters(characters)}>{title}</p>
    </Tooltip>
  );

  const handleDelete = useCallback(
    (id: string) => {
      const shouldDelete = window.confirm(
        "Sind Sie sicher, dass Sie diesen Auftrag löschen wollen?\nDiese Aktion kann nicht rückgängig gemacht werden."
      );
      if (!shouldDelete) return;
      deleteAssignment(id);
    },
    [deleteAssignment]
  );

  return (
    <DataContainer loading={assignmentPreviewsLoading} onReload={onReload} onSearchSubmit={onSearch} filterItems={filterItems}>
      <DataTablePaginatedAndSortable<PoolAssignmentPreview>
        data-testid={`pooltable-backoffice`}
        loading={assignmentPreviewsLoading}
        columns={columns}
        rows={assignmentPreviews}
        page={page}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        totalCount={totalCount}
        keyExtractor={(data) => data.assignmentId}
        onSortChange={onSortChange}
        sortedBy={sortedBy}
        renderRow={(assignment, index) => {
          return (
            <>
              <DataTableCell data-testid={`assignmentNumber`}>
                {assignment.assignmentNumber ? tooltipWithMaxCharacters(assignment.assignmentNumber, 20) : "-"}
              </DataTableCell>
              <DataTableCell data-testid="case">
                {assignment.relatedCaseNumber ? (
                  <Tooltip title={assignment.relatedCaseNumber}>
                    <Link
                      style={makeStyleEllipsisMaxCharacters(12)}
                      data-testid={`link-goto-case-${index}`}
                      to={`/cases?caseNumber=${assignment.relatedCaseNumber}`}
                    >
                      {assignment.relatedCaseNumber}
                    </Link>
                  </Tooltip>
                ) : (
                  "-"
                )}
              </DataTableCell>
              <DataTableCell data-testid={`assignmentType`}>
                {tooltipWithMaxCharacters(
                  PoolAssignmentConverter.fromAssignmentTypeToString(assignment.type, assignment.employee?.groups || []),
                  20
                )}
              </DataTableCell>
              <DataTableCell data-testid={`assignmentLastname`}>
                {tooltipWithMaxCharacters(
                  assignment.client
                    ? `${renderClientLabel(assignment.client)}`
                    : assignment.newClient
                    ? `${assignment.newClient?.firstname} ${assignment.newClient.lastname} (Neukunde)`
                    : "Unbekannter Kunde",
                  12
                )}
              </DataTableCell>
              {type === "internal" && (
                <DataTableCell data-testid={`assignmentClassification`}>
                  {tooltipWithMaxCharacters(
                    assignment.classification ? `${assignment.classification.name} (${assignment.classification.number})` : "-",
                    16
                  )}
                </DataTableCell>
              )}
              <DataTableCell data-testid={`assignmentCreatedDate`}>
                {tooltipWithMaxCharacters(format(assignment.createdAt, "dd.MM.yyyy HH:mm"), 10)}
              </DataTableCell>
              <DataTableCell data-testid={`status`}>
                <p style={makeStyleEllipsisMaxCharacters(20)}>
                  {user
                    ? renderAssignmentState({
                        email: user.email,
                        assignment,
                        userWithPrivilege: isAdmin || isB2BBackofficeUser,
                        index,
                        onFinishAssignment,
                      })
                    : ""}
                </p>
              </DataTableCell>
              {isAdmin && (
                <DataTableCell>
                  <DeleteIcon onClick={() => handleDelete(assignment.assignmentId)} style={{ cursor: "pointer" }} color={"secondary"} />
                </DataTableCell>
              )}
            </>
          );
        }}
        renderEmpty={() => (
          <EmptyState
            title="Keine Pool-Aufträge vorhanden"
            description="Auf dieser Seite sehen Sie die Aufträge die Sie vom Reha Team bekommen haben."
          />
        )}
      />
    </DataContainer>
  );
}
