import { AppBar, FormGroup, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  formSection: {
    flex: 1,
    padding: theme.spacing(2),
  },
  sectionHeader: {
    backgroundColor: "#f5f5f5",
    color: "rgba(0,0,0,0.54)",
    fontWeight: 600,
    fontSize: 12,
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    padding: "10px 15px",
  },
}));

interface FormSectionProps {
  label?: string;
}

const FormSection: React.FC<FormSectionProps> = ({ label, children }) => {
  const classes = useStyles();

  return (
    <>
      {label && (
        <AppBar className={classes.sectionHeader} position="static" color="default" elevation={0}>
          <span>{label}</span>
        </AppBar>
      )}
      <FormGroup data-testid="actionSector" className={classes.formSection}>
        {children}
      </FormGroup>
    </>
  );
};
export default FormSection;
