import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import useService from "core/di/useService";
import { RouteComponentProps, useParams } from "react-router-dom";
import UIRoute from "core/presentation/components/UIRoute";
import PoolService from "features/pools/domain/services/PoolService";
import AssignmentService from "../../../assignments/domain/services/AssignmentService";
import PoolAssignmentsTable from "../components/PoolAssignmentsTable";
import { usePageAndPageSizeAndAnyQueryFromRouteProps } from "core/utils";
import { useCallback } from "react";
import { useCurrentUserInGroup } from "features/login/presentation/hooks/useUser";
import PoolsSubHeader from "../components/PoolsSubHeader";
import ClientService from "features/clients/domain/services/ClientService";
import { SelectOption } from "features/assignments/domain/entities/Assignment";

export interface PoolAssignmentsRouteProps extends RouteComponentProps {}

export default function PoolAssignmentsRoute(props: PoolAssignmentsRouteProps) {
  const { page, pageSize, searchQueries } = usePageAndPageSizeAndAnyQueryFromRouteProps(props);
  const [sortedBy, setSortedBy] = useState<{ field: string; order: "asc" | "desc" }>({ field: "date", order: "desc" });
  const [clientQuery, setClientQuery] = useState<string>("");
  const poolService = useService(PoolService);
  const assignmentService = useService(AssignmentService);
  const clientService = useService(ClientService);
  const { id: poolId, status, type } = useParams<{
    id: string;
    status: "all" | "active" | "archived";
    type: "internal" | "b2b" | undefined;
  }>();
  const [isAdmin] = useCurrentUserInGroup("admin");
  const [isB2BBackofficeUser] = useCurrentUserInGroup("b2bBackoffice");

  const poolAssignmentTypeSelectOptions = useMemo(() => {
    return poolService.getPoolAssignmentTypeSelectOptions(isB2BBackofficeUser);
  }, [poolService, isB2BBackofficeUser]);

  const { data: clientOptions } = useQuery(
    [clientQuery],
    () => {
      return clientService.get({ fields: { clientNumber: clientQuery }, group: "", page: 1, pageSize: 10 }).then((r): SelectOption[] => {
        return r.data.map((client) => {
          return {
            label: `${client.firstname} ${client.lastname} (${client.clientNumber})`,
            value: client.id,
            clientNumber: client.clientNumber,
          };
        });
      });
    },
    { enabled: clientQuery }
  );

  let queryUri: string;
  if (poolId) {
    queryUri = `/pools/${poolId}/assignments`;
  } else {
    queryUri = "/pools/assignments";
  }
  if (type) {
    queryUri += `/${type}`;
  }
  if (status) {
    queryUri += `/${status}?page=`;
  } else {
    queryUri += `/all/?page=`;
  }

  const onHandleQuery = useCallback(
    (page, pageSize, searchQueries) => {
      let query = queryUri + page;
      if (pageSize) {
        query += "&page_size=" + pageSize;
      }
      if (searchQueries) {
        for (const [key, value] of Object.entries(searchQueries)) {
          if (value) {
            query += `&${key}=${value}`;
          }
        }
      }
      props.history.push(query);
    },
    [props.history, queryUri]
  );

  const onSortChange = useCallback((sortBy: string, sortOrder: "asc" | "desc") => {
    setSortedBy({ field: sortBy, order: sortOrder });
  }, []);

  const onChangeClientQueryChange = useCallback((query: string) => {
    setClientQuery(query);
  }, []);

  const {
    data: poolAssignmentPreviews,
    isLoading: poolAssignmentPreviewsLoading,
    refetch,
    isFetching: poolAssignmentPreviewsFetching,
  } = useQuery([`/pools/assignments/my`, { searchQueries, page, pageSize, status, sortedBy, poolId }], () => {
    if (poolId) {
      return poolService.getAssignmentsForPool(searchQueries, poolId, status, page, pageSize, sortedBy);
    } else {
      return poolService.getMyAssignmentPreviews(searchQueries, page, pageSize, sortedBy, status);
    }
  });

  const handleDeleteAssignment = useCallback(
    async (id: string) => {
      await assignmentService.deleteAssigment(id);
      refetch();
    },
    [refetch, assignmentService]
  );

  return (
    <UIRoute
      {...props}
      title="Pool-Auftragsliste"
      renderSubHeader={() =>
        (isAdmin || isB2BBackofficeUser) && (
          <PoolsSubHeader
            path={props.location.pathname}
            onTabChange={props.history.push}
            poolId={poolId}
            b2bView={isB2BBackofficeUser}
            poolType={type ?? "internal"}
          />
        )
      }
    >
      <PoolAssignmentsTable
        type={type}
        onSearch={(searchValue) => {
          onHandleQuery(1, pageSize, searchValue);
        }}
        clientTypeAhead={{ options: clientOptions || [], query: onChangeClientQueryChange }}
        poolAssignmentTypeSelectOptions={poolAssignmentTypeSelectOptions}
        page={page}
        pageSize={pageSize}
        totalCount={poolAssignmentPreviews?.totalCount || 0}
        onPageChange={(newPage: number) => onHandleQuery(newPage, pageSize, searchQueries)}
        onPageSizeChange={(newPageSize: number) => {
          onHandleQuery(page, newPageSize, searchQueries);
        }}
        sortedBy={sortedBy}
        onSortChange={onSortChange}
        assignmentPreviews={poolAssignmentPreviews?.data || []}
        assignmentPreviewsLoading={poolAssignmentPreviewsLoading || poolAssignmentPreviewsFetching}
        onReload={refetch}
        deleteAssignment={handleDeleteAssignment}
      />
    </UIRoute>
  );
}
