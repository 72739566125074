import React, { useMemo } from "react";
import { Field, FieldArray, Formik } from "formik";
import * as Yup from "yup";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import { makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import FormSection from "template/presentation/components/form/FormSection";
import FormContainer from "template/presentation/components/form/FormContainer";
import FormSubmitButton from "template/presentation/components/form/FormSubmitButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import { AllocatedArea, Branch } from "features/branches/domain/entities/BranchEntity";
import ValueGrid from "template/presentation/components/content/ValueGrid";
import LabeledValue from "template/presentation/components/content/LabeledValue";
import MultiSelectField from "template/presentation/components/form/MutiSelectField";

export interface CreateOrUpdateBranchFormValues {
  name: string;
  code: string;
  street: string;
  zipcode: string;
  city: string;
  default: boolean;
  allocatedAreas: AllocatedArea[];
  zipToAdd?: string;
  b2bClient?: string;
  isB2B: boolean;
}

const createOrUpdateBranchSchema = Yup.object().shape({
  name: Yup.string().required("Benötigt"),
  code: Yup.string().required("Benötigt"),
  street: Yup.string().required("Benötigt"),
  zipcode: Yup.string().required("Benötigt"),
  city: Yup.string().required("Benötigt"),
  default: Yup.boolean().required("Benötigt"),
  zipToAdd: Yup.string().matches(
    /^[0-9]{5}(?:-[0-9]{5})?$/,
    "Bitte geben Sie eine gültige Postleitzahl oder einen gültigen Postleitzahlen bereich ein. Beispiel: 12345 oder 12345-12345"
  ),
  isB2B: Yup.boolean().required("Benötigt"),
  b2bClient: Yup.string()
    .nullable()
    .when("isB2B", {
      is: true,
      then: Yup.string().required("Benötigt"),
    }),
});

export interface InstanceFormProps {
  isB2B: boolean;
  b2bPartner?: { id: string; value: string; label: string }[];
  setSelectedB2BClient?: (client: string) => void;
  selectedB2BClient?: string;
  onSubmit: (values: CreateOrUpdateBranchFormValues) => Promise<void>;
  error: string | null;
  branch?: Branch;
  defaultBranch?: Branch;
  onZipSearch: (zipSearch: string, allocatedAreas: AllocatedArea[]) => Promise<void | string[]>;
}

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(1),
  },
}));

export default function CreateorUpdateBranchForm(props: InstanceFormProps) {
  const classes = useStyles();
  const { isB2B, b2bPartner, setSelectedB2BClient, selectedB2BClient, onSubmit, error, branch, defaultBranch, onZipSearch } = props;

  const disableDefaultOption = useMemo(() => {
    if (defaultBranch) {
      if (branch) {
        return branch.id !== defaultBranch.id;
      }
      return true;
    }
    return isB2B && !selectedB2BClient;
  }, [defaultBranch, branch, isB2B, selectedB2BClient]);

  return (
    <Formik<CreateOrUpdateBranchFormValues>
      enableReinitialize
      initialValues={{
        name: branch?.name || "",
        code: branch?.code || "",
        street: branch?.street || "",
        zipcode: branch?.zipcode || "",
        city: branch?.city || "",
        default: branch?.default || false,
        allocatedAreas: branch?.allocatedAreas || [],
        zipToAdd: "",
        isB2B: isB2B,
        b2bClient: isB2B ? branch?.b2bOwner.id : undefined,
      }}
      validationSchema={createOrUpdateBranchSchema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ values, submitForm, isSubmitting, validateForm, setFieldValue, errors }) => (
        <FormContainer>
          <FormSection label="Allgemeine Informationen">
            {isB2B && (
              <Field
                name={"b2bClient"}
                data-testid="field-b2bClient"
                className={classes.field}
                component={MultiSelectField}
                single={true}
                options={b2bPartner || []}
                legend={"B2B-Partner zuordnen*"}
                onChange={(e: any, obj?: any) => {
                  if (obj) {
                    if (!!setSelectedB2BClient && !!b2bPartner) {
                      const partner = obj.value;
                      if (partner) {
                        setSelectedB2BClient(partner);
                      }
                    }
                    setFieldValue("b2bClient", obj.value);
                  } else {
                    setSelectedB2BClient && setSelectedB2BClient("");
                  }
                }}
                getTestIdForOption={(option: any) => option.id}
                getOptionSelected={(option: any, value: string) => option.value === value.toString()}
                getOptionLabel={(value: any) => {
                  return typeof value === "object" ? value.label : b2bPartner?.find((x) => x.id === value)?.label;
                }}
              />
            )}
            <Field
              data-testid="field-code"
              className={classes.field}
              component={TextField}
              variant="outlined"
              name="code"
              type="text"
              label="Nummer*"
            />
            <Field
              data-testid="field-name"
              className={classes.field}
              component={TextField}
              variant="outlined"
              name="name"
              type="text"
              label="Name*"
            />
            <Field
              component={CheckboxWithLabel}
              type="checkbox"
              name="default"
              disabled={disableDefaultOption}
              Label={{ label: "Default" }}
              data-test-id="field-default"
            />
          </FormSection>
          <FormSection label="Adresse">
            <Field
              data-testid="field-street"
              className={classes.field}
              component={TextField}
              variant="outlined"
              name="street"
              type="text"
              label="Straße*"
            />
            <Field
              data-testid="field-zipcode"
              className={classes.field}
              component={TextField}
              variant="outlined"
              name="zipcode"
              type="text"
              label="PLZ*"
            />
            <Field
              data-testid="field-city"
              className={classes.field}
              component={TextField}
              variant="outlined"
              name="city"
              type="text"
              label="Stadt*"
            />
          </FormSection>
          {!values.isB2B && (
            <FormSection label="Zuständigkeitsgebiete">
              <FieldArray
                name="allocatedAreas"
                render={(arrayHelpers) => (
                  <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Field
                        data-testid={`field-zipToAdd`}
                        style={{ flex: "0 0 95%", marginRight: "1%" }}
                        component={TextField}
                        variant={"outlined"}
                        label={"Postleitzahl"}
                        name={"zipToAdd"}
                      />
                      <div
                        style={{
                          flex: "0 0 4%",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        data-testid={`field-allocatedAreas-plus`}
                        onClick={() => {
                          validateForm().then(async (unvalid) => {
                            if (unvalid && !unvalid.zipToAdd) {
                              const zip = values.zipToAdd;
                              if (zip) {
                                const cities = await onZipSearch(zip, values.allocatedAreas);
                                if (cities) {
                                  arrayHelpers.push({ location: cities.join(", "), zip: zip });
                                }
                              }
                            }
                          });
                        }}
                      >
                        <AddCircleIcon color={"primary"} style={{ marginRight: "8px", fontSize: "3em" }} />
                      </div>
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <ValueGrid>
                        {values &&
                          values.allocatedAreas &&
                          values.allocatedAreas.map((key, index) => (
                            <div key={index} style={{ display: "flex", alignItems: "center" }}>
                              <DeleteIcon
                                data-testid={`field-allocatedArea-${index}-delete`}
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                }}
                                style={{ flex: "0 0 15%", cursor: "pointer" }}
                                color={"secondary"}
                              />
                              <LabeledValue label={values.allocatedAreas[index].location} value={values.allocatedAreas[index].zip} />
                            </div>
                          ))}
                      </ValueGrid>
                    </div>
                  </>
                )}
              />
            </FormSection>
          )}
          <FormSection>
            {error && (
              <Alert className={classes.field} data-testid="createbranch-error" severity="error">
                {error}
              </Alert>
            )}

            <FormSubmitButton data-testid={"create-button"} disabled={isSubmitting} onClick={submitForm}>
              {branch ? "Bearbeiten" : "Erstellen"}
            </FormSubmitButton>
          </FormSection>
        </FormContainer>
      )}
    </Formik>
  );
}
