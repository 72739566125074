import { useCallback, useState } from "react";

const useBooleanStateToggle = (initial: boolean = false): [boolean, () => void, () => void] => {
  const [booleanState, toggleStateTo] = useState<boolean>(initial);

  const setTrue = useCallback(() => toggleStateTo(true), [toggleStateTo]);
  const setFalse = useCallback(() => toggleStateTo(false), [toggleStateTo]);

  return [booleanState, setTrue, setFalse];
};

export default useBooleanStateToggle;
