import { renderMessageUserType, renderUserName } from "features/clients/presentation/utils";
import UserResponseModel from "../../data/UserResponseModel";
import User from "../entities/UserEntity";
export default class UserConverter {
  public static fromModelToEntity(userModel: UserResponseModel): User {
    const { _id, ...fields } = userModel;
    return { id: _id, ...fields };
  }

  public static toMessageRecipientOptions(users: User[]): { label: string; value: string; recipientType: string }[] {
    if (users.length > 0) {
      return users.map((u) => {
        const userType = renderMessageUserType(u);
        const value = userType === "AU" ? u.employeeNumber : userType === "B2B" ? u.b2bNumber : u.b2cClient;
        return { label: `${renderUserName(u)} (${userType})`, value: value, recipientType: userType, email: u.email };
      });
    } else {
      return [];
    }
  }
}
