import AdapterModel from "features/adapter/data/AdapterModel";
import Adapter from "../entities/Adapter";

export default class AdapterConverter {
  public static fromModelToEntity(model: AdapterModel): Adapter {
    return {
      id: model._id,
      default: model.default,
      name: model.name,
      type: model.adapterType,
      status: model.connectionStatus,
      statusChangedAt: new Date(model.connectionStatusChangedAt),
    };
  }
}
