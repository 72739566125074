import { AxiosInstance } from "axios";
import { PagedResponse } from "core/utils";
import { CreateOrUpdateFormularFormValues } from "../presentation/components/CreateOrUpdateFormularForm";
import FormularResponseModel from "./FormularModel";
import FormularRequestModel from "./FormularRequestModel";

export default class FormularApi {
  constructor(private authClient: AxiosInstance) {}

  public async get(params: any): Promise<PagedResponse<FormularResponseModel>> {
    return this.authClient
      .get("/formular", { params })
      .then(({ data }) => data)
      .catch(() => ({
        data: [],
        totalCount: 0,
      }));
  }

  public async update(id: string, values: CreateOrUpdateFormularFormValues): Promise<any> {
    return this.authClient
      .put(`/formular/${id}`, values)
      .then(({ data }) => data)
      .catch(() => {});
  }

  public async getById(id: string): Promise<FormularResponseModel> {
    return this.authClient.get("/formular/" + id).then(({ data }) => data);
  }

  public async create(formular: FormularRequestModel): Promise<any> {
    return await this.authClient.post("/formular", formular);
  }

  public async getFile(formularId: string) {
    return await this.authClient.get("formular/pdfFile/" + formularId);
  }
}
