import { AxiosInstance } from "axios";
import ClientResponseModel from "./ClientResponseModel";
import ClientRequestModel from "./ClientRequestModel";
import handleAxiosError from "axios-handle-error";
import { convertAxiosErrorToValidationError, ElementNotFoundError, NetworkError } from "core/data/errors";
import { PagedResponse } from "core/utils";
import Client from "../domain/entities/Client";
import { CreateClientOnIndustrySoftwareFormValues } from "../presentation/components/CreateClientOnIndustrySoftwareForm";
import PrescriberResponseModel from "./PrescriberResponseModel";

export default class ClientApi {
  constructor(private authClient: AxiosInstance) {}

  public async get(params: any): Promise<PagedResponse<ClientResponseModel>> {
    return this.authClient.get("/v2/clients", { params }).then((r) => {
      return r.data;
    });
  }

  public async getById(id: string): Promise<ClientResponseModel> {
    return this.authClient
      .get(`/v2/clients/${id}`)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          NO_RESPONSE: () => new NetworkError(),
          404: () => new ElementNotFoundError(),
        });
      });
  }

  public async getTypeAheadOptions(params: any): Promise<Client[]> {
    return this.authClient.get("/v2/clients/typeAheadOptions", { params }).then((r) => {
      return r.data;
    });
  }

  public async create(data: ClientRequestModel): Promise<ClientResponseModel> {
    return this.authClient
      .post("/v2/clients", data)
      .then((r) => r.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          NO_RESPONSE: () => new NetworkError(),
          404: () => new ElementNotFoundError(),
        });
      });
  }

  public async createOnIndustrySoftware(data: CreateClientOnIndustrySoftwareFormValues) {
    return this.authClient
      .post("/adapter/create-client", data)
      .then((r) => r.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          NO_RESPONSE: () => new NetworkError(),
          404: () => new ElementNotFoundError(),
        });
      });
  }

  public async update(id: string, values: ClientRequestModel): Promise<ClientResponseModel> {
    return this.authClient
      .put(`/v2/clients/${id}`, values)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          NO_RESPONSE: () => new NetworkError(),
          404: () => new ElementNotFoundError(),
        });
      });
  }

  public async getAllPrescribers(): Promise<PrescriberResponseModel[]> {
    return this.authClient.get("/prescriber").then((x) => x.data);
  }
}
