import React from "react";
import format from "date-fns/format";
import DataContainer from "template/presentation/components/table/DataContainer";
import DataTable, { DataTableCell } from "template/presentation/components/table/DataTable";
import AdapterLogEntry from "features/adapter/domain/entities/AdapterLogEntry";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { IconButton } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import WarningOutlinedIcon from "@material-ui/icons/WarningOutlined";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

export interface AdapterLogTableProps {
  logs: AdapterLogEntry[] | null;
  isLoading: boolean;
  onReload: () => void;
}

const columns = [{ name: "Datum" }, { name: "Level" }, { name: "Aktion" }, { name: "Richtung" }, { name: "Daten" }];

export default function AdapterLogTable(props: AdapterLogTableProps) {
  const { logs, isLoading, onReload } = props;

  return (
    <DataContainer loading={isLoading} onReload={onReload}>
      <DataTable<AdapterLogEntry>
        data-testid={`adapterlog-table`}
        loading={isLoading}
        columns={columns}
        rows={logs}
        keyExtractor={(data) => data.id}
        renderRow={(data, index) => {
          return (
            <>
              <DataTableCell>{format(data.date, "dd.MM.yyyy HH:mm")}</DataTableCell>
              <DataTableCell>
                {data.level === "INFO" ? (
                  <InfoOutlinedIcon color="primary" />
                ) : data.level === "WARNING" ? (
                  <WarningOutlinedIcon htmlColor="#ffd900" />
                ) : (
                  <ErrorOutlineIcon color="error" />
                )}
              </DataTableCell>
              <DataTableCell>{data.action}</DataTableCell>
              <DataTableCell>{data.direction}</DataTableCell>
              <DataTableCell>
                <IconButton
                  onClick={() => {
                    console.log(data);
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
              </DataTableCell>
            </>
          );
        }}
        renderEmpty={() => <div>Empty</div>}
      />
    </DataContainer>
  );
}
