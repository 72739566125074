import React from "react";
import ReactDOM from "react-dom";
import Root from "./Root";
import initDi from "./core/di/init";
import { MANDANT_PRODUCT } from "./core/data/backend";

initDi();

document.title = MANDANT_PRODUCT;

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById("root")
);
