import SignatureformsApi from "../../data/SignatureformsApi";
import Signatureform, { SignatureAssignmentRequest, ResponseSignatureform } from "../entities/Signatureform";

class SignatureformsService {
  constructor(private signatureformsApi: SignatureformsApi) {}

  public async getById(id: string): Promise<ResponseSignatureform | null> {
    return this.signatureformsApi.getById(id);
  }

  public async getDocument(id: string): Promise<string> {
    return this.signatureformsApi.getDocument(id);
  }

  public async getSignatureformsList() {
    return this.signatureformsApi.get();
  }

  public async create(formValues: Signatureform): Promise<void> {
    return this.signatureformsApi.create(formValues);
  }

  public async createAssignment(formValues: SignatureAssignmentRequest): Promise<void> {
    return this.signatureformsApi.createAssignment(formValues);
  }

  public async delete(id: string): Promise<void> {
    return await this.signatureformsApi.delete(id);
  }
}

export default SignatureformsService;
