import { PoolAssignmentReportBusinessInquiry } from "features/pools/domain/entities/PoolAssignment";
import React from "react";
import ContentSection from "template/presentation/components/content/ContentSection";
import LabeledValue from "template/presentation/components/content/LabeledValue";
import ValueGrid from "template/presentation/components/content/ValueGrid";

interface Props {
  report: PoolAssignmentReportBusinessInquiry;
}

export const BusinessInquirySection: React.FC<Props> = ({ report }) => {
  return (
    <ContentSection label="Details">
      <ValueGrid>
        <LabeledValue label="Name des Unternehmens" value={report.companyName} />
        <LabeledValue label="Name des Ansprechpartners" value={report.contactPersonName} />
        <LabeledValue label="Telefon" value={report.phone} />
        <LabeledValue label="E-Mail" value={report.email} />
        <LabeledValue label="Anschrift" value={report.street + ", " + report.zipcode + " " + report.city} />
      </ValueGrid>
    </ContentSection>
  );
};
