import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import { makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { SimpleFileUpload } from "formik-material-ui";

interface UpdateSigningRequestDialogProps {
  open: boolean;
  onDismiss: () => void;
  onSubmit: (values: UpdateSigingRequestFormProps) => any;
  error: string | null;
}

export interface UpdateSigingRequestFormProps {
  comment: string;
  file: Blob | null;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: "500px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "left",
    "& *": {
      marginBottom: 5,
    },
  },
  warning: {
    marginTop: 10,
  },
  field: {
    marginTop: 5,
  },
}));

const validationSchema = Yup.object().shape({
  file: Yup.string().required("Benötigt"),
  comment: Yup.string().optional(),
});

const UpdateSigningRequestDialog: React.FC<UpdateSigningRequestDialogProps> = ({ open, onSubmit, onDismiss, error }) => {
  const classes = useStyles();
  return (
    <Formik<UpdateSigingRequestFormProps>
      initialValues={{ comment: "", file: null }}
      onSubmit={(values) => onSubmit(values)}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ submitForm, isSubmitting, handleReset }) => {
        return (
          <Dialog
            open={open}
            onClose={onDismiss}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ paper: classes.paper }}
          >
            <DialogTitle id="alert-dialog-signingRequest-title">Unterschriftenanforderung aktualisieren</DialogTitle>
            <DialogContent className={classes.content}>
              <Field
                data-testid="field-comment"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"comment"}
                label={"Kommentar"}
              />
              <Field data-testid="pdfDatei_Upload" component={SimpleFileUpload} name="file" label="PDF-Datei" accept=".pdf" />

              {error && (
                <Alert className={classes.field} data-testid="signingRequest-dialog-form-error" severity="error">
                  {error}
                </Alert>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleReset();
                  onDismiss();
                }}
                disabled={isSubmitting && !error}
              >
                Abbrechen
              </Button>
              <Button onClick={submitForm} variant="outlined" color="primary" data-testid="modify-signingRequest" disabled={isSubmitting}>
                Dokument aktualisieren
              </Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default UpdateSigningRequestDialog;
