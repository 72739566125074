import React, { useCallback, useMemo } from "react";
import Client from "features/clients/domain/entities/Client";
import DataContainer, { FilterItem } from "template/presentation/components/table/DataContainerExtendedSearch";
import { DataTableCell } from "template/presentation/components/table/DataTable";
import { format } from "date-fns";
import DataTablePaginatedAndSortable from "template/presentation/components/table/DataTablePaginatedAndSortable";
import Case from "features/cases/domain/entities/Case";
import { EmptyState } from "core/presentation/components/EmptyState";
import { Link } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { makeStyles } from "@material-ui/core";

export interface CaseTableProps {
  cases?: Case[] | null;
  isLoading: boolean;
  onReload: () => void;
  addButtonText: string;
  onAdd: () => void;
  onSearch: (query: string | undefined) => void;
  page: number;
  pageSize: number;
  totalCount?: number;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
  onSortChange: (sortBy: string, sortOrder: "asc" | "desc") => void;
  sortedBy: { field: string; order: "asc" | "desc" };
  filterItems?: FilterItem[];
  type?: "active" | "inactive";
}

const CaseTable = (props: CaseTableProps) => {
  const useStyles = makeStyles((theme) => ({
    paper: {
      maxWidth: "95%",
    },
  }));
  const classes = useStyles();
  const {
    cases,
    isLoading,
    onReload,
    addButtonText,
    onAdd,
    onSearch,
    page,
    pageSize,
    onPageChange,
    onPageSizeChange,
    totalCount,
    onSortChange,
    sortedBy,
    filterItems,
    type,
  } = props;
  const columns = useMemo(() => {
    return [
      { name: "Vorgangsnummer" },
      { name: "Kunde" },
      { name: "Status" },
      { name: "Problem/Betreff" },
      { name: "Vereinbarung" },
      { name: "Datum", sortField: "date" },
      { name: "Ansehen" },
    ];
  }, []);

  const emptyState = useMemo(() => {
    switch (type) {
      case undefined:
        return (
          <EmptyState
            title="Noch kein Vorgang vorhanden"
            description="Erstellen Sie jetzt Ihren ersten Vorgang, um Business Partner und Endkunden über den aktuellen Status ihrer Anfragen auf dem laufenden zu halten."
            buttonLabel="Neuer Vorgang"
            onButtonClick={onAdd}
          />
        );

      case "active":
        return (
          <EmptyState
            title="Kein aktiver Vorgang vorhanden"
            description="Erstellen Sie jetzt Ihren ersten Vorgang, um Business Partner und Endkunden über den aktuellen Status ihrer Anfragen auf dem laufenden zu halten."
            buttonLabel="Neuer Vorgang"
            onButtonClick={onAdd}
          />
        );

      case "inactive":
        return (
          <EmptyState
            title="Keine abgeschlossenen Vorgänge vorhanden"
            description="Schließen Sie Vorgänge ab oder erstellen Sie Ihren ersten Vorgang."
            buttonLabel="Neuer Vorgang"
            onButtonClick={onAdd}
          />
        );
    }
  }, [type]);

  const renderFullName = useCallback(({ firstname, lastname }: any) => {
    if (firstname && lastname) {
      return `${firstname} ${lastname}`;
    }

    if (firstname && !lastname) {
      return firstname;
    }

    if (!firstname && lastname) {
      return lastname;
    }

    return "-";
  }, []);

  const renderClient = useCallback(
    ({ clientNumber, firstname, lastname, city, street, zipcode }: Client) => {
      if (city && street && zipcode) {
        return (
          <div>
            <span>
              {renderFullName({ firstname, lastname })} ({clientNumber})
            </span>
            <br />
            <span>{street},</span> <br />
            <span>
              {zipcode} {city}
            </span>
          </div>
        );
      }
      return <div>{renderFullName({ firstname, lastname })}</div>;
    },
    [renderFullName]
  );

  return (
    <DataContainer
      onSearchSubmit={onSearch}
      loading={isLoading}
      addButton={addButtonText}
      onAdd={onAdd}
      onReload={onReload}
      filterItems={filterItems}
      classes={{ paper: classes.paper }}
    >
      <DataTablePaginatedAndSortable<Case>
        data-testid={"CaseTable"}
        loading={isLoading}
        columns={columns}
        rows={cases}
        page={page}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        totalCount={totalCount}
        keyExtractor={(data) => data.id}
        onSortChange={onSortChange}
        sortedBy={sortedBy}
        renderRow={(data, index) => {
          return (
            <>
              <DataTableCell data-testid={"caseNumber"}>{data.caseNumber || "-"}</DataTableCell>
              <DataTableCell data-testid={"client"}>{data.client ? renderClient(data.client) : "Unbekannt"}</DataTableCell>
              <DataTableCell data-testid={"status"}>{data.status || "-"}</DataTableCell>
              <DataTableCell data-testid={"description"}>{data.subject ? data.subject : "-"}</DataTableCell>
              <DataTableCell data-testid={"agreement"}>{data.agreement || "-"}</DataTableCell>
              <DataTableCell data-testid={"date"}>
                {data.date ? format(new Date(data.date), "dd.MM.yyyy HH:mm") || "-" : "-"}
                <br />
              </DataTableCell>
              <DataTableCell>
                <Link data-testid={`link-view-row-${index}`} to={`/case/edit/${data.caseNumber}`}>
                  <VisibilityIcon color={"primary"} />
                </Link>
              </DataTableCell>
            </>
          );
        }}
        renderEmpty={() => emptyState}
      />
    </DataContainer>
  );
};

export default CaseTable;
