import { AxiosInstance } from "axios";

export default class ShopApi {
  constructor(private authClient: AxiosInstance) {}

  public async getShopEnabled(): Promise<string> {
    return await this.authClient.get(`/v2/shop/is-enabled`).then(({ data }) => data);
  }

  public async getShopUrl(): Promise<string | null> {
    return await this.authClient.get("/v2/shop/url").then(({ data }) => (data ? data : null));
  }
}
