import React, { useCallback, useState } from "react";
import { useQuery } from "react-query";
import { useService } from "core/di";
import UIRoute from "core/presentation/components/UIRoute";
import { RouteComponentProps } from "react-router-dom";
import ProductService from "features/products/domain/services/ProductService";
import { usePageAndPageSizeAndQueryFromRouteProps } from "core/utils";
import ProductsTable from "../components/ProductsTable";

export interface MessagesRouteProps extends RouteComponentProps {}

const ListProductsRoute = (props: MessagesRouteProps) => {
  const { page, pageSize, searchQuery } = usePageAndPageSizeAndQueryFromRouteProps(props);
  const [sortedBy, setSortedBy] = useState<{ field: string; order: "asc" | "desc" }>({ field: "category", order: "asc" });

  const productService = useService(ProductService);

  const { data, isLoading, refetch, isFetching } = useQuery(["productList", { searchQuery, page, pageSize, sortedBy }], () =>
    productService.get(searchQuery, page, pageSize, sortedBy)
  );

  const onHandleQuery = useCallback(
    (page, pageSize, searchQuery) => {
      let query = "/products?page=" + page;
      if (pageSize) {
        query += "&page_size=" + pageSize;
      }
      if (searchQuery) {
        query += "&searchQuery=" + searchQuery;
      }
      props.history.push(query);
    },
    [props.history]
  );

  const onSortChange = useCallback((sortBy: string, sortOrder: "asc" | "desc") => {
    setSortedBy({ field: sortBy, order: sortOrder });
  }, []);

  return (
    <UIRoute {...props} title="Produkte">
      <ProductsTable
        isLoading={isLoading || isFetching}
        onReload={refetch}
        searchQuery={searchQuery}
        onSearch={(searchValue) => {
          onHandleQuery(1, pageSize, searchValue);
        }}
        onAdd={() => {
          props.history.push("/products/import");
        }}
        products={data?.data}
        page={page}
        pageSize={pageSize}
        totalCount={data?.totalCount}
        onPageChange={(newPage: number) => {
          onHandleQuery(newPage, pageSize, searchQuery);
        }}
        onPageSizeChange={(newPageSize: number) => {
          onHandleQuery(page, newPageSize, searchQuery);
        }}
        sortedBy={sortedBy}
        onSortChange={onSortChange}
      />
    </UIRoute>
  );
};

export default ListProductsRoute;
