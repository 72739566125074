import AppointmentReservationModel from "features/pools/data/AppointmentReservationModel";
import AppointmentReservation from "../entities/AppointmentReservation";

export default class AppointmentConverter {
  public static fromEntityToModel(entity: AppointmentReservation): AppointmentReservationModel {
    return {
      TerminID: entity.assignmentId,
      AuftragNr: entity.assignmentNumber,
      MitarbeiterNr: entity.employeeNumber,
      TerminVon: entity.appointmentFrom.toISOString(),
      TerminBis: entity.appointmentUntil.toISOString(),
      b2bEmployeeNumber: entity.b2bEmployeeNumber,
    };
  }
}
