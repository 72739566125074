import React from "react";
import { Grid, IconButton, Avatar, Menu } from "@material-ui/core";

interface AvatarMenuLayoutProps {
  name: string;
  renderMenu: (close: () => void) => React.ReactNode[];
}

export default function ({ name, renderMenu }: AvatarMenuLayoutProps) {
  const [avatarMenuAnchor, setAvatarMenuAnchor] = React.useState<null | HTMLElement>(null);

  const handleAvatarMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAvatarMenuAnchor(event.currentTarget);
  };

  const handleAvatarMenuClose = () => {
    setAvatarMenuAnchor(null);
  };

  return (
    <Grid item>
      <IconButton
        onClick={handleAvatarMenuClick}
        color="inherit"
        style={{
          padding: 4,
        }}
      >
        <Avatar>{name}</Avatar>
      </IconButton>
      <Menu anchorEl={avatarMenuAnchor} keepMounted open={Boolean(avatarMenuAnchor)} onClose={handleAvatarMenuClose}>
        {renderMenu(handleAvatarMenuClose)}
      </Menu>
    </Grid>
  );
}
