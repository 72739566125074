import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
      fontWeight: "bold",
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  })
);

interface AccordionProps {
  // should be unique when used in lists
  key: string;
  title: string;
  details: React.ReactNode;
}

interface Props {
  accordions: (AccordionProps | null)[];
}

// modelled after example from https://material-ui.com/components/accordion/#controlled-accordion
const ControlledAccordions: React.FC<Props> = ({ accordions }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      {accordions.map((accordion) =>
        accordion ? (
          <Accordion key={accordion.key} expanded={expanded === accordion.key} onChange={handleChange(accordion.key)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} id={accordion.key + "-header"}>
              <Typography className={classes.heading}>{accordion.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>{accordion.details}</AccordionDetails>
          </Accordion>
        ) : null
      )}
    </div>
  );
};

export default ControlledAccordions;
