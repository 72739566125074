import React, { useCallback, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";
import FormSection from "template/presentation/components/form/FormSection";
import { Field, Formik } from "formik";
import MultiSelectField from "template/presentation/components/form/MutiSelectField";
import * as Yup from "yup";
import { TextField, CheckboxWithLabel } from "formik-material-ui";
import SelectOption from "core/interfaces/SelectOption";
import { queryCache, useQuery } from "react-query";
import UserService from "features/users/domain/services/UserService";
import { useService } from "core/di";
import AssignmentService from "features/assignments/domain/services/AssignmentService";

interface StartSignatureRequestDialogProps {
  open: boolean;
  assignmentId: string;
  clientSignatureOnly: boolean;
  clientSigNeeded: boolean;
  userSignatureCount?: number;
  signatureInfo: boolean;
  onDismiss: () => void;
}
export interface StartSignatureRequestDialogFormProps {
  user: SelectOption[];
  note: string;
  clientSigOnly: boolean;
  clientSigNeeded: boolean;
}

const validationSchema = Yup.object().shape({
  note: Yup.string().optional(),
  user: Yup.array().required("Benötigt"),
});

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: "500px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "left",
    "& *": {
      marginBottom: 5,
    },
  },
  warning: {
    marginTop: 10,
  },
  field: {
    marginTop: 5,
  },
}));

const StartSignatureRequestDialog: React.FC<StartSignatureRequestDialogProps> = ({
  onDismiss,
  open,
  assignmentId,
  clientSignatureOnly,
  userSignatureCount,
  clientSigNeeded,
  signatureInfo,
}) => {
  const classes = useStyles();
  const userService = useService(UserService);
  const assignmentService = useService(AssignmentService);
  const [error, setError] = useState<undefined | string>(undefined);

  const { data: userOptions } = useQuery(["userOptions", assignmentId], () =>
    userService.getUsersByPrescriberOrIntermediaryFromCurrentUser()
  );
  const onSubmit = useCallback(
    (model: StartSignatureRequestDialogFormProps, assignmentId: string) => {
      return assignmentService.startSignatureRequest(model, assignmentId);
    },
    [assignmentService]
  );
  return (
    <Formik<StartSignatureRequestDialogFormProps>
      initialValues={{
        note: "",
        user: [],
        clientSigOnly: clientSignatureOnly,
        clientSigNeeded: clientSigNeeded,
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          onSubmit(values, assignmentId)
            .then(() => queryCache.invalidateQueries(["/pools/assignments/", assignmentId]))
            .catch(() => setError("Ein unbekannter Fehler ist aufgetreten"));
        } finally {
          setSubmitting(false);
          onDismiss();
        }
      }}
    >
      {({ submitForm, isSubmitting, values }) => (
        <Dialog
          open={open}
          onClose={onDismiss}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: classes.paper }}
        >
          <DialogTitle id="alert-dialog-start-signatureRequest"> Unterschriften einholen </DialogTitle>
          <DialogContent className={classes.content}>
            {error && (
              <Alert className={classes.field} data-testid="start-signatureAssignment-error" severity="error">
                {error}
              </Alert>
            )}
            <FormSection label={"Auftragdetails"}>
              <Field
                data-testid="field-note"
                className={classes.field}
                component={TextField}
                variant="outlined"
                name="note"
                type="text"
                label="Bemerkung"
              />
              <Field
                data-testid={"field-involved-employees"}
                name="user"
                className={classes.field}
                legend={"Beteiligte Benutzer"}
                component={MultiSelectField}
                options={
                  userOptions
                    ? userOptions.map((u) => ({ label: `${u.firstname} ${u.lastname}`, value: u.id, b2bNumber: u.b2bNumber }))
                    : []
                }
                getTestIdForOption={(option: any) => option.b2bNumber}
              />
              {!signatureInfo && (
                <Alert className={classes.field} data-testid="user-signature-amount-hint" severity="warning">
                  {`Die Dokumentstruktur ist unbekannt. Ein Auftrag kann nicht erstellt werden.`}
                </Alert>
              )}
              {userSignatureCount !== undefined && signatureInfo && (
                <Alert className={classes.field} data-testid="user-signature-amount-hint" severity="info">
                  {`Benötigte Unterschriften von Benutzern: ${userSignatureCount}`}
                </Alert>
              )}
              {clientSigNeeded && signatureInfo && (
                <Alert className={classes.field} data-testid="client-signature-needed-hint" severity="info">
                  {`${clientSigNeeded ? "Eine " : "Keine "} Unterschrift des Kunden wird benötigt`}
                </Alert>
              )}
            </FormSection>
          </DialogContent>
          <DialogActions>
            <Button data-testid="create-case" disabled={isSubmitting || !signatureInfo} onClick={submitForm}>
              Auftrag erstellen{" "}
            </Button>
          </DialogActions>
          <DialogActions>
            <Button onClick={onDismiss}>Schließen</Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

export default StartSignatureRequestDialog;
