import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import RefreshIcon from "@material-ui/icons/Refresh";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import { FormControl, InputLabel } from "@material-ui/core";
import { B2BBranchProps } from "./BranchesTable";

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      maxWidth: 936,
      margin: "auto",
      overflow: "hidden",
    },
    searchBar: {},
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    block: {
      display: "block",
    },
    addUser: {
      marginRight: theme.spacing(1),
    },
    contentWrapper: {
      margin: "0px 0px",
    },
    toolbar: {
      paddingRight: 8,
    },
    select: {
      margin: theme.spacing(1),
      minWidth: 150,
      "&:focus": {
        backgroundColor: "inherit",
      },
    },
    selectOutlinePadding: {
      padding: 0,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  });

export interface DataContainerProps extends WithStyles<typeof styles> {
  loading?: boolean;
  createNewBranchText: string;
  onAddBranch: () => void;
  onReload?: () => void;
  b2b?: B2BBranchProps;
}

const BranchDataContainer: React.FC<DataContainerProps> = ({
  classes,
  loading,
  b2b,
  createNewBranchText,
  onAddBranch,
  onReload,
  children,
}) => {
  return (
    <Paper className={classes.paper}>
      <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
        <Toolbar className={classes.toolbar}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              {b2b && b2b.b2bPartnerOptions.length > 0 && (
                <FormControl className={classes.formControl}>
                  <InputLabel id="client-select" style={{ paddingLeft: 5 }}>
                    B2B-Partner
                  </InputLabel>
                  <Select
                    data-testid="client-select"
                    value={b2b.currentB2BPartner}
                    onChange={(e) => b2b.onCurrentB2BPartnerChange(e.target.value as string)}
                    variant="outlined"
                    // classes={{ select: classes.select, outlined: classes.selectOutlinePadding }}
                  >
                    <MenuItem key="label" value="" disabled>
                      B2B-Kunden auswählen
                    </MenuItem>
                    {b2b.b2bPartnerOptions.map((option, index) => (
                      <MenuItem key={index} value={option.id} data-testid={`client-select-${option.b2bNumber}`}>
                        {option.name + ` (${option.b2bNumber}) [${option.type === "Intermediary" ? "Vermittler" : "Verordner"}]`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>

            <Grid item>
              {createNewBranchText && (
                <Button
                  data-testid="newInternalBranch_Button"
                  onClick={onAddBranch}
                  variant="contained"
                  color="primary"
                  disabled={onAddBranch === undefined || loading === true}
                  className={classes.addUser}
                >
                  {createNewBranchText}
                </Button>
              )}
              {onReload && (
                <Tooltip title="Reload">
                  <span>
                    <IconButton data-testid="datatable-reload" onClick={onReload} disabled={loading === true}>
                      <RefreshIcon className={classes.block} color="inherit" />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.contentWrapper}>{children}</div>
    </Paper>
  );
};

export default withStyles(styles)(BranchDataContainer);
