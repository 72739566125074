import React, { useMemo } from "react";
import { Field, Formik } from "formik";
import PoolAssignment from "features/pools/domain/entities/PoolAssignment";
import SelectField from "template/presentation/components/form/SelectField";
import { ChangePoolModel, SelectOption } from "features/pools/domain/entities/PoolEntity";
import { Button, makeStyles } from "@material-ui/core";

interface Props {
  assignment: PoolAssignment;
  poolOptions: SelectOption[] | undefined;
  onChangePool: (values: ChangePoolModel) => Promise<void>;
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

export const ChangePoolSection: React.FC<Props> = ({ assignment, poolOptions, onChangePool }) => {
  const classes = useStyles();

  const initValues = useMemo(() => {
    return { targetPoolId: "", assignmentId: assignment.assignmentId };
  }, [assignment]);

  return (
    <div>
      <Formik<ChangePoolModel>
        initialValues={initValues}
        onSubmit={async (values: ChangePoolModel) => {
          await onChangePool(values);
        }}
      >
        {({ submitForm }) => {
          return poolOptions ? (
            <div className={classes.container}>
              <Field
                data-testid="field-changepool"
                name="targetPoolId"
                legend="Pool wählen"
                component={SelectField}
                options={poolOptions}
                getTestIdForOption={(option: any) => option.label}
              />
              <Button className={classes.button} color="primary" variant="contained" data-testid="button-changepool" onClick={submitForm}>
                Pool zuweisen
              </Button>
            </div>
          ) : (
            <div>Kein anderer Pool vorhanden.</div>
          );
        }}
      </Formik>
    </div>
  );
};
