import React, { ChangeEventHandler, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import FormContainer from "template/presentation/components/form/FormContainer";
import FormSection from "template/presentation/components/form/FormSection";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import { Alert } from "@material-ui/lab";
import FormSubmitButton from "template/presentation/components/form/FormSubmitButton";
import SelectField from "template/presentation/components/form/SelectField";
import { format } from "date-fns";
import ValueGrid from "template/presentation/components/content/ValueGrid";
import LabeledValue from "template/presentation/components/content/LabeledValue";
import { endOfDay } from "date-fns/esm";
import SelectOption from "core/interfaces/SelectOption";
import Case from "features/cases/domain/entities/Case";
import ControlledAutocomplete from "template/presentation/components/form/ControlledAutocomplete";
import ComboBox from "template/presentation/components/form/ComboBox";
import { Branch } from "features/branches/domain/entities/BranchEntity";
import MultiSelectField from "template/presentation/components/form/MutiSelectField";
import { PrescriberOption } from "features/clients/domain/entities/Client";

export interface CreateOrUpdateCaseFormValues {
  clientNumber: string;
  employeeNumber: string;
  branchNumber: string;
  appointmentFrom?: string;
  appointmentUntil?: string;
  subject: string;
  agreement: string;
  classificationNumber: string;
  branchId?: string;
  genAssignment?: boolean;
  prescribers?: PrescriberOption[];
}

interface CreateOrUpdateCaseFormProps {
  onSubmit: (values: CreateOrUpdateCaseFormValues) => void;
  error: string | null;
  initialCaseData?: Case;
  clientNumber?: string | null;
  branchOptions: SelectOption[];
  employeeOptions: (SelectOption & { number: string })[];
  classificationOptions: SelectOption[];
  isLoading?: boolean;
  preSelectedBranch?: string;
  setSelectedBranch: (selectedBranch: string) => void;
  clientSelection: {
    clientOptions: SelectOption[];
    setClientQuery: (value: string) => void;
    clientOptionsLoading: boolean;
  };
  myBranch?: Branch | null;
  enableReinitialize?: boolean;
  prescriberOptions?: PrescriberOption[];
}

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(1),
  },
}));

const CreateOrUpdateCaseForm: React.FC<CreateOrUpdateCaseFormProps> = (props) => {
  const {
    onSubmit,
    error,
    initialCaseData,
    clientNumber,
    classificationOptions,
    branchOptions,
    employeeOptions,
    preSelectedBranch,
    setSelectedBranch,
    clientSelection,
    myBranch,
    enableReinitialize,
    prescriberOptions,
  } = props;
  const { clientOptions, setClientQuery } = clientSelection;

  const classes = useStyles();

  const employeeOptionsWithLabelAsValue = useMemo(() => {
    return employeeOptions.map((employee) => ({
      value: employee.label,
      label: employee.label,
      number: employee.number,
    }));
  }, [employeeOptions]);

  const initClassificationNumber = useMemo(() => {
    if (initialCaseData?.classification) {
      return initialCaseData?.classification.number;
    }
    return "";
  }, [initialCaseData?.classification]);

  const initEmployeeNumber = useMemo(() => {
    if (initialCaseData?.employee && initialCaseData.employee.employeeNumber) {
      return initialCaseData?.employee.employeeNumber;
    }
    return "";
  }, [initialCaseData]);

  const initBranchNumber = useMemo(() => {
    if (myBranch) {
      return myBranch.code;
    } else if (branchOptions.length === 1) {
      return branchOptions[0].number ? branchOptions[0].number : "";
    } else if (preSelectedBranch) {
      let branch = branchOptions.find((op) => op.value === preSelectedBranch);
      if (branch && branch.number) {
        return branch.number;
      }
    }
    return "";
  }, [branchOptions, myBranch, preSelectedBranch]);

  const initBranchId = useMemo(() => {
    if (myBranch) {
      return myBranch.id;
    } else if (branchOptions.length === 1) {
      return branchOptions[0].value;
    } else if (preSelectedBranch) {
      return preSelectedBranch;
    }
    return "";
  }, [branchOptions, myBranch, preSelectedBranch]);

  const initialValuesFromProps = useMemo(() => {
    if (initialCaseData === undefined) {
      const values: CreateOrUpdateCaseFormValues = {
        clientNumber: clientNumber || "",
        appointmentFrom: "",
        appointmentUntil: "",
        employeeNumber: "",
        agreement: "",
        classificationNumber: "",
        subject: "",
        branchNumber: initBranchNumber,
        branchId: initBranchId,
      };
      return values;
    } else {
      const values: CreateOrUpdateCaseFormValues = {
        clientNumber: clientNumber || "",
        appointmentFrom: initialCaseData.appointmentFrom ? format(initialCaseData.appointmentFrom, "yyyy-MM-dd'T'HH:mm") : "",
        appointmentUntil: initialCaseData.appointmentUntil ? format(initialCaseData.appointmentUntil, "yyyy-MM-dd'T'HH:mm") : "",
        employeeNumber: initEmployeeNumber,
        agreement: initialCaseData.agreement || "",
        classificationNumber: initClassificationNumber,
        subject: initialCaseData.subject ? initialCaseData.subject : "",
        branchNumber: initBranchNumber,
        branchId: initBranchId,
        genAssignment: false,
        prescribers:
          (initialCaseData.prescribers?.map((prescriberId) =>
            prescriberOptions?.find((po) => po.value === prescriberId)
          ) as PrescriberOption[]).filter((x) => !!x) || [],
      };
      return values;
    }
  }, [clientNumber, initBranchNumber, initialCaseData, initClassificationNumber, initEmployeeNumber, prescriberOptions]);

  const createCaseSchema = Yup.object().shape({
    clientNumber: Yup.string().required("Benötigt"),
    employeeNumber: initialCaseData ? Yup.string().optional() : Yup.string().required("Benötigt"),
    branchNumber: Yup.string().required("Benötigt"),
    subject: Yup.string().required("Benötigt"),
    appointmentFrom: Yup.string().optional(),
    appointmentUntil: Yup.string()
      .when("appointmentFrom", {
        is: (date) => date,
        then: Yup.string().required("Benötigt"),
        otherwise: Yup.string().optional(),
      })
      .when("appointmentFrom", (appointmentFrom: Date) => {
        if (appointmentFrom) {
          return Yup.date().min(appointmentFrom, "Der Endzeitpunkt muss nach dem Startzeitpunkt liegen.");
        }
      }),
    agreement: Yup.string().required("Benötigt"),
    classificationNumber: Yup.string().required("Benötigt"),
  });

  const updateCaseSchema = Yup.object().shape({
    clientNumber: Yup.string().optional(),
    employeeNumber: Yup.string().optional(),
    branchNumber: Yup.string().optional(),
    subject: Yup.string().optional(),
    appointmentFrom: Yup.string().optional(),
    appointmentUntil: Yup.string().optional(),
    agreement: Yup.string().required("Benötigt"),
    classificationNumber: Yup.string().optional(),
  });

  return (
    <Formik<CreateOrUpdateCaseFormValues>
      initialValues={initialValuesFromProps}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
        } finally {
          setSubmitting(false);
        }
      }}
      validationSchema={initialCaseData ? updateCaseSchema : createCaseSchema}
      enableReinitialize={enableReinitialize !== undefined ? enableReinitialize : true}
    >
      {({ submitForm, isSubmitting, values, setFieldValue, handleChange, errors }) => {
        return (
          <FormContainer>
            {initialCaseData && (
              <FormSection label={"Identifikatoren"}>
                <ValueGrid>
                  <LabeledValue label="Vorgangsnummer" value={initialCaseData.caseNumber} />
                </ValueGrid>
              </FormSection>
            )}
            <FormSection label={"Um welchen Kunden handelt es sich?"}>
              <Field
                data-testid={"field-client"}
                className={classes.field}
                component={ComboBox}
                options={clientOptions || []}
                getTestIdForOption={(option: any) => option.number}
                variant={"outlined"}
                legend="Kundennummer"
                name="clientNumber"
                onSelectOption={setClientQuery ? (value: string) => setClientQuery(value) : undefined}
                onChangeText={setClientQuery ? (value: string) => setClientQuery(value) : undefined}
                disabled={!!initialCaseData?.client}
              />
            </FormSection>
            <FormSection label={"Details"}>
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <Field
                  data-testid="field-time-from"
                  style={{ flex: "0 0 49.5%", marginRight: "1%" }}
                  className={classes.field}
                  component={TextField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={!!initialCaseData}
                  value={values.appointmentFrom}
                  type={"datetime-local"}
                  variant={"outlined"}
                  name={"appointmentFrom"}
                  label={"Kundentermin Von"}
                />
                <Field
                  data-testid="field-time-until"
                  style={{ flex: "0 0 49.5%" }}
                  className={classes.field}
                  component={TextField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type={"datetime-local"}
                  variant={"outlined"}
                  name={"appointmentUntil"}
                  value={values.appointmentUntil}
                  label={"Bis*"}
                  inputProps={{
                    min: values.appointmentFrom && format(new Date(values.appointmentFrom), "yyyy-MM-dd'T'HH:mm"),
                    max: values.appointmentFrom && format(endOfDay(new Date(values.appointmentFrom)), "yyyy-MM-dd'T'HH:mm"),
                  }}
                  disabled={!values.appointmentFrom || !!initialCaseData}
                />
              </div>
              <Field
                data-testid="field-subject"
                className={classes.field}
                component={TextField}
                value={values.subject}
                disabled={!!initialCaseData}
                variant={"outlined"}
                name={"subject"}
                label={"Problem/Betreff*"}
              />
              <Field
                data-testid="field-agreement"
                className={classes.field}
                component={TextField}
                multiline
                rows={4}
                value={values.agreement}
                variant={"outlined"}
                name={"agreement"}
                label={"Vereinbarung*"}
              />
              {!initialCaseData && (
                <ControlledAutocomplete
                  className={classes.field}
                  data-testid="field-classification"
                  label="Klassifizierung"
                  disabled={!!initialCaseData}
                  name="classificationNumber"
                  initialValue={values.classificationNumber}
                  options={classificationOptions || []}
                  onSelectOption={handleChange("classificationNumber")}
                />
              )}
            </FormSection>

            <FormSection label="Welche Niederlassung bearbeitet den Vorgang?">
              <Field
                data-testid="field-branch"
                className={classes.field}
                component={SelectField}
                options={branchOptions || []}
                disabled={!!initialCaseData}
                getTestIdForOption={(option: SelectOption) => option.number}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  let branchNumber = branchOptions.find((op) => event.target.value === op.value)?.number;
                  if (!branchNumber) {
                    return;
                  }
                  setFieldValue("branchNumber", branchNumber);
                  setFieldValue("branchId", event.target.value);
                  setSelectedBranch(event.target.value);
                }}
                variant={"outlined"}
                name={"branchNumber"}
                value={values.branchId}
                legend="Niederlassung wählen*"
              />
            </FormSection>

            {!initialCaseData && (
              <FormSection label={"Welcher Mitarbeiter ist zuständig?"}>
                <Field
                  data-testid={"field-user"}
                  className={classes.field}
                  component={ComboBox}
                  values={values.employeeNumber}
                  options={employeeOptionsWithLabelAsValue || []}
                  getTestIdForOption={(option: any) => option.number}
                  variant={"outlined"}
                  legend={values.branchNumber ? "Mitarbeiter wählen*" : "Zuerst Niederlassung wählen"}
                  name={"employeeNumber"}
                />
              </FormSection>
            )}

            {values.prescribers && (
              <FormSection label={"Verordner"}>
                <Field
                  disabled={true}
                  data-testid="field-prescribers"
                  name="prescribers"
                  legend="Verordner"
                  component={MultiSelectField}
                  options={prescriberOptions || []}
                  getTestIdForOption={(option: any) => option.number}
                  style={{ marginTop: 10 }}
                />
              </FormSection>
            )}

            <FormSection>
              {error && (
                <Alert className={classes.field} data-testid="case-form-error" severity="error">
                  {error}
                </Alert>
              )}

              {initialCaseData && (
                <Field
                  component={CheckboxWithLabel}
                  type="checkbox"
                  name="genAssignment"
                  Label={{ label: "Pool Auftrag erstellen" }}
                  data-testid="field-genAssignment"
                />
              )}

              <FormSubmitButton data-testid="button-createcase" disabled={isSubmitting} onClick={submitForm}>
                {initialCaseData ? "Bearbeiten" : "Vorgang erstellen"}
              </FormSubmitButton>
            </FormSection>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default CreateOrUpdateCaseForm;
