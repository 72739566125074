import React from "react";
import SubTabHeader from "template/presentation/components/nav/SubTabHeader";

interface Props {
  path: string;
  onTabChange: (path: string) => void;
  poolId: string;
  b2bView: boolean;
  poolType: "internal" | "b2b";
}

const PoolsSubHeader: React.FC<Props> = ({ path, onTabChange, poolId, b2bView, poolType }) => (
  <SubTabHeader
    currentPath={path}
    onTabChange={onTabChange}
    tabs={
      b2bView
        ? [
            { value: `/pools/assignments/all`, label: "Alle" },
            { value: `/pools/assignments/active`, label: "Offene", testId: "submenu-open-cases" },
            { value: `/pools/assignments/archived`, label: "Archivierte", testId: "submenu-close-cases" },
          ]
        : [
            { value: `/pools/${poolId}/assignments/${poolType}/all`, label: "Alle" },
            { value: `/pools/${poolId}/assignments/${poolType}/active`, label: "Offene", testId: "submenu-open-cases" },
            {
              value: `/pools/${poolId}/assignments/${poolType}/archived`,
              label: "Archivierte",
              testId: "submenu-close-cases",
            },
          ]
    }
  />
);

export default PoolsSubHeader;
