import { SortCriteria } from "core/interfaces/Sort";
import ClassificationApi from "features/classifications/data/ClassificationApi";
import { ClassificationRequestModel } from "features/classifications/data/ClassificationRequestModel";
import ClassificationConverter from "../converters/ClassificationConverter";
import { Classification, SelectMappingOptions } from "../entities/ClassificationEntity";

export const mappingTypes = ["DELIVERY", "COLLECTION", "CONSULTING", "REPAIR", "VISIT", "VIDEO_CONSULTATION"] as const;

export type MappingType = typeof mappingTypes[number];

const translationMappingTypes: { [key in MappingType]: string } = {
  DELIVERY: "Lieferung",
  COLLECTION: "Abholung",
  CONSULTING: "Beratung",
  REPAIR: "Reparatur",
  VISIT: "Heimbesuch",
  VIDEO_CONSULTATION: "Videoberatung",
};

export default class ClassificationService {
  constructor(private classificationApi: ClassificationApi) {}

  public get(searchQuery?: string | undefined, sortCriteria: SortCriteria = { order: "asc", field: "number" }) {
    return this.classificationApi
      .getAll(searchQuery, sortCriteria)
      .then((classifications) =>
        classifications.map((classification) => ClassificationConverter.fromResponseModelToEntity(classification))
      );
  }

  public async getById(id: string): Promise<Classification> {
    return this.classificationApi.getById(id).then((classification) => ClassificationConverter.fromResponseModelToEntity(classification));
  }

  public async update(id: string, values: ClassificationRequestModel) {
    return this.classificationApi.update(id, values);
  }

  public async create(values: ClassificationRequestModel) {
    return this.classificationApi.create(values);
  }

  public getMappingOptions(): SelectMappingOptions[] {
    return mappingTypes.map((val) => {
      return { value: val, label: translationMappingTypes[val] };
    });
  }

  public translateMapping(mapping: MappingType): string {
    return translationMappingTypes[mapping];
  }

  public getAvailable() {
    return this.classificationApi
      .getAvailable()
      .then((classifications) =>
        classifications.map((classification) => ClassificationConverter.fromResponseModelToEntity(classification))
      );
  }

  public getAvailableNotInBranch(branchId: string) {
    return this.classificationApi
      .getAvailableNotInBranch(branchId)
      .then((classifications) =>
        classifications.map((classification) => ClassificationConverter.fromResponseModelToEntity(classification))
      );
  }

  public getAvailableAndUsed(poolId: string) {
    return this.classificationApi
      .getAvailableAndUsed(poolId)
      .then((classifications) =>
        classifications.map((classification) => ClassificationConverter.fromResponseModelToEntity(classification))
      );
  }

  public getAvailableNotInBranchAndUsed(poolId: string, branchId: string) {
    return this.classificationApi
      .getAvailableNotInBranchAndUsed(poolId, branchId)
      .then((classifications) =>
        classifications.map((classification) => ClassificationConverter.fromResponseModelToEntity(classification))
      );
  }
}
