import { Dialog, Button, DialogTitle, DialogContent, DialogActions, makeStyles } from "@material-ui/core";
import { RotateRight } from "@material-ui/icons";
import React, { useCallback, useState } from "react";

interface ImageDialogProps {
  fileUri: string;
  maxHeight?: string | number;
  testId?: string;
}

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: "flex",
    justifyContent: "center",
  },
  dialogActions: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export const ImagePreviewWithDialog: React.FC<ImageDialogProps> = ({ fileUri, maxHeight, testId }) => {
  const classes = useStyles();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [rotateDegrees, setRotateDegrees] = useState<number>(0);

  const rotateBy90Degrees = useCallback(() => setRotateDegrees((rotateDegrees + 90) % 360), [rotateDegrees, setRotateDegrees]);
  const openImageInNewTab = useCallback(() => {
    window.open(fileUri);
  }, [fileUri]);

  return (
    <>
      <img
        data-testid={testId}
        style={{ maxHeight: maxHeight || "300px" }}
        key={fileUri}
        src={fileUri}
        alt={"Anhang"}
        onClick={() => setDialogOpen(true)}
      />
      <Dialog data-testid="image-dialog" fullWidth={true} maxWidth="lg" open={dialogOpen}>
        <DialogTitle>Anhang</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <img style={{ transform: `rotate(${rotateDegrees}deg)`, objectFit: "contain" }} key={fileUri} src={fileUri} alt={"Anhang"} />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button variant="outlined" onClick={rotateBy90Degrees} endIcon={<RotateRight />}>
            Drehen
          </Button>
          <Button data-testid="image-open-new-tab" variant="outlined" onClick={openImageInNewTab}>
            Bild im neuen Tab öffnen
          </Button>
          <Button variant="outlined" color="primary" onClick={() => setDialogOpen(false)}>
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
