import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from "@material-ui/icons/Refresh";

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      maxWidth: 936,
      margin: "auto",
      overflow: "hidden",
    },
    searchBar: {},
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    block: {
      display: "block",
    },
    addUser: {
      marginRight: theme.spacing(1),
    },
    contentWrapper: {
      margin: "0px 0px",
    },
    toolbar: {
      paddingRight: 8,
    },
  });

export interface DataContainerProps extends WithStyles<typeof styles> {
  loading?: boolean;
  searchPlaceholder?: string;
  addButton?: string;
  onAdd?: () => void;
  onReload?: () => void;
  onSearch?: (query: string | undefined) => void;
  showSearchbar?: boolean;
  onSearchSubmit?: (query: string | undefined) => void;
  searchQuery?: string;
}

const DataContainer: React.FC<DataContainerProps> = ({
  classes,
  loading,
  searchPlaceholder,
  addButton,
  onAdd,
  onReload,
  onSearch,
  children,
  showSearchbar = true,
  onSearchSubmit,
  searchQuery,
}) => {
  const [searchValue, setSearchValue] = useState<string>(searchQuery || "");

  useEffect(() => {
    if (onSearch) {
      onSearch(searchValue === "" ? undefined : searchValue);
    }
  }, [onSearch, searchValue]);

  return (
    <Paper className={classes.paper}>
      {showSearchbar && (
        <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
          <Toolbar className={classes.toolbar}>
            <Grid container spacing={2} alignItems="center">
              {searchPlaceholder && (
                <>
                  <Grid item>
                    <SearchIcon
                      className={classes.block}
                      color="inherit"
                      onClick={() => {
                        if (onSearchSubmit) {
                          onSearchSubmit(searchValue);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      fullWidth
                      value={searchValue}
                      onChange={(x) => {
                        setSearchValue(x.target.value as string);
                      }}
                      placeholder={searchPlaceholder}
                      data-testid={"search-field"}
                      InputProps={{
                        disableUnderline: true,
                        className: classes.searchInput,
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && onSearchSubmit) {
                          onSearchSubmit(searchValue);
                        }
                      }}
                    />
                  </Grid>
                </>
              )}
              <Grid item>
                {addButton && (
                  <Button
                    data-testid="datatable-add"
                    onClick={onAdd}
                    variant="contained"
                    color="primary"
                    disabled={onAdd === undefined || loading === true}
                    className={classes.addUser}
                  >
                    {addButton}
                  </Button>
                )}
                {onReload && (
                  <Tooltip title="Reload">
                    <span>
                      <IconButton data-testid="datatable-reload" onClick={onReload} disabled={loading === true}>
                        <RefreshIcon className={classes.block} color="inherit" />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      )}
      <div className={classes.contentWrapper}>
        {/*
        <Typography color="textSecondary" align="center">
          No users for this project yet
        </Typography>
        */}
        {children}
      </div>
    </Paper>
  );
};

export default withStyles(styles)(DataContainer);
