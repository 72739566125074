import React, { useMemo, useState } from "react";
import { withStyles, Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import { TableFooter } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

export const DataTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#f5f5f5",
      color: "rgba(0,0,0,0.54)",
      fontWeight: 600,
      fontSize: 12,
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      padding: "3px 15px",
    },
    body: {
      fontSize: 13,
      color: "rgba(0,0,0,0.54)",
      borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
    },
  })
)(TableCell);

export const DataTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {},
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

interface DataTableColumn {
  name: string;
}

interface DataTableProps<T> {
  columns: DataTableColumn[];
  rows: T[] | null | undefined;
  loading?: boolean;
  initialPage?: number;
  pageSize?: number;
  renderRow: (data: T, index: number) => React.ReactNode;
  keyExtractor: (data: T, index: number) => string;
  renderEmpty?: () => React.ReactNode;
  "data-testid"?: string;
}

function DataTable<T>(props: DataTableProps<T>): React.ReactElement<DataTableProps<T>> {
  const { columns, rows, loading, renderRow, renderEmpty, keyExtractor, initialPage = 0, pageSize } = props;
  const classes = useStyles();

  const [currentPage, setCurrentPage] = useState<number>(initialPage);

  const hasEntries = rows && rows.length > 0;

  const pagedRows = useMemo(() => {
    if (!pageSize || !rows) {
      return rows;
    }

    const beginIndex = currentPage * pageSize;
    const endIndex = (currentPage + 1) * pageSize;

    return rows.slice(beginIndex, endIndex);
  }, [rows, currentPage, pageSize]);

  const pagination = useMemo(() => {
    if (!pageSize || !rows || !hasEntries) {
      return null;
    }

    return (
      <TableFooter>
        <TableRow>
          <TableCell align="center" colSpan={columns.length}>
            <Pagination
              siblingCount={5}
              onChange={(e, x) => {
                setCurrentPage(x - 1);
              }}
              count={Math.ceil(rows.length / pageSize)}
              shape="rounded"
            />
          </TableCell>
        </TableRow>
      </TableFooter>
    );
  }, [columns.length, pageSize, rows, hasEntries]);

  return (
    <div style={{ position: "relative" }}>
      {hasEntries && loading ? (
        <div
          style={{
            position: "absolute",
            display: "flex",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            paddingTop: 24,
            justifyContent: "center",
            backgroundColor: "#FFFFFF88",
          }}
        >
          <CircularProgress />
        </div>
      ) : null}
      <Table data-testid={props["data-testid"]} className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            {columns.map((x) => (
              <DataTableCell key={x.name}>{x.name}</DataTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {pagedRows &&
            pagedRows.map((row, index) => (
              <DataTableRow data-testid={`${props["data-testid"]}-row-${index}`} key={keyExtractor(row, index)}>
                {renderRow(row, index)}
              </DataTableRow>
            ))}
        </TableBody>
        {pagination}
      </Table>
      {!hasEntries && loading ? (
        <div
          style={{
            display: "flex",
            padding: 24,
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : null}
      {!hasEntries && !loading && renderEmpty ? renderEmpty() : null}
    </div>
  );
}

export default DataTable;
