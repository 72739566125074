import React, { useCallback, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useService } from "core/di";
import UIRoute from "core/presentation/components/UIRoute";
import { RouteComponentProps, useParams } from "react-router-dom";
import { usePageAndPageSizeAndAnyQueryFromRouteProps } from "core/utils";
import CaseService from "features/cases/domain/services/CaseService";
import CaseTable from "../components/CaseTable";
import CasesSubHeader from "../components/CasesSubHeader";
import useUrlQueryParams from "core/presentation/hooks/useUrlQueryParams";
import { FilterItem } from "template/presentation/components/table/DataContainerExtendedSearch";
import SelectOption from "core/interfaces/SelectOption";
import ClientService from "features/clients/domain/services/ClientService";

export interface CasesRouteProps extends RouteComponentProps {}

const CasesRoute = (props: CasesRouteProps) => {
  const { type } = useParams<{ type?: "active" | "inactive" }>();
  const urlQueryParams = useUrlQueryParams();
  const filterByCaseNumber = urlQueryParams.get("caseNumber");

  const { page, pageSize, searchQueries } = usePageAndPageSizeAndAnyQueryFromRouteProps(props);
  const [sortedBy, setSortedBy] = useState<{ field: string; order: "asc" | "desc" }>({ field: "date", order: "desc" });
  const [clientQuery, setClientQuery] = useState<string>("");

  const caseService = useService(CaseService);
  const clientService = useService(ClientService);

  const { data: cases, refetch: refetchCases, isLoading: casesLoading } = useQuery(
    [
      "caseList",
      {
        caseNumber: searchQueries.caseNumber,
        firstname: searchQueries.firstname,
        lastname: searchQueries.lastname,
        client: searchQueries.client,
        sortedBy,
        page,
        pageSize,
        type,
      },
    ],
    () =>
      caseService.get({
        caseNumberQuery: searchQueries.caseNumber,
        clientIdQuery: searchQueries.client,
        firstname: searchQueries.firstname,
        lastname: searchQueries.lastname,
        page,
        pageSize,
        sortedBy,
        // undefined => get all cases
        closed: type === undefined ? undefined : type === "inactive",
      }),
    { enabled: sortedBy && page && pageSize }
  );

  const onHandleQuery = useCallback(
    (page, pageSize, searchQueries) => {
      let query = "/cases" + (type !== undefined ? "/" + type : "") + "?page=" + page;
      if (pageSize) {
        query += "&page_size=" + pageSize;
      }

      if (searchQueries) {
        for (const [key, value] of Object.entries(searchQueries)) {
          if (value) {
            query += `&${key}=${value}`;
          }
        }
      }

      props.history.replace(query);
    },
    [props.history, type]
  );

  const onSortChange = useCallback((sortBy: string, sortOrder: "asc" | "desc") => {
    setSortedBy({ field: sortBy, order: sortOrder });
  }, []);

  const { data: clientOptions } = useQuery(
    [clientQuery],
    () => {
      return clientService.get({ fields: { clientNumber: clientQuery }, group: "b2c", page: 1, pageSize: 10 }).then((r): SelectOption[] => {
        return r.data.map((client) => {
          return {
            label: `${client.firstname} ${client.lastname} (${client.clientNumber})`,
            value: client.id,
            number: client.clientNumber,
          };
        });
      });
    },
    { enabled: clientQuery }
  );

  const filterItems: FilterItem[] = useMemo(() => {
    return [
      {
        name: "Vorgangsnummer",
        searchField: "caseNumber",
        initialValue: { label: filterByCaseNumber || "", value: filterByCaseNumber || "" },
      },
      {
        name: "Kundennummer",
        searchField: "client",
        setTypeAheadQuery: (query) => setClientQuery(query),
        typeAheadOptions: clientOptions,
        typeAheadTestProperty: "number",
      },
      {
        name: "Vorname",
        searchField: "firstname",
        initialValue: { label: "", value: "" },
      },
      {
        name: "Nachname",
        searchField: "lastname",
        initialValue: { label: "", value: "" },
      },
    ];
  }, [filterByCaseNumber, setClientQuery, clientOptions]);

  return (
    <UIRoute
      {...props}
      title="Vorgänge"
      renderSubHeader={() => <CasesSubHeader path={props.location.pathname} onTabChange={props.history.push} />}
    >
      <div data-testid="cases-table">
        <CaseTable
          addButtonText="Neuer Vorgang"
          onAdd={() => props.history.push("/cases/create")}
          cases={cases?.data || null}
          onReload={refetchCases}
          isLoading={casesLoading}
          page={page}
          pageSize={pageSize}
          totalCount={cases?.totalCount}
          onPageChange={(newPage: number) => onHandleQuery(newPage, pageSize, searchQueries)}
          onPageSizeChange={(newPageSize: number) => onHandleQuery(page, newPageSize, searchQueries)}
          sortedBy={sortedBy}
          onSortChange={onSortChange}
          onSearch={(searchValue) => onHandleQuery(1, pageSize, searchValue)}
          filterItems={filterItems}
          type={type}
        />
      </div>
    </UIRoute>
  );
};

export default CasesRoute;
