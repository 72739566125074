import React, { useCallback } from "react";
import { useCurrentUserInGroup } from "../../../login/presentation/hooks/useUser";
import Message from "../../domain/entities/Message";
import MessageService from "../../domain/services/MessageService";
import { useService } from "core/di";
import { format } from "date-fns";
import ContentContainer from "template/presentation/components/content/ContentContainer";
import ContentSection from "template/presentation/components/content/ContentSection";
import LabeledValue from "template/presentation/components/content/LabeledValue";
import ValueGrid from "template/presentation/components/content/ValueGrid";
import AssignmentService from "features/assignments/domain/services/AssignmentService";

export interface ReadMessageProps {
  message: Message;
}

const ReadMessage = (props: ReadMessageProps) => {
  const { message } = props;

  const messageService = useService(MessageService);
  const assignmentService = useService(AssignmentService);
  const [, isNotB2BBackoffice] = useCurrentUserInGroup("b2bBackoffice");

  const renderRead = useCallback((unread: boolean) => {
    if (unread) {
      return "Ungelesen";
    }

    return "Gelesen";
  }, []);

  return (
    <ContentContainer>
      <ContentSection label="Nachrichten Kopf">
        <ValueGrid>
          <LabeledValue
            data-testid="message_recipient"
            label="Empfänger"
            value={message.recipient ? messageService.getFullName(message.recipient) : "-"}
          />
          <LabeledValue
            data-testid="message_sender"
            label="Absender"
            value={message.sender ? messageService.getFullName(message.sender) : "-"}
          />
          <LabeledValue data-testid="message_type" label="Nachrichten Typ" value={messageService.translateType(message.type) || "-"} />
          <LabeledValue data-testid="message_date" label="Datum" value={format(new Date(message.date), "dd.MM.yyyy HH:mm") || "-"} />
          <LabeledValue data-testid="message_subject" label="Betreff" value={message.subject || "-"} />
        </ValueGrid>
      </ContentSection>
      <ContentSection label="Nachricht">
        <LabeledValue data-testid="message_text" label="Nachricht" value={message.text || "-"} />
        <LabeledValue data-testid="message_status" label="Nachrichtenstatus" value={renderRead(message.unread)} />
      </ContentSection>
      {isNotB2BBackoffice && message.relatedAssignment && (
        <ContentSection label="Auftrag Details">
          <ValueGrid>
            <LabeledValue
              data-testid="message_assignmentNumber"
              label="Auftragsnummer"
              value={message.relatedAssignment.assignmentNumber || "-"}
            />
            <LabeledValue
              data-testid="message_relatedAssignmentClient"
              label="Kunde"
              value={message.relatedAssignment.client ? messageService.getFullName(message.relatedAssignment.client) : "-"}
            />
            <LabeledValue
              data-testid="message_relatedAssignmentEmployee"
              label="Zuständiger Mitarbeiter"
              value={messageService.getFullName(message.relatedAssignment.employee) || "-"}
            />
            <LabeledValue
              data-testid="message_relatedAssignmentType"
              label="Auftragstyp"
              value={assignmentService.translateType(message.relatedAssignment.type) || "-"}
            />
          </ValueGrid>
          <LabeledValue
            data-testid="message_relatedAssignmentNote"
            label="Auftragsbeschreibung"
            value={message.relatedAssignment.note || "-"}
          />
        </ContentSection>
      )}
      {message.deletedAssignmentNumber && (
        <ContentSection label="Auftrag Details">
          <ValueGrid>
            <LabeledValue
              data-testid="message_deletedAssignmentNumber"
              label="Auftragsnummer"
              value={message.deletedAssignmentNumber || "-"}
            />
          </ValueGrid>
        </ContentSection>
      )}
    </ContentContainer>
  );
};

export default ReadMessage;
