import React, { useCallback, useState } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { useService } from "core/di";
import AdapterService from "features/adapter/domain/AdapterService";

interface Props {
  adapterId: string;
  onFinished: () => void;
}

const SyncAllRehaItemsButton: React.FC<Props> = ({ adapterId, onFinished }) => {
  const adapterService = useService(AdapterService);

  const [loading, setLoading] = useState<boolean>(false);

  const onClick = useCallback(() => {
    setLoading(true);
    adapterService.syncAllRehaItems(adapterId).finally(() => {
      setLoading(false);
      onFinished();
    });
  }, [adapterId, adapterService, onFinished]);

  return (
    <Button fullWidth={true} disabled={loading} onClick={onClick} variant="contained" color="primary">
      {loading && <CircularProgress size="1rem" color="primary" style={{ marginRight: 5 }} />}
      <span>Rehamittel</span>
    </Button>
  );
};

export default SyncAllRehaItemsButton;
