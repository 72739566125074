import React, { useCallback, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useQuery, useQueryCache } from "react-query";
import useService from "core/di/useService";
import UIRoute from "core/presentation/components/UIRoute";
import { useQueryFromRouteProps } from "../../../../core/utils";
import UserService from "../../domain/services/UserService";
import { CreateOrUpdateUserFormValues } from "../components/CreateOrUpdateUserForm";
import CreateOrUpdateUserForm from "../components/CreateOrUpdateUserForm";
import { ValidationMessageError } from "core/data/errors";
import B2BService from "features/b2b/domain/services/B2BService";
import AreaResponseModel from "features/b2b/domain/components/AreaResponseModel";
import ClientService from "features/clients/domain/services/ClientService";
import BranchService from "features/branches/domain/services/BranchService";
import { renderClientLabel } from "features/clients/presentation/utils";

export interface CreateUserRouteProps extends RouteComponentProps {}

export default function CreateUserRoute(props: CreateUserRouteProps) {
  const { group = "" } = useQueryFromRouteProps(props);
  const userService = useService(UserService);
  const queryCache = useQueryCache();
  const clientService = useService(ClientService);
  const b2bService = useService(B2BService);
  const branchService = useService(BranchService);
  const [error, setError] = useState<string | null>(null);
  const { data: prescriberOptions } = useQuery("prescriberOptions", () => clientService.getPrescriberOptions());
  const { data: intermediaryOptions } = useQuery("intermediaryOptions", () => clientService.getIntermediaryOptions());
  const { data: internalBranchOptions } = useQuery("internalBranchOptions", () => branchService.getBranchOptions());
  const [linkedB2BPartner, setLinkedB2BPartner] = useState<{ id: string; type?: "INTERMEDIARY" | "PRESCRIBER"; b2bNumber?: string }>({
    id: "",
  });

  const { data: b2bBranchOptions } = useQuery(
    ["b2bBranchOptions", linkedB2BPartner.id],
    async () =>
      (await branchService.getB2BBackofficeUserBranchOptions(linkedB2BPartner)).map((branch: any) => ({
        value: branch.code,
        label: branch.name,
        id: branch.id,
      })),
    {
      enabled: linkedB2BPartner.id !== "",
    }
  );

  const { data: caretakerOptions } = useQuery(["caretakerOptions"], async () => {
    return clientService.getClientList(undefined, "intermediary", 1, 5000, { field: "firstname", order: "asc" }).then((r) => {
      return r.data.map((x) => {
        return { label: renderClientLabel(x), value: x.id, number: x.clientNumber };
      });
    });
  });

  const { data: areaData } = useQuery(
    ["areaData", { clientNumber: linkedB2BPartner.b2bNumber }],
    () => {
      return b2bService.getAreasByClientNumber(linkedB2BPartner.b2bNumber!).then((r) => {
        return r.map(({ id, areaNumber, name }: AreaResponseModel) => ({
          value: id,
          areaNumber,
          label: `${name} (${areaNumber})`,
        }));
      });
    },
    {
      enabled: linkedB2BPartner.b2bNumber && linkedB2BPartner.type === "INTERMEDIARY",
    }
  );

  const onSubmit = useCallback(
    async (values: CreateOrUpdateUserFormValues) => {
      await userService
        .create(values)
        .then(() => {
          setError(null);
        })
        .then(() => {
          queryCache.invalidateQueries("usersList");
        })
        .then(() => {
          props.history.goBack();
        })
        .catch((e) => {
          if (e instanceof ValidationMessageError) {
            switch (e.code) {
              case "NOTFOUND_B2BCLIENT":
                setError("Zu der angegebenen Pflegeheim-Nummer konnte kein Pflegeheim gefunden werden");
                return;
              case "NOTFOUND_B2CCLIENT":
                setError("Zu der angegebenen Kundenummer konnte kein Kunde gefunden werden");
                return;
              case "DUPLICATE_EMAIL":
                setError("Die E-Mail Adresse wird bereits für einen anderen Benutzer verwendet");
                return;
              case "DUPLICATE_EMPLOYEENUMBER":
                setError("Ein Mitarbeiter mit dieser Nummer existiert bereits");
                return;
              case "DUPLICATE_BACKOFFICENUMBER":
                setError("Ein Innendienstmitarbeiter mit dieser Nummer existiert bereits");
                return;
              default:
                setError(`Ein unbekannter Fehler ist aufgetreten. Code: ${e.code || "VMSG400"}`);
                return;
            }
          } else {
            setError(`Ein unbekannter Fehler ist aufgetreten`);
          }
        });
    },
    [props.history, queryCache, userService]
  );
  return (
    <UIRoute {...props} title="Neuen Benutzer erstellen">
      <CreateOrUpdateUserForm
        group={group?.toString()}
        error={error}
        onSubmit={onSubmit}
        careHomeOptions={intermediaryOptions || []}
        prescriberOptions={prescriberOptions || []}
        internalBranchOptions={internalBranchOptions}
        b2bBranchOptions={b2bBranchOptions}
        setLinkedB2BPartner={(clientNumber) => {
          setLinkedB2BPartner(clientNumber);
        }}
        areasForCareHome={areaData}
        caretakerOptions={caretakerOptions || []}
      />
    </UIRoute>
  );
}
