import { Box, Typography } from "@material-ui/core";
import { EmptyState } from "core/presentation/components/EmptyState";
import React, { useMemo } from "react";
import DataContainer from "template/presentation/components/table/DataContainer";
import { DataTableCell } from "template/presentation/components/table/DataTable";
import DataTablePaginatedAndSortable from "template/presentation/components/table/DataTablePaginatedAndSortable";
import Product from "../../domain/entities/Product";

export interface ProductTableProps {
  products?: Product[] | null;
  isLoading: boolean;
  onReload: () => void;
  onAdd: () => void;
  onSearch: (query: string | undefined) => void;
  page: number;
  pageSize: number;
  totalCount?: number;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
  onSortChange: (sortBy: string, sortOrder: "asc" | "desc") => void;
  sortedBy: { field: string; order: "asc" | "desc" };
  searchQuery?: string;
}

const priceFormatter = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
});

const ProductsTable = (props: ProductTableProps) => {
  const {
    products,
    isLoading,
    onReload,
    onAdd,
    onSearch,
    page,
    pageSize,
    onPageChange,
    onPageSizeChange,
    totalCount,
    onSortChange,
    sortedBy,
    searchQuery,
  } = props;

  const columns = useMemo(() => {
    return [
      { name: "Warengruppe", sortField: "category" },
      { name: "Artikelnummer", sortField: "articleNumber" },
      { name: "Beschreibung", sortField: "description" },
      { name: "Größe" },
      { name: "GTiN", sortField: "gtin" },
      { name: "PZN", sortField: "pzn" },
      { name: "ApoEK" },
    ];
  }, []);

  return (
    <DataContainer
      onSearchSubmit={onSearch}
      searchQuery={searchQuery}
      loading={isLoading}
      searchPlaceholder="Suche ..."
      onAdd={onAdd}
      addButton="CSV Import"
      onReload={onReload}
    >
      <DataTablePaginatedAndSortable<Product>
        data-testid={"productstable"}
        loading={isLoading}
        columns={columns}
        rows={products}
        page={page}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        totalCount={totalCount}
        keyExtractor={(data) => data.id}
        onSortChange={onSortChange}
        sortedBy={sortedBy}
        renderRow={(data) => {
          return (
            <>
              <DataTableCell data-testid="category">{data.category || "-"}</DataTableCell>
              <DataTableCell data-testid="articleNumber">{data.articleNumber || "-"}</DataTableCell>
              <DataTableCell data-testid="description">{data.description || "-"}</DataTableCell>
              <DataTableCell data-testid="size">{data.size || "-"}</DataTableCell>
              <DataTableCell data-testid="gtin">{data.gtin || "-"}</DataTableCell>
              <DataTableCell data-testid="pzn">{data.pzn || "-"}</DataTableCell>
              <DataTableCell data-testid="apoek">{(data.apoek && priceFormatter.format(data.apoek)) || "-"}</DataTableCell>
            </>
          );
        }}
        renderEmpty={() => (
          <EmptyState
            title="Noch keine Produkte vorhanden"
            description="Importieren Sie Ihre Produkte, um Empfehlungen für Rezepte erstellen zu können."
            buttonLabel="Produkte importieren"
            onButtonClick={onAdd}
          />
        )}
      />
    </DataContainer>
  );
};

export default ProductsTable;
