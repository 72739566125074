import React from "react";
import dashboardEmptyImage from "assets/img/dashboard_empty.svg";
import { Box, Button, Typography } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";

interface Props {
  title: string;
  description: string;
  buttonLabel?: string;
  onButtonClick?: () => void;
}

export const EmptyState: React.FC<Props> = ({ title, description, buttonLabel, onButtonClick }) => {
  return (
    <Box
      data-testid="table-area-empty"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      paddingX="175px"
      paddingY="40px"
    >
      <img src={dashboardEmptyImage} style={{ height: 280 }} />

      <Typography variant="h6" style={{ fontWeight: "bold", textAlign: "center" }}>
        {title}
      </Typography>
      <Box paddingTop="10px">
        <Typography style={{ textAlign: "center", fontSize: 16 }}>{description}</Typography>
      </Box>
      {buttonLabel && (
        <Box paddingTop="20px">
          <Button data-testid="newBranch_Button" startIcon={<AddCircleIcon />} variant="contained" color="primary" onClick={onButtonClick}>
            {buttonLabel}
          </Button>
        </Box>
      )}
    </Box>
  );
};
