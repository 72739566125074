import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DeleteAreaDependencies } from "./ManageAreaForm";

interface DeleteAreaDialogProps {
  open: boolean;
  onAgree: () => void;
  onDismiss: () => void;
  dependencies?: DeleteAreaDependencies;
}

const DeleteAreaDialog: React.FC<DeleteAreaDialogProps> = ({ open, onAgree, onDismiss, dependencies }) => {
  return (
    <div>
      <Dialog open={open} onClose={onDismiss} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Entfernen von Wohnbereich"}</DialogTitle>
        <DialogContent>
          {dependencies && dependencies.membersCount === "0" && dependencies.usersCount === "0" && (
            <DialogContentText id="alert-dialog-description">
              Sind sie sicher, dass Sie diesen Wohnbereich entfernen möchten?
            </DialogContentText>
          )}
          {dependencies && dependencies.membersCount === "0" && dependencies.usersCount > "0" && (
            <DialogContentText id="alert-dialog-description">
              Es befinden sich noch {dependencies.usersCount} Mitarbeiter in diesen Wohnbereich. Diese werden ebenso aus dem Wohnbereich
              entfernt.
            </DialogContentText>
          )}
          {dependencies && dependencies.membersCount > "0" && dependencies.usersCount === "0" && (
            <DialogContentText id="alert-dialog-description">
              Es befinden sich noch {dependencies.membersCount} Bewohner in diesen Wohnbereich. Diese werden ebenso aus dem Wohnbereich
              entfernt.
            </DialogContentText>
          )}
          {dependencies && dependencies.membersCount > "0" && dependencies.usersCount > "0" && (
            <DialogContentText id="alert-dialog-description">
              Es befinden sich noch {dependencies.usersCount} Mitarbeiter und {dependencies.membersCount} Bewohner in diesen Wohnbereich.
              Diese werden ebenso aus dem Wohnbereich entfernt.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onDismiss}>Abbrechen</Button>
          <Button onClick={onAgree} color="secondary" autoFocus>
            Wohnbereich Entfernen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteAreaDialog;
