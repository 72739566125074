import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import FormContainer from "template/presentation/components/form/FormContainer";
import FormSection from "template/presentation/components/form/FormSection";
import { TextField } from "formik-material-ui";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Alert } from "@material-ui/lab";
import FormSubmitButton from "template/presentation/components/form/FormSubmitButton";
import Message, { SelectRecipientTypeOptions, SelectMessageTypeOptions } from "../../domain/entities/Message";
import SelectField from "template/presentation/components/form/SelectField";
import MessageService, { MessageType, RecipientType } from "features/messages/domain/services/MessageService";
import Assignment from "features/assignments/domain/entities/Assignment";
import ContentSection from "template/presentation/components/content/ContentSection";
import LabeledValue from "template/presentation/components/content/LabeledValue";
import ValueGrid from "template/presentation/components/content/ValueGrid";
import { useService } from "core/di";
import SearchIcon from "@material-ui/icons/Search";
import AssignmentService from "features/assignments/domain/services/AssignmentService";
import { Link } from "react-router-dom";
import MultiSelectField from "template/presentation/components/form/MutiSelectField";
import UserConverter from "features/users/domain/converter/UserConverter";

export interface CreateOrUpdateMessageFormValues {
  recipient?: { value: string; label: string; recipientType: string }[];
  recipientGroups?: { value: string; label: string }[];
  recipientType?: RecipientType;
  type: MessageType;
  subject?: string;
  text: string;
  relatedAssignmentNumber?: string;
}

interface ICreateOrUpdateMessageFormProps {
  onSubmit: (values: CreateOrUpdateMessageFormValues) => void;
  error: string | null;
  message?: Message;
  typeOptions: [SelectMessageTypeOptions];
  recipientTypeOptions: [SelectRecipientTypeOptions];
  getAssignmentData?: (assignmentNumber: string) => void;
  assignmentData?: void | Assignment | null;
  repicientOptions: { label: string; value: string; data?: any }[];
  repicientOptionsChange: (searchQuery: string) => void;
  groupOptions?: Array<{ value: string; label: string }>;
  hideAssignment?: boolean;
  loadingRecipients?: boolean;
}

const groupOptionsDefault = [
  { value: "au", label: "Außendienst" },
  { value: "b2b", label: "B2B" },
  { value: "b2c", label: "B2C" },
];

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(1),
  },
}));

const createOrUpdateMessageSchema = Yup.object().shape(
  {
    recipient: Yup.array()
      .nullable()
      .when("recipientGroups", {
        is: (val) => !val || val.length === 0,
        then: Yup.array().required("Empfänger oder Gruppe wird benötigt"),
      }),
    recipientGroups: Yup.array()
      .nullable()
      .when("recipient", {
        is: (val) => !val || val.length === 0,
        then: Yup.array().required("Empfänger oder Gruppe wird benötigt"),
      }),
    recipientType: Yup.string().required("Benötigt"),
    type: Yup.string().required("Benötigt"),
    subject: Yup.string().max(40, "Maximal 40 Zeichen"),
    text: Yup.string().required("Benötigt").min(1, "Mindestens 1 Zeichen"),
    relatedAssignmentNumber: Yup.string(),
  },
  [["recipientGroups", "recipient"]]
);

const CreateOrUpdateMessageForm: React.FC<ICreateOrUpdateMessageFormProps> = (props) => {
  const {
    onSubmit,
    groupOptions = groupOptionsDefault,
    error,
    message,
    typeOptions,
    getAssignmentData,
    hideAssignment,
    assignmentData,
    repicientOptions,
    repicientOptionsChange,
    loadingRecipients,
  } = props;
  const classes = useStyles();
  const messageService = useService(MessageService);
  const assignmentService = useService(AssignmentService);
  const [assignment, setAssignment] = useState(false);

  const initValues = useMemo((): CreateOrUpdateMessageFormValues => {
    if (message === undefined) {
      return {
        recipient: undefined,
        recipientGroups: undefined,
        recipientType: "FIELDSTAFF",
        type: "GENERAL",
        subject: "",
        text: "",
        relatedAssignmentNumber: "",
      };
    }

    return {
      recipient: message.recipient ? UserConverter.toMessageRecipientOptions([message.recipient]) : undefined,
      recipientType: message.recipientType || "FIELDSTAFF",
      type: message.type || "GENERAL",
      subject: message.subject || "",
      text: message.text || "",
      relatedAssignmentNumber: message.relatedAssignment?.assignmentNumber || "",
    };
  }, [message]);

  return (
    <Formik<CreateOrUpdateMessageFormValues>
      enableReinitialize
      initialValues={initValues}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
        } finally {
          setSubmitting(false);
        }
      }}
      validationSchema={createOrUpdateMessageSchema}
    >
      {({ values, submitForm, isSubmitting }) => {
        return (
          <FormContainer>
            <FormSection label={"Nachrichtenkopf"}>
              <Field
                data-testid="field-recipentGroups"
                name={"recipientGroups"}
                legend={"Empfängergruppe(n)*"}
                component={MultiSelectField}
                options={groupOptions}
                disabled={!!values?.recipient?.length}
              />
              <Field
                data-testid="field-recipient"
                className={classes.field}
                component={MultiSelectField}
                getTestIdForOption={(option: any) => option.email}
                variant={"outlined"}
                name={"recipient"}
                legend={"Empfänger*"}
                isServerSideFilter
                loading={loadingRecipients}
                options={repicientOptions}
                onChangeText={repicientOptionsChange}
                disabled={!!values?.recipientGroups?.length}
              />
              <Field
                data-testid="field-type"
                className={classes.field}
                component={SelectField}
                options={typeOptions}
                variant={"outlined"}
                name={"type"}
                legend={"Typ*"}
              />

              <Field
                data-testid="field-subject"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"subject"}
                label={"Betreff"}
              />
            </FormSection>

            <FormSection label={"Nachricht"}>
              <Field
                data-testid="field-text"
                className={classes.field}
                component={TextField}
                multiline
                rows={4}
                variant={"outlined"}
                name={"text"}
                placeholder={"Schreiben Sie hier Ihre Nachricht"}
              />
            </FormSection>
            {!hideAssignment && (
              <FormSection label={"Auftrag"}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Field
                    data-testid="field-assignment"
                    style={{ flex: "0 0 94%", marginRight: "1%" }}
                    className={classes.field}
                    component={TextField}
                    variant={"outlined"}
                    name={"relatedAssignmentNumber"}
                    label={"Auftragsnummer"}
                  />
                  <div
                    style={{
                      marginTop: "10px",
                      flex: "0 0 4%",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    data-testid={`field-employeeEmail-plus`}
                    onClick={() => {
                      if (getAssignmentData) {
                        const assignmentNumber = values.relatedAssignmentNumber;
                        if (assignmentNumber) {
                          getAssignmentData(assignmentNumber);
                          setAssignment(true);
                        } else {
                          setAssignment(false);
                        }
                      }
                    }}
                  >
                    <SearchIcon color={"primary"} style={{ marginRight: "8px", fontSize: "3em" }} />
                  </div>
                </div>
              </FormSection>
            )}
            {assignment && assignmentData && (
              <ContentSection label="Auftrag Details">
                <ValueGrid>
                  <LabeledValue label="Kunde" value={messageService.getFullName(assignmentData.client) || "-"} />
                  <LabeledValue label="Zuständiger Mitarbeiter" value={messageService.getFullName(assignmentData.employee) || "-"} />
                  <LabeledValue label="Autfragstyp" value={assignmentService.translateType(assignmentData.type) || "-"} />
                  <Link to={`/assignment/edit/${assignmentData.id}`}>
                    <VisibilityIcon />
                  </Link>
                </ValueGrid>
                <LabeledValue label="Auftragsbeschreibung" value={assignmentData.note || "-"} />
              </ContentSection>
            )}

            <FormSection>
              {error && (
                <Alert className={classes.field} data-testid="create-message-error" severity="error">
                  {error}
                </Alert>
              )}

              <FormSubmitButton data-testid="button-createmessage" disabled={isSubmitting} onClick={submitForm}>
                {message ? "Bearbeiten" : "Senden"}
              </FormSubmitButton>
            </FormSection>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default CreateOrUpdateMessageForm;
