import React, { useCallback, useMemo, useState } from "react";
import { queryCache, useQuery } from "react-query";
import useService from "core/di/useService";
import { RouteComponentProps, useParams } from "react-router-dom";
import UIRoute from "core/presentation/components/UIRoute";
import AssignmentService from "../../domain/services/AssignmentService";
import CreateOrUpdateAssignmentForm, { CreateOrUpdateAssignmentFormValues } from "../components/CreateOrUpdateAssignmentForm";
import { ValidationMessageError } from "core/data/errors";
import CaseService from "features/cases/domain/services/CaseService";
import { formatISO, parse } from "date-fns";
import UserService from "features/users/domain/services/UserService";
import { renderUserName } from "features/clients/presentation/utils";
import RehaItemConverter from "features/rehaItems/domain/converter/RehaItemConverter";
import RehaItemService from "features/rehaItems/domain/services/RehaItemService";

export interface EditAssignmentRouteProps extends RouteComponentProps {}

const EditAssignmentsRoute = (props: EditAssignmentRouteProps) => {
  const { id } = useParams<{ id: string }>();

  const assignmentService = useService(AssignmentService);
  const caseService = useService(CaseService);
  const userService = useService(UserService);
  const rehaItemService = useService(RehaItemService);

  const [error, setError] = useState<string | null>(null);
  const [caseNumberQuery, setCaseNumberQuery] = useState<string | null>("");

  const { data: assignment } = useQuery(["assignment", id], () => assignmentService.getById(id));

  const { data: employeeOptions } = useQuery(["allAvailableEmployeesForAssignment"], () =>
    userService.getEmployees({ groups: ["employee"] }).then((users) =>
      users.data.map((user) => ({
        value: user.employeeNumber || "",
        label: `${renderUserName(user)} (${user.employeeNumber})`,
      }))
    )
  );

  const { data: rehaItems } = useQuery(
    ["rehaItems", id],
    async () => RehaItemConverter.fromEntityToSelectOptions(await rehaItemService.getByClientNumber(assignment!.client!.clientNumber)),
    {
      enabled: assignment !== undefined,
    }
  );

  const onSubmit = useCallback(
    (values: CreateOrUpdateAssignmentFormValues) => {
      //can only be cancel if type is rent extension at the moment
      if (values.type === "RENT_EXTENSION") {
        return assignmentService
          .cancelRentExtension(id)
          .then(() => {
            setError(null);
            return queryCache.invalidateQueries("assignmentList");
          })
          .then(() => {
            return queryCache.invalidateQueries(["assignment", id]);
          })
          .then(() => {
            props.history.push("/assignments");
          })
          .catch((e) => {
            if (e instanceof ValidationMessageError) {
              switch (e.code) {
                case "DUPLICATE_ASSIGNMENT_NUMBER":
                  setError("Die Auftragsnummer ist bereits vergeben");
                  return;
                default:
                  setError(`Ein unbekannter Fehler ist aufgetreten. Code: ${e.code}`);
                  return;
              }
            } else {
              setError("Ein unbekannter Fehler ist aufgetreten");
            }
          });
      }

      return assignmentService
        .update(id, {
          ...values,
          timeFrom: values.timeFrom && formatISO(parse(values.timeFrom, "yyyy-MM-dd'T'HH:mm", new Date())),
          timeUntil: values.timeUntil && formatISO(parse(values.timeUntil, "yyyy-MM-dd'T'HH:mm", new Date())),
          employeeNumber: employeeOptions?.find((employee) => values.employeeNumber === employee.label)?.value,
          rehaItems: values?.rehaItems?.map((i) => i.value),
        })
        .then(() => {
          setError(null);
          return queryCache.invalidateQueries("assignmentList");
        })
        .then(() => {
          return queryCache.invalidateQueries(["assignment", id]);
        })
        .then(() => {
          props.history.push("/assignments");
        })
        .catch((e) => {
          if (e instanceof ValidationMessageError) {
            switch (e.code) {
              case "DUPLICATE_ASSIGNMENT_NUMBER":
                setError("Die Auftragsnummer ist bereits vergeben");
                return;
              default:
                setError(`Ein unbekannter Fehler ist aufgetreten. Code: ${e.code}`);
                return;
            }
          } else {
            setError("Ein unbekannter Fehler ist aufgetreten");
          }
        });
    },
    [assignmentService, id, props.history, employeeOptions]
  );

  const { data: queriedCaseOptions, isLoading: casesLoading } = useQuery(
    ["cases", { caseNumberQuery, pageSize: 10 }],
    () =>
      caseService.get({ caseNumberQuery: caseNumberQuery || undefined, pageSize: 10 }).then(({ data }) => {
        return data.map((kase) => ({
          value: kase.caseNumber,
          label: kase.caseNumber,
        }));
      }),
    { enabled: caseNumberQuery && caseNumberQuery.length >= 1 }
  );

  const relatedCaseNumberOfAssignmentWins = useCallback(
    () => assignment && assignment.relatedCaseNumber && (!caseNumberQuery || caseNumberQuery.length === 0),
    [assignment, caseNumberQuery]
  );

  const caseOptions = useMemo(() => {
    if (relatedCaseNumberOfAssignmentWins()) {
      return [{ value: assignment?.relatedCaseNumber || "", label: assignment?.relatedCaseNumber || "" }];
    } else {
      return queriedCaseOptions;
    }
  }, [assignment?.relatedCaseNumber, queriedCaseOptions, relatedCaseNumberOfAssignmentWins]);

  const typeOptions = useMemo(() => {
    if (assignment?.type === "VIDEO_CONSULTATION" || assignment?.type === "ON_SITE_CONSULTATION") {
      // These are "edit-only" options and it should not be possible to change the assignment's type
      return [{ value: assignment.type, label: assignment.type === "VIDEO_CONSULTATION" ? "Videoberatung" : "Beratung vor Ort" }];
    }
    return assignmentService.getTypeOptions();
  }, [assignment, assignmentService]);

  return (
    <UIRoute {...props} title={"Auftrag bearbeiten"}>
      {assignment ? (
        <div data-testid="assignment-form-edit">
          <CreateOrUpdateAssignmentForm
            error={error}
            onSubmit={onSubmit}
            statusOptions={assignmentService.getStatusOptions()}
            typeOptions={typeOptions}
            assignment={assignment}
            caseOptions={caseOptions || []}
            setCaseNumberQuery={setCaseNumberQuery}
            caseOptionsLoading={casesLoading}
            employeeOptions={employeeOptions || []}
            onLoadRehaItems={() => {}}
            rehaItems={rehaItems || []}
          />
        </div>
      ) : (
        <div />
      )}
    </UIRoute>
  );
};

export default EditAssignmentsRoute;
