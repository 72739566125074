import { FormControl, FormLabel, FormControlLabel, Radio } from "@material-ui/core";
import React from "react";

interface RadioFieldProps {
  field: any;
  form: any;
  legend?: string;
  options: { value: string; label: string }[];
  "data-testid"?: string;
}

const RadioField: React.FC<RadioFieldProps> = ({ field, form, legend, options, ...props }) => {
  return (
    <FormControl>
      {legend && <FormLabel component="legend">{legend}</FormLabel>}
      {options.map((option) => (
        <FormControlLabel
          key={option.value}
          control={
            <Radio
              data-test-id={props["data-testid"] ? `${props["data-testid"]}-${option.value}` : undefined}
              disabled={form.isSubmitting}
              onChange={() => form.setFieldValue(field.name, option.value)}
              checked={field.value === option.value}
            />
          }
          label={option.label}
        />
      ))}
    </FormControl>
  );
};

export default RadioField;
