import format from "date-fns/format";
import { PoolAssignmentReportVideoAppointment } from "features/pools/domain/entities/PoolAssignment";
import React from "react";
import ContentSection from "template/presentation/components/content/ContentSection";
import LabeledValue from "template/presentation/components/content/LabeledValue";
import ValueGrid from "template/presentation/components/content/ValueGrid";
import { RehaItem } from "features/rehaItems/domain/entities/RehaItem";
import { RehaItemsPoolView } from "../RehaItemsPoolView";
interface Props {
  report: PoolAssignmentReportVideoAppointment;
  rehaItems?: RehaItem[];
}

export const VideoConsultationInfoSection: React.FC<Props> = ({ report, rehaItems }) => {
  return (
    <ContentSection label="Details">
      <ValueGrid>
        <LabeledValue label="Anliegen" value={report.text} />
        <LabeledValue label="Rückfragen per" value={report.preferredCommunication === "email" ? "E-Mail" : "Telefon"} />
        <LabeledValue label="Telefon" value={report.phone} />
        <LabeledValue label="E-Mail" value={report.email} />
        <LabeledValue label="Termin" value={format(report.appointmentDateAndTime, "dd.MM.yyyy HH:mm")} />
        <LabeledValue label="Status" value={report.appointmentStatus === "ACCEPTED" ? "Akzeptiert" : "Storniert"} />
      </ValueGrid>
      <RehaItemsPoolView rehaItems={rehaItems} />
    </ContentSection>
  );
};
