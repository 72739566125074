import React from "react";
import { Grid, GridSize } from "@material-ui/core";

interface Props {
  verticalAlignment?: boolean;
  itemSize?: number;
}

const ValueGrid: React.FC<Props> = ({ itemSize, children, verticalAlignment }) => {
  return (
    <Grid container spacing={1}>
      <Grid container direction={verticalAlignment ? "row" : "column"} item xs={"auto"} spacing={1}>
        {React.Children.map(children, (child) => {
          return (
            child && (
              <Grid item xs={itemSize ? (itemSize as GridSize) : "auto"}>
                {child}
              </Grid>
            )
          );
        })}
      </Grid>
    </Grid>
  );
};
export default ValueGrid;
