export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || (window as any)._env_?.BACKEND_URL || "http://localhost:8080";

export const MANDANT_SHORT = process.env.MANDANT_SHORT || (window as any)._env_?.MANDANT_SHORT || "RHTW";

export const MANDANT_NAME = process.env.MANDANT_NAME || (window as any)._env_?.MANDANT_NAME || "reha team West";

export const MANDANT_MAIL = process.env.MANDANT_MAIL || (window as any)._env_?.MANDANT_MAIL || "kr@rtwest.de";

export const MANDANT_WEB = process.env.MANDANT_WEB || (window as any)._env_?.MANDANT_WEB || "https://www.reha-team-west.net";

export const MANDANT_FACEBOOK =
  process.env.MANDANT_FACEBOOK || (window as any)._env_?.MANDANT_FACEBOOK || "https://www.facebook.com/rehateamWest/";

export const MANDANT_APPSTORELINK =
  process.env.MANDANT_APPSTORELINK || (window as any)._env_?.MANDANT_APPSTORELINK || "https://apps.apple.com/us/app/rehamigo/id1530777490";

export const MANDANT_PLAYSTORELINK =
  process.env.MANDANT_PLAYSTORELINK ||
  (window as any)._env_?.MANDANT_PLAYSTORELINK ||
  "https://play.google.com/store/apps/details?id=de.reha_team_west.b2c";

export const MANDANT_ADDRESSLINE =
  process.env.MANDANT_ADDRESSLINE ||
  (window as any)._env_?.MANDANT_ADDRESSLINE ||
  "reha team West GmbH & Co. KG - Mevissenstraße 64a · 47803 Krefeld";

export const MANDANT_PRODUCT = process.env.MANDANT_PRODUCT || (window as any)._env_?.MANDANT_PRODUCT || "rehamigo";
