import React, { useCallback, useState } from "react";
import { Field, Formik } from "formik";
import { TextField } from "formik-material-ui";
import FormSection from "template/presentation/components/form/FormSection";
import FormContainer from "template/presentation/components/form/FormContainer";
import FormSubmitButton from "template/presentation/components/form/FormSubmitButton";
import { makeStyles, Paper, Typography } from "@material-ui/core";
import * as Yup from "yup";
import UpdateAreaRequestModel from "features/b2b/domain/components/UpdateAreaRequestModel";
import { Alert } from "@material-ui/lab";
import AreaResponseModel from "../../domain/components/AreaResponseModel";
import DataTable, { DataTableCell } from "../../../../template/presentation/components/table/DataTable";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import DataContainer from "../../../../template/presentation/components/table/DataContainer";
import DeleteClientInAreaDialog from "./DeleteClientInAreaDialog";

interface EditAreaFormProps {
  onGoBack: () => void;
  onUpdateArea: (data: UpdateAreaRequestModel) => void;
  onDeleteClientInArea: (data: DeleteClientData) => void;
  onDeleteUserInArea: (data: DeleteClientData) => void;
  error: string | null;
  areaData?: AreaResponseModel;
  onLoading: boolean;
}
interface EditAreaFormValues {
  areaNumber: string;
  name: string;
}
export interface DeleteClientData {
  clientNumber: string;
  id: string;
}
const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(1),
  },
}));

export default function EditAreaForm(props: EditAreaFormProps) {
  const { onUpdateArea, onGoBack, error, areaData, onLoading, onDeleteClientInArea, onDeleteUserInArea } = props;
  const classes = useStyles();

  const EditAreaValidationSchema = Yup.object().shape({
    areaNumber: Yup.string().required("Benötigt"),
    name: Yup.string().required("Benötigt"),
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState("");
  const [clientToDelete, setClientToDelete] = useState<undefined | DeleteClientData>(undefined);
  const [userToDelete, setUserToDelete] = useState<undefined | DeleteClientData>(undefined);

  const handleDelete = useCallback(
    (clientNumber: string, id: string, type: string) => {
      const dt = type === "user" ? "Mitarbeiter" : "Bewohner";
      setDialogOpen(true);
      setDialogType(dt);
      if (type === "user") {
        setUserToDelete({ clientNumber, id });
      } else {
        setClientToDelete({ clientNumber: clientNumber, id: id });
      }
    },
    [setDialogOpen, setClientToDelete]
  );

  const onDismiss = useCallback(() => {
    setDialogOpen(false);
  }, [setDialogOpen]);

  const onAgree = useCallback(() => {
    setDialogOpen(false);
    if (clientToDelete) {
      onDeleteClientInArea(clientToDelete);
      setClientToDelete(undefined);
    } else if (userToDelete) {
      onDeleteUserInArea(userToDelete);
      setUserToDelete(undefined);
    }
  }, [clientToDelete, userToDelete, onDeleteClientInArea, onDeleteUserInArea]);

  return !onLoading ? (
    <>
      <DeleteClientInAreaDialog open={dialogOpen} onDismiss={onDismiss} onAgree={onAgree} type={dialogType} />
      <DataContainer loading={false} showSearchbar={false}>
        {areaData && areaData.users && areaData.users.length > 0 ? (
          <DataTable
            columns={[{ name: "Name" }, { name: "Mitarbeiter Nummer" }, { name: "Entfernen" }]}
            rows={areaData.users}
            keyExtractor={(data) => data.id}
            data-testid="table-area-employees"
            renderRow={(data, index) => {
              return (
                <>
                  <DataTableCell>{data.firstname + " " + data.lastname}</DataTableCell>
                  <DataTableCell>{data.b2bNumber}</DataTableCell>
                  <DataTableCell>
                    <Button
                      data-testid={`table-area-employees-${data.b2bNumber}-delete`}
                      onClick={() => handleDelete(data.b2bNumber, data.id, "user")}
                    >
                      {" "}
                      <DeleteIcon />{" "}
                    </Button>{" "}
                  </DataTableCell>
                </>
              );
            }}
          />
        ) : (
          <Paper data-testid="table-area-employees-empty">
            <Typography color="textSecondary" align="center">
              Keine Mitarbeiter im Wohnbereich vorhanden
            </Typography>
          </Paper>
        )}
      </DataContainer>
      <div style={{ marginTop: 50 }}>
        <DataContainer loading={false} showSearchbar={false}>
          {areaData && areaData.members && areaData.members.length > 0 ? (
            <DataTable
              columns={[{ name: "Name" }, { name: "Kundennummer" }, { name: "Entfernen" }]}
              rows={areaData.members}
              keyExtractor={(data) => data.id}
              renderRow={(data, index) => {
                return (
                  <>
                    <DataTableCell>{data.firstname + " " + data.lastname}</DataTableCell>
                    <DataTableCell>{data.clientNumber}</DataTableCell>
                    <DataTableCell>
                      <Button
                        data-testid={`table-area-clients-${data.clientNumber}-delete`}
                        onClick={() => handleDelete(data.clientNumber, data.id, "client")}
                      >
                        {" "}
                        <DeleteIcon />{" "}
                      </Button>{" "}
                    </DataTableCell>
                  </>
                );
              }}
            />
          ) : (
            <Paper data-testid="table-area-clients-empty">
              <Typography color="textSecondary" align="center">
                Keine Bewohner im Wohnbereich vorhanden
              </Typography>
            </Paper>
          )}
        </DataContainer>
      </div>
      <div style={{ marginTop: 50 }}>
        <Formik<EditAreaFormValues>
          initialValues={{
            areaNumber: areaData?.areaNumber || "",
            name: areaData?.name || "",
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            if (areaData) {
              try {
                const data: UpdateAreaRequestModel = {
                  path: { areaId: areaData ? areaData.id : "" },
                  body: { areaNumber: values.areaNumber, name: values.name },
                };
                onUpdateArea(data);
                resetForm({});
              } finally {
                setSubmitting(false);
                onGoBack();
              }
            }
          }}
          validationSchema={EditAreaValidationSchema}
        >
          {({ submitForm, isSubmitting }) => (
            <FormContainer>
              <FormSection label={"Bearbeiten eines Wohnbereiches"}>
                <Field
                  data-testid="field-nameArea"
                  className={classes.field}
                  component={TextField}
                  variant={"outlined"}
                  name={"name"}
                  label={"Name des Wohnbereichs*"}
                />
                <Field
                  data-testid="field-numberArea"
                  className={classes.field}
                  component={TextField}
                  variant={"outlined"}
                  name={"areaNumber"}
                  label={"Nummer des Wohnbereichs*"}
                />
                <FormSection>
                  {error && (
                    <Alert className={classes.field} data-testid="add-area-error" severity="error">
                      {error}
                    </Alert>
                  )}
                  <FormSubmitButton data-testid="livingarea-submit" disabled={isSubmitting} onClick={submitForm}>
                    Bearbeiten
                  </FormSubmitButton>
                </FormSection>
              </FormSection>
            </FormContainer>
          )}
        </Formik>
      </div>
    </>
  ) : (
    <div />
  );
}
