import React, { useMemo, useState } from "react";
import PoolAssignment from "features/pools/domain/entities/PoolAssignment";
import ContentSection from "template/presentation/components/content/ContentSection";
import ValueGrid from "template/presentation/components/content/ValueGrid";
import LabeledValue from "template/presentation/components/content/LabeledValue";
import { Button, makeStyles } from "@material-ui/core";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import PoolAssignmentConverter from "../../../domain/converters/PoolAssignmentConverter";
import ModifyNewCaseDialog from "./ModifyNewCaseDialog";
import SelectOption from "core/interfaces/SelectOption";
import useBooleanStateToggle from "core/presentation/hooks/useBooleanStateToggle";
import { CreateOrUpdateCaseFormValues } from "features/cases/presentation/components/CreateOrUpdateCaseForm";
import Case from "features/cases/domain/entities/Case";
import ControlledAutocomplete from "template/presentation/components/form/ControlledAutocomplete";

type NewCase = CreateOrUpdateCaseFormValues;

interface Props {
  assignment: PoolAssignment;
  onCreateNewCase?: (newCase: NewCase) => Promise<any>;
  isB2BBackoffice: boolean;
  classifications?: SelectOption[];
  employees?: SelectOption[];
  connectedCase?: Case;
  caseQuery: {
    caseOptions: SelectOption[];
    setCaseNumberQuery: (value: string) => void;
    setCaseOptionSelected?: (values: string) => void;
    caseOptionsLoading: boolean;
    onAssignCase: () => void;
  };
  selectedCaseDetails: React.ReactNode | null;
  error?: string;
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  textField: {
    width: "100%",
    textAlign: "center",
    fontSize: theme.typography.pxToRem(15),
  },
}));

export const AssignmentInfoSection: React.FC<Props> = ({
  assignment,
  onCreateNewCase,
  isB2BBackoffice,
  classifications,
  employees,
  connectedCase,
  caseQuery,
  selectedCaseDetails,
  error,
}) => {
  const { caseOptions, setCaseNumberQuery, caseOptionsLoading, onAssignCase, setCaseOptionSelected } = caseQuery;

  const classes = useStyles();
  const [newCaseIsBeingCreated, setNewCaseIsBeingCreated] = useState<boolean>(false);
  const [modifyNewCaseDialogOpen, openModifyNewCaseDialog, closeModifyNewCaseDialog] = useBooleanStateToggle();

  const { b2bTransfer } = assignment;
  const transferredBySection = useMemo(() => {
    if (!b2bTransfer) {
      return null;
    }
    const transferredBy = b2bTransfer.transferredBy ? " - " + b2bTransfer.transferredBy : " (Unbekannt)";
    if (b2bTransfer.transferredFrom === "b2b") {
      return (
        <LabeledValue
          data-testid="b2bTransfer-reason"
          label={"Grund für Übergabe von B2B-Kunde" + transferredBy}
          value={b2bTransfer.reason}
        />
      );
    }
    return (
      <LabeledValue
        data-testid="rehaTransfer-reason"
        label={"Grund für Übergabe von Sanitätshaus" + transferredBy}
        value={b2bTransfer.reason}
      />
    );
  }, [b2bTransfer]);

  const renderCreateCaseButton = useMemo(() => {
    if (assignment.relatedCaseNumber || !onCreateNewCase) {
      return null;
    }

    let agreement = (assignment.report as any).text;

    return (
      <div style={{ maxWidth: "35%" }}>
        <div className={classes.container}>
          <ControlledAutocomplete
            data-testid="relatedCaseNumber-field"
            label="Vorgangsnummer"
            name="caseNumber"
            initialValue=""
            options={caseOptions}
            onSelectOption={(val) => {
              setCaseNumberQuery(val);
              if (setCaseOptionSelected) {
                setCaseOptionSelected(val);
              }
            }}
            onChangeInput={setCaseNumberQuery}
            loading={caseOptionsLoading}
          />
          {selectedCaseDetails}
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            data-testid="button-connectwithcase"
            onClick={onAssignCase}
          >
            Mit Vorgang verknüpfen
          </Button>
        </div>
        <p className={classes.textField}>oder</p>
        <Button
          className={classes.button}
          data-testid="button-caseCreate"
          startIcon={<SyncAltIcon />}
          disabled={assignment.assignmentState.status === "DONE" || newCaseIsBeingCreated}
          color="primary"
          variant="contained"
          onClick={openModifyNewCaseDialog}
        >
          Neuen Vorgang erstellen
        </Button>
        <ModifyNewCaseDialog
          inputCase={{
            clientNumber: assignment.client?.clientNumber,
            classificationNumber: assignment.classification?.number,
            branchNumber: assignment.branch.code,
            agreement,
          }}
          classifications={classifications || []}
          employees={employees || []}
          open={modifyNewCaseDialogOpen}
          onDismiss={closeModifyNewCaseDialog}
          onSubmit={async (newCase) => {
            try {
              setNewCaseIsBeingCreated(true);
              await onCreateNewCase(newCase);
            } catch (err) {
              setNewCaseIsBeingCreated(false);
            } finally {
              setNewCaseIsBeingCreated(false);
            }
          }}
          error={error}
        />
      </div>
    );
  }, [
    assignment,
    newCaseIsBeingCreated,
    onCreateNewCase,
    classifications,
    employees,
    closeModifyNewCaseDialog,
    openModifyNewCaseDialog,
    modifyNewCaseDialogOpen,
    setCaseNumberQuery,
    onAssignCase,
    caseOptions,
    caseOptionsLoading,
    classes,
    selectedCaseDetails,
    setCaseOptionSelected,
    error,
  ]);

  return (
    <ContentSection label="Allgemeine Informationen">
      <ValueGrid>
        <LabeledValue
          data-testid="typ"
          label="Typ"
          value={PoolAssignmentConverter.fromAssignmentTypeToString(assignment.type, assignment.employee?.groups || [])}
        />
        {assignment.type !== "SIGNATURE_REQUEST" && (
          <LabeledValue
            data-testid="AD-assignmentNumber"
            label="AD-Auftragsnummer"
            value={assignment.assignmentNumber !== undefined ? assignment.assignmentNumber : "-"}
          />
        )}
        {!isB2BBackoffice && (
          <>
            <LabeledValue data-testid="caseNumber" label="Verknüpfter Vorgang" value={assignment.relatedCaseNumber || "-"} />
            {connectedCase && (
              <LabeledValue data-testid="connectedCase" label="Status des verknüpften Vorgangs" value={connectedCase.status} />
            )}
          </>
        )}
        {transferredBySection}
      </ValueGrid>
      {!isB2BBackoffice && renderCreateCaseButton}
    </ContentSection>
  );
};
