import { useService } from "core/di";
import { EmptyState } from "core/presentation/components/EmptyState";
import { renderFullName } from "core/utils";
import Client from "features/clients/domain/entities/Client";
import FormularResponseModel from "features/formular-center/data/FormularModel";
import FormularService from "features/formular-center/domain/services/FormularService";
import React, { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import DataContainer, { FilterItem } from "template/presentation/components/table/DataContainerExtendedSearch";
import { DataTableCell } from "template/presentation/components/table/DataTable";
import DataTablePaginatedAndSortable from "template/presentation/components/table/DataTablePaginatedAndSortable";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import ViewAndDownloadDialog from "./ViewAndDownloadDialog";

export interface FormularTableProps {
  formularList: FormularResponseModel[] | undefined;
  isLoading: boolean;
  onReload: () => void;
  addButtonText: string;
  onAdd: () => void;
  onSearch: (query: string) => void;
  page: number;
  pageSize: number;
  totalCount?: number;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
  filterItems?: Array<FilterItem>;
  onSearchSubmit?: (values: any) => void;
}

const FormularTable = (props: FormularTableProps) => {
  const {
    formularList,
    isLoading,
    onReload,
    addButtonText,
    onAdd,
    onSearch,
    page,
    pageSize,
    totalCount,
    onPageChange,
    onPageSizeChange,
    filterItems,
    onSearchSubmit,
  } = props;
  const formularService = useService(FormularService);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formularId, setFormularId] = useState<undefined | string>(undefined);
  const columns = useMemo(() => {
    return [
      { name: "Dokumententyp" },
      { name: "Kunde" },
      { name: "Vorgang" },
      { name: "Auftrag" },
      { name: "Bearbeiter" },
      { name: "Sig." },
      { name: "Aktion" },
    ];
  }, []);

  const emptyState = useMemo(() => {
    return (
      <EmptyState
        title="Noch kein Formular vorhanden"
        description="Erstellen Sie jetzt Ihr erstes Formular."
        buttonLabel="Neues Formular"
        onButtonClick={onAdd}
      />
    );
  }, [onAdd]);

  const renderClient = useCallback(({ clientNumber, firstname, lastname, city, street, zipcode }: Client) => {
    if (city && street && zipcode) {
      return (
        <div>
          <span>
            {renderFullName({ firstname, lastname })} ({clientNumber})
          </span>
          <br />
          <span>{street},</span> <br />
          <span>
            {zipcode} {city}
          </span>
        </div>
      );
    }
    return <div>{renderFullName({ firstname, lastname })}</div>;
  }, []);

  return (
    <>
      <ViewAndDownloadDialog open={dialogOpen} onDismiss={setDialogOpen} formularId={formularId} />
      <DataContainer
        loading={isLoading}
        addButton={addButtonText}
        onAdd={onAdd}
        onReload={onReload}
        onSearchSubmit={onSearchSubmit}
        filterItems={filterItems}
      >
        <DataTablePaginatedAndSortable<any>
          data-testid={"CaseTable"}
          loading={isLoading}
          columns={columns}
          rows={formularList}
          page={page}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          totalCount={totalCount}
          keyExtractor={(data) => data.id}
          onSortChange={() => {}}
          sortedBy={{ field: "", order: "asc" }}
          renderRow={(data, index) => (
            <>
              <DataTableCell data-testid={"caseNumber"}>{formularService.translateType(data.type) || "-"}</DataTableCell>
              <DataTableCell data-testid={"client"}>{data.client ? renderClient(data.client) : "Unbekannt"}</DataTableCell>
              <DataTableCell data-testid={"status"}>{data.relatedCase.caseNumber}</DataTableCell>
              <DataTableCell data-testid={"description"}>{data.relatedAssignment?.assignmentNumber || "Unbekannt"}</DataTableCell>
              <DataTableCell data-testid={"description"}>
                {data.employee ? renderFullName({ firstname: data.employee.firstname, lastname: data.employee.lastname }) : "Unbekannt"}
              </DataTableCell>
              <DataTableCell>
                {data.pdfData?.signature ? <CheckIcon data-testid="default-check" style={{ color: "green", fontSize: "16px" }} /> : "-"}
              </DataTableCell>
              <DataTableCell>
                {data.pdfData?.signature ? (
                  <CloudDownloadIcon
                    color={"primary"}
                    onClick={() => {
                      setDialogOpen(true);
                      setFormularId(data._id);
                    }}
                  />
                ) : (
                  <Link data-testid={`link-edit-row-${index}`} to={`/formular-center/edit/${data._id}`}>
                    <EditIcon color={"primary"} />
                  </Link>
                )}
              </DataTableCell>
            </>
          )}
          renderEmpty={() => emptyState}
        />
      </DataContainer>
    </>
  );
};

export default FormularTable;
