import CreateUserModel from "features/users/data/CreateUserModel";
import { CreateOrUpdateUserFormValues } from "features/users/presentation/components/CreateOrUpdateUserForm";

export default class CreateUserConverter {
  public static fromFormToModel(formValues: CreateOrUpdateUserFormValues): CreateUserModel {
    const groups = formValues.groups.map((group) => (group === "au" ? "employee" : group)).filter((_, index) => index === 0); // Find first

    const caretakerOff = formValues.caretakerOff?.map((x) => x.value);
    return { ...formValues, caretakerOff, groups };
  }
}
