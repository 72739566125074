import React, { useCallback, useEffect, useState } from "react";
import { queryCache, useQuery } from "react-query";
import useService from "core/di/useService";
import { RouteComponentProps, useParams } from "react-router-dom";
import UIRoute from "core/presentation/components/UIRoute";
import { ValidationMessageError } from "core/data/errors";
import CaseService from "features/cases/domain/services/CaseService";
import ClassificationService from "features/classifications/domain/services/ClassificationService";
import CreateOrUpdateCaseForm, { CreateOrUpdateCaseFormValues } from "../components/CreateOrUpdateCaseForm";
import BranchService from "features/branches/domain/services/BranchService";
import UserService from "features/users/domain/services/UserService";
import { AssignmentType, TranslatedAssignmentTypeMap } from "features/assignments/domain/services/AssignmentService";
import { renderClientLabel, renderUserName } from "features/clients/presentation/utils";
import ClientService from "features/clients/domain/services/ClientService";

export interface EditCaseRouteProps extends RouteComponentProps {}

const EditCaseRoute = (props: EditCaseRouteProps) => {
  const { caseNumber } = useParams<{ caseNumber: string }>();

  const caseService = useService(CaseService);
  const branchService = useService(BranchService);
  const classificationService = useService(ClassificationService);
  const userService = useService(UserService);
  const clientService = useService(ClientService);

  const [selectedBranch, setSelectedBranch] = useState<string>("");
  const [clientQuery, setClientQuery] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const { data: caseData } = useQuery(["/cases/" + caseNumber], () => caseService.getByNumber(caseNumber), { enabled: caseNumber });

  const { data: employeeOptions } = useQuery(
    ["allAvailableEmployeesForCase", selectedBranch],
    () =>
      userService.getEmployees({ groups: ["employee", "backoffice"], inBranch: selectedBranch }).then((users) =>
        users.data.map((user) => ({
          value: user.employeeNumber || "",
          label: `${renderUserName(user)} (${user.employeeNumber})`,
          number: user.employeeNumber || "",
        }))
      ),
    { enabled: selectedBranch }
  );

  const { data: branchOptions } = useQuery("internalBranchOptions", () =>
    branchService
      .get()
      .then((branches) => branches.map((branch) => ({ value: branch.id, label: `${branch.name} (${branch.code})`, number: branch.code })))
  );

  const { data: classificationOptions } = useQuery(["allClassifications"], () =>
    classificationService.get().then((classifications) => {
      return classifications.map((classification) => ({
        value: classification.number,
        label: `${classification.name} (${classification.number} - ${
          TranslatedAssignmentTypeMap[classification.assignmentMapping as AssignmentType] || "Unbekannte Klassifizierung"
        })`,
      }));
    })
  );

  const { data: clientOptions, isLoading: isLoadingClientOptions } = useQuery(
    ["ClientSearch", { clientQuery }],
    () => {
      return clientService.get({ fields: { clientNumber: clientQuery }, group: "", page: 1, pageSize: 10 }).then((r) => {
        return r.data.map((client) => {
          return {
            label: renderClientLabel(client),
            value: client.clientNumber,
            number: client.clientNumber,
          };
        });
      });
    },
    { enabled: clientQuery && clientQuery.length > 1 }
  );

  const { data: prescriberOptions } = useQuery("prescriberOptions", () => clientService.getPrescriberOptions());

  useEffect(() => {
    // preselect only if there is just one option or the employee is assigned to a specific branch
    if (caseData && caseData.branch) {
      setSelectedBranch(caseData.branch.id);
    }
  }, [caseData, caseData?.branch]);

  const onSubmit = useCallback(
    (values: CreateOrUpdateCaseFormValues) => {
      return caseService
        .update(caseNumber, values, values.genAssignment ? values.genAssignment : false)
        .then(async () => {
          await queryCache.invalidateQueries("caseList");
          props.history.push(`/cases`);
        })
        .catch((e) => {
          if (e instanceof ValidationMessageError) {
            switch (e.code) {
              case "EMPLOYEE_NOT_FOUND":
                setError("Unbekannten Mitarbeiter angegeben");
                return;
              case "CLIENT_NOT_FOUND":
                setError("Unbekannten Kunden angegeben");
                return;
              case "CASE_NOT_FOUND":
                setError("Unbekannten Vorgang angegeben");
                return;
              case "CASE_CREATION_FAILED":
                setError("Vorgang konnte auf Branchensoftware nicht erstellt werden.");
                return;
              default:
                setError(`Ein unbekannter Fehler ist aufgetreten. Vorgang konnte nicht erstellt werden Code: ${e.code}`);
                return;
            }
          } else {
            setError("Ein unbekannter Fehler ist aufgetreten");
          }
        });
    },
    [caseService, caseNumber, props.history]
  );

  return (
    <UIRoute {...props} title={"Vorgang bearbeiten"}>
      <CreateOrUpdateCaseForm
        error={error}
        initialCaseData={caseData}
        onSubmit={onSubmit}
        clientNumber={caseData?.client?.clientNumber}
        classificationOptions={classificationOptions || []}
        branchOptions={branchOptions || []}
        employeeOptions={employeeOptions || []}
        preSelectedBranch={selectedBranch}
        setSelectedBranch={setSelectedBranch}
        clientSelection={{
          clientOptions: clientOptions || [],
          clientOptionsLoading: isLoadingClientOptions,
          setClientQuery: setClientQuery,
        }}
        prescriberOptions={prescriberOptions}
      />
    </UIRoute>
  );
};

export default EditCaseRoute;
