import { AxiosInstance } from "axios";
import handleAxiosError from "axios-handle-error";
import {
  BadRequestError,
  convertAxiosErrorToValidationError,
  ElementNotFoundError,
  NetworkError,
  NotAcceptableError,
  UnauthorizedError,
  ValidationMessageError,
  WrongMimeTypeError,
} from "core/data/errors";
import { PagedResponse } from "core/utils";
import { StartSignatureRequestDialogFormProps } from "features/pools/presentation/components/detail/StartSignatureRequestDialog";
import { UpdateSigingRequestFormProps } from "features/pools/presentation/components/detail/UpdateSigningRequestDialog";
import Assignment, { CalendarAssignment } from "../domain/entities/Assignment";
import AssignmentRequestModel, { PatchAssignmentModel } from "./AssignmentRequestModel";

export default class AssignmentsApi {
  constructor(private authClient: AxiosInstance) {}

  public async get(params: any): Promise<PagedResponse<Assignment>> {
    return this.authClient.get("/v2/assignments", { params }).then((r) => {
      return r.data;
    });
  }

  public async getByNumber(number: string): Promise<Assignment> {
    return this.authClient
      .get(`/assignments/bynumber/${number}`)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          NO_RESPONSE: () => new NetworkError(),
          404: () => new ElementNotFoundError(),
        });
      });
  }

  public async getById(id: string): Promise<Assignment> {
    return this.authClient
      .get(`/assignments/${id}`)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          NO_RESPONSE: () => new NetworkError(),
          404: () => new ElementNotFoundError(),
        });
      });
  }

  public async getTypeAheadOptions(params: any): Promise<Assignment[]> {
    return this.authClient.get("/v2/assignments/typeAheadOptions", { params }).then((r) => {
      return r.data;
    });
  }

  public async create(data: AssignmentRequestModel): Promise<{ id: string }> {
    return this.authClient
      .post("/v2/assignments", data)
      .then(({ data }) => data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          NO_RESPONSE: () => new NetworkError(),
          404: () => new ElementNotFoundError(),
        });
      });
  }

  public async update(id: string, values: AssignmentRequestModel): Promise<Assignment> {
    return this.authClient
      .put(`/v2/assignments/${id}`, values)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          NO_RESPONSE: () => new NetworkError(),
          404: () => new ElementNotFoundError(),
        });
      });
  }

  public async patch(id: string, values: PatchAssignmentModel): Promise<Assignment> {
    return this.authClient
      .patch(`/v2/assignments/${id}`, values)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          NO_RESPONSE: () => new NetworkError(),
          404: () => new ElementNotFoundError(),
        });
      });
  }

  public async cancelRentExtension(id: string): Promise<Assignment> {
    return this.authClient
      .post(`/rent-extension/cancel/${id}`)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          NO_RESPONSE: () => new NetworkError(),
          404: () => new ElementNotFoundError(),
        });
      });
  }

  public async assignCaseAndClient(assignmentId: string, caseNumber: string): Promise<void> {
    return this.authClient.patch(`/v2/assignments/${assignmentId}/assign-case-and-client/${caseNumber}`);
  }

  public async getCalendarAssignmentsForEmployeeInDatetimeRange(
    userId: string,
    dateTimeRange: { start: Date; end: Date }
  ): Promise<CalendarAssignment[]> {
    const { start, end } = dateTimeRange;
    const params = {
      employee: userId,
      startDate: start,
      endDate: end,
    };

    return this.authClient.get("/v2/assignments/calendar", { params }).then(({ data }) => data);
  }

  public async assignClient(assignmentId: string, clientNumber: string) {
    return this.authClient.patch(`/v2/assignments/${assignmentId}/assign-client/${clientNumber}`);
  }

  public async getPrescriberByAssignment(id: string) {
    return this.authClient
      .get(`/v2/assignments/prescriberByAssignment/${id}`)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          NO_RESPONSE: () => new NetworkError(),
          404: () => new ElementNotFoundError(),
        });
      });
  }

  public async updateSigningRequest(id: string, model: UpdateSigingRequestFormProps) {
    const body = new FormData();
    body.append("file", model.file!);
    body.append("formData", JSON.stringify({ comment: model.comment }));

    return this.authClient
      .post(`/v2/assignments/updateSignatureRequest?id=${id}`, body)
      .then((x) => {
        return x.data;
      })
      .catch((e) => {
        throw handleAxiosError(e, {
          401: () => new UnauthorizedError("Nicht Autorisiert.", "401"),
          404: () => new WrongMimeTypeError(),
          NO_RESPONSE: () => new NetworkError(),
          500: () => new Error("Ein unerwarteteter Fehler ist aufgetreten."),
          403: () => new ValidationMessageError("Nutzer muss entweder einen Vermittler oder einem Verodner zugewiesen sein."),
          406: () => new NotAcceptableError("Die Datei ist möglicherweise mit Schadsoftware infiziert und kann nicht akzeptiert werden."),
          400: () => new BadRequestError("Die Datei darf nicht größer als 10MB sein."),
        });
      });
  }

  public async getSignatureRequestHistoryDocument(assignmentId: string, historyId: string) {
    return this.authClient
      .get(`/v2/assignments/getSignatureRequestHistoryDocument?assignmentId=${assignmentId}&historyId=${historyId}`, {
        responseType: "blob",
      })
      .then(({ data }) => {
        return window.URL.createObjectURL(new Blob([data], { type: "application/pdf" }));
      })
      .catch((e) => {
        throw e;
      });
  }

  public async startSignatureRequest(model: StartSignatureRequestDialogFormProps, assignmentId: string) {
    let requestModel: { note: string; user: string[]; clientSigOnly: boolean; clientSigNeeded: boolean } = {
      user: model.user.map((u) => u.value),
      note: model.note,
      clientSigOnly: model.clientSigOnly,
      clientSigNeeded: model.clientSigNeeded,
    };

    return this.authClient
      .post("/v2/assignments/startSignatureRequest", { assignmentId, ...requestModel })
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          NO_RESPONSE: () => new NetworkError(),
          404: () => new ElementNotFoundError(),
        });
      });
  }

  public async deleteAssigment(assignmentId: string) {
    return this.authClient.delete(`/v2/assignments/${assignmentId}`);
  }
}
