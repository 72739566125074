import { BranchResponseModel } from "features/branches/data/BranchResponseModel";
import { Branch } from "../entities/BranchEntity";

export default class BranchConverter {
  public static fromResponseModelToEntity(resMod: BranchResponseModel): Branch {
    const { _id, ...fields } = resMod;
    return {
      ...fields,
      id: _id,
      isB2B: !!fields.isB2B,
      b2bOwner: resMod.b2bOwner?._id
        ? {
            name: resMod.b2bOwner.name,
            b2bNumber: resMod.b2bOwnerType === "Intermediary" ? resMod.b2bOwner.intermediaryNumber : resMod.b2bOwner.prescriberNumber,
            id: resMod.b2bOwner._id,
          }
        : resMod.b2bOwner,
    };
  }
}
