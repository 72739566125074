import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

interface DeleteMessageDialogProps {
  open: boolean;
  onAgree: () => void;
  onDismiss: () => void;
}

const DeleteMessageDialog: React.FC<DeleteMessageDialogProps> = ({ open, onAgree, onDismiss }) => {
  return (
    <div>
      <Dialog open={open} onClose={onDismiss} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Nachricht löschen?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Sind Sie sicher dass Sie diese Nachricht löschen wollen?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button data-testid="message_cancel" onClick={onDismiss}>
            Abbrechen
          </Button>
          <Button data-testid="message_delete" onClick={() => onAgree()} color="secondary" autoFocus>
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteMessageDialog;
