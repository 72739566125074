import MessageRequestModel from "features/messages/data/MessageRequestModel";
import { CreateOrUpdateMessageFormValues } from "features/messages/presentation/components/CreateOrUpdateMessageForm";
import MessageResponseModel from "../../data/MessageResponseModel";
import Message from "../entities/Message";

export default class MessageConverter {
  public static fromResponseModelToMessage(messageModel: MessageResponseModel): Message {
    const { _id, ...fields } = messageModel;
    return { id: _id, ...fields };
  }

  public static cleanMessageRequest(data: CreateOrUpdateMessageFormValues): MessageRequestModel {
    const res: MessageRequestModel = {
      subject: data.subject ? data.subject : undefined,
      relatedAssignmentNumber: data.relatedAssignmentNumber ? data.relatedAssignmentNumber : undefined,
      recipient: data.recipient ? data.recipient.map((r) => ({ number: r.value, recipientType: r.recipientType })) : undefined,
      recipientGroups: data.recipientGroups ? data.recipientGroups.map((x) => x.value) : undefined,
      type: data.type,
      text: data.text,
    };

    return res;
  }
}
