import { AxiosInstance } from "axios";
import handleAxiosError from "axios-handle-error";
import { convertAxiosErrorToValidationError, NetworkError } from "core/data/errors";
import { PagedResponse } from "core/utils";
import { CreateOrUpdateCaseFormValues } from "../presentation/components/CreateOrUpdateCaseForm";
import CaseCreateOrUpdateModel from "./CaseCreateOrUpdateModel";
import CaseReponseModel from "./CaseModel";
import Case from "../domain/entities/Case";

export default class CaseApi {
  constructor(private authClient: AxiosInstance) {}

  public async get(params: any): Promise<PagedResponse<CaseReponseModel>> {
    return this.authClient.get("/cases", { params }).then(({ data }) => data);
  }

  public async getTypeAheadOptions(params: any): Promise<Case[]> {
    return this.authClient.get("/cases/typeAheadOptions", { params }).then((r) => {
      return r.data;
    });
  }

  public async getByNumber(caseNumber: string): Promise<CaseReponseModel> {
    return this.authClient.get("/cases?caseNumber=" + caseNumber).then(({ data }) => data);
  }

  public async create(model: CreateOrUpdateCaseFormValues): Promise<{ id: string }> {
    return this.authClient
      .post("/adapter/create-case", model)
      .then(({ data }) => data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          NO_RESPONSE: () => new NetworkError(),
        });
      });
  }

  public update(id: string, updateCaseModel: CaseCreateOrUpdateModel, genAssignment: boolean) {
    return this.authClient
      .put("/adapter/update-case/" + id, { model: updateCaseModel, genAssignment: genAssignment })
      .then(({ data }) => data);
  }
}
