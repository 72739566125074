import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FormControl, FormHelperText, Typography } from "@material-ui/core";
import SelectOption from "core/interfaces/SelectOption";

interface Props {
  field: any;
  form: any;
  legend: string;
  options: SelectOption[];
  "data-testid"?: string;
  getTestIdForOption?: (option: any) => string;
  onChangeText?: (x: string) => void;
  onSelectOption?: (x: string) => void;
  className?: string;
}

const ComboBox: React.FC<Props> = ({
  field,
  form,
  legend,
  options,
  getTestIdForOption,
  onChangeText,
  onSelectOption,
  className = "",
  ...props
}) => {
  const testId = props["data-testid"];
  const [inputValue, setInputValue] = useState("");

  // in case we get a value passed as the initial value,
  // set the input value to the field value as long as it changes (due to multiple rerenders)
  useEffect(() => {
    if (field.value) {
      setInputValue(field.value);
    }
  }, [field.value, setInputValue]);

  return (
    <FormControl error={form.touched[field.name] && !!form.errors[field.name]} className={className}>
      <Autocomplete
        data-testid={testId}
        options={options}
        value={field.value}
        onChange={(e, x) => {
          form.setFieldValue(field.name, x ? x.value : null);
          if (onSelectOption) {
            onSelectOption(x ? x.value : "");
          }
        }}
        getOptionLabel={(option: any) => option.label || ""}
        filterSelectedOptions
        renderOption={(option) => (
          <Typography data-testid={`${testId}-${getTestIdForOption ? getTestIdForOption(option) : option.value}`} noWrap>
            {option.label}
          </Typography>
        )}
        inputValue={inputValue}
        getOptionSelected={(option, value) => option.value === value}
        onInputChange={(_, value) => setInputValue(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={legend}
            placeholder={legend}
            error={form.touched[field.name] && !!form.errors[field.name]}
            onChange={onChangeText ? (e) => onChangeText(e.target.value) : undefined}
          />
        )}
        // set the input value to the selected option, otherwise the input is cleared if field loses focus
        onBlur={() => setInputValue(field.value || "")}
        noOptionsText="Keine weiteren Optionen verfügbar"
        {...props}
      />
      <FormHelperText>{form.touched[field.name] && form.errors[field.name]}</FormHelperText>
    </FormControl>
  );
};

export default ComboBox;
