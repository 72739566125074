import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import useService from "core/di/useService";
import { RouteComponentProps, useParams } from "react-router-dom";
import UIRoute from "core/presentation/components/UIRoute";
import BranchService from "features/branches/domain/services/BranchService";
import BranchesTable from "../components/BranchesTable";
import B2BService from "features/b2b/domain/services/B2BService";

export interface BranchesRouteProps extends RouteComponentProps {}

export default function BranchesRoute(props: BranchesRouteProps) {
  const { group } = useParams<any>();
  const { type } = useParams<{ type: "internal" | "b2b" }>();
  const isB2B = useMemo(() => type === "b2b", [type]);
  const branchService = useService(BranchService);
  const b2bService = useService(B2BService);

  const [currentB2BPartner, setCurrentB2BPartner] = useState<string>("");

  const { data: branches, isLoading: branchesLoading, refetch } = useQuery(
    ["branchesList", { group, type, currentClient: currentB2BPartner }],
    () => {
      return isB2B ? branchService.get(undefined, true, currentB2BPartner) : branchService.get();
    },
    { enabled: !isB2B || (isB2B && currentB2BPartner !== "") }
  );
  const { data: b2bPartnerOptions } = useQuery("b2bPartnerWithBranches", () => b2bService.getB2BPartnerWithBranches(), { enabled: isB2B });

  return (
    <UIRoute {...props} title={type === "internal" ? "Interne Niederlassungen" : "B2B-Niederlassungen"}>
      <BranchesTable
        branches={branches || null}
        isLoading={branchesLoading}
        onReload={refetch}
        onAddBranch={() => {
          props.history.push("/branch/create/" + type);
        }}
        b2b={
          (isB2B && {
            b2bPartnerOptions: b2bPartnerOptions || [],
            currentB2BPartner: currentB2BPartner,
            onCurrentB2BPartnerChange: (partner) => setCurrentB2BPartner(partner),
          }) ||
          undefined
        }
      />
    </UIRoute>
  );
}
