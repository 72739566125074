import React, { useCallback } from "react";
import { createStyles, AppBar, Tabs, WithStyles, Tab } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = createStyles({
  secondaryBar: {
    zIndex: 0,
  },
});

export interface SubTabHeaderProps extends WithStyles<typeof styles> {
  currentPath: string;
  onTabChange: (value: string) => void;
  tabs: { value: string; label: string; testId?: string }[];
}

function SubTabHeaderBase(props: SubTabHeaderProps) {
  const { classes, currentPath, onTabChange, tabs } = props;

  const handleRouteChange = useCallback(
    (_: any, path: string) => {
      onTabChange(path);
    },
    [onTabChange]
  );

  return (
    <AppBar component="div" className={classes.secondaryBar} color="primary" position="static" elevation={0}>
      <Tabs value={currentPath} onChange={handleRouteChange} textColor="inherit">
        {tabs.map((x) => (
          <Tab data-testid={x.testId || `subheader-${x.value}`} key={x.value} value={x.value} textColor="inherit" label={x.label} />
        ))}
      </Tabs>
    </AppBar>
  );
}

export default withStyles(styles)(SubTabHeaderBase);
