import { Chip, makeStyles } from "@material-ui/core";
import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DataTable, { DataTableCell } from "template/presentation/components/table/DataTable";
import { Pool } from "features/pools/domain/entities/PoolEntity";
import { useCurrentUserInGroup } from "features/login/presentation/hooks/useUser";
import PoolDataContainer from "./PoolDataContainer";
import { Branch } from "features/branches/domain/entities/BranchEntity";
import { EmptyState } from "core/presentation/components/EmptyState";
import { B2BPartner } from "features/clients/domain/entities/Client";

const useStyles = makeStyles((theme) => ({
  classification: {
    marginRight: 5,
    marginBottom: 5,
  },
}));

export interface PoolsTableProps {
  branches: Branch[];
  branchesLoading: boolean;
  currentBranch: string;
  onCurrentBranchChange: (branch: string) => void;
  pools: Pool[];
  poolsLoading: boolean;
  onReload: () => void;
  onAddInternalPool?: () => void;
  onAddB2BPool?: () => void;
  createNewInternalPool?: string;
  createNewB2BPool?: string;
  b2bPartnerOptions?: B2BPartner[];
  currentB2BPartner?: string;
  onCurrentB2BPartnerChange?: (partner: string) => void;
  type?: "internal" | "b2b";
}

const adminColumns = [{ name: "Anzahl Mitarbeiter" }, { name: "Default" }, { name: "Bearbeiten" }, { name: "Ansehen" }];

export default function PoolsTable(props: PoolsTableProps) {
  const styles = useStyles();
  const {
    branches,
    branchesLoading,
    currentBranch,
    onCurrentBranchChange,
    pools,
    poolsLoading,
    onReload,
    onAddInternalPool,
    onAddB2BPool,
    createNewInternalPool,
    createNewB2BPool,
    b2bPartnerOptions,
    currentB2BPartner,
    onCurrentB2BPartnerChange,
    type,
  } = props;
  const [isAdmin] = useCurrentUserInGroup("admin");

  const columns = useMemo(() => {
    const columns = [{ name: "Pool" }];

    if (type === "internal") {
      columns.push({ name: "Klassifizierungen" });
    }

    if (isAdmin) {
      columns.push(...adminColumns);
    }

    return columns;
  }, [type, isAdmin]);

  const renderDefault = useCallback((defaultBranch: boolean) => {
    if (defaultBranch) {
      return <CheckIcon data-testid="default-check" style={{ color: "green", fontSize: "16px" }} />;
    }

    return "-";
  }, []);

  const emptyState = useMemo(() => {
    switch (type) {
      case "b2b":
        return (
          <EmptyState
            title="Noch kein Pool für Business Partner vorhanden oder ausgewählt"
            description="Erstellen Sie jetzt Ihren ersten Pool, damit Business Partner eingehende Anfragen und Rückmeldungen verarbeiten zu können."
            buttonLabel="Neuer Pool"
            onButtonClick={onAddB2BPool}
          />
        );
      case "internal":
        return (
          <EmptyState
            title="Noch kein Pool vorhanden"
            description="Erstellen Sie jetzt Ihren ersten Pool, um eingehende Anfragen von Endkunden und Rückmeldungen von Außendienstmitarbeitern verarbeiten zu können."
            buttonLabel="Neuer Pool"
            onButtonClick={onReload}
          />
        );
    }
  }, [type, onReload, onAddB2BPool]);

  return (
    <PoolDataContainer
      loading={poolsLoading}
      createNewInternalPool={createNewInternalPool}
      createNewB2BPool={createNewB2BPool}
      onAddInternalPool={onAddInternalPool}
      onAddB2BPool={onAddB2BPool}
      onReload={onReload}
      branchOptions={branches || []}
      currentBranch={currentBranch}
      onCurrentBranchChange={onCurrentBranchChange}
      b2bPartnerOptions={b2bPartnerOptions}
      currentB2BPartner={currentB2BPartner}
      onCurrentB2BPartnerChange={onCurrentB2BPartnerChange}
    >
      <DataTable<Pool>
        data-testid={`pooltable-${isAdmin ? "admin" : "backoffice"}`}
        loading={poolsLoading && branchesLoading}
        columns={columns}
        rows={pools}
        keyExtractor={(data) => data.id}
        renderRow={(data, index) => {
          return (
            <>
              <DataTableCell data-testid="poolname">{data.name}</DataTableCell>
              {type === "internal" && (
                <DataTableCell>
                  {data.classifications.map((x, index) => (
                    <Chip
                      data-testid={`poolclass-${x.number}`}
                      size="small"
                      key={index}
                      label={x.name + " (" + x.number + ")"}
                      className={styles.classification}
                    />
                  ))}
                </DataTableCell>
              )}
              {isAdmin && (
                <>
                  <DataTableCell data-testid="poolemployees">{data.employees?.length}</DataTableCell>
                  <DataTableCell data-testid="pooldefault">{renderDefault(data.default)}</DataTableCell>
                  <DataTableCell>
                    <Link data-testid={`link-edit-row-${index}`} to={`/pools/edit/${data.id}`}>
                      <EditIcon color={"primary"} />
                    </Link>
                  </DataTableCell>
                  <DataTableCell>
                    <Link data-testid={`link-view-row-${index}`} to={`/pools/${data.id}/assignments/${type}/all`}>
                      <VisibilityIcon color={"primary"} />
                    </Link>
                  </DataTableCell>
                </>
              )}
            </>
          );
        }}
        renderEmpty={() => emptyState}
      />
    </PoolDataContainer>
  );
}
