import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import FormContainer from "template/presentation/components/form/FormContainer";
import FormSection from "template/presentation/components/form/FormSection";
import { SimpleFileUpload, TextField } from "formik-material-ui";
import { Alert } from "@material-ui/lab";
import FormSubmitButton from "template/presentation/components/form/FormSubmitButton";
import Signatureform from "features/signatureforms/domain/entities/Signatureform";

interface CreateSignatureFormProps {
  onSubmit: (values: Signatureform) => void;
  error: string | null;
}

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(1),
  },
}));

const importDocumentSchema = Yup.object().shape({
  file: Yup.string().required("Benötigt"),
  title: Yup.string().required("Benötigt"),
});

const CreateSignatureformsForm: React.FC<CreateSignatureFormProps> = (props) => {
  const { onSubmit, error } = props;
  const classes = useStyles();

  const initValues = useMemo((): Signatureform => {
    return {
      id: "",
      title: "",
      file: null,
    };
  }, []);

  return (
    <Formik<Signatureform>
      initialValues={initValues}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
        } finally {
          setSubmitting(false);
        }
      }}
      validationSchema={importDocumentSchema}
    >
      {({ submitForm, isSubmitting, values }) => {
        return (
          <FormContainer data-testid="pdf-datei">
            <FormSection label={"Bezeichnung"}>
              <Field
                data-testid="signatureforms-title"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"title"}
                label={"Titel"}
              />
            </FormSection>
            <FormSection label={"Datei"}>
              <Field component={SimpleFileUpload} name="file" label="PDF-Datei" accept=".pdf" />
            </FormSection>
            <FormSection>
              {error && (
                <Alert className={classes.field} data-testid="documentimport-form-error" severity="error">
                  {error}
                </Alert>
              )}

              <FormSubmitButton data-testid="button-createsignatureform" disabled={isSubmitting} onClick={submitForm}>
                {"Speichern"}
              </FormSubmitButton>
            </FormSection>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default CreateSignatureformsForm;
