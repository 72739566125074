import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { createBrowserHistory } from "history";
import { BACKEND_URL } from "./backend";

function getAccessToken() {
  return localStorage.getItem("accessToken");
}

function getRefreshToken() {
  return localStorage.getItem("refreshToken");
}

const unauthorizedClient = axios.create({ baseURL: BACKEND_URL });

unauthorizedClient.interceptors.request.use(
  function (config) {
    process.env.NODE_ENV !== "production" && console.log(`[unauthorizedClient][${config.method}][${config.url}]`);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

let authorizedClient = axios.create({ baseURL: BACKEND_URL });

createAuthRefreshInterceptor(
  authorizedClient,
  async (error) => {
    try {
      const token = await unauthorizedClient.post("/auth/refresh", { refreshToken: getRefreshToken() });
      localStorage.setItem("accessToken", token.data.accessToken);
      localStorage.setItem("refreshToken", token.data.refreshToken);
      error.response.config.headers.Authorization = `Bearer ${token.data.accessToken}`;
      return Promise.resolve(error.response!.config);
    } catch (err) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      createBrowserHistory().push("/#/login");
      window.location.reload();
      return Promise.reject(err);
    }
  },
  {}
);

authorizedClient.interceptors.request.use(
  function (config) {
    if (config.headers.Authorization === undefined) {
      const accessToken = getAccessToken();
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    process.env.NODE_ENV !== "production" && console.log(`[authorizedClient][${config.method}][${config.url}]`);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export { unauthorizedClient };
export { authorizedClient };
