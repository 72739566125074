import React, { useState } from "react";
import { CircularProgress, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

interface TypeAheadFieldProps {
  legend: string;
  typeAheadOptions: { value: string; label: string }[];
  value?: { value: string; label: string };
  variant?: "standard" | "outlined" | "filled";
  "data-testid"?: string;
  getTestIdForOption?: (option: any) => string;
  setTypeAheadQuery: (searchQuery: string) => void;
  typeAheadChange: (value: string) => void;
  isLoading?: boolean;
}

const TypeAheadField: React.FC<TypeAheadFieldProps> = ({
  legend,
  typeAheadOptions,
  getTestIdForOption,
  setTypeAheadQuery,
  typeAheadChange,
  value,
  variant,
  isLoading,
  ...props
}) => {
  const testId = props["data-testid"];
  const [open, setOpen] = useState(false);

  let loading = isLoading ? isLoading : open && typeAheadOptions?.length === 0;

  return (
    <>
      <Autocomplete
        data-testid={testId}
        options={typeAheadOptions}
        open={open}
        value={value}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        getOptionLabel={(option: { label: string; value: string }) => option.label}
        onChange={(e: any, x: any) => {
          if (x) {
            typeAheadChange(x.value);
          } else {
            typeAheadChange("");
          }
        }}
        loading={loading}
        renderInput={(params) => (
          <TextField
            variant={variant || "standard"}
            {...params}
            label={legend}
            placeholder={legend}
            onChange={(e) => {
              setTypeAheadQuery(e.target.value);
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        renderOption={(option) => (
          <Typography data-testid={`${testId}-${getTestIdForOption ? getTestIdForOption(option) : option.value}`} noWrap>
            {option.label}
          </Typography>
        )}
        {...props}
      />
    </>
  );
};

export default TypeAheadField;
