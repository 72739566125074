import React from "react";
import format from "date-fns/format";
import DataContainer from "template/presentation/components/table/DataContainer";
import DataTable, { DataTableCell } from "template/presentation/components/table/DataTable";
import AdapterEntitySizeEntry from "features/adapter/domain/entities/AdapterEntitySizeEntry";

export interface AdapterTableSizesTableProps {
  data: AdapterEntitySizeEntry[] | null;
  isLoading: boolean;
  onReload: () => void;
}

const columns = [{ name: "Name" }, { name: "Anzahl" }, { name: "Aktualisiert" }];

export default function AdapterTableSizesTable(props: AdapterTableSizesTableProps) {
  const { data, isLoading, onReload } = props;

  return (
    <DataContainer loading={isLoading} onReload={onReload}>
      <DataTable<AdapterEntitySizeEntry>
        data-testid={`adapterentitysize-table`}
        loading={isLoading}
        columns={columns}
        rows={data}
        keyExtractor={(data) => data.id}
        renderRow={(data, index) => {
          return (
            <>
              <DataTableCell>{data.entity}</DataTableCell>
              <DataTableCell>{data.size}</DataTableCell>
              <DataTableCell>{format(data.date, "dd.MM.yyyy HH:mm")}</DataTableCell>
            </>
          );
        }}
        renderEmpty={() => <div>Empty</div>}
      />
    </DataContainer>
  );
}
