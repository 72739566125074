import { AxiosInstance } from "axios";
import handleAxiosError from "axios-handle-error";
import { ClientNotFound, UnauthorizedError } from "core/data/errors";

import { NetworkError } from "core/data/errors";
import { RehaItem } from "../../entities/RehaItem";

export default class RehaItemApi {
  constructor(private authClient: AxiosInstance) {}

  public async getByClientNumber(clientNumber: string): Promise<RehaItem[] | undefined> {
    return this.authClient
      .get(`/rehaItems`, { params: { clientNumber } })
      .then((x) => {
        return x.data;
      })
      .catch((e) => {
        throw handleAxiosError(e, {
          401: () => new UnauthorizedError("Nicht Autorisiert.", "401"),
          NO_RESPONSE: () => new NetworkError(),
          500: () => new Error("Ein unerwarteteter Fehler ist aufgetreten."),
          404: () => new ClientNotFound("Kunde konnte nicht gefunden werden."),
        });
      });
  }
}
