import React, { useCallback, useEffect } from "react";
import { useQuery } from "react-query";
import useService from "core/di/useService";
import { RouteComponentProps } from "react-router-dom";
import UIRoute from "core/presentation/components/UIRoute";
import PoolService from "features/pools/domain/services/PoolService";
import PoolsTable from "../components/PoolsTable";
import BranchService from "features/branches/domain/services/BranchService";
import { InternalPoolsStore } from "../stores";

interface PoolsRouteProps extends RouteComponentProps {}

export default function InternalPoolsRoute(props: PoolsRouteProps) {
  const poolService = useService(PoolService);

  const branchService = useService(BranchService);
  const { data: branches, isLoading: branchesLoading, isFetching: branchesFetching } = useQuery(["/internalBranches"], () =>
    branchService.get(undefined, false)
  );

  const currentBranch = InternalPoolsStore.useState((s) => s.currentBranch);
  const onCurrentBranchChange = useCallback(
    (branch: string) =>
      InternalPoolsStore.update((s) => {
        s.currentBranch = branch;
      }),
    []
  );

  const { data: pools, isLoading: poolsLoading, refetch, isFetching: poolsFetching } = useQuery(
    ["internal/pools?branch=" + currentBranch],
    () => poolService.getAllForBranch(currentBranch),
    {
      enabled: currentBranch !== "",
    }
  );

  useEffect(() => {
    if (currentBranch === "" && branches && branches?.length > 0) {
      InternalPoolsStore.update((s) => {
        s.currentBranch = branches[0].id;
      });
    }
  }, [currentBranch, branches]);

  return (
    <UIRoute {...props} title="Pools">
      <PoolsTable
        branches={branches || []}
        branchesLoading={branchesLoading || branchesFetching}
        currentBranch={currentBranch}
        onCurrentBranchChange={onCurrentBranchChange}
        pools={pools || []}
        poolsLoading={poolsLoading || poolsFetching}
        onReload={refetch}
        onAddInternalPool={() => props.history.push("/pools/create/internal")}
        createNewInternalPool="Neuer Pool (intern)"
        type="internal"
      />
    </UIRoute>
  );
}
