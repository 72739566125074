import React from "react";

interface Props {
  className?: string;
}

const DeformedEgg: React.FC<Props> = (props) => {
  return (
    <svg width="779" height="786" viewBox="0 0 779 786" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M743.037 56.9889C798.219 122.149 777.672 253.057 761.235 367.528C744.798 481.999 732.47 580.033 677.288 651.064C622.106 722.682 524.07 766.709 411.945 781.385C299.82 795.473 173.019 779.036 94.9427 708.006C16.2791 636.975 -13.66 510.177 5.71239 403.337C25.0848 296.497 94.9427 209.617 173.019 143.869C251.683 78.709 338.565 34.6818 447.755 12.9616C556.944 -8.7585 687.855 -8.7585 743.037 56.9889Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient id="paint0_linear" x1="88" y1="242.5" x2="438.5" y2="470" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F6F9FE" />
          <stop offset="0.902391" stopColor="#F2F5FD" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default DeformedEgg;
