import React, { useCallback, useMemo } from "react";
import { withStyles, Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box, MenuItem, Select, TableFooter, TableSortLabel } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import theme from "template/utils/theme/theme";

export const DataTablePaginatedAndSortableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#f5f5f5",
      color: "rgba(0,0,0,0.54)",
      fontWeight: 600,
      fontSize: 12,
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      padding: "3px 15px",
      position: "relative",
    },
    body: {
      fontSize: 13,
      color: "rgba(0,0,0,0.54)",
      borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
    },
  })
)(TableCell);

export const DataTablePaginatedAndSortableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {},
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  field: {
    marginTop: theme.spacing(1),
  },
});

export interface DataTablePaginatedAndSortableColumn {
  name: string;
  sortField?: string;
}

interface DataTablePaginatedAndSortableProps<T> {
  columns: DataTablePaginatedAndSortableColumn[];
  rows: T[] | null | undefined;
  loading?: boolean;
  page: number;
  pageSize: number;
  totalCount?: number;
  sortedBy: { field: string; order: "asc" | "desc" };
  renderRow: (data: T, index: number) => React.ReactNode;
  keyExtractor: (data: T, index: number) => string;
  renderEmpty?: () => React.ReactNode;
  "data-testid"?: string;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
  onSortChange: (sortBy: string, sortOrder: "asc" | "desc") => void;
}

function DataTablePaginatedAndSortable<T>(
  props: DataTablePaginatedAndSortableProps<T>
): React.ReactElement<DataTablePaginatedAndSortableProps<T>> {
  const {
    columns,
    rows,
    loading,
    renderRow,
    renderEmpty,
    page,
    pageSize,
    onPageChange,
    onPageSizeChange,
    totalCount,
    sortedBy,
    onSortChange,
  } = props;
  const classes = useStyles();

  // const [currentPage, setCurrentPage] = useState<number>(initialPage);

  const hasEntries = rows && rows.length > 0;

  const pagination = useMemo(() => {
    if (!pageSize || !rows || totalCount === undefined || totalCount === 0) {
      return null;
    }

    return (
      <TableFooter>
        <TableRow>
          <TableCell colSpan={columns.length}>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Pagination
                page={page}
                siblingCount={5}
                onChange={(e, x) => {
                  onPageChange(x);
                }}
                count={Math.ceil(totalCount / pageSize)}
                shape="rounded"
                data-testid={`page-${page}`}
              />
              <Select
                className={classes.field}
                data-testid="field-pageSize"
                value={pageSize}
                onChange={(e) => onPageSizeChange(e.target.value as any)}
                variant="outlined"
                displayEmpty
                style={{ marginLeft: "auto" }}
              >
                <MenuItem value="" disabled>
                  Elemente pro Seite
                </MenuItem>
                <MenuItem data-testid={"field-pageSize-100"} key={100} value={100}>
                  100
                </MenuItem>
                <MenuItem data-testid={"field-pageSize-50"} key={50} value={50}>
                  50
                </MenuItem>
                <MenuItem data-testid={"field-pageSize-25"} key={25} value={25}>
                  25
                </MenuItem>
              </Select>
            </Box>
          </TableCell>
        </TableRow>
      </TableFooter>
    );
  }, [columns.length, classes.field, onPageChange, onPageSizeChange, page, pageSize, rows, totalCount]);

  const onSortToggle = useCallback(
    (column, currentSort) => {
      if (column.sortField !== currentSort.field) {
        onSortChange(column.sortField, "asc");
      } else {
        onSortChange(column.sortField, currentSort.order === "asc" ? "desc" : "asc");
      }
    },
    [onSortChange]
  );

  return (
    <div style={{ position: "relative", overflow: "auto hidden" }}>
      {hasEntries && loading ? (
        <div
          style={{
            position: "absolute",
            display: "flex",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            paddingTop: 24,
            justifyContent: "center",
            backgroundColor: "#FFFFFF88",
          }}
        >
          <CircularProgress />
        </div>
      ) : null}
      <Table data-testid={props["data-testid"]} className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            {columns.map((x, key) => (
              <DataTablePaginatedAndSortableCell key={key}>
                {x.sortField ? (
                  <TableSortLabel
                    style={{ color: "rgba(0,0,0,0.54)" }}
                    active={x.sortField === sortedBy.field}
                    direction={sortedBy.order}
                    onClick={() => {
                      onSortToggle(x, sortedBy);
                    }}
                  >
                    {x.name}
                  </TableSortLabel>
                ) : (
                  x.name
                )}
              </DataTablePaginatedAndSortableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows &&
            rows.map((row, index) => (
              <DataTablePaginatedAndSortableRow data-testid={`${props["data-testid"]}-row-${index}`} key={index}>
                {renderRow(row, index)}
              </DataTablePaginatedAndSortableRow>
            ))}
        </TableBody>
        {pagination}
      </Table>
      {!hasEntries && loading ? (
        <div
          style={{
            display: "flex",
            padding: 24,
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : null}
      {!hasEntries && !loading && renderEmpty ? renderEmpty() : null}
    </div>
  );
}

export default DataTablePaginatedAndSortable;
