import { Chip } from "@material-ui/core";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import DataContainer from "template/presentation/components/table/DataContainer";
import { DataTableCell } from "template/presentation/components/table/DataTable";
import User from "../../domain/entities/UserEntity";
import DataTablePaginatedAndSortable from "template/presentation/components/table/DataTablePaginatedAndSortable";
import { EmptyState } from "core/presentation/components/EmptyState";
import { useCurrentUserInGroup } from "features/login/presentation/hooks/useUser";

export interface UsersTableProps {
  group?: string;
  users: any | User[] | null;
  isLoading: boolean;
  onReload: () => void;
  onSearch: (query: string | undefined) => void;
  page: number;
  pageSize: number;
  totalCount?: number;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
  onSortChange: (sortBy: string, sortOrder: "asc" | "desc") => void;
  sortedBy: { field: string; order: "asc" | "desc" };
  searchQuery?: string;
  onAdd: () => void;
}

const defaultColumns = [{ name: "Nachname", sortField: "lastname" }, { name: "Vorname", sortField: "firstname" }, { name: "E-Mail" }];

export default function UsersTable(props: UsersTableProps) {
  const {
    group,
    users,
    isLoading,
    onReload,
    onSearch,
    page,
    pageSize,
    totalCount,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    sortedBy,
    searchQuery,
    onAdd,
  } = props;

  const [, isNotB2BBackoffice] = useCurrentUserInGroup("backoffice");

  const columns = useMemo(() => {
    let columns;
    if (group === undefined) {
      columns = [...defaultColumns, { name: "Gruppen" }];
    } else if (group === "au") {
      columns = [{ name: "Nummer" }, ...defaultColumns];
    } else if (group === "b2b") {
      columns = [{ name: "Nummer" }, { name: "Vermittler/Verordner" }, ...defaultColumns];
    } else if (group === "b2bBackoffice") {
      columns = [{ name: "Nummer" }, ...defaultColumns];
    } else if (group === "b2c") {
      columns = [{ name: "Nummer" }, ...defaultColumns];
    } else if (group === "backoffice") {
      columns = [{ name: "Nummer" }, ...defaultColumns];
    } else {
      columns = defaultColumns;
    }

    if (isNotB2BBackoffice) {
      columns = [...columns, { name: "Aktion" }];
    }

    return columns;
  }, [group, isNotB2BBackoffice]);

  const emptyState = useMemo(() => {
    switch (group) {
      case "au":
        return (
          <EmptyState
            title="Noch kein Außendienst-Benutzer vorhanden"
            description="Erstellen Sie jetzt Ihren ersten Außendienst-Benutzer, um Aufträge einfach und digital von unterwegs erledigen zu können."
            buttonLabel="Neuer Benutzer"
            onButtonClick={onAdd}
          />
        );

      case "b2b":
        return (
          <EmptyState
            title="Noch kein Benutzer für Business Partner vorhanden"
            description="Erstellen Sie jetzt Ihren ersten B2B-Benutzer, um neue Aufträge einfach und digital zu empfangen."
            buttonLabel="Neuer Benutzer"
            onButtonClick={onAdd}
          />
        );

      case "b2c":
        return (
          <EmptyState
            title="Noch kein Endkunden Account vorhanden"
            description="Erstellen Sie jetzt Ihren ersten Benutzer für Endkunden, damit dieser Aufträge einfach und digital für die B2C-App empfangen kann."
            buttonLabel="Neuer Benutzer"
            onButtonClick={onAdd}
          />
        );

      case "backoffice":
        return (
          <EmptyState
            title="Noch kein Innendienst-Benutzer vorhanden"
            description="Erstellen Sie jetzt Ihren ersten Innendienst-Benutzer, um eingehende Aufträge und Vorgänge bearbeiten zu können."
            buttonLabel="Neuer Benutzer"
            onButtonClick={onAdd}
          />
        );

      case "b2bBackoffice":
        return (
          <EmptyState
            title="Noch kein Innendienst-Benutzer für Business Partner vorhanden"
            description="Erstellen Sie jetzt Ihren ersten Innendienst-Benutzer für Business Partner, damit diese eingehende Aufträge und Vorgänge bearbeiten zu können."
            buttonLabel="Neuer Benutzer"
            onButtonClick={onAdd}
          />
        );
    }
  }, [group]);

  return (
    <DataContainer
      loading={isLoading}
      searchPlaceholder="Suche ..."
      onSearchSubmit={onSearch}
      searchQuery={searchQuery}
      addButton="Neuer Benutzer"
      onAdd={onAdd}
      onReload={onReload}
    >
      <DataTablePaginatedAndSortable<User>
        data-testid={`usertable-${!group ? "all" : group}`}
        loading={isLoading}
        columns={columns}
        rows={users}
        page={page}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        totalCount={totalCount}
        keyExtractor={(data) => data.email}
        onSortChange={onSortChange}
        sortedBy={sortedBy}
        renderRow={(data, index) => {
          return (
            <>
              {group === "au" && <DataTableCell data-testid={"employeeNumber"}>{data.employeeNumber}</DataTableCell>}
              {group === "b2b" && <DataTableCell data-testid={"b2bNumber"}>{data.b2bNumber}</DataTableCell>}
              {group === "b2b" && data.b2bClient && (
                <DataTableCell data-testid={"clientNumber"}>
                  {data.b2bClient?.b2bName} ({data.b2bClient?.clientNumber})<br />
                  {data.b2bRole === "RESIDENCE" ? "Heimleitung" : "Wohnbereich"}
                </DataTableCell>
              )}
              {group === "b2b" && data.prescriber && <DataTableCell data-testid={"prescriber"}>{"Verordner"}</DataTableCell>}
              {group === "b2c" && <DataTableCell data-testid={"clientNumber"}>{data.b2cClient?.clientNumber}</DataTableCell>}
              {(group === "backoffice" || group === "b2bBackoffice") && (
                <DataTableCell data-testid={"employeeNumber"}>{data.employeeNumber}</DataTableCell>
              )}
              <DataTableCell data-testid={"lastname"}>{data.lastname}</DataTableCell>
              <DataTableCell data-testid={"firstname"}>{data.firstname}</DataTableCell>
              <DataTableCell data-testid={"email"}>{data.email}</DataTableCell>
              {group === undefined && (
                <DataTableCell>
                  {data.groups.map((x) => (
                    <Chip
                      size="small"
                      key={x}
                      label={((grp: string): string => {
                        switch (grp) {
                          case "employee":
                            return "AU";
                          default:
                            return grp.toUpperCase();
                        }
                      })(x)}
                    />
                  ))}
                </DataTableCell>
              )}
              {isNotB2BBackoffice && (
                <DataTableCell>
                  <Link data-testid={`link-edit-row-${index}`} to={`/users/edit/${data.id}`}>
                    <EditIcon color={"primary"} />
                  </Link>
                </DataTableCell>
              )}
            </>
          );
        }}
        renderEmpty={() => emptyState}
      />
    </DataContainer>
  );
}
