import BranchConverter from "features/branches/domain/converters/BranchConverter";
import ClassificationConverter from "features/classifications/domain/converters/ClassificationConverter";
import PoolCreateOrUpdateModel from "features/pools/data/PoolCreateOrUpdateModel";
import { PoolLeanResponseModel, PoolResponseModel } from "features/pools/data/PoolResponseModel";
import { CreateOrUpdatePoolFormValues } from "features/pools/presentation/components/CreateOrUpdatePoolForm";
import UserConverter from "features/users/domain/converter/UserConverter";
import { DefaultPool, Pool } from "../entities/PoolEntity";

export default class PoolConverter {
  public static fromResponseModelToEntity(responseModel: PoolResponseModel): Pool {
    const { _id, ...fields } = responseModel;
    return {
      id: _id,
      name: fields.name,
      classifications: fields.classifications.map((x) => ClassificationConverter.fromResponseModelToEntity(x)),
      branch: BranchConverter.fromResponseModelToEntity(fields.branch),
      employees: fields.employees ? fields.employees.map((x) => UserConverter.fromModelToEntity(x)) : undefined,
      fieldstaffEmployees: fields.fieldstaffEmployees
        ? fields.fieldstaffEmployees.map((x) => UserConverter.fromModelToEntity(x))
        : undefined,
      default: fields.default,
      canChangeClassification: fields.canChangeClassification,
      canChangeEmployee: fields.canChangeEmployee,
      isB2B: !!fields.isB2B,
    };
  }

  public static fromCreateOrUpdateFormToModel(formInput: CreateOrUpdatePoolFormValues): PoolCreateOrUpdateModel {
    return {
      name: formInput.name,
      branch: formInput.branch as string,
      classifications: formInput.classifications.map((classification) => classification.value),
      employees: formInput.employees.map((employee) => employee.value),
      fieldstaffEmployees: formInput.fieldstaffEmployees.map((employee) => employee.value),
      default: formInput.default,
      canChangeClassification: formInput.canChangeClassification,
      canChangeEmployee: formInput.canChangeEmployee,
    };
  }

  public static fromLeanResponseToDefaultEntity(leanResponse: PoolLeanResponseModel): DefaultPool {
    const { ...fields } = leanResponse;

    return {
      id: fields.id,
      name: fields.name,
      classifications: fields.classifications,
      branch: fields.branch,
      employees: fields.employees ? fields.employees : undefined,
      default: fields.default,
      canChangeClassification: fields.canChangeClassification,
      canChangeEmployee: fields.canChangeEmployee,
      isB2B: !!fields.isB2B,
    };
  }
}
