import React, { useMemo } from "react";
import clsx from "clsx";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Drawer, { DrawerProps } from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HomeIcon from "@material-ui/icons/Home";
import { Omit } from "@material-ui/types";

import { BACKEND_URL } from "core/data/backend";

const styles = (theme: Theme) =>
  createStyles({
    categoryHeader: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    categoryHeaderPrimary: {
      color: "#565655",
    },
    item: {
      paddingTop: 1,
      paddingBottom: 1,
    },
    itemCategory: {
      boxShadow: "0 -1px 0 #F1F1F1 inset",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    firebase: {
      fontSize: 24,
    },
    itemActiveItem: {
      color: "#4fc3f7",
    },
    itemPrimary: {
      fontSize: "inherit",
    },
    itemIcon: {
      minWidth: "auto",
      marginRight: theme.spacing(2),
    },
    divider: {
      marginTop: theme.spacing(2),
      backgroundColor: "#F1F1F1",
    },
  });

export interface NavigationProps extends Omit<DrawerProps, "classes">, WithStyles<typeof styles> {
  path: string;
  handleRouteChange: (route: string) => void;
  title: string;
  categories: any[];
}

function Navigation(props: NavigationProps) {
  const { classes, title, categories, handleRouteChange, path: currentPath, ...other } = props;

  const renderCategories = useMemo(() => {
    return categories.map(({ id, children }: any) => (
      <React.Fragment key={id}>
        <ListItem className={classes.categoryHeader}>
          <ListItemText
            classes={{
              primary: classes.categoryHeaderPrimary,
            }}
          >
            {id}
          </ListItemText>
        </ListItem>
        {children.map(({ id: childId, icon, path, external, hide }: any) => {
          let active = currentPath ? currentPath.startsWith(path) : false;
          return (
            !hide && (
              <ListItem
                data-testid={`menuitem-${childId}`}
                data-cy={`menuitem-${id}-${childId}`}
                key={childId}
                button
                onClick={() => {
                  if (!external) {
                    handleRouteChange(path);
                  } else {
                    window.open(path);
                  }
                }}
                className={clsx(classes.item, active && classes.itemActiveItem)}
              >
                <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                >
                  {childId}
                </ListItemText>
              </ListItem>
            )
          );
        })}
        <Divider className={classes.divider} />
      </React.Fragment>
    ));
  }, [categories, classes, currentPath, handleRouteChange]);

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
          <img src={`${BACKEND_URL}/assets/logo`} alt="Logo des Sanitätshauses" width={200} />
        </ListItem>
        <ListItem
          button
          onClick={() => handleRouteChange("/")}
          className={clsx(classes.item, classes.itemCategory, currentPath === "/" && classes.itemActiveItem)}
        >
          <ListItemIcon className={classes.itemIcon}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
            }}
          >
            Überblick
          </ListItemText>
        </ListItem>
        {renderCategories}
      </List>
    </Drawer>
  );
}

export default withStyles(styles)(Navigation);
