import AdapterEntitySizeEntryModel from "features/adapter/data/AdapterEntitySizeEntryModel";
import AdapterEntitySizeEntry from "../entities/AdapterEntitySizeEntry";

export default class AdapterEntitySizeEntryConverter {
  public static fromModelListToEntityList(models: AdapterEntitySizeEntryModel[]): AdapterEntitySizeEntry[] {
    return models.map((x) => AdapterEntitySizeEntryConverter.fromModelToEntity(x));
  }

  public static fromModelToEntity(model: AdapterEntitySizeEntryModel): AdapterEntitySizeEntry {
    console.log(model);
    return {
      id: model.id,
      entity: model.entity,
      size: model.size,
      date: new Date(model.date),
    };
  }
}
