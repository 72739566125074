import { RouteComponentProps } from "react-router-dom";
import queryString from "query-string";
import { useCallback, useMemo } from "react";
import { format, parse } from "date-fns";

export function usePageAndQueryFromRouteProps(routeProps: RouteComponentProps) {
  return useMemo(() => {
    const queryParams = queryString.parse(routeProps.location.search);
    const page = queryParams && queryParams.page && typeof queryParams.page === "string" ? parseInt(queryParams.page) : 1;
    const searchQuery = queryParams && typeof queryParams.searchQuery === "string" ? queryParams.searchQuery : undefined;
    return { page, searchQuery };
  }, [routeProps.location.search]);
}

export function usePageAndPageSizeAndQueryFromRouteProps(routeProps: RouteComponentProps) {
  return useMemo(() => {
    const queryParams = queryString.parse(routeProps.location.search);
    const page = queryParams && queryParams.page && typeof queryParams.page === "string" ? parseInt(queryParams.page) : 1;
    const pageSize =
      queryParams && queryParams.page_size && typeof queryParams.page_size === "string" ? parseInt(queryParams.page_size) : 50;
    const searchQuery = queryParams && typeof queryParams.searchQuery === "string" ? queryParams.searchQuery : undefined;
    return { page, pageSize, searchQuery };
  }, [routeProps.location.search]);
}

export function usePageAndPageSizeAndAnyQueryFromRouteProps(routeProps: RouteComponentProps) {
  return useMemo(() => {
    const queryParams = queryString.parse(routeProps.location.search);
    const page = queryParams && queryParams.page && typeof queryParams.page === "string" ? parseInt(queryParams.page) : 1;
    const pageSize =
      queryParams && queryParams.page_size && typeof queryParams.page_size === "string" ? parseInt(queryParams.page_size) : 50;
    const searchQueries: any = Object.fromEntries(Object.entries(queryParams).filter(([key]) => key !== "page" && key !== "page_size"));
    return { page, pageSize, searchQueries };
  }, [routeProps.location.search]);
}

export function renderFullName({ firstname, lastname }: { firstname: string | undefined; lastname: string | undefined }): string {
  if (firstname && lastname) {
    return `${firstname} ${lastname}`;
  }

  if (firstname && !lastname) {
    return firstname;
  }

  if (!firstname && lastname) {
    return lastname;
  }

  return "-";
}

export function useQueryFromRouteProps(routeProps: RouteComponentProps) {
  return useMemo(() => queryString.parse(routeProps.location.search) ?? {}, [routeProps]);
}

export interface PagedResponse<T> {
  data: T[];
  totalCount: number;
  page: number;
  pageSize: number;
}

export const mergeDateAndTime = (date: Date, time: Date): Date => {
  const dateFormat = "yyyy-MM-dd XX";
  const timeFormat = "HH:mm:ss";
  return parse(format(date, dateFormat) + " " + format(time, timeFormat), dateFormat + " " + timeFormat, new Date());
};
