import { PagedResponse } from "core/utils";
import Product from "../entities/Product";
import ProductsApi from "../../data/ProductsApi";
import { ImportProductsFormValues } from "features/products/presentation/components/ImportProductsForm";

class ProductService {
  constructor(private productApi: ProductsApi) {}

  public async get(
    searchQuery: string | undefined,
    page: number,
    pageSize: number,
    sortedBy: { field: string; order: "asc" | "desc" }
  ): Promise<PagedResponse<Product>> {
    return this.productApi.get(searchQuery, page, pageSize, sortedBy);
  }

  public async import(formValues: ImportProductsFormValues): Promise<void> {
    return this.productApi.import(formValues);
  }
}

export default ProductService;
