import { AxiosInstance } from "axios";
import handleAxiosError from "axios-handle-error";
import { convertAxiosErrorToValidationError, ElementNotFoundError, NetworkError } from "core/data/errors";
import { BranchRequestModel } from "./BranchRequestModel";
import { BranchResponseModel } from "./BranchResponseModel";

export default class BranchApi {
  constructor(private authClient: AxiosInstance) {}

  public async getCityByZip(zip: string): Promise<string[]> {
    return await this.authClient
      .get(`/zip/${zip}`)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          NO_RESPONSE: () => new NetworkError(),
          404: () => new ElementNotFoundError(),
        });
      });
  }

  public async get(searchQuery?: string | undefined, isB2B?: boolean, b2bClientId?: string): Promise<BranchResponseModel[]> {
    let url: string = "/branches";
    if (isB2B) {
      url += "?type=b2b";
    } else {
      url += "?type=internal";
    }
    return this.authClient
      .get(url, {
        params: { code__re: searchQuery, b2bClient__eq: b2bClientId },
      })
      .then(({ data }) => data);
  }

  public async getById(id: string): Promise<BranchResponseModel> {
    return this.authClient
      .get(`/branches/${id}`)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          NO_RESPONSE: () => new NetworkError(),
          404: () => new ElementNotFoundError(),
        });
      });
  }

  public async create(values: BranchRequestModel) {
    return this.authClient
      .post("/branches", values)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          NO_RESPONSE: () => new NetworkError(),
        });
      });
  }

  public async update(id: string, values: BranchRequestModel) {
    return this.authClient
      .put(`/branches/${id}`, values)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          NO_RESPONSE: () => new NetworkError(),
          404: () => new ElementNotFoundError(),
        });
      });
  }

  public async getMine() {
    return this.authClient.get<BranchResponseModel>("/branches/mine").then(({ data }) => data);
  }

  public async getDefault(forB2BClient?: string): Promise<BranchResponseModel> {
    return this.authClient.get("/branches/getDefault" + (forB2BClient ? "?forB2BClient=" + forB2BClient : "")).then((x) => x.data);
  }

  public async getB2BBackofficeUserBranchOptions(data: { id: string; type?: string }) {
    return this.authClient
      .get(`/branches/getB2BBackofficeUserBranchOptions`, { params: { id: data.id, type: data.type } })
      .then((x) => x.data);
  }
}
