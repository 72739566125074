import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RouteComponentProps, useParams } from "react-router-dom";
import { useQuery, useQueryCache } from "react-query";
import useService from "core/di/useService";
import UIRoute from "core/presentation/components/UIRoute";
import CreateOrUpdatePoolForm, { CreateOrUpdatePoolFormValues } from "../components/CreateOrUpdatePoolForm";
import { convertAxiosErrorToValidationError, ValidationMessageError } from "core/data/errors";
import PoolService from "features/pools/domain/services/PoolService";
import ClassificationService from "features/classifications/domain/services/ClassificationService";
import UserService from "features/users/domain/services/UserService";
import { B2BLinkOptions } from "features/clients/domain/entities/Client";
import B2BService from "features/b2b/domain/services/B2BService";

export interface UpdatePoolRouteProps extends RouteComponentProps {}

export default function UpdatePoolRoute(props: UpdatePoolRouteProps) {
  const { id } = useParams<{ id: string }>();
  const poolService = useService(PoolService);
  const classificationService = useService(ClassificationService);
  const userService = useService(UserService);
  const b2bService = useService(B2BService);

  const queryCache = useQueryCache();

  const [error, setError] = useState<string | null>(null);
  const [selectedBranch, setSelectedBranch] = useState<string>("");

  const { data: pool } = useQuery(["/pools/" + id], () => poolService.getById(id), { enabled: id });

  const { data: b2bPartnerOptions } = useQuery("b2bPartner", () => b2bService.getB2BPartners(), { enabled: pool?.branch?.isB2B });

  const { data: defaultPool } = useQuery(
    ["/defaultPoolForBranch/", selectedBranch],
    () => poolService.getDefaultForBranch(selectedBranch),
    {
      enabled: selectedBranch,
      onError: () => {
        queryCache.removeQueries("/defaultPoolForBranch/");
      },
      retry: false,
    }
  );
  const { data: employees } = useQuery(
    ["allBackofficeEmployees"],
    () =>
      userService.get(
        undefined,
        pool?.branch.isB2B ? "b2bBackoffice" : "backoffice",
        0,
        0,
        { field: "lastname", order: "asc" },
        pool && pool.branch.id
      ),
    { enabled: pool !== undefined }
  );

  const { data: fieldstaffEmployees } = useQuery(
    ["allAvailableFieldstaffEmployeesAndUsed", id],
    () => poolService.getAvailableFieldstaffEmployeesAndUsedForPool(id),
    { enabled: selectedBranch && id }
  );

  useEffect(() => {
    if (pool) {
      setSelectedBranch(pool.branch.id);
    }
  }, [pool]);

  const { data: classifications } = useQuery(
    ["allAvailableAndUsedClassifications", selectedBranch, id],
    () => classificationService.getAvailableNotInBranchAndUsed(id, selectedBranch),
    { enabled: !pool?.branch.isB2B && selectedBranch }
  );

  const onSubmit = useCallback(
    (values: CreateOrUpdatePoolFormValues) => {
      return poolService
        .update(id, values)
        .then(() => {
          return setError(null);
        })
        .then(() => {
          queryCache.invalidateQueries(["/pools?branch=" + values.branch]);
          queryCache.invalidateQueries(["/pools/default-for-branch/" + values.branch]);
          return queryCache.invalidateQueries(["/pools/" + id]);
        })
        .then(() => {
          return props.history.goBack();
        })
        .catch((e) => {
          const err = convertAxiosErrorToValidationError(e);
          if (err instanceof ValidationMessageError) {
            switch (err.code) {
              case "NOTFOUND_BRANCH":
                setError(`Angegebene Niederlassung existiert nicht (${err.message})`);
                return;
              case "NOTFOUND_CLASSIFICATION":
                setError(`Angegebene Klassifizierung existiert nicht (${err.message})`);
                return;
              case "NOTFOUND_USER":
                setError(`Angegebener Mitarbeiter existiert nicht (${err.message})`);
                return;
              case "DUPLICATE_NAME":
                setError(`Ein Pool mit der Bezeichnung "${values.name}" existiert bereits`);
                return;
              default:
                setError(`Ein unbekannter Fehler ist aufgetreten (${err.message})`);
                return;
            }
          } else {
            setError(`Ein unbekannter Fehler ist aufgetreten (${err.message})`);
          }
        });
    },
    [id, props.history, queryCache, poolService]
  );

  return (
    <UIRoute {...props} title="Pool bearbeiten">
      <CreateOrUpdatePoolForm
        b2bPartner={b2bPartnerOptions || []}
        pool={pool}
        branches={(pool?.branch && [pool.branch]) || []}
        setSelectedBranch={setSelectedBranch}
        classifications={classifications || []}
        employees={employees?.data || []}
        fieldstaffEmployees={fieldstaffEmployees || []}
        defaultPool={defaultPool}
        error={error}
        onSubmit={onSubmit}
        isB2B={pool?.branch.isB2B || false}
      />
    </UIRoute>
  );
}
