import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { COPYRIGHT_NAME, APP_VERSION } from "core/config";

export default function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="#">
        {COPYRIGHT_NAME}
      </Link>{" "}
      {new Date().getFullYear()}
      {"- v"}
      {APP_VERSION}
    </Typography>
  );
}
