import { useService } from "core/di";
import UIRoute from "core/presentation/components/UIRoute";
import { addDays, endOfDay } from "date-fns";
import startOfDay from "date-fns/startOfDay";
import { CalendarAssignment } from "features/assignments/domain/entities/Assignment";
import AssignmentService, { TranslatedAssignmentTypeMap } from "features/assignments/domain/services/AssignmentService";
import Client from "features/clients/domain/entities/Client";
import { renderClientLabel, renderUserName } from "features/clients/presentation/utils";
import React, { useMemo } from "react";
import { Event } from "react-big-calendar";
import { useQuery } from "react-query";
import { RouteComponentProps } from "react-router";
import ComboBox from "template/presentation/components/form/ComboBox";
import AssignmentsCalendar from "../components/Calendar";
import { Field, Formik } from "formik";
import { useState } from "react";
import UserService from "features/users/domain/services/UserService";
import { makeStyles, Paper } from "@material-ui/core";

export interface CalendarRouteProps extends RouteComponentProps {}

const now = new Date();

const toCalendarEvent = (assignment: CalendarAssignment) => {
  const renderedClient = renderClientLabel(assignment.client as Client);
  const renderedAddress = `${assignment.address.street}, ${assignment.address.city} ${assignment.address.zip}`;
  const renderedType = TranslatedAssignmentTypeMap[assignment.type];
  let title = `${renderedClient}, ${renderedAddress}, Typ: ${renderedType}, Betreff: ${assignment.appointment.subject}`;

  return {
    id: assignment.id,
    title,
    start: assignment.appointment.timeFrom,
    end: assignment.appointment.timeUntil,
    clientNumber: assignment.client.clientNumber,
    type: assignment.type,
  };
};

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "1200px",
    margin: "auto",
    padding: 10,
  },
  calendar: {
    height: "65vh",
  },
  field: {
    width: "100%",
    marginBottom: 10,
  },
}));

const CalendarRoute = (props: CalendarRouteProps) => {
  const classes = useStyles();

  const [minDate, maxDate] = useMemo(() => {
    const minDate = startOfDay(now);
    return [minDate, endOfDay(addDays(minDate, 14))];
  }, []);
  const [employeeId, setEmployeeId] = useState<string>("");

  const userService = useService(UserService);
  const { data: employeeOptions } = useQuery(["CalendarEmployeeOptions"], () =>
    userService.getEmployees({ groups: ["employee"] }).then(({ data }) =>
      data.map((emp) => ({
        id: emp.id,
        value: `${renderUserName(emp)} (${emp.employeeNumber})`,
        label: `${renderUserName(emp)} (${emp.employeeNumber})`,
      }))
    )
  );

  const assignmentService = useService(AssignmentService);
  const { data: assignments } = useQuery(
    ["CalendarAssignments", { employeeId }],
    () => assignmentService.getCalendarAssignmentsForEmployeeInDatetimeRange(employeeId, { start: minDate, end: maxDate }),
    { enabled: employeeId && employeeId !== "" }
  );

  const calendarEvents: Event[] = useMemo(() => {
    if (assignments) {
      return assignments.map((assignment) => toCalendarEvent(assignment));
    }
    return [];
  }, [assignments]);

  return (
    <UIRoute {...props} title="Auftragskalender">
      <Paper className={classes.container}>
        <Formik initialValues={{ employee: "" }} onSubmit={({ employee }) => setEmployeeId(employee)}>
          <Field
            className={classes.field}
            data-testid="field-user"
            component={ComboBox}
            options={employeeOptions || []}
            getTestIdForOption={(option: any) => option.value}
            variant="outlined"
            legend="Mitarbeiter wählen*"
            name="employeeNumber"
            onSelectOption={(employeeValue: string) => {
              const employee = employeeOptions?.find((emp) => emp.value === employeeValue);
              if (employee) {
                setEmployeeId(employee.id);
              }
            }}
          />
        </Formik>

        <AssignmentsCalendar className={classes.calendar} events={calendarEvents} minDate={minDate} maxDate={maxDate} />
      </Paper>
    </UIRoute>
  );
};

export default CalendarRoute;
