import React, { useMemo } from "react";
import { Field, Formik } from "formik";
import PoolAssignment from "features/pools/domain/entities/PoolAssignment";
import SelectField from "template/presentation/components/form/SelectField";
import { TransferToB2BPartnerModel } from "features/pools/domain/entities/PoolEntity";
import { Button, makeStyles } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import SelectOption from "core/interfaces/SelectOption";

interface Props {
  assignment: PoolAssignment;
  b2bPartnerOptions: SelectOption[] | undefined;
  onAssignB2BPartner: (values: TransferToB2BPartnerModel) => Promise<void>;
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  textField: {
    marginTop: theme.spacing(1),
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

export const TransferToB2BPartnerSection: React.FC<Props> = ({ assignment, b2bPartnerOptions, onAssignB2BPartner }) => {
  const classes = useStyles();

  const initValues = useMemo(() => {
    return { b2bPartnerId: "", assignmentId: assignment.assignmentId, reason: "" };
  }, [assignment]);

  return (
    <div>
      <Formik initialValues={initValues} onSubmit={onAssignB2BPartner}>
        {({ submitForm, values }) => {
          return b2bPartnerOptions ? (
            <div className={classes.container}>
              <Field
                data-testid="field-transferB2b"
                name="b2bPartnerId"
                legend="B2B-Partner auswählen"
                component={SelectField}
                options={b2bPartnerOptions}
                getTestIdForOption={(option: any) => option.number}
              />
              <Field
                data-testid="field-transferB2bReason"
                component={TextField}
                multiline
                rows={4}
                variant={"outlined"}
                name="reason"
                label={"Begründung"}
                className={classes.textField}
              />
              <Button className={classes.button} color="primary" variant="contained" data-testid="button-transferB2b" onClick={submitForm}>
                An B2B-Partner übergeben
              </Button>
            </div>
          ) : (
            <div>Keine B2B-Partner verfügbar.</div>
          );
        }}
      </Formik>
    </div>
  );
};
