import React, { useCallback, useMemo } from "react";
import { createStyles, AppBar, WithStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Adapter from "features/adapter/domain/entities/Adapter";
import CircularProgress from "@material-ui/core/CircularProgress";
import format from "date-fns/format";

const styles = createStyles({
  secondaryBar: {
    zIndex: 0,
    paddingBottom: 5,
  },
  detailBox: {
    marginLeft: 25,
  },
});

export interface AdapterDetailSubHeaderProps extends WithStyles<typeof styles> {
  adapter?: Adapter;
}

function AdapterDetailSubHeader(props: AdapterDetailSubHeaderProps) {
  const { classes, adapter } = props;

  const renderAdapter = useCallback(
    (adapter: Adapter) => {
      return (
        <div className={classes.detailBox}>
          <span>{adapter.type}</span>, <span>{adapter.status}</span> seit <span>{format(adapter.statusChangedAt, "dd.MM.yyyy HH:mm")}</span>
        </div>
      );
    },
    [classes.detailBox]
  );

  const renderLoading = useMemo(() => {
    return <CircularProgress />;
  }, []);

  return (
    <AppBar component="div" className={classes.secondaryBar} color="primary" position="static" elevation={0}>
      {adapter ? renderAdapter(adapter) : renderLoading}
    </AppBar>
  );
}

export default withStyles(styles)(AdapterDetailSubHeader);
