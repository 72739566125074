import React from "react";
import AssignmentIcon from "@material-ui/icons/Assignment";
import TimerIcon from "@material-ui/icons/Timer";
import PeopleIcon from "@material-ui/icons/People";
import SettingsIcon from "@material-ui/icons/Settings";
import DnsRoundedIcon from "@material-ui/icons/DnsRounded";
import CalendarIcon from "@material-ui/icons/Event";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import B2BSpace from "features/clients/domain/services/B2BSpace";
import BallotIcon from "@material-ui/icons/Ballot";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import AccountBoxIcon from "@material-ui/icons/AccountBox";

export default function backOfficeMenu(shopEnabled?: boolean, shopUrl?: string) {
  return [
    {
      id: "Allgemein",
      children: [
        { id: "Mitteilungen", icon: <DnsRoundedIcon />, path: "/messages/" },
        { id: "Shop", icon: <ShoppingCartIcon />, path: `${shopUrl}/admin`, external: true, hide: !shopEnabled },
        { id: "Formular Center", icon: <BallotIcon />, path: "/formular-center/" },
      ],
    },
    {
      id: "B2C",
      children: [
        { id: "Kunden", icon: <PeopleIcon />, path: "/clients/b2c" },
        { id: "App-Konten", icon: <AccountBoxIcon />, path: "/users/b2c" },
      ],
    },
    {
      id: "B2B",
      children: [
        { id: "Vermittler", icon: <BusinessCenterIcon />, path: `/clients/${B2BSpace.INTERMEDIARY}` },
        { id: "Verordner", icon: <LocalHospitalIcon />, path: `/clients/${B2BSpace.PRESCRIBER}` },
      ],
    },
    {
      id: "Pool-Auftragsübersicht",
      children: [{ id: "Vorgänge", icon: <BallotIcon />, path: "/pools/assignments/active" }],
    },
    {
      id: "Außendienst",
      children: [
        { id: "Aufträge", icon: <LocalShippingIcon />, path: "/assignments" },
        { id: "Kalender", icon: <CalendarIcon />, path: "/assignments-calendar" },
      ],
    },
    {
      id: "Innendienst",
      children: [
        { id: "Vorgänge", icon: <BallotIcon />, path: "/cases" },
        { id: "Neuen Endkunden in Branchensoftware anlegen", icon: <PersonAddIcon />, path: "/clients/createOnIndustrySoftware" },
      ],
    },
  ];
}
