import React, { useCallback, useEffect, useState } from "react";
import { queryCache, useQuery } from "react-query";
import useService from "core/di/useService";
import { RouteComponentProps } from "react-router-dom";
import UIRoute from "core/presentation/components/UIRoute";
import { ValidationMessageError } from "core/data/errors";
import CaseService from "features/cases/domain/services/CaseService";
import useUrlQueryParams from "core/presentation/hooks/useUrlQueryParams";
import ClassificationService from "features/classifications/domain/services/ClassificationService";
import { formatISO, parse } from "date-fns";
import CreateOrUpdateCaseForm, { CreateOrUpdateCaseFormValues } from "../components/CreateOrUpdateCaseForm";
import BranchService from "features/branches/domain/services/BranchService";
import UserService from "features/users/domain/services/UserService";
import { AssignmentType, TranslatedAssignmentTypeMap } from "features/assignments/domain/services/AssignmentService";
import { renderClientLabel, renderUserName } from "features/clients/presentation/utils";
import ClientService from "features/clients/domain/services/ClientService";

export interface CreateCaseRouteProps extends RouteComponentProps {}

const CreateCaseRoute = (props: CreateCaseRouteProps) => {
  const urlQueryParams = useUrlQueryParams();
  const clientNumber = urlQueryParams.get("clientNumber");

  const caseService = useService(CaseService);
  const branchService = useService(BranchService);
  const classificationService = useService(ClassificationService);
  const userService = useService(UserService);
  const clientService = useService(ClientService);

  const [selectedBranch, setSelectedBranch] = useState<string>("");
  const [clientQuery, setClientQuery] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const { data: employeeOptions } = useQuery(
    ["allAvailableEmployeesForCase", selectedBranch],
    () =>
      userService.getEmployees({ groups: ["employee", "backoffice"], inBranch: selectedBranch }).then((users) =>
        users.data.map((user) => ({
          value: user.employeeNumber || "",
          label: `${renderUserName(user)} (${user.employeeNumber})`,
          number: user.employeeNumber || "",
        }))
      ),
    { enabled: selectedBranch }
  );

  const { data: myBranch } = useQuery(["MyBranch"], () => branchService.getMine());

  const onSubmit = useCallback(
    (values: CreateOrUpdateCaseFormValues) => {
      return caseService
        .create({
          ...values,
          appointmentFrom: values.appointmentFrom && formatISO(parse(values.appointmentFrom, "yyyy-MM-dd'T'HH:mm", new Date())),
          appointmentUntil: values.appointmentUntil && formatISO(parse(values.appointmentUntil, "yyyy-MM-dd'T'HH:mm", new Date())),
          employeeNumber: employeeOptions?.find((employee) => values.employeeNumber === employee.label)?.value || "",
        })
        .then(async () => {
          await queryCache.invalidateQueries("caseList");
          props.history.push("/cases");
        })
        .catch((e) => {
          if (e instanceof ValidationMessageError) {
            switch (e.code) {
              case "EMPLOYEE_NOT_FOUND":
                setError("Unbekannten Mitarbeiter angegeben");
                return;
              case "CLIENT_NOT_FOUND":
                setError("Unbekannten Kunden angegeben");
                return;
              case "CASE_NOT_FOUND":
                setError("Unbekannten Vorgang angegeben");
                return;
              case "CASE_CREATION_FAILED":
                setError("Vorgang konnte auf Branchensoftware nicht erstellt werden.");
                return;
              default:
                setError(`Ein unbekannter Fehler ist aufgetreten. Vorgang konnte nicht erstellt werden Code: ${e.code}`);
                return;
            }
          } else {
            setError("Ein unbekannter Fehler ist aufgetreten");
          }
        });
    },
    [caseService, props.history, employeeOptions]
  );

  const { data: branchOptions } = useQuery("internalBranchOptions", () =>
    branchService
      .get()
      .then((branches) => branches.map((branch) => ({ value: branch.id, label: `${branch.name} (${branch.code})`, number: branch.code })))
  );

  const { data: classificationOptions } = useQuery(["allClassifications"], () =>
    classificationService.get().then((classifications) => {
      return classifications.map((classification) => ({
        value: classification.number,
        label: `${classification.name} (${classification.number} - ${
          TranslatedAssignmentTypeMap[classification.assignmentMapping as AssignmentType] || "Unbekannte Klassifizierung"
        })`,
      }));
    })
  );

  const { data: clientOptions, isLoading: isLoadingClientOptions } = useQuery(
    ["ClientSearch", { clientQuery }],
    () => {
      return clientService.get({ fields: { clientNumber: clientQuery }, group: "", page: 1, pageSize: 10 }).then((r) => {
        return r.data.map((client) => {
          return {
            label: renderClientLabel(client),
            value: client.clientNumber,
            number: client.clientNumber,
          };
        });
      });
    },
    { enabled: clientQuery && clientQuery.length > 1 }
  );

  useEffect(() => {
    // preselect only if there is just one option or the employee is assigned to a specific branch
    if (branchOptions) {
      if (branchOptions.length === 1) {
        setSelectedBranch(branchOptions[0].value);
      } else if (myBranch) {
        setSelectedBranch(myBranch.id);
      }
    }
  }, [branchOptions, myBranch]);

  return (
    <UIRoute {...props} title={"Neuen Vorgang erstellen"}>
      <CreateOrUpdateCaseForm
        error={error}
        onSubmit={onSubmit}
        clientNumber={clientNumber}
        classificationOptions={classificationOptions || []}
        branchOptions={branchOptions || []}
        employeeOptions={employeeOptions || []}
        preSelectedBranch={selectedBranch}
        setSelectedBranch={setSelectedBranch}
        clientSelection={{
          clientOptions: clientOptions || [],
          clientOptionsLoading: isLoadingClientOptions,
          setClientQuery: setClientQuery,
        }}
        myBranch={myBranch}
        enableReinitialize={false}
      />
    </UIRoute>
  );
};

export default CreateCaseRoute;
