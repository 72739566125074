import React, { useState, useCallback } from "react";
import useService from "core/di/useService";
import { RouteComponentProps } from "react-router-dom";
import UIRoute from "core/presentation/components/UIRoute";
import SignatureformsService from "../../domain/services/SignatureformsService";
import { SignatureAssignmentRequest } from "features/signatureforms/domain/entities/Signatureform";
import CreateSignatureAssignmentForm from "../components/CreateSignatureAssignmentForm";
import { ElementNotFoundError, NetworkError, UnauthorizedError } from "core/data/errors";

export interface CreateSignatureAssignmentRouteProps extends RouteComponentProps {}

const CreateSignatureAssignmentRoute = (props: CreateSignatureAssignmentRouteProps) => {
  const signatureformsService = useService(SignatureformsService);
  const [error, setError] = useState<string | null>(null);

  const onSubmit = useCallback(
    (values: SignatureAssignmentRequest) => {
      return signatureformsService
        .createAssignment(values)
        .then(() => {
          props.history.goBack();
        })
        .catch((e: any) => {
          if (e instanceof UnauthorizedError) {
            setError("Sie haben nicht notwendige Berechtigung diesen Auftrag zu starten");
          } else if (e instanceof NetworkError) {
            setError("Netzwerkfehler");
          } else if (e instanceof ElementNotFoundError) {
            setError("Formular oder Niederlassung konnte nicht gefunden werden");
          } else {
            setError("Ein unbekannter Fehler ist aufgetreten");
          }
        });
    },
    [signatureformsService, props.history]
  );

  return (
    <UIRoute {...props} title={"Unterschriftenauftrag erstellen"}>
      <div data-testid="signatureforms-assignment-create">
        <CreateSignatureAssignmentForm error={error} onSubmit={onSubmit} />
      </div>
    </UIRoute>
  );
};

export default CreateSignatureAssignmentRoute;
