import useService from "core/di/useService";
import UIRoute from "core/presentation/components/UIRoute";
import AssignmentService from "features/assignments/domain/services/AssignmentService";
import CaseService from "features/cases/domain/services/CaseService";
import ClientService from "features/clients/domain/services/ClientService";
import FormularService from "features/formular-center/domain/services/FormularService";
import UserService from "features/users/domain/services/UserService";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { queryCache, useQuery } from "react-query";
import { RouteComponentProps, useParams } from "react-router-dom";
import CreateOrUpdateFormularForm, { CreateOrUpdateFormularFormValues } from "../components/CreateOrUpdateFormularForm";

export interface EditFormularRouteProps extends RouteComponentProps {}

const EditFormularRoute = (props: EditFormularRouteProps) => {
  const { id } = useParams<{ id: string }>();
  const formularService = useService(FormularService);

  const { data: formular } = useQuery(["formular", id], () => {
    return formularService.getById(id);
  });

  const caseService = useService(CaseService);
  const assignmentService = useService(AssignmentService);
  const clientService = useService(ClientService);

  const [caseNumberQuery, setCaseNumberQuery] = useState<string | undefined>(undefined);
  const [caseOptionSelected, setCaseOptionSelected] = useState<string | undefined>(undefined);
  const [assignmentNumberQuery, setAssignmentNumberQuery] = useState<string | undefined>(undefined);
  const [assignmentOptionSelected, setAssignmentOptionSelected] = useState<string | undefined>(undefined);
  const [clientNumberQuery, setClientNumberQuery] = useState<string | undefined>(undefined);
  const [clientOptionSelected, setClientOptionSelected] = useState<string>("");
  const [employeeNumberQuery, setEmployeeNumberQuery] = useState<string | undefined>("");
  const [employeeOptionSelected, setEmployeeOptionSelected] = useState<string | undefined>("");
  const [renderState, setRenderState] = useState<boolean>(false);

  const { data: caseOptions, isLoading: casesLoading } = useQuery(
    ["cases", { caseNumberQuery, pageSize: 10 }],
    () => caseService.getTypeAheadOptions(caseNumberQuery || ""), //.then(({ data }) => data),
    { enabled: caseNumberQuery && caseNumberQuery.length >= 1 }
  );

  const { data: assignmentOptions, isLoading: assignmentsLoading } = useQuery(
    ["assignments", { assignmentNumberQuery, pageSize: 10 }, caseOptionSelected],
    () =>
      assignmentService
        .getAssignmentList({ assignmentNumber: assignmentNumberQuery || "", relatedCaseNumber: caseOptionSelected }, 1, 10, {
          field: "sortedBy",
          order: "asc",
        })
        .then(({ data }) => data),
    { enabled: assignmentNumberQuery && assignmentNumberQuery.length >= 1 }
  );

  const { data: clientOptions, isLoading: clientsLoading } = useQuery(
    ["clients", { clientNumberQuery }],
    () => clientService.getClientsByNumber(clientNumberQuery as string, "", true),
    { enabled: clientNumberQuery && clientNumberQuery.length >= 1 }
  );

  const queriedCaseOptions = useMemo(() => {
    if (caseOptions) {
      return caseOptions.map((kase) => ({
        value: kase.caseNumber,
        label: kase.caseNumber,
      }));
    }
    return [];
  }, [caseOptions]);

  const queriedAssignmentOptions = useMemo(() => {
    if (assignmentOptions) {
      return assignmentOptions.map((assignment) => ({
        value: assignment._id,
        label: assignment.assignmentNumber,
      }));
    }
    return [];
  }, [assignmentOptions]);

  const queriedClientOptions = useMemo(() => {
    if (clientOptions) {
      return clientOptions.map((client) => ({
        value: client.clientNumber,
        label: `${client.firstname ?? ""} ${client.lastname ?? ""} (${client.clientNumber})`,
      }));
    }
    return [];
  }, [clientOptions]);

  const onSubmit = useCallback(
    (values: CreateOrUpdateFormularFormValues, id?: string) => {
      formularService.update(id!, values).then(() => {
        queryCache.invalidateQueries(["formular", id]);
        props.history.goBack();
      });
    },
    [formularService, props.history]
  );

  useEffect(() => {
    if (formular) {
      setCaseNumberQuery(formular.relatedCase?.caseNumber);
      setAssignmentNumberQuery(formular.relatedAssignment?.assignmentNumber);
      setClientNumberQuery(formular.client.clientNumber);
      setEmployeeNumberQuery(formular.employee.employeeNumber);
      queryCache.invalidateQueries(["cases", "assignments", "clients", "employees"]);
      setCaseOptionSelected(formular.relatedCase?.caseNumber);
      setAssignmentOptionSelected(formular.relatedAssignment?.id);
      setClientOptionSelected(formular.client.clientNumber);
      setEmployeeOptionSelected(formular.employee.employeeNumber);
    }
  }, [formular]);

  useEffect(() => {
    if (formular) {
      if (
        (formular.relatedCase ? queriedCaseOptions.length > 0 : true) &&
        (formular.relatedAssignment ? queriedAssignmentOptions.length > 0 : true) &&
        (formular.client ? queriedClientOptions.length > 0 : true)
      ) {
        setRenderState(true);
      }
    }
  }, [
    formular,
    formular?.client,
    formular?.employee,
    formular?.relatedAssignment,
    formular?.relatedCase,
    queriedAssignmentOptions.length,
    queriedCaseOptions.length,
    queriedClientOptions.length,
  ]);

  return (
    <UIRoute {...props} title={"Formular bearbeiten"}>
      {formular && renderState ? (
        <CreateOrUpdateFormularForm
          onSubmit={onSubmit}
          initialFormularData={formular}
          formularId={id}
          caseQuery={{
            caseOptions: queriedCaseOptions || [],
            caseOptionsLoading: casesLoading,
            setCaseNumberQuery: setCaseNumberQuery,
            setCaseOptionSelected: setCaseOptionSelected,
          }}
          assignmentQuery={{
            assignmentOptions: queriedAssignmentOptions || [],
            assignmentOptionsLoading: assignmentsLoading,
            setAssignmentNumberQuery: setAssignmentNumberQuery,
            setAssignmentOptionSelected: setAssignmentOptionSelected,
          }}
          clientQuery={{
            clientOptions: queriedClientOptions || [],
            clientOptionsLoading: clientsLoading,
            setClientNumberQuery: setClientNumberQuery,
            setClientOptionSelected: setClientOptionSelected,
          }}
        />
      ) : (
        <div />
      )}
    </UIRoute>
  );
};

export default EditFormularRoute;
