import React from "react";
import SubTabHeader from "template/presentation/components/nav/SubTabHeader";
import B2BSpace from "../../domain/services/B2BSpace";

interface Props {
  path: string;
  onTabChange: (path: string) => void;
}

const StandingDataSubHeader: React.FC<Props> = ({ path, onTabChange }) => (
  <SubTabHeader
    currentPath={path}
    onTabChange={onTabChange}
    tabs={[
      { value: "/clients/b2c", label: "Kunden" },
      { value: `/clients/${B2BSpace.INTERMEDIARY}`, label: "Vermittler" },
      { value: `/clients/${B2BSpace.PRESCRIBER}`, label: "Verordner" },
    ]}
  />
);

export default StandingDataSubHeader;
