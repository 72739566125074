import { useQuery } from "react-query";
import { useService } from "core/di";
import LoginService from "features/login/domain/services/LoginService";

export function useCurrentUser() {
  const loginService = useService(LoginService);
  const queryResult = useQuery("aboutMe", () => loginService.aboutMe());
  return queryResult;
}

export function useCurrentUserInGroup(group: string): [isRole: boolean, isNotRole: boolean, isRoleLoaded: boolean] {
  const user = useCurrentUser();
  const hasRole = !!user?.data?.groups?.includes(group);
  const isRoleLoaded = user.isFetched;
  return [hasRole && isRoleLoaded, !hasRole && isRoleLoaded, isRoleLoaded];
}
