import React, { useState } from "react";
import { RouteComponentProps, useParams } from "react-router-dom";
import UIRoute from "core/presentation/components/UIRoute";
import EditAreaForm, { DeleteClientData } from "../components/EditAreaForm";
import UpdateAreaRequestModel from "features/b2b/domain/components/UpdateAreaRequestModel";
import { useService } from "core/di";
import B2BService from "features/b2b/domain/services/B2BService";
import { BadRequestError, UnauthorizedError } from "core/data/errors";
import { useQuery, useQueryCache } from "react-query";

export interface ManageAreaRouteProps extends RouteComponentProps {}

const EditAreaRoute = (props: ManageAreaRouteProps) => {
  const { clientNumber: b2bClientNumber } = useParams<{ clientNumber: string }>();
  const { areaNumber } = useParams<{ areaNumber: string }>();
  const b2bService = useService(B2BService);
  const [error, setError] = useState<string | null>(null);
  const queryCache = useQueryCache();

  const { data: areaData, isLoading: areaDataLoading, refetch } = useQuery(["editAreaRoute", b2bClientNumber], () =>
    b2bService.getAreasByB2BClientAndAreaNumber(b2bClientNumber, areaNumber)
  );

  const onUpdateArea = (data: UpdateAreaRequestModel) => {
    b2bService
      .editArea(data)
      .then(() => setError(null))
      .catch((e) => {
        if (e instanceof BadRequestError || e instanceof UnauthorizedError) {
          setError(e.message);
        } else {
          setError("Ein unbekannter Fehler ist aufgetreten");
        }
      });
  };

  const onDeleteClientInArea = (data: DeleteClientData) => {
    b2bService
      .deleteClientInArea(areaNumber, data.clientNumber, b2bClientNumber)
      .then(() => setError(null))
      .then(() => refetch())
      .then(() => {
        return queryCache.invalidateQueries(["client", data.id]);
      })
      .catch((e) => {
        if (e instanceof BadRequestError || e instanceof UnauthorizedError) {
          setError(e.message);
        } else {
          setError("Ein unbekannter Fehler ist aufgetreten");
        }
      });
  };

  const onDeleteUserInArea = (data: DeleteClientData) => {
    b2bService
      .deleteUserInArea(areaNumber, data.clientNumber, b2bClientNumber)
      .then(() => setError(null))
      .then(() => refetch())
      .then(async () => {
        return queryCache.invalidateQueries(["user", data.id]);
      })
      .catch((e) => {
        if (e instanceof BadRequestError || e instanceof UnauthorizedError) {
          setError(e.message);
        } else {
          setError("Ein unbekannter Fehler ist aufgetreten");
        }
      });
  };

  return (
    <UIRoute {...props} title="Wohnbereich Bearbeiten">
      <EditAreaForm
        areaData={areaData}
        onGoBack={() => {
          props.history.goBack();
        }}
        onUpdateArea={onUpdateArea}
        error={error}
        onLoading={areaDataLoading}
        onDeleteClientInArea={onDeleteClientInArea}
        onDeleteUserInArea={onDeleteUserInArea}
      />
    </UIRoute>
  );
};

export default EditAreaRoute;
