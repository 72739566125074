import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Branch } from "features/branches/domain/entities/BranchEntity";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import { Chip, FormControl, InputLabel } from "@material-ui/core";
import { useCurrentUserInGroup } from "features/login/presentation/hooks/useUser";
import { B2BPartner } from "features/clients/domain/entities/Client";

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      maxWidth: 936,
      margin: "auto",
      overflow: "hidden",
    },
    searchBar: {},
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    block: {
      display: "block",
    },
    addUser: {
      marginRight: theme.spacing(1),
    },
    contentWrapper: {
      margin: "0px 0px",
    },
    toolbar: {
      paddingRight: 8,
    },
    select: {
      margin: theme.spacing(1),
      minWidth: 150,
      "&:focus": {
        backgroundColor: "inherit",
      },
    },
    selectOutlinePadding: {
      padding: 0,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 160,
    },
  });

export interface DataContainerProps extends WithStyles<typeof styles> {
  loading?: boolean;
  searchPlaceholder?: string;
  createNewInternalPool?: string;
  createNewB2BPool?: string;
  branchOptions: Branch[];
  currentBranch: string;
  onCurrentBranchChange: (branch: string) => void;
  onAddInternalPool?: () => void;
  onAddB2BPool?: () => void;
  onReload?: () => void;
  onSearch?: (query: string | undefined) => void;
  showSearchbar?: boolean;
  b2bPartnerOptions?: B2BPartner[];
  currentB2BPartner?: string;
  onCurrentB2BPartnerChange?: (partner: string) => void;
}

const PoolDataContainer: React.FC<DataContainerProps> = ({
  classes,
  loading,
  searchPlaceholder,
  branchOptions,
  currentBranch,
  onCurrentBranchChange,
  createNewInternalPool,
  createNewB2BPool,
  onAddInternalPool,
  onAddB2BPool,
  onReload,
  onSearch,
  children,
  showSearchbar = true,
  b2bPartnerOptions,
  currentB2BPartner,
  onCurrentB2BPartnerChange,
}) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [isAdmin] = useCurrentUserInGroup("admin");

  useEffect(() => {
    if (onSearch) {
      onSearch(searchValue === "" ? undefined : searchValue);
    }
  }, [onSearch, searchValue]);
  return (
    <Paper className={classes.paper}>
      {showSearchbar && (
        <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
          <Toolbar className={classes.toolbar}>
            <Grid container spacing={2} alignItems="center">
              {isAdmin && b2bPartnerOptions && onCurrentB2BPartnerChange ? (
                <Grid container item xs>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="client-select" style={{ paddingLeft: 10 }}>
                      B2B-Partner
                    </InputLabel>
                    <Select
                      id="client-select"
                      data-testid="client-select"
                      value={currentB2BPartner}
                      onChange={(e) => {
                        onCurrentBranchChange("");
                        onCurrentB2BPartnerChange(e.target.value as string);
                      }}
                      variant="outlined"
                    >
                      <MenuItem key="label" value="" disabled>
                        B2B-Partner auswählen
                      </MenuItem>
                      {b2bPartnerOptions.map((option, index) => (
                        <MenuItem key={index} value={option.id} data-testid={`client-select-${option.b2bNumber}`}>
                          {option.name + " (" + option.b2bNumber + ")"}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="branch-select" style={{ paddingLeft: 20 }}>
                      Niederlassung
                    </InputLabel>
                    <Select
                      data-testid="branch-select"
                      value={currentBranch}
                      onChange={(e) => onCurrentBranchChange(e.target.value as string)}
                      variant="outlined"
                      disabled={currentB2BPartner === ""}
                    >
                      <MenuItem key="label" value="" disabled>
                        Niederlassung auswählen
                      </MenuItem>
                      {branchOptions.map((branchOption, index) => (
                        <MenuItem key={index} value={branchOption.id} data-testid={`branch-select-${branchOption.code}`}>
                          {`${branchOption.name} (${branchOption.code})`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              ) : (
                (isAdmin && (
                  <Grid item xs>
                    <Select
                      id="branch-select"
                      value={currentBranch}
                      onChange={(e) => onCurrentBranchChange(e.target.value as string)}
                      variant="outlined"
                      classes={{ select: classes.select, outlined: classes.selectOutlinePadding }}
                    >
                      <MenuItem key="label" value="" disabled>
                        Niederlassung auswählen
                      </MenuItem>
                      {branchOptions.map((branchOption, index) => (
                        <MenuItem key={index} value={branchOption.id} data-testid={`branch-select-${branchOption.code}`}>
                          {`${branchOption.name} (${branchOption.code})`}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                )) ||
                (branchOptions.length === 1 && <Chip size="medium" label={branchOptions[0].name} />)
              )}

              {searchPlaceholder && (
                <>
                  <Grid item>
                    <SearchIcon className={classes.block} color="inherit" />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      fullWidth
                      value={searchValue}
                      onChange={(x) => {
                        setSearchValue(x.target.value as string);
                      }}
                      placeholder={searchPlaceholder}
                      InputProps={{
                        disableUnderline: true,
                        className: classes.searchInput,
                      }}
                    />
                  </Grid>
                </>
              )}
              <Grid item>
                {isAdmin && createNewInternalPool && (
                  <Button
                    onClick={onAddInternalPool}
                    data-testid="button-createpool-internal"
                    variant="contained"
                    color="primary"
                    disabled={onAddInternalPool === undefined || loading === true || !currentBranch}
                    className={classes.addUser}
                  >
                    {createNewInternalPool}
                  </Button>
                )}
                {isAdmin && createNewB2BPool && (
                  <Button
                    onClick={onAddB2BPool}
                    data-testid="button-createpool-external"
                    variant="contained"
                    color="primary"
                    disabled={onAddB2BPool === undefined || loading === true}
                    className={classes.addUser}
                  >
                    {createNewB2BPool}
                  </Button>
                )}
                {onReload && (
                  <Tooltip title="Reload">
                    <span>
                      <IconButton onClick={onReload} disabled={loading === true}>
                        <RefreshIcon className={classes.block} color="inherit" />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      )}
      <div className={classes.contentWrapper}>{children}</div>
    </Paper>
  );
};

export default withStyles(styles)(PoolDataContainer);
