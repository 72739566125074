import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

interface FinishAssignmentDialogProps {
  open: boolean;
  onAgree: () => void;
  onDismiss: () => void;
}

const FinishAssignmentDialog: React.FC<FinishAssignmentDialogProps> = ({ open, onAgree, onDismiss }) => {
  return (
    <div>
      <Dialog open={open} onClose={onDismiss} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Pool-Auftrag abschließen?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sind Sie sicher dass Sie diese Pool-Auftrag abschließen wollen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button data-testid="finishassignment-dismiss" onClick={onDismiss}>
            Abbrechen
          </Button>
          <Button data-testid="finishassignment-on Agree" onClick={() => onAgree()} color="primary" autoFocus>
            Abschließen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FinishAssignmentDialog;
