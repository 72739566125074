import ClientApi from "../../data/ClientApi";
import ClientRequestModel from "../../data/ClientRequestModel";
import Client, { B2BLinkOptions, PrescriberOption, PrescriberTypeOption } from "../entities/Client";
import ClientConverter from "../converter/ClientConverter";
import B2BSpace from "./B2BSpace";
import { PagedResponse } from "core/utils";
import { CreateClientOnIndustrySoftwareFormValues } from "features/clients/presentation/components/CreateClientOnIndustrySoftwareForm";
import parse from "date-fns/parse";
import format from "date-fns/format";

function cleanFields(obj: any) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === "" || obj[key] === null || obj[key] === undefined) {
      delete obj[key];
    }
  });
}

class ClientService {
  constructor(private clientApi: ClientApi) {}

  public async getById(id: string): Promise<Client | null> {
    return this.clientApi.getById(id).then((clientModel) => ClientConverter.fromResponseModelToClient(clientModel));
  }

  public async get({
    fields = {},
    group,
    page,
    pageSize,
    sortedBy = { field: "lastname", order: "asc" },
  }: {
    fields?: {
      lastname?: string;
      firstname?: string;
      clientNumber?: string;
      birthdate?: string;
    };
    group?: string;
    page: number;
    pageSize: number;
    sortedBy?: { field: string; order: "asc" | "desc" };
  }) {
    cleanFields(fields);

    const params = {
      lastname__ire: fields.lastname ? `^${fields.lastname}` : undefined,
      firstname__ire: fields.firstname ? `^${fields.firstname}` : undefined,
      clientNumber: fields.clientNumber,
      birthdate: fields.birthdate ? format(parse(fields.birthdate, "dd.MM.yyyy", new Date()), "yyyy-MM-dd") : undefined,
      group: group,
      __skip: (page - 1) * pageSize,
      __limit: pageSize,
      __sort: (sortedBy.order === "asc" ? "" : "-") + sortedBy.field,
    };

    return this.clientApi.get(params).then((clients) => ({
      pageSize: clients.pageSize,
      page: clients.page,
      totalCount: clients.totalCount,
      data: clients.data.map((client) => ClientConverter.fromResponseModelToClient(client)),
    }));
  }

  public async getClientList(
    searchQuery: string | undefined,
    group: string | undefined,
    page: number,
    pageSize: number,
    sortedBy: { field: string; order: "asc" | "desc" }
  ): Promise<PagedResponse<Client>> {
    const params = {
      clientNumber__ire: searchQuery,
      firstname__ire: searchQuery,
      lastname__ire: searchQuery,
      street__ire: searchQuery,
      zipcode__ire: searchQuery,
      city__ire: searchQuery,
      phone__ire: searchQuery,
      _id: searchQuery,
      isCareHome: group === B2BSpace.INTERMEDIARY,
      __skip: (page - 1) * pageSize,
      __limit: pageSize,
      __sort: (sortedBy.order === "asc" ? "" : "-") + sortedBy.field,
    };

    return this.clientApi.get(params).then((clients) => ({
      pageSize: clients.pageSize,
      page: clients.page,
      totalCount: clients.totalCount,
      data: clients.data.map((client) => ClientConverter.fromResponseModelToClient(client)),
    }));
  }

  public async getTypeAheadOptions(searchQuery: string): Promise<Client[]> {
    const params = {
      firstname__ire: searchQuery.split(" ")[0],
      lastname__ire: searchQuery.split(" ")[1],
    };

    return this.clientApi.getTypeAheadOptions(params);
  }

  public async getClientTypeAheadOptions(searchQuery: string, group: string, assignmentId?: string): Promise<Client[]> {
    const params = {
      firstname__ire: `^${searchQuery.split(" ")[0]}`,
      lastname__ire: searchQuery.split(" ")[1],
      clientNumber__ire: `^${searchQuery}`,
      isCareHome: group === B2BSpace.INTERMEDIARY,
      __sort: "lastname",
      __limit: "100",
      disjunction: "clientNumber__ire",
      assignmentId: assignmentId,
    };
    return this.clientApi.getTypeAheadOptions(params);
  }

  public async getClientsByNumber(clientNumber: string, group: string, beginsWithMatching?: boolean): Promise<Client[]> {
    const params = {
      clientNumber__ire: beginsWithMatching ? `^${clientNumber}` : clientNumber,
      isCareHome: group === B2BSpace.INTERMEDIARY,
      __sort: "lastname",
      __limit: "100",
    };
    return this.clientApi.getTypeAheadOptions(params);
  }

  public async createNewClient(data: ClientRequestModel) {
    return this.clientApi.create(data);
  }

  public async createNewClientOnIndustrySoftware(data: CreateClientOnIndustrySoftwareFormValues) {
    return this.clientApi.createOnIndustrySoftware(data);
  }

  public async update(id: string, data: ClientRequestModel) {
    return this.clientApi.update(id, data);
  }

  public async getIntermediaryOptions(): Promise<B2BLinkOptions[]> {
    const params = {
      group: B2BSpace.INTERMEDIARY,
      __sort: "firstname",
    };

    const res = await this.clientApi
      .get(params)
      .then((clients) => clients.data.map((client) => ClientConverter.fromResponseModelToClient(client)));

    return res.map(({ id, firstname, clientNumber }: Client) => ({
      value: id,
      b2bNumber: clientNumber,
      label: `${firstname} (${clientNumber})`,
    }));
  }

  public async getClientsByIds(members: string[]) {
    const params = {};

    const res = await this.clientApi
      .get(params)
      .then((clients) => clients.data.map((client) => ClientConverter.fromResponseModelToClient(client)));
    return res;
  }

  public getPrescriberOptions(): Promise<PrescriberOption[]> {
    return this.clientApi
      .getAllPrescribers()
      .then((prescribers) => prescribers.map((prescriber) => ClientConverter.fromPrescriberResponseModelToPrescriberOption(prescriber)));
  }
}

export default ClientService;
