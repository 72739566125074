import { Item } from "features/formular-center/domain/entities/Formular";
import { Field, FieldArray } from "formik";
import React from "react";
import FormSection from "template/presentation/components/form/FormSection";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import { TextField } from "formik-material-ui";

export interface DeliverynoteFormValues {
  employeeNumber: string;
  itemList?: Item[];
  costUnit?: {};
  ikNumber?: string;
  branchId?: string;
  groupKey?: string;
  signaturePath?: any;
  pdfFile?: any;
}

interface DeliverynoteFormProps {
  values?: any;
}

const DeliverynoteForm: React.FC<DeliverynoteFormProps> = (props) => {
  const { values } = props;

  return (
    <>
      <FormSection label={"Artikel hinzufügen"}>
        <FieldArray
          name="itemList"
          render={(arrayHelpers) => (
            <div>
              {values &&
                values.itemList &&
                values.itemList.map((item: Item, index: any) => (
                  <div key={index} style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                    <Field
                      data-testid={`field-itemList-row-${index}-quantity`}
                      style={{ flex: "0 0 8%", marginRight: "5px" }}
                      component={TextField}
                      variant={"outlined"}
                      label={"Menge"}
                      name={`itemList.${index}.quantity`}
                    />
                    <Field
                      data-testid={`field-itemList-row-${index}-article`}
                      style={{ flex: "0 0 30%", marginRight: "5px" }}
                      component={TextField}
                      variant={"outlined"}
                      label={"Artikel"}
                      name={`itemList.${index}.article`}
                    />
                    <Field
                      data-testid={`field-itemList-row-${index}-articleNumber`}
                      style={{ flex: "0 0 20%", marginRight: "5px" }}
                      component={TextField}
                      variant={"outlined"}
                      label={"Artikelnummer"}
                      name={`itemList.${index}.articleNumber`}
                    />
                    <Field
                      data-testid={`field-itemList-row-${index}-productGroup`}
                      style={{ flex: "0 0 15%", marginRight: "5px" }}
                      component={TextField}
                      variant={"outlined"}
                      label={"Warengruppe"}
                      name={`itemList.${index}.productGroup`}
                    />
                    <Field
                      data-testid={`field-itemList-row-${index}-articleNumberInt`}
                      style={{ flex: "0 0 20%" }}
                      component={TextField}
                      variant={"outlined"}
                      label={"Artikelnummer intern"}
                      name={`itemList.${index}.internalArticleNumber`}
                    />
                    <DeleteIcon
                      data-testid={`field-itemList-row-${index}-delete`}
                      onClick={() => {
                        arrayHelpers.remove(index);
                      }}
                      style={{ flex: "0 0 10%", cursor: "pointer", margin: "-20px" }}
                      color={"secondary"}
                    />
                  </div>
                ))}
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                data-testid={`field-itemList-plus`}
                onClick={() => {
                  arrayHelpers.push({ quantity: 1, article: "", articleNumber: "", productGroup: "", internalArticleNumber: "" });
                }}
              >
                <AddCircleIcon color={"primary"} style={{ marginRight: "8px" }} />
                <span>Weiteren Artikel hinzufügen</span>
              </div>
            </div>
          )}
        />
      </FormSection>
      <FormSection label={"Weitere Informationen"}>
        <Field
          data-testid={`field-ikNumber`}
          component={TextField}
          variant={"outlined"}
          label={"IK Nummer"}
          name={"ikNumber"}
          style={{ marginTop: 10 }}
        />
        <Field
          data-testid={`field-groupKey`}
          component={TextField}
          variant={"outlined"}
          label={"Gruppenschlüssel"}
          name={"groupKey"}
          style={{ marginTop: 10 }}
        />
      </FormSection>
    </>
  );
};

export default DeliverynoteForm;
