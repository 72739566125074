import { PoolAssignmentReportVisit } from "features/pools/domain/entities/PoolAssignment";
import { RehaItem } from "features/rehaItems/domain/entities/RehaItem";
import React from "react";
import ContentSection from "template/presentation/components/content/ContentSection";
import LabeledValue from "template/presentation/components/content/LabeledValue";
import ValueGrid from "template/presentation/components/content/ValueGrid";
import { RehaItemsPoolView } from "../RehaItemsPoolView";

interface Props {
  report: PoolAssignmentReportVisit;
  rehaItems?: RehaItem[];
}

export const VisitSection: React.FC<Props> = ({ rehaItems }) => {
  return (
    <ContentSection label="Details">
      <ValueGrid>
        <LabeledValue label="Status" value="Durchgeführt" />
      </ValueGrid>
      <RehaItemsPoolView rehaItems={rehaItems} />
    </ContentSection>
  );
};
