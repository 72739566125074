import { AxiosInstance } from "axios";
import handleAxiosError from "axios-handle-error";
import UserResponseModel from "./UserResponseModel";
import CreateUserModel from "./CreateUserModel";
import { convertAxiosErrorToValidationError, ElementNotFoundError } from "core/data/errors";
import { NetworkError } from "core/data/errors";
import { PagedResponse } from "core/utils";
import User from "../domain/entities/UserEntity";

export default class UserApi {
  constructor(private authClient: AxiosInstance) {}

  public async get(params: any): Promise<PagedResponse<UserResponseModel>> {
    return this.authClient.get("/v2/users", { params }).then((x) => x.data);
  }

  public async getById(id: string): Promise<UserResponseModel> {
    return this.authClient.get(`/v2/users/${id}`).then((x) => x.data);
  }

  public async getTypeAheadOptions(params: any): Promise<User[]> {
    return this.authClient.get("/v2/users/typeAheadOptions", { params }).then((r) => {
      return r.data;
    });
  }

  public async create(values: CreateUserModel) {
    return this.authClient
      .post("/v2/users", values)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          NO_RESPONSE: () => new NetworkError(),
        });
      });
  }

  public async update(id: string, values: CreateUserModel) {
    return this.authClient
      .put(`/v2/users/${id}`, values)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          NO_RESPONSE: () => new NetworkError(),
          404: () => new ElementNotFoundError(),
        });
      });
  }

  public async getUserByNameFullTextSearch(text: string) {
    return this.authClient
      .get("/v2/users/getUsersByFulltextNameSearch", text !== "" ? { params: { query: text } } : undefined)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          NO_RESPONSE: () => new NetworkError(),
          404: () => new ElementNotFoundError(),
        });
      });
  }

  public async getUsersByPrescriberOrIntermediaryFromCurrentUser(): Promise<User[]> {
    return this.authClient
      .get("/v2/users/getB2BUsersByPrescriberOrIntermediaryFromCurrentUser")
      .then((x) => {
        const user: any[] = x.data;
        return user.map((u) => ({ ...u, id: u._id }));
      })
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          NO_RESPONSE: () => new NetworkError(),
          404: () => new ElementNotFoundError(),
        });
      });
  }
}
