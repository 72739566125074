import { AxiosInstance } from "axios";
import handleAxiosError from "axios-handle-error";
import { convertAxiosErrorToValidationError, NetworkError, ElementNotFoundError } from "core/data/errors";
import { SortCriteria } from "core/interfaces/Sort";
import { ClassificationRequestModel } from "./ClassificationRequestModel";
import { ClassificationResponseModel } from "./ClassificationResponseModel";

export default class ClassificationApi {
  constructor(private authClient: AxiosInstance) {}

  public async getAll(searchQuery: string | undefined, sortCriteria: SortCriteria): Promise<ClassificationResponseModel[]> {
    return this.authClient
      .get("/classifications", {
        params: { number__re: searchQuery, __sort: (sortCriteria.order === "asc" ? "" : "-") + sortCriteria.field },
      })
      .then(({ data }) => data);
  }

  public async getById(id: string): Promise<ClassificationResponseModel> {
    return this.authClient
      .get(`/classifications/${id}`)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          NO_RESPONSE: () => new NetworkError(),
          404: () => new ElementNotFoundError(),
        });
      });
  }

  public async create(values: ClassificationRequestModel) {
    return this.authClient
      .post("/classifications", values)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          NO_RESPONSE: () => new NetworkError(),
        });
      });
  }

  public async update(id: string, values: ClassificationRequestModel) {
    return this.authClient
      .put(`/classifications/${id}`, values)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          NO_RESPONSE: () => new NetworkError(),
          404: () => new ElementNotFoundError(),
        });
      });
  }

  public async getAvailable(): Promise<ClassificationResponseModel[]> {
    return this.authClient.get("/classifications/available").then(({ data }) => data);
  }

  public async getAvailableNotInBranch(branchId: string): Promise<ClassificationResponseModel[]> {
    return this.authClient.get(`/classifications/available-not-in-branch/${branchId}`).then(({ data }) => data);
  }

  public async getAvailableAndUsed(poolId: string): Promise<ClassificationResponseModel[]> {
    return this.authClient.get("/classifications/available-and-used/" + poolId).then(({ data }) => data);
  }

  public async getAvailableNotInBranchAndUsed(poolId: string, branchId: string): Promise<ClassificationResponseModel[]> {
    return this.authClient.get(`/classifications/available-not-in-branch-and-used/${poolId}?branch=${branchId}`).then(({ data }) => data);
  }
}
