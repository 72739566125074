import React, { useCallback, useState } from "react";
import AssignmentsTable from "../components/AssignmentsTable";
import { useQuery } from "react-query";
import AssignmentsService from "../../domain/services/AssignmentService";
import { useService } from "core/di";
import UIRoute from "core/presentation/components/UIRoute";
import { RouteComponentProps, useParams } from "react-router-dom";
import { usePageAndPageSizeAndAnyQueryFromRouteProps } from "core/utils";
import ClientService from "features/clients/domain/services/ClientService";
import UserService from "features/users/domain/services/UserService";
import { SelectOption } from "features/assignments/domain/entities/Assignment";
import AssignmentsSubHeader from "../components/AssignmentsSubHeader";
import { useDebounce } from "use-debounce";

export interface AssignmentsRouteProps extends RouteComponentProps {}

const AssignmentsRoute = (props: AssignmentsRouteProps) => {
  const { status } = useParams<{ status?: "todo" | "done" }>();
  const { page, pageSize, searchQueries } = usePageAndPageSizeAndAnyQueryFromRouteProps(props);

  const [sortedBy, setSortedBy] = useState<{ field: string; order: "asc" | "desc" }>({ field: "date", order: "desc" });
  const [clientQuery, setClientQuery] = useState<string>("");
  const [employeeQueryRaw, setEmployeeQuery] = useState<string>("");
  const [employeeQuery] = useDebounce(employeeQueryRaw, 500);

  const assignmentsService = useService(AssignmentsService);
  const clientService = useService(ClientService);
  const userService = useService(UserService);

  const { data: clientOptions } = useQuery(
    [clientQuery],
    () => {
      return clientService.get({ fields: { clientNumber: clientQuery }, group: "b2c", page: 1, pageSize: 10 }).then((r): SelectOption[] => {
        return r.data.map((client) => {
          return {
            label: `${client.firstname} ${client.lastname} (${client.clientNumber})`,
            value: client.id,
            number: client.clientNumber,
          };
        });
      });
    },
    { enabled: clientQuery }
  );

  const { data: employeeOptions } = useQuery(
    [employeeQuery],
    () => {
      return userService.getTypeAheadOptions(employeeQuery, undefined, undefined, "employee").then((r): SelectOption[] => {
        return r.map((employee) => {
          return {
            label: employee.employeeNumber ? `${employee.firstname} ${employee.lastname} (${employee.employeeNumber})` : "",
            // @ts-ignore
            value: employee._id,
            number: employee.employeeNumber,
          };
        });
      });
    },
    { enabled: employeeQuery }
  );

  const onHandleQuery = useCallback(
    (page, pageSize, searchQueries) => {
      let query = "/assignments" + (status !== undefined ? "/" + status : "") + "?page=" + page;
      if (pageSize) {
        query += "&page_size=" + pageSize;
      }
      if (searchQueries) {
        for (const [key, value] of Object.entries(searchQueries)) {
          if (value) {
            query += `&${key}=${value}`;
          }
        }
      }
      props.history.push(query);
    },
    [props.history, status]
  );

  const onSortChange = useCallback((sortBy: string, sortOrder: "asc" | "desc") => {
    setSortedBy({ field: sortBy, order: sortOrder });
  }, []);

  const onEmployeeQueryChange = useCallback((query: string) => {
    setEmployeeQuery(query);
  }, []);

  const onClientQueryChange = useCallback((query: string) => {
    setClientQuery(query);
  }, []);

  const { data, isLoading, refetch, isFetching } = useQuery(
    ["assignmentList", { searchQueries, page, pageSize, sortedBy, status }],
    () => {
      return assignmentsService.getAssignmentList(searchQueries, page, pageSize, sortedBy, status);
    },
    { enabled: searchQueries && page && pageSize && sortedBy }
  );

  return (
    <UIRoute
      {...props}
      title="Aufträge"
      renderSubHeader={() => <AssignmentsSubHeader path={props.location.pathname} onTabChange={props.history.push} />}
    >
      <AssignmentsTable
        isLoading={isLoading || isFetching}
        onReload={refetch}
        clientTypeAhead={{ options: clientOptions || [], query: onClientQueryChange }}
        employeeTypeAhead={{ options: employeeOptions || [], query: onEmployeeQueryChange }}
        onSearch={(searchValue) => {
          onHandleQuery(1, pageSize, searchValue);
        }}
        addButtonText="Neuer Auftrag"
        onAdd={() => {
          props.history.push("/assignments/create");
        }}
        assignments={data?.data}
        page={page}
        pageSize={pageSize}
        totalCount={data?.totalCount}
        onPageChange={(newPage: number) => {
          onHandleQuery(newPage, pageSize, searchQueries);
        }}
        onPageSizeChange={(newPageSize: number) => {
          onHandleQuery(page, newPageSize, searchQueries);
        }}
        sortedBy={sortedBy}
        onSortChange={onSortChange}
        status={status}
      />
    </UIRoute>
  );
};

export default AssignmentsRoute;
