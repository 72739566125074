import React, { useMemo, useState } from "react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import { makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import FormSection from "template/presentation/components/form/FormSection";
import FormContainer from "template/presentation/components/form/FormContainer";
import RadioField from "template/presentation/components/form/RadioField";
import PasswordField from "template/presentation/components/form/PasswordField";
import SelectField from "template/presentation/components/form/SelectField";
import MultiSelectField from "template/presentation/components/form/MutiSelectField";
import FormSubmitButton from "template/presentation/components/form/FormSubmitButton";
import User from "../../domain/entities/UserEntity";
import { AreasForCareHomeOptions, B2BLinkOptions, PrescriberOption } from "features/clients/domain/entities/Client";
import { B2bAreasOfCareHomeInterface } from "../../data/CreateUserModel";
import { BranchOption } from "features/branches/domain/entities/BranchEntity";
import { useCurrentUserInGroup } from "features/login/presentation/hooks/useUser";
import SelectOption from "core/interfaces/SelectOption";
import { useQueryCache } from "react-query";

type PasswordType = "keep" | "manual" | "generate";

export interface CreateOrUpdateUserFormValues {
  firstname: string;
  lastname: string;
  email: string;
  groups: Array<string>;
  employeeNumber: string | null;
  branchCode: string | null;
  b2bNumber: string | null;
  b2bClientNumber: string | null;
  b2bRole: string | null;
  b2cClientNumber: string | null;
  b2bBroker: boolean;
  prescriber: string | null;
  deactivated: boolean;
  sendWelcomeEmail: boolean;
  passwordType: PasswordType;
  password: string | null;
  b2bAreasOfCareHome?: B2bAreasOfCareHomeInterface[] | null;
  caretakerOff?: SelectOption[];
}

const createOrUpdateUserSchema = Yup.object().shape(
  {
    groups: Yup.array().min(1, "Es muss eine Rolle zugewiesen sein").max(1, "Es darf nicht mehr als eine Rolle zugewiesen sein"),
    email: Yup.string().email("Keine gültige E-Mail Adresse").required("Benötigt"),
    firstname: Yup.string().required("Benötigt"),
    lastname: Yup.string().required("Benötigt"),
    employeeNumber: Yup.string()
      .nullable()
      .when("groups", {
        is: (val) => val.map((x: any) => x.value).includes("au") || val.map((x: any) => x.value).includes("backoffice"),
        then: Yup.string().required("Benötigt"),
      }),
    branchCode: Yup.string()
      .nullable()
      .when("groups", {
        is: (val) => val.map((x: any) => x.value).includes("backoffice") || val.map((x: any) => x.value).includes("au"),
        then: Yup.string().required("Benötigt"),
      }),
    b2bNumber: Yup.string()
      .nullable()
      .when("groups", {
        is: (val) => val.map((x: any) => x.value).includes("b2b") || val.map((x: any) => x.value).includes("b2bBackoffice"),
        then: Yup.string().required("Benötigt"),
      }),
    b2bClientNumber: Yup.string()
      .nullable()
      .when(["groups", "prescriber"], {
        is: (groups, prescriber) =>
          (groups.map((x: any) => x.value).includes("b2b") || groups.map((x: any) => x.value).includes("b2bBackoffice")) &&
          prescriber === null,
        then: Yup.string().required("Benötigt"),
        otherwise: Yup.string().optional(),
      }),
    b2bRole: Yup.string()
      .nullable()
      .when(["groups", "b2bClientNumber"], {
        is: (groups, b2bClientNumber) => groups.map((x: any) => x.value).includes("b2b") && b2bClientNumber !== null,
        then: Yup.string().required("Benötigt"),
      }),
    prescriber: Yup.string()
      .nullable()
      .when(["b2bClientNumber", "groups"], {
        is: (val, groups) => val === null && groups.map((x: any) => x.value).includes("b2b"),
        then: Yup.string().required("Benötigt"),
      }),
    b2cClientNumber: Yup.string()
      .nullable()
      .when("groups", {
        is: (val) => val.map((x: any) => x.value).includes("b2c"),
        then: Yup.string().required("Benötigt"),
      }),
    passwordType: Yup.string().oneOf(["manual", "generate", "keep"]).required(),
    // password is required if password type is not generate
    password: Yup.string()
      .nullable()
      .when("passwordType", {
        is: "manual",
        then: Yup.string()
          .required()
          .min(8, "Mindestens 8 Zeichen erforderlich")
          .matches(/(?=.*[a-z])/, "Mindestens ein Kleinbuchstabe erforderlich")
          .matches(/(?=.*[A-Z])/, "Mindestens ein Großbuchstabe erforderlich")
          .matches(/(?=.*[0-9])/, "Mindestens eine Zahl erforderlich"),
      }),
  },
  [["prescriber", "b2bClientNumber"]]
);

export interface InstanceFormProps {
  group?: string;
  onSubmit: (values: CreateOrUpdateUserFormValues) => Promise<void>;
  error: string | null;
  user?: User;
  careHomeOptions?: B2BLinkOptions[];
  prescriberOptions?: PrescriberOption[];
  internalBranchOptions?: BranchOption[];
  b2bBranchOptions?: BranchOption[];
  areasForCareHome?: AreasForCareHomeOptions[];
  setLinkedB2BPartner?: (partner: { id: string; type?: "INTERMEDIARY" | "PRESCRIBER"; b2bNumber?: string }) => void;
  caretakerOptions?: SelectOption[];
}

interface CareHomeOptionsByClientNr {
  value: string;
  id: string;
  label: string;
}

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(1),
  },
}));

export default function CreateUserForm(props: InstanceFormProps) {
  const [isAdmin, isNotAdmin] = useCurrentUserInGroup("admin");
  const queryCache = useQueryCache();
  const groupOptions = useMemo(() => {
    if (isAdmin) {
      return [
        { value: "admin", label: "Admin" },
        { value: "au", label: "Außendienst" },
        { value: "b2b", label: "B2B" },
        { value: "b2bBackoffice", label: "B2B-Innendienst" },
        { value: "b2c", label: "B2C" },
        { value: "backoffice", label: "Innendienst" },
      ];
    }
    return [{ value: "b2c", label: "B2C" }];
  }, [isAdmin]);

  const passwordOptionsCreate = useMemo(
    () =>
      isAdmin
        ? [
            { label: "automatisch generieren", value: "generate" },
            { label: "manuell setzen", value: "manual" },
          ]
        : isNotAdmin
        ? [{ label: "automatisch generieren", value: "generate" }]
        : [],
    [isAdmin, isNotAdmin]
  );

  const passwordOptionsUpdate = useMemo(() => [{ label: "Passwort nicht ändern", value: "keep" }, ...passwordOptionsCreate], [
    passwordOptionsCreate,
  ]);

  const classes = useStyles();
  const {
    group,
    onSubmit,
    error,
    user,
    careHomeOptions,
    internalBranchOptions,
    b2bBranchOptions,
    areasForCareHome,
    setLinkedB2BPartner,
    caretakerOptions,
    prescriberOptions,
  } = props;
  const [areaUser, setAreaSelected] = useState(false);

  const [initialValuesFromProps, initialErrorsFromProps] = useMemo(() => {
    let values: CreateOrUpdateUserFormValues;
    let errors: any = {};
    if (user === undefined) {
      values = {
        firstname: "",
        lastname: "",
        email: "",
        groups: [group].filter((g) => groupOptions.some(({ value }) => value === g)) as Array<string>,
        employeeNumber: "",
        branchCode: null,
        b2bNumber: "",
        b2bClientNumber: null,
        b2bRole: null,
        b2bBroker: false,
        b2cClientNumber: null,
        sendWelcomeEmail: false,
        passwordType: "generate",
        password: null,
        caretakerOff: [],
        prescriber: null,
        deactivated: false,
      };
    } else {
      if (user.b2bRole === "AREA" && !!setLinkedB2BPartner) {
        setAreaSelected(true);
      }

      if (user.groups.length !== 1) {
        errors["groups"] = `Dieser Nutzer hat ${user.groups.length} Rollen, es darf aber nur 1 Rolle vergeben werden.`;
      }

      values = {
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        groups: user.groups.length !== 1 ? [] : user.groups.map((group) => (group === "employee" ? "au" : group)),
        employeeNumber: user.employeeNumber || null,
        branchCode: user.branch?.code || null,
        b2bNumber: user.b2bNumber || null,
        b2bClientNumber: user.b2bClient?._id || null,
        b2bRole: user.b2bRole || null,
        b2bBroker: user.b2bBroker || false,
        b2cClientNumber: user.b2cClient?.clientNumber || null,
        sendWelcomeEmail: false,
        passwordType: "keep",
        b2bAreasOfCareHome:
          user?.b2bAreas?.map(({ id, areaNumber, name }: any) => ({
            value: id,
            label: `${name} (${areaNumber})`,
            areaNumber,
          })) || [],
        password: null,
        caretakerOff: user.caretakerOff?.map((x) => ({ label: `${x.firstname} (${x.clientNumber})`, value: x._id })),
        prescriber: (user.prescriber as string) || null,
        deactivated: user.deactivated || false,
      };
    }
    return [values, errors];
  }, [group, setLinkedB2BPartner, user, groupOptions]);

  return (
    <Formik<CreateOrUpdateUserFormValues>
      enableReinitialize
      initialTouched={initialErrorsFromProps}
      initialErrors={initialErrorsFromProps}
      initialValues={initialValuesFromProps}
      validationSchema={createOrUpdateUserSchema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ values, submitForm, isSubmitting, setFieldValue }) => (
        <FormContainer>
          <FormSection label="Allgemeine Informationen">
            <Field
              data-testid="field-group"
              name="groups"
              legend="Rolle*"
              className={classes.field}
              component={SelectField}
              options={groupOptions}
              onChange={(e: any) => {
                setFieldValue("groups", [e.target.value]);
              }}
              {...(!values.groups.length ? {} : { value: values.groups[0] })}
            />
            <Field
              data-testid="field-firstname"
              className={classes.field}
              component={TextField}
              variant="outlined"
              name="firstname"
              type="text"
              label="Vorname*"
            />
            <Field
              data-testid="field-lastname"
              className={classes.field}
              component={TextField}
              variant="outlined"
              name="lastname"
              type="text"
              label="Nachname*"
            />
            <Field
              data-testid="field-email"
              className={classes.field}
              component={TextField}
              variant="outlined"
              name="email"
              type="text"
              label="E-Mail*"
            />
          </FormSection>
          {(values.groups.includes("backoffice") || values.groups.includes("au")) && (
            <FormSection label="Mitarbeiter-spezifische Informationen">
              <Field
                data-testid="field-employeeNumber"
                component={TextField}
                variant="outlined"
                name="employeeNumber"
                type="text"
                label="Mitarbeiter-Nummer*"
              />
              <Field
                data-testid="field-branchCode"
                className={classes.field}
                component={SelectField}
                options={internalBranchOptions || []}
                name={"branchCode"}
                legend={"Niederlassung zuordnen*"}
              />
              {values.groups.includes("au") && (
                <Field
                  className={classes.field}
                  data-testid={"test-caretakerOff"}
                  component={MultiSelectField}
                  options={caretakerOptions || []}
                  variant={"outlined"}
                  legend={"Betreut Filiale(n)"}
                  name="caretakerOff"
                  getTestIdForOption={(option: SelectOption) => option.number}
                />
              )}
            </FormSection>
          )}
          {(values.groups.includes("b2b") || values.groups.includes("b2bBackoffice")) && (
            <>
              <FormSection label="Mitarbeiter Informationen">
                <Field
                  data-testid="field-b2bNumber"
                  component={TextField}
                  variant="outlined"
                  name="b2bNumber"
                  type="text"
                  label="B2B Mitarbeiter-Nummer*"
                />
              </FormSection>
              {careHomeOptions && careHomeOptions.length > 0 && (
                <FormSection label="Vermittler Informationen">
                  <Field
                    name={"b2bClientNumber"}
                    className={classes.field}
                    data-testid="field-b2bClient"
                    component={MultiSelectField}
                    single={true}
                    disabled={values.prescriber !== null}
                    options={careHomeOptions}
                    legend={"Vermittler zuordnen*"}
                    onChange={(e: any, obj?: B2BLinkOptions) => {
                      if (!obj) {
                        setFieldValue("b2bClientNumber", null);
                        setFieldValue("branchCode", null);
                        queryCache.invalidateQueries("b2bBranchOptions");
                        setFieldValue("b2bRole", null);
                        if (setLinkedB2BPartner) {
                          setLinkedB2BPartner({ id: "" });
                        }
                        return;
                      }
                      if (!!setLinkedB2BPartner && !!careHomeOptions) {
                        const id = obj.value;
                        const b2bClientNumber = obj.b2bNumber;
                        if (b2bClientNumber) {
                          setLinkedB2BPartner({ id: id, type: "INTERMEDIARY", b2bNumber: b2bClientNumber });
                          setFieldValue("b2bAreasOfCareHome", []);
                        }
                      }
                      setFieldValue("b2bClientNumber", obj.value);
                      setFieldValue("prescriber", null);
                    }}
                    getOptionLabel={(value: B2BLinkOptions) => {
                      const temp = typeof value === "object" ? value.label : careHomeOptions?.find((x) => x.value === value)?.label;
                      return temp;
                    }}
                    getOptionSelected={(option: B2BLinkOptions, value: string) => {
                      return option.value === value;
                    }}
                    getTestIdForOption={(option: any) => option.b2bNumber}
                  />

                  {values.groups.includes("b2b") && (
                    <>
                      <Field
                        data-testid="field-b2bRole"
                        className={classes.field}
                        name="b2bRole"
                        component={SelectField}
                        legend="B2B-Rolle*"
                        disabled={values.prescriber !== null}
                        options={[
                          { value: "RESIDENCE", label: "Heimleitung" },
                          { value: "AREA", label: "Wohnbereich" },
                        ]}
                        onChange={(e: any) => {
                          if (e.target.value === "AREA") {
                            setAreaSelected(true);
                          } else if (e.target.value === "RESIDENCE") {
                            setAreaSelected(false);
                          }
                          setFieldValue("b2bRole", e.target.value);
                        }}
                      />
                      {areaUser && areasForCareHome && (
                        <Field
                          className={classes.field}
                          data-testid="field-areaOfCareHome"
                          name="b2bAreasOfCareHome"
                          legend="Wohnbereiche zuordnen"
                          component={MultiSelectField}
                          getTestIdForOption={(option: any) => option.areaNumber}
                          options={areasForCareHome}
                          getOptionSelected={(option: B2BLinkOptions, value: B2BLinkOptions) => option.value === value.value}
                        />
                      )}
                      <Field
                        data-testid="check-mobilAgent"
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name="b2bBroker"
                        disabled={values.prescriber !== null}
                        Label={{ label: "mobiler Vermittler" }}
                      />
                    </>
                  )}
                </FormSection>
              )}
              <FormSection label="Verordner Informationen">
                {prescriberOptions && (
                  <Field
                    name={"prescriber"}
                    className={classes.field}
                    data-testid="field-prescriber"
                    component={MultiSelectField}
                    single={true}
                    options={prescriberOptions}
                    legend={"Verordner zuordnen*"}
                    disabled={values.b2bClientNumber !== null}
                    onChange={(e: any, obj?: B2BLinkOptions) => {
                      if (!obj) {
                        setFieldValue("prescriber", null);
                        setFieldValue("branchCode", null);
                        queryCache.invalidateQueries("b2bBranchOptions");
                        if (setLinkedB2BPartner) {
                          setLinkedB2BPartner({ id: "" });
                        }
                        return;
                      }
                      if (setLinkedB2BPartner) {
                        setLinkedB2BPartner({ id: obj.value, type: "PRESCRIBER" });
                      }
                      setFieldValue("prescriber", obj.value);
                    }}
                    getOptionLabel={(value: B2BLinkOptions) => {
                      return typeof value === "object" ? value.label : prescriberOptions?.find((x) => x.value === value)?.label;
                    }}
                    getOptionSelected={(option: B2BLinkOptions, value: string) => option.value === value}
                    getTestIdForOption={(option: any) => option.number}
                  />
                )}
              </FormSection>
              {values.groups.includes("b2bBackoffice") && (
                <FormSection label="Niederlassung auswählen">
                  {b2bBranchOptions && (
                    <Field
                      data-testid="field-branchCode"
                      className={classes.field}
                      component={MultiSelectField}
                      single={true}
                      options={b2bBranchOptions || []}
                      name={"branchCode"}
                      legend={
                        values.b2bClientNumber || values.prescriber
                          ? "B2B-Niederlassung zuordnen*"
                          : "Wählen Sie zuerst einen B2B-Partner aus*"
                      }
                      onChange={(e: any, obj?: BranchOption) => {
                        if (obj) {
                          setFieldValue("branchCode", obj.value);
                        } else {
                          setFieldValue("branchCode", null);
                        }
                      }}
                      getOptionLabel={(value: any) => {
                        return typeof value === "object" ? value.label : b2bBranchOptions?.find((x) => x.value === value)?.label;
                      }}
                      getOptionSelected={(option: any, value: string) => {
                        return option.value === value.toString();
                      }}
                    />
                  )}
                </FormSection>
              )}
            </>
          )}
          {values.groups.includes("b2c") && (
            <FormSection label="B2C-spezifische Informationen">
              <Field
                data-testid="field-b2cClientNumber"
                component={TextField}
                variant="outlined"
                name="b2cClientNumber"
                type="text"
                label="Kunden-Nummer*"
              />
            </FormSection>
          )}
          <FormSection label="Sicherheit">
            <Field name="deactivated" component={CheckboxWithLabel} type="checkbox" Label={{ label: "Benutzer deaktivieren" }} />

            <Field
              name="passwordType"
              component={RadioField}
              legend="Passwort"
              options={user ? passwordOptionsUpdate : passwordOptionsCreate}
              data-testid="field-passwordType"
            />

            {values.passwordType === "manual" && (
              <Field data-testid="field-password" className={classes.field} component={PasswordField} name="password" label="Password" />
            )}
          </FormSection>
          {values.passwordType !== "keep" && (
            <FormSection label="Sonstiges">
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="sendWelcomeEmail"
                Label={{ label: "Willkommens-Email versenden" }}
                data-test-id="field-welcomemail"
              />
            </FormSection>
          )}
          <FormSection>
            {/*
              <Alert className={classes.field} data-testid="createuser-error" severity="info">
                {JSON.stringify(values, null, 4)}
              </Alert>
            */}

            {error && (
              <Alert className={classes.field} data-testid="createuser-error" severity="error">
                {error}
              </Alert>
            )}

            <FormSubmitButton data-testid="form-create" disabled={isSubmitting} onClick={submitForm}>
              {user ? "Bearbeiten" : "Erstellen"}
            </FormSubmitButton>
          </FormSection>
        </FormContainer>
      )}
    </Formik>
  );
}
