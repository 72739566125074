import React from "react";
import { makeStyles, Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 936,
    margin: "auto",
    overflow: "hidden",
    marginBottom: 16,
  },
}));

interface Props {
  style?: React.CSSProperties;
}

const ContentContainer: React.FC<Props> = ({ style, children }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper} style={style}>
      {children}
    </Paper>
  );
};
export default ContentContainer;
