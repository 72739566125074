import React, { useCallback, useMemo } from "react";
import Assignment, { SelectOption } from "../../domain/entities/Assignment";
import Client from "features/clients/domain/entities/Client";
import AssignmentService, { createUpdateAssignmentTypes } from "../../domain/services/AssignmentService";
import { useService } from "core/di";
import DataContainer from "template/presentation/components/table/DataContainerExtendedSearch";
import { DataTableCell } from "template/presentation/components/table/DataTable";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import { Tooltip } from "@material-ui/core";
import { format } from "date-fns";
import DataTablePaginatedAndSortable from "template/presentation/components/table/DataTablePaginatedAndSortable";
import { EmptyState } from "core/presentation/components/EmptyState";
import { group } from "console";

export interface AssignmentTableProps {
  assignments?: Assignment[] | null;
  isLoading: boolean;
  onReload: () => void;
  addButtonText: string;
  onAdd: () => void;
  onSearch: ((query: string | undefined) => void) | undefined;
  page: number;
  pageSize: number;
  totalCount?: number;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
  onSortChange: (sortBy: string, sortOrder: "asc" | "desc") => void;
  sortedBy: { field: string; order: "asc" | "desc" };
  clientTypeAhead: { options: SelectOption[]; query: (query: string) => void };
  employeeTypeAhead: { options: SelectOption[]; query: (query: string) => void };
  status?: "todo" | "done";
}

const AssignmentTable = (props: AssignmentTableProps) => {
  const {
    assignments,
    isLoading,
    onReload,
    addButtonText,
    onAdd,
    onSearch,
    page,
    pageSize,
    onPageChange,
    onPageSizeChange,
    totalCount,
    onSortChange,
    sortedBy,
    clientTypeAhead,
    employeeTypeAhead,
    status,
  } = props;
  const assignmentService = useService(AssignmentService);

  const columns = useMemo(() => {
    return [
      { name: "Auftragsnummer" },
      { name: "Zugehöriger Vorgang" },
      { name: "Termin", sortField: "date" },
      { name: "Status" },
      { name: "Typ" },
      { name: "Kunde" },
      { name: "Zugewiesener Benutzer" },
      { name: "Aktion" },
    ];
  }, []);

  const filterItems = useMemo(() => {
    return [
      { name: "Auftragsnummer", searchField: "assignmentNumber" },
      {
        name: "Kundennummer",
        searchField: "client",
        setTypeAheadQuery: clientTypeAhead.query,
        typeAheadOptions: clientTypeAhead.options,
        typeAheadTestProperty: "number",
      },
      {
        name: "zugewiesener Benutzer",
        searchField: "employee",
        setTypeAheadQuery: employeeTypeAhead.query,
        typeAheadOptions: employeeTypeAhead.options,
        typeAheadTestProperty: "number",
      },
    ];
  }, [clientTypeAhead.query, clientTypeAhead.options, employeeTypeAhead.query, employeeTypeAhead.options]);

  const renderFullName = useCallback(({ firstname, lastname }: any) => {
    if (firstname && lastname) {
      return `${firstname} ${lastname}`;
    }

    if (firstname && !lastname) {
      return firstname;
    }

    if (!firstname && lastname) {
      return lastname;
    }

    return "-";
  }, []);

  const renderClient = useCallback(
    ({ clientNumber, firstname, lastname, city, street, zipcode }: Client) => {
      if (city && street && zipcode) {
        return (
          <div>
            <span data-testid="client-name">
              {renderFullName({ firstname, lastname })} ({clientNumber})
            </span>
            <br />
            <span data-testid="client-street">{street}</span> <br />
            <span data-testid="client-city">
              {zipcode} {city}
            </span>
          </div>
        );
      }
      return <div>{renderFullName({ firstname, lastname })}</div>;
    },
    [renderFullName]
  );

  const emptyState = useMemo(() => {
    switch (status) {
      case undefined:
        return (
          <EmptyState
            title="Noch kein Auftrag vorhanden"
            description="Erstellen Sie jetzt Ihren ersten Auftrag, um Ihre Außendienstmitarbeiter mit Arbeit und Ihre Kunden mit Hilfsmitteln zu versorgen."
            buttonLabel="Neuer Auftrag"
            onButtonClick={onAdd}
          />
        );

      case "todo":
        return (
          <EmptyState
            title="Kein offener Auftrag vorhanden"
            description="Schon alles erledigt? Erstellen Sie jetzt einen neuen Auftrag, um Ihre Außendienstmitarbeiter mit Arbeit zu versorgen."
            buttonLabel="Neuer Auftrag"
            onButtonClick={onAdd}
          />
        );

      case "done":
        return (
          <EmptyState
            title="Keine abgeschlossenen Aufträge vorhanden"
            description="Warten Sie bis bestehende Aufträge erledigt wurden oder erstellen Sie hier einen neuen Auftrag."
            buttonLabel="Neuer Auftrag"
            onButtonClick={onAdd}
          />
        );
    }
  }, [status]);

  return (
    <DataContainer
      onSearchSubmit={onSearch}
      loading={isLoading}
      addButton={addButtonText}
      onAdd={onAdd}
      onReload={onReload}
      filterItems={filterItems}
    >
      <DataTablePaginatedAndSortable<Assignment>
        data-testid={"assignmenttable"}
        loading={isLoading}
        columns={columns}
        rows={assignments}
        page={page}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        totalCount={totalCount}
        keyExtractor={(data) => data._id}
        onSortChange={onSortChange}
        sortedBy={sortedBy}
        renderRow={(data, index) => {
          return (
            <>
              <DataTableCell data-testid="assignment">
                {data.assignmentNumber ? (
                  <Tooltip title={data.assignmentNumber} placement="top">
                    <p>{data.assignmentNumber.length > 20 ? data.assignmentNumber.substring(0, 20) + "..." : data.assignmentNumber}</p>
                  </Tooltip>
                ) : (
                  "-"
                )}
              </DataTableCell>
              <DataTableCell data-testid="relatedCaseNumber">
                {data.relatedCaseNumber ? (
                  <Link data-testid={`link-goto-case-${index}`} to={`/cases?caseNumber=${data.relatedCaseNumber}`}>
                    {data.relatedCaseNumber}
                  </Link>
                ) : (
                  "-"
                )}
              </DataTableCell>
              <DataTableCell data-testid="appointment">
                {format(new Date(data.date), "dd.MM.yyyy") || "-"} <br />
                {assignmentService.renderTime(data)}
              </DataTableCell>
              <DataTableCell data-testid="status">{assignmentService.translateStatus(data.status)}</DataTableCell>
              <DataTableCell data-testid="type">{assignmentService.translateType(data.type)}</DataTableCell>
              <DataTableCell data-testid="client">{data.client ? renderClient(data.client) : "-"}</DataTableCell>
              <DataTableCell data-testid="employee">{data.employee ? renderFullName(data.employee) : "-"}</DataTableCell>
              <DataTableCell>
                {data.client && data.employee && createUpdateAssignmentTypes.indexOf(data.type) > -1 && (
                  <Link data-testid={`link-edit-row-${index}`} to={`/assignment/edit/${data._id}`}>
                    <EditIcon color={"primary"} />
                  </Link>
                )}
              </DataTableCell>
            </>
          );
        }}
        renderEmpty={() => emptyState}
      />
    </DataContainer>
  );
};

export default AssignmentTable;
