import React, { useCallback, useState } from "react";
import { queryCache, useQuery } from "react-query";
import useService from "core/di/useService";
import { RouteComponentProps, useParams } from "react-router-dom";
import UIRoute from "core/presentation/components/UIRoute";
import ClientService from "../../domain/services/ClientService";
import CreateOrUpdateClientForm, { CreateOrUpdateClientFormValues } from "../components/CreateOrUpdateClientForm";
import ClientConverter from "../../domain/converter/ClientConverter";
import B2BSpace from "../../domain/services/B2BSpace";
import { ValidationMessageError } from "core/data/errors";
import B2BService from "../../../b2b/domain/services/B2BService";
import AreaResponseModel from "../../../b2b/domain/components/AreaResponseModel";
import { AreasForCareHomeOptions } from "../../domain/entities/Client";
import BranchService from "features/branches/domain/services/BranchService";
import UserService from "features/users/domain/services/UserService";
import SelectOption from "core/interfaces/SelectOption";
import User from "features/users/domain/entities/UserEntity";
import { renderUserB2BClient, renderUserName } from "../utils";
import { useMemo } from "react";

export interface CreateClientRouteProps extends RouteComponentProps {}

const CreateClientRoute = (props: CreateClientRouteProps) => {
  const { group } = useParams<any>();
  const clientService = useService(ClientService);
  const b2bService = useService(B2BService);
  const branchService = useService(BranchService);
  const userService = useService(UserService);
  const [error, setError] = useState<string | null>(null);
  const [areasForCareHome, setAreasForCareHome] = useState<AreasForCareHomeOptions[] | []>([]);

  const { data } = useQuery("careHomeOptions", (key) => clientService.getIntermediaryOptions());
  const { data: b2bUserOptions } = useQuery(["usersListOptions"], () =>
    userService.get(undefined, "b2b", 1, 1000, { field: "lastname", order: "asc" })
  );

  const { data: prescriberOptions } = useQuery("prescriberOptions", () => clientService.getPrescriberOptions());
  const { data: internalBranchOptions } = useQuery("internalBranchOptions", () => branchService.getBranchOptions());

  const onSubmit = useCallback(
    (values: CreateOrUpdateClientFormValues) => {
      const clientRequest = ClientConverter.fromClientFormToRequestModel(values, group);

      return clientService
        .createNewClient(clientRequest)
        .then(() => {
          setError(null);
          return queryCache.invalidateQueries("clientList");
        })
        .then(() => {
          return queryCache.invalidateQueries("careHomeOptions");
        })
        .then(() => {
          props.history.goBack();
        })
        .catch((e) => {
          if (e instanceof ValidationMessageError) {
            switch (e.code) {
              case "DUPLICATE_CLIENT_NUMBER":
                setError("Die Kundennummer ist bereits vergeben");
                return;
              default:
                setError(`Ein unbekannter Fehler ist aufgetreten. Code: ${e.code}`);
                return;
            }
          } else {
            setError("Ein unbekannter Fehler ist aufgetreten");
          }
        });
    },
    [clientService, group, props.history]
  );

  const onGetAreasOfCareHome = useCallback(
    (clientNumber: string) => {
      return b2bService.getAreasByClientNumber(clientNumber).then((r) => {
        const areas = r.map(({ id, areaNumber, name }: AreaResponseModel) => ({ value: id, areaNumber, label: `${name} (${areaNumber})` }));
        setAreasForCareHome(areas);
      });
    },
    [b2bService]
  );

  const convertUserToSelection = useCallback((data: User[]): SelectOption[] => {
    return data.map((x) => {
      return { label: `${renderUserName(x)}  (${renderUserB2BClient(x)}) `, value: x.id };
    });
  }, []);

  const title = useMemo(() => {
    if (group === B2BSpace.INTERMEDIARY) {
      return "Neuen Vermittler anlegen";
    } else if (group === B2BSpace.PRESCRIBER) {
      return "Neuen Verordner anlegen";
    }
    return "Neuen Kunden anlegen";
  }, [group]);

  return (
    <UIRoute {...props} title={title}>
      {data && b2bUserOptions ? (
        <CreateOrUpdateClientForm
          error={error}
          onSubmit={onSubmit}
          group={group}
          careHomeOptions={data}
          getAreasForCareHome={onGetAreasOfCareHome}
          areasForCareHome={areasForCareHome}
          internalBranchOptions={internalBranchOptions}
          prescriberOptions={prescriberOptions}
          b2bUserOptions={convertUserToSelection(b2bUserOptions.data)}
        />
      ) : (
        <div />
      )}
    </UIRoute>
  );
};

export default CreateClientRoute;
