import React from "react";
import { Link } from "react-router-dom";
import DataContainer from "template/presentation/components/table/DataContainer";
import DataTable, { DataTableCell } from "template/presentation/components/table/DataTable";
import Adapter from "features/adapter/domain/entities/Adapter";
import format from "date-fns/format";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckIcon from "@material-ui/icons/Check";
import { EmptyState } from "core/presentation/components/EmptyState";

export interface AdapterTableProps {
  adapter: Adapter[] | null;
  isLoading: boolean;
  onReload: () => void;
}

const columns = [
  { name: "Type" },
  { name: "Name" },
  { name: "Status" },
  { name: "Statusänderung am" },
  { name: "Default" },
  { name: "Details" },
];

export default function AdapterTable(props: AdapterTableProps) {
  const { adapter, isLoading, onReload } = props;

  return (
    <DataContainer loading={isLoading} onReload={onReload}>
      <DataTable<Adapter>
        data-testid={`adapter-table`}
        loading={isLoading}
        columns={columns}
        rows={adapter}
        keyExtractor={(data) => data.id}
        renderRow={(data, index) => {
          return (
            <>
              <DataTableCell>{data.type}</DataTableCell>
              <DataTableCell>{data.name}</DataTableCell>
              <DataTableCell>{data.status}</DataTableCell>
              <DataTableCell>{format(data.statusChangedAt, "dd.MM.yyyy HH:mm")}</DataTableCell>
              <DataTableCell>{data.default ? <CheckIcon style={{ color: "green", fontSize: "16px" }} /> : ""}</DataTableCell>
              <DataTableCell>
                <Link to={`/adapter/${data.id}`}>
                  <VisibilityIcon />
                </Link>
              </DataTableCell>
            </>
          );
        }}
        renderEmpty={() => (
          <EmptyState
            title="Noch kein Adapter vorhanden"
            description="Verbinden Sie das System mit Ihrer Branchensoftware, um Daten aus dieser zu synchronisieren."
          />
        )}
      />
    </DataContainer>
  );
}
