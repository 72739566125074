import React from "react";
import { SafeUserModel } from "features/users/data/UserResponseModel";
import ContentSection from "template/presentation/components/content/ContentSection";
import LabeledValue from "template/presentation/components/content/LabeledValue";
import ValueGrid from "template/presentation/components/content/ValueGrid";

interface Props {
  user?: SafeUserModel;
  prescriberInformation?: any;
  assignmentType: string;
}
const mapUserRole = (role: string) => {
  switch (role) {
    case "ADMIN":
      return "Admin";
    case "BACKOFFICE":
      return "Innendienst";
    case "EMPLOYEE":
      return "Außendienst";
    case "B2B":
      return "B2B";
    case "B2BBACKOFFICE":
      return "B2B-Innendienst";
    case "B2C":
      return "B2C";
    default:
      return "";
  }
};
export const SenderInfoSection: React.FC<Props> = ({ user, prescriberInformation, assignmentType }) => {
  return (
    <ContentSection label="Absender">
      <ValueGrid>
        {user ? (
          <>
            <LabeledValue
              data-testid="senderType"
              label="Benutzerrolle"
              value={user.groups.map((x) => mapUserRole(x.toUpperCase())).join(",")}
            />
            {user.employeeNumber ? (
              <LabeledValue data-testid="employeeNumber" label="AD-Mitarbeiter Nr." value={user.employeeNumber} />
            ) : null}
            {user.b2cNumber ? <LabeledValue data-testid="b2cNumber" label="B2C-Kunde Nr." value={user.b2cNumber} /> : null}
            {user.b2bNumber ? <LabeledValue data-testid="b2bNumber" label="B2B-Mitarbeiter Nr." value={user.b2bNumber} /> : null}
            <LabeledValue data-testid="senderLastname" label="Nachname" value={user.lastname} />
            <LabeledValue data-testid="senderFirstname" label="Vorname" value={user.firstname} />
            <LabeledValue data-testid="senderEmail" label="E-Mail" value={user.email} />
            {prescriberInformation ? (
              <LabeledValue data-testid="prescriberNumber" label="Verordnernummer" value={prescriberInformation.prescriberNumber} />
            ) : null}
            {prescriberInformation ? (
              <LabeledValue data-testid="prescriberName" label="Name des Verordners" value={prescriberInformation.name} />
            ) : null}
          </>
        ) : (
          <LabeledValue
            data-testid="potentiaBusinessPartner"
            label="Typ"
            value={assignmentType.includes("B2C") ? "Gelöschter B2C Nutzer" : "Potenzieller Geschäftspartner"}
          />
        )}
      </ValueGrid>
    </ContentSection>
  );
};
