import { AxiosInstance } from "axios";
import MessageResponseModel from "./MessageResponseModel";
import handleAxiosError from "axios-handle-error";
import { convertAxiosErrorToValidationError, ElementNotFoundError, NetworkError, UnauthorizedError } from "core/data/errors";
import MessageRequestModel from "./MessageRequestModel";
import { PagedResponse } from "core/utils";

export default class MessageApi {
  constructor(private authClient: AxiosInstance) {}

  public async get(params: any): Promise<PagedResponse<MessageResponseModel>> {
    return this.authClient
      .get<PagedResponse<MessageResponseModel>>("/v2/messages", { params })
      .then(({ data }) => {
        return data;
      });
  }

  public async getById(id: string): Promise<MessageResponseModel> {
    return this.authClient
      .get(`/v2/messages/${id}`)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          NO_RESPONSE: () => new NetworkError(),
          404: () => new ElementNotFoundError(),
        });
      });
  }

  public async sendMessage(params: MessageRequestModel): Promise<void> {
    return this.authClient.post("/v2/messages", params);
  }

  public async deleteById(id: string): Promise<void> {
    return this.authClient
      .delete(`/v2/messages/${id}`)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          401: () => new UnauthorizedError("Nicht Autotisiert", "401"),
          404: () => new ElementNotFoundError("Nachricht nicht gefunden"),
          NO_RESPONSE: () => new NetworkError(),
        });
      });
  }

  public async sendSignatureNotification(params: any): Promise<void> {
    return this.authClient.post("/v2/messages/sendSignatureNotification", params);
  }
}
