import { AxiosInstance } from "axios";
import { PagedResponse } from "core/utils";
import Product from "../domain/entities/Product";
import { ImportProductsFormValues } from "../presentation/components/ImportProductsForm";

export default class ProductsApi {
  constructor(private authClient: AxiosInstance) {}

  public async get(
    searchQuery: string | undefined,
    page: number,
    pageSize: number,
    sortedBy: { field: string; order: "asc" | "desc" }
  ): Promise<PagedResponse<Product>> {
    const params = {
      articleNumber__ire: searchQuery,
      category__ire: searchQuery,
      description__ire: searchQuery,
      gtin__ire: searchQuery,
      pzn__ire: searchQuery,
      __skip: (page - 1) * pageSize,
      __limit: pageSize,
      __sort: (sortedBy.order === "asc" ? "" : "-") + sortedBy.field,
    };
    return this.authClient.get("/products", { params }).then((r) => {
      return r.data;
    });
  }

  public async import(formValues: ImportProductsFormValues) {
    const body = new FormData();
    body.append("file", formValues.file!);

    return this.authClient.post(`/products/import?replace=${formValues.replace}`, body).then((x) => {
      return;
    });
  }
}
