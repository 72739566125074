import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import { FormControlLabel, makeStyles, Radio, RadioGroup } from "@material-ui/core";
import { NewClient } from "features/clients/domain/entities/Client";
import { format } from "date-fns";
interface ModifyNewClientDialogProps {
  open: boolean;
  onDismiss: () => void;
  newClient: NewClient;
  onSubmit: (newClient: NewClient) => void;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: "500px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "left",
    "& *": {
      marginBottom: 5,
    },
  },
  warning: {
    marginTop: 10,
  },
  field: {
    marginTop: 5,
  },
}));

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required("Benötigt"),
  lastname: Yup.string().required("Benötigt"),
  birthdate: Yup.string().required("Benötigt"),
  street: Yup.string().optional(),
  zipcode: Yup.string().optional(),
  city: Yup.string().optional(),
  phone: Yup.string().optional(),
  email: Yup.string().email("Ungültige Email Adresse").optional(),
});

const ModifyNewClientDialog: React.FC<ModifyNewClientDialogProps> = ({ open, onDismiss, newClient, onSubmit }) => {
  const classes = useStyles();

  return (
    <Formik<NewClient>
      initialValues={{
        firstname: newClient.firstname || "",
        lastname: newClient.lastname || "",
        birthdate: newClient.birthdate ? format(new Date(newClient.birthdate), "yyyy-MM-dd") : "",
        street: newClient.street || "",
        zipcode: newClient.zipcode || "",
        city: newClient.city || "",
        email: newClient.email || "",
        phone: newClient.phone || "",
        gender: newClient.gender || "F",
      }}
      onSubmit={(values) => onSubmit(values)}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting, values, setFieldValue }) => {
        return (
          <Dialog
            open={open}
            onClose={onDismiss}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ paper: classes.paper }}
          >
            <DialogTitle id="alert-dialog-title">Daten des Neukunden überprüfen</DialogTitle>
            <DialogContent className={classes.content}>
              <RadioGroup
                row
                aria-labelledby="salutation-group-label"
                name="gender"
                value={values.gender}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue("gender", (event.target as HTMLInputElement).value);
                }}
              >
                <FormControlLabel value="F" control={<Radio />} label="Frau" />
                <FormControlLabel value="M" control={<Radio />} label="Mann" />
              </RadioGroup>
              <Field
                data-testid="field-firstname"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"firstname"}
                label={"Vorname*"}
              />
              <Field
                data-testid="field-lastname"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"lastname"}
                label={"Nachname*"}
              />
              <Field
                data-testid="field-birthday"
                className={classes.field}
                component={TextField}
                type={"date"}
                InputLabelProps={{
                  shrink: true,
                }}
                variant={"outlined"}
                name={"birthdate"}
                label={"Geburtstag*"}
              />
              <Field
                data-testid="field-email"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"email"}
                label={"Email"}
              />
              <Field
                data-testid="field-street"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"street"}
                label={"Straße"}
              />
              <Field
                data-testid="field-zipcode"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"zipcode"}
                label={"Postleitzahl"}
              />
              <Field
                data-testid="field-city"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"city"}
                label={"Stadt"}
              />
              <Field
                data-testid="field-phone"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"phone"}
                label={"Telefon"}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onDismiss}>Abbrechen</Button>
              <Button onClick={submitForm} variant="outlined" color="primary" data-testid="modify-new-client" disabled={isSubmitting}>
                Neukunde anlegen
              </Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default ModifyNewClientDialog;
