import Client from "features/clients/domain/entities/Client";
import User from "features/users/domain/entities/UserEntity";
import Intermediary from "../../../users/domain/entities/IntermediaryEntity";
import Prescriber from "../../../users/domain/entities/PrescriberEntity";

export const renderClientLabel = (client: Client) => {
  if (client.firstname && client.lastname) {
    return `${client.firstname} ${client.lastname} (${client.clientNumber})`;
  } else if (!client.lastname) {
    return `${client.firstname} (${client.clientNumber})`;
  } else if (!client.firstname) {
    return ` ${client.lastname} (${client.clientNumber})`;
  }
  return `- (${client.clientNumber})`;
};

export const renderUserName = (user: User) => {
  return [
    user.firstname,
    user.lastname,
    (user.intermediary as Intermediary)?.name && `[${(user.intermediary as Intermediary)?.name}]`,
    (user.prescriber as Prescriber)?.name && `[${(user.prescriber as Prescriber)?.name}]`,
  ].join(" ");
};

export const renderUserB2BClient = (user: User) => {
  if (!user.b2bClient) {
    return "-";
  }
  if (user.b2bClient.firstname) {
    return user.b2bClient.firstname;
  } else {
    return "-";
  }
};

export const renderMessageUserType = (user: User) => {
  if (user.groups.includes("b2c")) {
    return "B2C";
  } else if (user.groups.includes("b2b")) {
    return "B2B";
  } else if (user.groups.includes("employee")) {
    return "AU";
  }
  return "Kunde";
};
