import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

interface Props {
  open: boolean;
  onAgree: () => void;
  onDismiss: () => void;
}

export const SignatureformDeleteDialog: React.FC<Props> = ({ open, onAgree, onDismiss }) => {
  return (
    <div>
      <Dialog open={open} onClose={onDismiss} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Dokument wirklich löschen?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sind Sie sicher dass Sie dieses Dokument löschen wollen? Alle Offene Pool-Aufträge dieses Dokuments können von dieser Aktion
            beeinträchtigt werden und ggf. nicht mehr abgeschlossen werden.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button data-testid="dismiss-delete" onClick={() => onDismiss()}>
            Abbrechen
          </Button>
          <Button data-testid="agree-delete" onClick={() => onAgree()} color="primary" autoFocus>
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
