import { SelectMessageTypeOptions, SelectRecipientTypeOptions } from "../../domain/entities/Message";
import Client from "features/clients/domain/entities/Client";
import MessageRequestModel from "features/messages/data/MessageRequestModel";
import User from "features/users/domain/entities/UserEntity";
import MessageApi from "../../data/MessageApi";
import MessageConverter from "../converter/MessageConverter";
import Message from "../entities/Message";
import { PagedResponse } from "core/utils";
import { CreateOrUpdateMessageFormValues } from "features/messages/presentation/components/CreateOrUpdateMessageForm";

export const messageTypes = [
  "GENERAL",
  "SIGNATURE_NOTIFICATION",
  "CONSULTATION_APPOINTMENT_ACCEPTED",
  "CONSULTATION_APPOINTMENT_CANCELLED",
  "CONSULTATION_APPOINTMENT_PROPOSE_OTHER_DATE",
] as const;

export type MessageType = typeof messageTypes[number];

export const recipientTypes = ["FIELDSTAFF", "B2B", "B2C"] as const;

export type RecipientType = typeof recipientTypes[number];

const translationRecipientType: { [key in RecipientType]: string } = {
  FIELDSTAFF: "Außendienstmitarbeiter",
  B2B: "B2B Mitarbeiter",
  B2C: "B2C Kunde",
};

const translationTableType: { [key in MessageType]: string } = {
  GENERAL: "Allgemein",
  SIGNATURE_NOTIFICATION: "Unterschriftenanforderung",
  CONSULTATION_APPOINTMENT_ACCEPTED: "Beratung zugesagt",
  CONSULTATION_APPOINTMENT_CANCELLED: "Beratung abgesagt",
  CONSULTATION_APPOINTMENT_PROPOSE_OTHER_DATE: "Terminvorschlag ändern",
};

class MessageService {
  constructor(private messageApi: MessageApi) {}

  public async getMessageList(
    searchQueries: { subject: string; recipient: string; relatedAssignment: string },
    page: number,
    pageSize: number,
    sortedBy: { field: string; order: "asc" | "desc" }
  ) {
    const params = {
      subject__ire: searchQueries.subject,
      recipient: searchQueries.recipient,
      relatedAssignment: searchQueries.relatedAssignment,
      __skip: (page - 1) * pageSize,
      __limit: pageSize,
      __sort: (sortedBy.order === "asc" ? "" : "-") + sortedBy.field,
    };

    return this.messageApi.get(params).then((messagePagedResponse) => {
      const messagesPaged: PagedResponse<Message> = {
        ...messagePagedResponse,
        data: messagePagedResponse.data.map((messageModel) => MessageConverter.fromResponseModelToMessage(messageModel)),
      };
      return messagesPaged;
    });
  }

  public async getById(id: string): Promise<Message> {
    return this.messageApi.getById(id).then((props) => MessageConverter.fromResponseModelToMessage(props));
  }

  public async sendMessage(data: CreateOrUpdateMessageFormValues) {
    const res = MessageConverter.cleanMessageRequest(data);
    return this.messageApi.sendMessage(res);
  }

  public async deleteById(id: string) {
    return await this.messageApi.deleteById(id);
  }

  public translateType(type: MessageType): string {
    return translationTableType[type];
  }

  public getTypeOptions(): [SelectMessageTypeOptions] | any {
    return messageTypes.map((val) => {
      return { value: val, label: translationTableType[val] };
    });
  }

  public getRecipientTypeOptions(): [SelectRecipientTypeOptions] | any {
    return recipientTypes.map((val) => {
      return { value: val, label: translationRecipientType[val] };
    });
  }

  public getFullName({ firstname, lastname }: User | Client): string {
    if (firstname && lastname) {
      return `${firstname} ${lastname}`;
    }

    if (firstname && !lastname) {
      return firstname;
    }

    if (!firstname && lastname) {
      return lastname;
    }

    return "-";
  }

  public sendSigningNotification(data: any, assignment: any) {
    const signatureNotificationData: any = { ...data, assignmentId: assignment };
    return this.messageApi.sendSignatureNotification(signatureNotificationData);
  }
}

export default MessageService;
