import { RehaItem } from "../entities/RehaItem";

export default class RehaItemConverter {
  public static fromEntityToSelectOptions(items?: RehaItem[]) {
    if (items) {
      return items.map((i) => ({
        value: i.id,
        label: `${i.manufacturer ? i.manufacturer : ""} - ${i.model ? i.model : ""} - ${
          i.description ? i.description : ""
        } - (Rehamittelnr.: ${i.number})`,
      }));
    }
    return items;
  }
}
