import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RouteComponentProps, useParams } from "react-router-dom";
import { useQuery, useQueryCache } from "react-query";
import useService from "core/di/useService";
import UIRoute from "core/presentation/components/UIRoute";
import UserService from "../../domain/services/UserService";
import CreateOrUpdateUserForm, { CreateOrUpdateUserFormValues } from "../components/CreateOrUpdateUserForm";
import { ValidationMessageError } from "core/data/errors";
import ClientService from "features/clients/domain/services/ClientService";
import AreaResponseModel from "features/b2b/domain/components/AreaResponseModel";
import B2BService from "features/b2b/domain/services/B2BService";
import BranchService from "features/branches/domain/services/BranchService";
import { renderClientLabel } from "features/clients/presentation/utils";

export interface EditUserRouteProps extends RouteComponentProps {}

export default function EditUserRoute(props: EditUserRouteProps) {
  const { id } = useParams<{ id: string }>();
  const userService = useService(UserService);
  const clientService = useService(ClientService);
  const queryCache = useQueryCache();
  const b2bService = useService(B2BService);
  const branchService = useService(BranchService);
  const [error, setError] = useState<string | null>(null);

  const { data: user } = useQuery(["user", id], () => userService.getById(id));

  const { data: intermediaryOptions } = useQuery("intermediaryOptions", () => clientService.getIntermediaryOptions());
  const { data: prescriberOptions } = useQuery("prescriberOptions", () => clientService.getPrescriberOptions());
  const [linkedB2BPartner, setLinkedB2BPartner] = useState<{ id: string; type?: "INTERMEDIARY" | "PRESCRIBER"; b2bNumber?: string }>({
    id: "",
  });

  const { data: internalBranchOptions } = useQuery("internalBranchOptions", () => branchService.getBranchOptions());
  const { data: b2bBranchOptions } = useQuery(
    ["b2bBranchOptions", user?.branch?.b2bOwner, linkedB2BPartner],
    async () =>
      (
        await branchService.getB2BBackofficeUserBranchOptions({
          id: linkedB2BPartner.id || user?.branch?.b2bOwner,
          type: user?.branch?.b2bOwnerType.toUpperCase() || linkedB2BPartner.type,
        })
      ).map((branch: any) => ({ value: branch.code, label: branch.name, id: branch.id })),
    {
      enabled: linkedB2BPartner.id !== "" && user?.groups.includes("b2bBackoffice"),
    }
  );

  const { data: caretakerOptions } = useQuery(["caretakerOptions"], async () => {
    return clientService.getClientList(undefined, "intermediary", 1, 5000, { field: "firstname", order: "asc" }).then((r) => {
      return r.data.map((x) => {
        return { label: renderClientLabel(x), value: x.id, number: x.clientNumber };
      });
    });
  });

  useEffect(() => {
    if (user && (user?.groups.includes("b2b") || user?.groups.includes("b2bBackoffice"))) {
      // if prescriber/intermediary was deleted, the user stays without a connected b2b partner
      if (!user.prescriber && !user.b2bClient) {
        return;
      }
      setLinkedB2BPartner(
        (user.prescriber as string)
          ? {
              id: user.prescriber as string,
              type: "PRESCRIBER",
            }
          : {
              id: user.b2bClient._id,
              b2bNumber: user.b2bClient.clientNumber,
              type: "INTERMEDIARY",
            }
      );
    }
  }, [user]);

  const { data: areaData } = useQuery(
    ["areaDataEdit", linkedB2BPartner],
    () => {
      return b2bService.getAreasByClientNumber(linkedB2BPartner.b2bNumber!).then((r) => {
        return r.map(({ id, areaNumber, name }: AreaResponseModel) => ({
          value: id,
          areaNumber,
          label: `${name} (${areaNumber})`,
        }));
      });
    },
    {
      enabled: linkedB2BPartner.b2bNumber && user?.groups.includes("b2b"),
    }
  );

  const onSubmit = useCallback(
    (values: CreateOrUpdateUserFormValues) => {
      return userService
        .update(id, values)
        .then(() => {
          setError(null);
        })
        .then(() => {
          queryCache.invalidateQueries("usersList");
        })
        .then(() => {
          queryCache.invalidateQueries(["user", id]);
        })
        .then(() => {
          props.history.goBack();
        })
        .catch((e) => {
          if (e instanceof ValidationMessageError) {
            switch (e.code) {
              case "NOTFOUND_B2BCLIENT":
                setError("Zu der angegebenen Pflegeheim-Nummer konnte kein Pflegeheim gefunden werden");
                return;
              case "NOTFOUND_B2CCLIENT":
                setError("Zu der angegebenen Kundenummer konnte kein Kunde gefunden werden");
                return;
              case "DUPLICATE_EMAIL":
                setError("Die E-Mail Adresse wird bereits für einen anderen Benutzer verwendet");
                return;
              case "DUPLICATE_EMPLOYEENUMBER":
                setError("Ein Mitarbeiter mit dieser Nummer existiert bereits");
                return;
              case "DUPLICATE_BACKOFFICENUMBER":
                setError("Ein Innendienstmitarbeiter mit dieser Nummer existiert bereits");
                return;
              default:
                setError(`Ein unbekannter Fehler ist aufgetreten. Code: ${e.code || "VMSG400"}`);
                return;
            }
          } else {
            setError(`Ein unbekannter Fehler ist aufgetreten`);
          }
        });
    },
    [props.history, queryCache, userService, id]
  );
  return (
    <UIRoute {...props} title="Benutzer bearbeiten">
      {user ? (
        <CreateOrUpdateUserForm
          user={user}
          error={error}
          onSubmit={onSubmit}
          careHomeOptions={intermediaryOptions || []}
          internalBranchOptions={internalBranchOptions}
          b2bBranchOptions={b2bBranchOptions}
          setLinkedB2BPartner={(partner) => {
            setLinkedB2BPartner(partner);
          }}
          areasForCareHome={areaData}
          caretakerOptions={caretakerOptions || []}
          prescriberOptions={prescriberOptions}
        />
      ) : (
        <div />
      )}
    </UIRoute>
  );
}
