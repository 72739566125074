import React, { useCallback, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import DataTable, { DataTableCell } from "template/presentation/components/table/DataTable";
import { Branch } from "../../domain/entities/BranchEntity";
import BranchDataContainer from "./BranchDataContainer";
import { EmptyState } from "core/presentation/components/EmptyState";
import { B2BPartner } from "features/clients/domain/entities/Client";
export interface B2BBranchProps {
  b2bPartnerOptions: B2BPartner[];
  currentB2BPartner: string;
  onCurrentB2BPartnerChange: (partner: string) => void;
}
export interface BranchesTableProps {
  branches: Branch[] | null;
  isLoading: boolean;
  onAddBranch: () => void;
  onReload?: () => void;
  b2b?: B2BBranchProps;
}

export default function BranchesTable(props: BranchesTableProps) {
  const { branches, isLoading, onReload, onAddBranch, b2b } = props;
  const { type } = useParams<{ type: "internal" | "b2b" }>();

  const columns = useMemo(() => {
    if (type === "b2b") {
      return [{ name: "B2B-Kunde" }, { name: "Nummer" }, { name: "Name" }, { name: "Anschrift" }, { name: "Default" }, { name: "Aktion" }];
    }
    return [{ name: "Nummer" }, { name: "Name" }, { name: "Anschrift" }, { name: "Default" }, { name: "Aktion" }];
  }, [type]);

  const renderDefault = useCallback((defaultBranch: boolean) => {
    if (defaultBranch) {
      return <CheckIcon data-testid="default-check" style={{ color: "green", fontSize: "16px" }} />;
    }

    return "-";
  }, []);

  const emptyState = useMemo(() => {
    switch (type) {
      case "b2b":
        return (
          <EmptyState
            title="Noch keine Niederlassungen für Business Partner vorhanden"
            description="Erstellen Sie jetzt Ihre erste Niederlassung für Business Partner oder wählen Sie einen aus."
            buttonLabel="Neue B2B Niederlassung"
            onButtonClick={onAddBranch}
          />
        );
    }
    return (
      <EmptyState
        title="Noch keine Niederlassung vorhanden"
        description="Erstellen Sie jetzt Ihre erste Niederlassung, um Ihre Vorgänge und Pools zu strukturieren."
        buttonLabel="Neue Niederlassung"
        onButtonClick={onAddBranch}
      />
    );
  }, [type]);

  return (
    <BranchDataContainer
      loading={isLoading}
      createNewBranchText={type === "internal" ? "Neue Niederlassung (intern)" : "Neue Niederlassung (B2B)"}
      onAddBranch={onAddBranch}
      onReload={onReload}
      b2b={b2b}
    >
      <DataTable<Branch>
        data-testid={`branchtable`}
        loading={isLoading}
        columns={columns}
        rows={branches}
        keyExtractor={(data) => data.id}
        renderRow={(data, index) => {
          return (
            <>
              {type === "b2b" && (
                <DataTableCell data-testid={`b2bClient`}>{data.b2bOwner?.name + " (" + data.b2bOwner?.b2bNumber + ")"}</DataTableCell>
              )}
              <DataTableCell data-testid={`branchnumber`}>{data.code}</DataTableCell>
              <DataTableCell data-testid={`branchname`}>{data.name}</DataTableCell>
              <DataTableCell data-testid={`branchaddress`}>{`${data.street}, ${data.zipcode} ${data.city}`}</DataTableCell>
              <DataTableCell data-testid={`branchdefault`}>{renderDefault(data.default)}</DataTableCell>
              <DataTableCell>
                <Link data-testid={`link-edit-row-${index}`} to={`/branch/edit/${data.id}/${type}`}>
                  <EditIcon />
                </Link>
              </DataTableCell>
            </>
          );
        }}
        renderEmpty={() => emptyState}
      />
    </BranchDataContainer>
  );
}
