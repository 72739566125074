import { ValidationMessageError } from "core/data/errors";
import { PagedResponse } from "core/utils";
import { CreateCaseForPoolAssignmentModel } from "features/pools/data/CreateCaseForPoolAssignmentModel";
import PoolApi from "features/pools/data/PoolApi";
import PoolUserModel from "features/pools/data/PoolUserModel";
import { CreateOrUpdatePoolFormValues } from "features/pools/presentation/components/CreateOrUpdatePoolForm";
import { UpdateSigingRequestFormProps } from "features/pools/presentation/components/detail/UpdateSigningRequestDialog";
import UserConverter from "features/users/domain/converter/UserConverter";
import AppointmentConverter from "../converters/AppointmentConverter";
import PoolAssignmentConverter from "../converters/PoolAssignmentConverter";
import PoolConverter from "../converters/PoolConverter";
import AppointmentReservation from "../entities/AppointmentReservation";
import PoolAssignment, { PoolAssignmentDocType, PoolAssignmentPreview } from "../entities/PoolAssignment";
import {
  TransferToB2BPartnerModel,
  ChangeBranchModel,
  ChangePoolModel,
  ClassifyModel,
  DefaultPool,
  HandOverModel,
  Pool,
  TransferToInternalModel,
} from "../entities/PoolEntity";

export default class PoolService {
  constructor(private poolApi: PoolApi) {}

  public getPoolAssignmentTypeSelectOptions(b2bView?: boolean) {
    if (b2bView && b2bView === true) {
      return [
        { label: "Terminanfrage vor Ort", value: "ATTACHMENT_B2C_TO_B2B_WITH_APPOINTMENT" },
        { label: "Beratung vor Ort (B2C)", value: "ON_SITE_CONSULTATION" },
        { label: "Videoberatung (B2C)", value: "VIDEO_CONSULTATION" },
        { label: "Wunderversorgung", value: "WOUNDCARE" },
      ];
    }
    return [
      { label: "Anhang (AU)", value: "ATTACHMENT_AU" },
      { label: "Anhang (B2B)", value: "ATTACHMENT_B2B" },
      { label: "Anhang (B2C)", value: "ATTACHMENT_B2C" },
      { label: "Profiländerung (B2C)", value: "EDIT_PROFILE" },
      { label: "Anhang (B2C) mit Terminwunsch", value: "ATTACHMENT_B2C_WITH_APPOINTMENT" },
      { label: "Terminanfrage vor Ort", value: "ATTACHMENT_B2C_TO_B2B_WITH_APPOINTMENT" },
      { label: "Videoberatungsanfrage (B2C)", value: "CONSULTATION_REQUEST_B2C" },
      { label: "Videoterminanfrage", value: "CONSULTATION_REQUEST_B2C_TO_B2B" },
      { label: "Beratung vor Ort (B2C)", value: "ON_SITE_CONSULTATION" },
      { label: "Videoberatung (B2C)", value: "VIDEO_CONSULTATION" },
      { label: "Lieferung", value: "DELIVERY" },
      { label: "ungeplante Lieferung", value: "UNPLANNED_DELIVERY" },
      { label: "Abholung", value: "COLLECTION" },
      { label: "Reparatur/Wartung", value: "REPAIR" },
      { label: "Beratung", value: "CONSULTING" },
      { label: "Videoberatung Ergebnis", value: "VIDEO_CONSULTING" },
      { label: "Mietverlängerung", value: "RENT_EXTENSION" },
      { label: "Videoberatungsanfrage (B2B)", value: "CONSULTATION_REQUEST_B2B" },
      { label: "Videoberatung (B2B)", value: "VIDEO_CONSULTATION_B2B" },
      { label: "Geschäftsanfrage", value: "BUSINESS_INQUIRY" },
      { label: "Posteingang", value: "INBOX" },
      { label: "Wunderversorgung", value: "WOUNDCARE" },
      { label: "Profiländerung", value: "EDIT_PROFILE" },
      { label: "Elektronische Verordnung", value: "EVO" },
    ];
  }

  public async create(poolFormInput: CreateOrUpdatePoolFormValues) {
    return this.poolApi.create(PoolConverter.fromCreateOrUpdateFormToModel(poolFormInput));
  }

  public async update(id: string, poolFormInput: CreateOrUpdatePoolFormValues) {
    return this.poolApi.update(id, PoolConverter.fromCreateOrUpdateFormToModel(poolFormInput));
  }

  public async getAll(): Promise<Pool[]> {
    return this.poolApi.getAll().then((pools) => pools.map((pool) => PoolConverter.fromResponseModelToEntity(pool)));
  }

  public async getAllForBranch(branchId: string): Promise<Pool[]> {
    return this.poolApi.getAllForBranch(branchId).then((pools) => pools.map((pool) => PoolConverter.fromResponseModelToEntity(pool)));
  }

  public async getById(poolId: string): Promise<Pool> {
    return this.poolApi.getById(poolId).then((pool) => PoolConverter.fromResponseModelToEntity(pool));
  }

  public async getAssignmentsForPool(
    searchQueries: { assignmentNumber: string; client: string; type: string; newClient: boolean; newClientName: string },
    poolId: string,
    status: "all" | "active" | "archived",
    page: number,
    pageSize: number,
    sortedBy: { field: string; order: "asc" | "desc" }
  ) {
    let active;
    if (status === "all") {
      active = "A";
    } else if (status === "active") {
      active = "Y";
    } else if (status === "archived") {
      active = "N";
    }

    const newClientFirstname = searchQueries.newClientName?.split(" ")[0];
    const newClientLastname = searchQueries.newClientName?.split(" ")[1];

    const params = {
      assignmentNumber__ire: searchQueries.assignmentNumber,
      client: searchQueries.client,
      type: searchQueries.type,
      "newClient.firstname__ire": newClientFirstname,
      "newClient.lastname__ire": newClientLastname,
      newClient__exists: searchQueries.newClient,
      client__exists: searchQueries.newClient ? false : undefined,
      __skip: (page - 1) * pageSize,
      __limit: pageSize,
      __sort: (sortedBy.order === "asc" ? "" : "-") + sortedBy.field,
      active,
    };

    const assignments = await this.poolApi.getAssignmentPreviewsForPool(poolId, params);
    return {
      data: assignments.data.map((assignment: any) => PoolAssignmentConverter.fromPreviewResponseModelToEntity(assignment)),
      totalCount: assignments.totalCount,
      page: page + 1,
      pageSize: assignments.pageSize,
    };
  }

  public async getMyAssignmentPreviews(
    searchQueries: { assignmentNumber: string; client: string; type: string },
    page: number,
    pageSize: number,
    sortedBy: { field: string; order: "asc" | "desc" },
    status?: string
  ): Promise<PagedResponse<PoolAssignmentPreview>> {
    let active;
    if (status === "all") {
      active = "A";
    } else if (status === "active") {
      active = "Y";
    } else if (status === "archived") {
      active = "N";
    }
    const params = {
      assignmentNumber__ire: searchQueries.assignmentNumber,
      client: searchQueries.client,
      type: searchQueries.type,
      active: active,
      __skip: (page - 1) * pageSize,
      __limit: pageSize,
      __sort: (sortedBy.order === "asc" ? "" : "-") + sortedBy.field,
    };

    const assignments = await this.poolApi.getMyAssignmentPreviews(params);
    return {
      data: assignments.data.map((assignment) => PoolAssignmentConverter.fromPreviewResponseModelToEntity(assignment)),
      totalCount: assignments.totalCount,
      page: page + 1,
      pageSize: assignments.pageSize,
    };
  }

  public disableDefault(defaultPool?: DefaultPool, pool?: any) {
    if (pool.branch) {
      if (defaultPool?.default) {
        if (pool.id === defaultPool.id) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  public async getDefaultForBranch(branchId: string): Promise<DefaultPool> {
    return this.poolApi.getDefaultForBranch(branchId).then((pool) => PoolConverter.fromLeanResponseToDefaultEntity(pool));
  }

  public async getEmployeesInPool(poolId: string): Promise<PoolUserModel[]> {
    return this.poolApi.getEmployeesInPool(poolId);
  }

  public async claimAssignment(assignmentId: string): Promise<boolean> {
    return this.poolApi.claimAssignment(assignmentId);
  }

  public async getAssignment(assignmentId: string): Promise<PoolAssignment> {
    const assignment = await this.poolApi.getAssignment(assignmentId).then((x) => PoolAssignmentConverter.fromResponseModelToEntity(x));
    if (!assignment) {
      throw new ValidationMessageError("Ungültiger Auftragstyp", "INVALID_ASSIGNMENT_TYPE");
    }

    return assignment;
  }

  public async getBranchesExceptAllocatedByPool(poolId: string) {
    return this.poolApi.getBranchesExceptAllocatedByPool(poolId);
  }

  public async getClassificationsForBranchByPool(poolId: string) {
    return this.poolApi.getClassificationsForBranchByPool(poolId);
  }

  public async submitAssignmentWithNewClient(assignmentId: string) {
    return this.poolApi.submitAssignmentWithNewClient(assignmentId);
  }

  public async createCaseForAssignment(assignmentId: string, model: CreateCaseForPoolAssignmentModel) {
    return this.poolApi.createCaseForAssignment(assignmentId, model);
  }

  public async createCaseFilesForAssignment(assignmentId: string) {
    return this.poolApi.createCaseFilesForAssignment(assignmentId);
  }

  public async setClassification(model: ClassifyModel) {
    return this.poolApi.setClassification(model);
  }

  public async changeBranch(model: ChangeBranchModel) {
    return this.poolApi.changeBranch(model);
  }

  // TODO extract this method to a document service
  public async getDocByFilenameFromAssignment(assignmentId: string, filename: string, doctype: PoolAssignmentDocType) {
    return this.poolApi.getDocByFilenameFromAssignment(assignmentId, filename, doctype);
  }

  public async getDeliveryDocumentFromAssignment(assignmentId: string) {
    return this.poolApi.getDeliveryDocumentFromAssignment(assignmentId);
  }

  public async finishAssignment(assignmentId: string) {
    return this.poolApi.finishAssignment(assignmentId);
  }

  public async handOverAssignment(model: HandOverModel) {
    return this.poolApi.handOverAssignment(model);
  }

  public async releaseAssignment(assignmentId: string) {
    return this.poolApi.releaseAssignment(assignmentId);
  }

  public async reserveB2CAppointmentInWantedTimeRange(appointment: AppointmentReservation) {
    return this.poolApi.acceptB2CAppointment(AppointmentConverter.fromEntityToModel(appointment));
  }

  public async reserveB2CAppointmentInUnWantedTimeRange(appointment: AppointmentReservation) {
    return this.poolApi.proposeB2CAppointment(AppointmentConverter.fromEntityToModel(appointment));
  }

  public async reserveB2BAppointmentInWantedTimeRange(appointment: AppointmentReservation) {
    return this.poolApi.acceptB2BAppointment(AppointmentConverter.fromEntityToModel(appointment));
  }

  public async reserveB2BAppointmentInUnWantedTimeRange(appointment: AppointmentReservation) {
    return this.poolApi.proposeB2BAppointment(AppointmentConverter.fromEntityToModel(appointment));
  }

  public async getB2BEmployeesFromMyBranch() {
    return this.poolApi.getB2BEmployeesFromMyBranch().then((data) => data.map((userModel) => UserConverter.fromModelToEntity(userModel)));
  }

  public async getAvailableFieldstaffEmployeesFromBranch(branchId: string) {
    return this.poolApi
      .getAvailableFieldstaffEmployeesFromBranch(branchId)
      .then((data) => data.map((userModel) => UserConverter.fromModelToEntity(userModel)));
  }

  public async getAvailableFieldstaffEmployeesAndUsedForPool(poolId: string) {
    return this.poolApi
      .getAvailableFieldstaffEmployeesAndUsedForPool(poolId)
      .then((data) => data.map((userModel) => UserConverter.fromModelToEntity(userModel)));
  }

  public async changePoolForAssignment(model: ChangePoolModel) {
    return this.poolApi.changePoolForAssignment(model);
  }

  public async transferAssignmentToB2BPartner(model: TransferToB2BPartnerModel) {
    return this.poolApi.transferAssignmentToB2BClient(model);
  }

  public async transferB2BAssignmentToInternal(model: TransferToInternalModel) {
    return this.poolApi.transferB2BAssignmentToInternal(model);
  }
}
