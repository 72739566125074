import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import { useDrawer } from "../hooks/useDrawer";
import { useQuery } from "react-query";
import useService from "core/di/useService";
import AdapterService from "features/adapter/domain/AdapterService";
import { useCurrentUserInGroup } from "features/login/presentation/hooks/useUser";

const lightColor = "rgba(255, 255, 255, 0.7)";

const styles = (theme: Theme) =>
  createStyles({
    menuButton: {
      marginLeft: -theme.spacing(1),
    },
    link: {
      textDecoration: "none",
      color: lightColor,
      "&:hover": {
        color: theme.palette.common.white,
      },
    },
    button: {
      borderColor: lightColor,
    },
    secondaryBar: {
      zIndex: 0,
    },
    banner: {
      backgroundColor: "red",
      color: "white",
      fontSize: "30",
      fontWeight: "bold",
      padding: 5,
    },
  });

export interface HeaderProps extends WithStyles<typeof styles> {
  renderSecondaryHeader?: () => React.ReactNode;
  renderIcons?: () => React.ReactNode;
  getTitle?: () => string;
  onLogout?: () => void;
  title?: string;
}

function HeaderBase(props: HeaderProps) {
  const { classes, renderSecondaryHeader, renderIcons, getTitle, title } = props;
  const adapterService = useService(AdapterService);
  const [isAdmin] = useCurrentUserInGroup("admin");
  const [isBackoffice] = useCurrentUserInGroup("backoffice");
  const { data } = useQuery("adapterStatus", () => adapterService.getAdapterStatus(), {
    refetchInterval: 5000,
    staleTime: 5000,
    enabled: isAdmin || isBackoffice,
  });

  const { handleDrawerToggle } = useDrawer();

  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center" style={{ paddingTop: 15, paddingBottom: 15 }}>
            <Hidden smUp>
              <Grid item>
                <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle} className={classes.menuButton}>
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                {title || (getTitle && getTitle())}
              </Typography>
            </Grid>
            {renderIcons && renderIcons()}
          </Grid>
        </Toolbar>
        {data && data === "OFFLINE" && <div className={classes.banner}>Verbindung zur Branchensoftware getrennt.</div>}
      </AppBar>
      {renderSecondaryHeader && renderSecondaryHeader()}
    </React.Fragment>
  );
}

export default withStyles(styles)(HeaderBase);
