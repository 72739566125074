import React, { useCallback, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import useService from "../../../../core/di/useService";
import LoginService from "../../domain/services/LoginService";
import { useHistory } from "react-router-dom";
import LoginForm, { LoginFormValues } from "../components/LoginForm";
import { BACKEND_URL } from "core/data/backend";
import Showcase from "../components/showcase/Showcase";
import { Grid, Paper, useMediaQuery } from "@material-ui/core";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "75%",
    margin: "auto",
    height: "80vh",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  body: {
    backgroundColor: "F8FAFE",
  },
  title: {
    fontWeight: "bold",
    textAlign: "left",
  },
  logo: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  showcase: {
    marginTop: theme.spacing(8),
  },
}));

export default function LoginScreen() {
  const theme = useTheme();
  const matchesMediaquery = useMediaQuery(theme.breakpoints.up("md"));

  const classes = useStyles();

  const loginService = useService(LoginService);
  const history = useHistory();

  const [error, setError] = useState<string | null>(null);

  const handleSubmit = useCallback(
    (values: LoginFormValues) => {
      return loginService
        .login(values.username, values.password)
        .then((token) => {
          localStorage.setItem("accessToken", token.accessToken);
          localStorage.setItem("refreshToken", token.refreshToken);

          history.replace("/");
        })
        .catch((e) => {
          setError(e.message);
          throw e;
        });
    },
    [history, loginService]
  );
  useEffect(() => {
    if (localStorage.getItem("accessToken") != null && localStorage.getItem("refreshToken") != null) {
      history.replace("/");
    }
  }, [history]);

  return (
    <Grid container>
      <Grid item xl={6} lg={6} md={6} sm={12}>
        <Paper elevation={0} className={classes.paper}>
          <img src={`${BACKEND_URL}/assets/logo`} alt="Logo des Sanitätshauses" width={200} className={classes.logo} />
          <LoginForm handleSubmit={handleSubmit} error={error} />
        </Paper>
      </Grid>
      {matchesMediaquery && (
        <Grid item xl={6} lg={6} md={6}>
          <Showcase className={classes.showcase} />
        </Grid>
      )}
    </Grid>
  );
}
