import React, { useMemo } from "react";
import { useQuery } from "react-query";
import useService from "core/di/useService";
import { RouteComponentProps, useParams } from "react-router-dom";
import UIRoute from "core/presentation/components/UIRoute";
import AdapterService from "features/adapter/domain/AdapterService";
import { makeStyles } from "@material-ui/core";
import AdapterDetailSubHeader from "../components/AdapterDetailSubHeader";
import AdapterLogTable from "../components/AdapterLogTable";
import SyncAllClientsButton from "../components/SyncAllClientsButton";
import SyncAllClassificationsButton from "../components/SyncAllClassificationsButton";
import SyncAllBranchesButton from "../components/SyncAllBranchesButton";
import ValueGrid from "template/presentation/components/content/ValueGrid";
import ContentContainer from "template/presentation/components/content/ContentContainer";
import ContentSection from "template/presentation/components/content/ContentSection";
import SyncAllPrescribersButton from "../components/SyncAllPrescribersButton";
import SyncAllIntermediariesButton from "../components/SyncAllIntermediariesButton";
import SyncAllRehaItemsButton from "../components/SyncAllRehaItemsButton";
import AdapterTableSizesTable from "../components/AdapterTableSizesTable";

const useStyles = makeStyles((theme) => ({
  actionSectionContainer: {
    maxWidth: 936,
    margin: "auto",
    marginTop: theme.spacing(2),
  },
}));

export interface AdapterDetailRouteProps extends RouteComponentProps {}

export default function AdapterDetailRoute(props: AdapterDetailRouteProps) {
  const { id } = useParams<{ id: string; group: string }>();
  const adapterService = useService(AdapterService);

  const adapterDetailQueryResult = useQuery(["adapterDetail", id], (key) => adapterService.getById(id));
  const adapterLogsQueryResult = useQuery(["adapterLogs", id], (key) => adapterService.getLogsById(id));
  const adapterTableSizesQueryResult = useQuery(["adapterTableSizes", id], (key) => adapterService.getTableSizesById(id));

  const classes = useStyles();

  const renderAdapterSyncSection = useMemo(() => {
    if (adapterDetailQueryResult.data) {
      return (
        <>
          <ContentContainer>
            <ContentSection label="Synchronisieren">
              <ValueGrid>
                <SyncAllClassificationsButton adapterId={adapterDetailQueryResult.data?.id} onFinished={adapterDetailQueryResult.refetch} />
                <SyncAllBranchesButton adapterId={adapterDetailQueryResult.data?.id} onFinished={adapterDetailQueryResult.refetch} />
                <SyncAllIntermediariesButton adapterId={adapterDetailQueryResult.data?.id} onFinished={adapterDetailQueryResult.refetch} />
                <SyncAllPrescribersButton adapterId={adapterDetailQueryResult.data?.id} onFinished={adapterDetailQueryResult.refetch} />
                <SyncAllClientsButton adapterId={adapterDetailQueryResult.data?.id} onFinished={adapterDetailQueryResult.refetch} />
                <SyncAllRehaItemsButton adapterId={adapterDetailQueryResult.data?.id} onFinished={adapterDetailQueryResult.refetch} />
              </ValueGrid>
            </ContentSection>
          </ContentContainer>
        </>
      );
    } else {
      return <div />;
    }
  }, [adapterDetailQueryResult.data, adapterDetailQueryResult.refetch]);

  const renderAdapterTableSizesSection = useMemo(() => {
    if (adapterTableSizesQueryResult.data) {
      return (
        <div className={classes.actionSectionContainer}>
          <AdapterTableSizesTable
            data={adapterTableSizesQueryResult.data}
            isLoading={adapterTableSizesQueryResult.isLoading || adapterTableSizesQueryResult.isFetching}
            onReload={adapterTableSizesQueryResult.refetch}
          />
        </div>
      );
    } else {
      return <div />;
    }
  }, [
    adapterTableSizesQueryResult.data,
    adapterTableSizesQueryResult.isFetching,
    adapterTableSizesQueryResult.isLoading,
    adapterTableSizesQueryResult.refetch,
    classes.actionSectionContainer,
  ]);

  const renderAdapterLogs = useMemo(() => {
    if (adapterLogsQueryResult.data) {
      return (
        <div className={classes.actionSectionContainer}>
          <AdapterLogTable
            logs={adapterLogsQueryResult.data}
            isLoading={adapterLogsQueryResult.isLoading || adapterLogsQueryResult.isFetching}
            onReload={adapterLogsQueryResult.refetch}
          />
        </div>
      );
    } else {
      return <div />;
    }
  }, [
    adapterLogsQueryResult.data,
    adapterLogsQueryResult.isFetching,
    adapterLogsQueryResult.isLoading,
    adapterLogsQueryResult.refetch,
    classes.actionSectionContainer,
  ]);

  return (
    <UIRoute
      {...props}
      title={`Adapter: ${adapterDetailQueryResult.data?.name || "..."}`}
      renderSubHeader={() => {
        return <AdapterDetailSubHeader adapter={adapterDetailQueryResult.data} />;
      }}
    >
      <>
        {renderAdapterSyncSection}
        {renderAdapterTableSizesSection}
        {renderAdapterLogs}
      </>
    </UIRoute>
  );
}
