import { Button, IconButton, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import ContentSection from "template/presentation/components/content/ContentSection";
import LabeledValue from "template/presentation/components/content/LabeledValue";
import ValueGrid from "template/presentation/components/content/ValueGrid";
import UpdateSigningRequestDialog, { UpdateSigingRequestFormProps } from "./UpdateSigningRequestDialog";
import ReplayIcon from "@material-ui/icons/Replay";
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import DataTable, { DataTableCell } from "template/presentation/components/table/DataTable";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { format } from "date-fns";
import ViewPDFDocumentDialog from "./ViewPDFDocumentDialog";
import StartSignatureRequestDialog from "./StartSignatureRequestDialog";
import de from "date-fns/locale/de";
import { Alert } from "@material-ui/lab";
import MailIcon from "@material-ui/icons/Mail";

type SigningRequest = any;

interface Props {
  report: SigningRequest;
  client: any;
  assignmentId: string;
  status: string;
  onSigningRequestUpdate: (model: UpdateSigingRequestFormProps) => Promise<any>;
  onUpdateError: string | null;
  sendSignatureNotification: (data: any, assignment: any) => void;
}

export const SignatureRequestSection: React.FC<Props> = ({
  report,
  onSigningRequestUpdate,
  onUpdateError,
  assignmentId,
  status,
  client,
  sendSignatureNotification,
}) => {
  const [updateSigningModalOpen, setSigningRequestModalOpen] = useState(false);
  const [documentViewModalOpen, setDocumentViewModalOpen] = useState(false);
  const [startAssignmentViewModalOpen, setStartAssignmentViewModalOpen] = useState(false);
  const [selectedHistoryEntry, setSelectedHistoryId] = useState<string | undefined>(undefined);

  const useStyles = makeStyles((theme) => ({
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(2),
      width: "45%",
    },
    history: {
      marginTop: 20,
    },
    signatures: {
      marginTop: 20,
    },
  }));

  const classes = useStyles();

  return (
    <ContentSection label="Details der Unterschriftenanforderung">
      <ValueGrid>
        <LabeledValue data-testid="signing-request-title" label="Titel" value={report.title} />
        <LabeledValue data-testid="signing-request-comment" label="Kommentar" value={report.comment} />
      </ValueGrid>
      <UpdateSigningRequestDialog
        open={updateSigningModalOpen}
        onSubmit={async (values) => {
          const res = await onSigningRequestUpdate(values);
          if (res) {
            setSigningRequestModalOpen(!updateSigningModalOpen);
          }
        }}
        onDismiss={() => setSigningRequestModalOpen(!updateSigningModalOpen)}
        error={onUpdateError}
      />
      <StartSignatureRequestDialog
        open={startAssignmentViewModalOpen}
        onDismiss={() => setStartAssignmentViewModalOpen(!startAssignmentViewModalOpen)}
        assignmentId={assignmentId}
        clientSignatureOnly={report.clientSignatureOnly}
        userSignatureCount={report.userSignatureCount}
        clientSigNeeded={report.clientSigNeeded}
        signatureInfo={report.signaturePositionInfo}
      />
      <ViewPDFDocumentDialog
        assignmentId={assignmentId}
        documentHistoryId={selectedHistoryEntry}
        open={documentViewModalOpen}
        onDismiss={() => {
          setSelectedHistoryId(undefined);
          setDocumentViewModalOpen(!documentViewModalOpen);
        }}
      />
      <Button
        className={classes.button}
        data-testid="button-updateDocument"
        startIcon={<ReplayIcon />}
        disabled={status !== "PENDING"}
        color="primary"
        variant="contained"
        onClick={() => setSigningRequestModalOpen(!updateSigningModalOpen)}
      >
        Dokument aktualisieren
      </Button>
      <Button
        className={classes.button}
        data-testid="button-obtainSignature"
        startIcon={<PlayArrowIcon />}
        disabled={status !== "PENDING"}
        color="primary"
        variant="contained"
        onClick={() => setStartAssignmentViewModalOpen(!startAssignmentViewModalOpen)}
      >
        Unterschriften einholen
      </Button>
      <div className={classes.history}>
        <ContentSection label="Dokumentenhistorie">
          <DataTable<any>
            columns={[{ name: "Kommentar" }, { name: "Geändert von" }, { name: "Datum" }, { name: "Dokument" }]}
            rows={report.documentHistory}
            keyExtractor={(data) => data.added}
            renderRow={(data) => {
              return (
                <>
                  <DataTableCell data-testid="history-comment"> {data.comment}</DataTableCell>
                  <DataTableCell data-testid="changed-by">{`${data.changedByUser.firstname}  ${data.changedByUser.lastname}`}</DataTableCell>
                  <DataTableCell data-testid="changed-at"> {format(new Date(data.changedAt), "dd-MM-yyyy", { locale: de })}</DataTableCell>
                  <DataTableCell data-testid="view-doc">
                    <IconButton
                      onClick={() => {
                        setSelectedHistoryId(data.id);
                        setDocumentViewModalOpen(!documentViewModalOpen);
                      }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </DataTableCell>
                </>
              );
            }}
            renderEmpty={() => <div data-testid="noDocumentHistory"> Keine Dokumentenhistorie vorhanden.</div>}
          />
        </ContentSection>
      </div>
      {status !== "PENDING" && (
        <div className={classes.signatures}>
          <ContentSection label="Unterschriften-Spiegel">
            <DataTable<any>
              data-testid={`signature-status`}
              columns={[
                { name: "Personen" },
                { name: "Für Kunden" },
                { name: "Datum" },
                { name: "Benachrichtigung" },
                { name: "Unterschrieben" },
              ]}
              rows={report.signatures}
              keyExtractor={(data) => data.added}
              renderRow={(data) => {
                return (
                  <>
                    <DataTableCell data-testid="changed-by">
                      {data.byUser ? `${data.byUser.firstname} ${data.byUser.lastname}` : "-"}
                    </DataTableCell>
                    <DataTableCell data-testid="view-himself">
                      {!data.client ? "Nein" : `Ja - ${client.firstname} ${client.lastname}`}
                    </DataTableCell>
                    <DataTableCell data-testid="changed-at">
                      {data.date ? format(new Date(data.date), "dd-MM-yyyy", { locale: de }) : "-"}
                    </DataTableCell>
                    <DataTableCell data-testid="notification">
                      {data.byUser && !data.signature && (
                        <IconButton
                          className={classes.button}
                          data-testid="button-caseCreate"
                          onClick={() => sendSignatureNotification(data, assignmentId)}
                        >
                          <MailIcon color={"primary"} />
                        </IconButton>
                      )}
                    </DataTableCell>
                    <DataTableCell>
                      {data.signature ? (
                        <CheckCircleOutlineIcon data-testid="signature-true" style={{ color: "#4caf50" }} />
                      ) : (
                        <RadioButtonUnchecked data-testid="signature-false" style={{ color: "red" }} />
                      )}
                    </DataTableCell>
                  </>
                );
              }}
              renderEmpty={() => <div> Keine Unterschriften ausgewählt.</div>}
            />
            {status === "DONE" && (
              <Alert data-testid="assignment-done" severity="success">
                {"Alle Unterschriften vorhanden."}
              </Alert>
            )}
          </ContentSection>
        </div>
      )}
    </ContentSection>
  );
};
