import User from "../entities/UserEntity";
import UserConverter from "../converter/UserConverter";
import UserApi from "../../data/UserApi";
import CreateUserConverter from "../converter/CreateUserConverter";
import { CreateOrUpdateUserFormValues } from "../../presentation/components/CreateOrUpdateUserForm";

export default class UserService {
  constructor(private userApi: UserApi) {}

  public async get(
    searchQuery: string | undefined,
    group: string | undefined,
    page: number,
    pageSize: number,
    sortedBy: { field: string; order: "asc" | "desc" },
    inBranch?: string
  ) {
    let params: any = {
      email__ire: searchQuery,
      firstname__ire: searchQuery,
      lastname__ire: searchQuery,
      __skip: (page - 1) * pageSize,
      __limit: pageSize,
      __sort: (sortedBy.order === "asc" ? "" : "-") + sortedBy.field,
    };

    if (inBranch && inBranch !== "") {
      params.branch__eq = inBranch;
    }

    switch (group) {
      case "au":
        params.employeeNumber__ire = searchQuery;
        params.groups__in = "employee";
        break;
      case "admin":
        params.groups__in = "admin";
        break;
      case "b2b":
        params.b2bNumber__ire = searchQuery;
        params.groups__in = "b2b";
        break;
      case "b2c":
        params.groups__in = "b2c";
        break;
      case "backoffice":
        params.employeeNumber__ire = searchQuery;
        params.groups__in = "backoffice";
        break;
      case "b2bBackoffice":
        params.employeeNumber__ire = searchQuery;
        params.groups__in = "b2bBackoffice";
        break;
    }

    return this.userApi.get(params).then((userModels) => {
      const { data, ...fields } = userModels;
      return {
        ...fields,
        data: data.map((userModel) => UserConverter.fromModelToEntity(userModel)),
      };
    });
  }

  public async getEmployees(options?: {
    employeeId?: string;
    employeeNumber?: string;
    groups?: string[];
    page?: number;
    pageSize?: number;
    sortedBy?: { field: string; order: "asc" | "desc" };
    inBranch?: string;
  }) {
    const {
      employeeId,
      employeeNumber,
      groups = ["employee", "backoffice"],
      page = 1,
      pageSize = 0,
      sortedBy = { field: "lastname", order: "asc" },
      inBranch,
    } = options || {};

    let params: any = {
      _id: employeeId,
      employeeNumber__ire: employeeNumber,
      groups__in: groups,
      branch__eq: inBranch,
      __skip: (page - 1) * pageSize,
      __limit: pageSize,
      __sort: (sortedBy.order === "asc" ? "" : "-") + sortedBy.field,
    };

    return this.userApi.get(params).then((userModels) => {
      const { data, ...fields } = userModels;
      return {
        ...fields,
        data: data.map((userModel) => UserConverter.fromModelToEntity(userModel)),
      };
    });
  }

  public async getTypeAheadOptions(searchQuery: string, limit?: string, sort?: string, userGroup?: string): Promise<User[]> {
    const params = {
      firstname__ire: `^${searchQuery.split(" ")[0]}`,
      lastname__ire: searchQuery.split(" ")[1],
      groups__in: userGroup,
      __limit: limit,
      __sort: sort,
    };

    return this.userApi.getTypeAheadOptions(params);
  }

  public async getById(id: string): Promise<User> {
    return this.userApi.getById(id).then((userModel) => UserConverter.fromModelToEntity(userModel));
  }

  public async create(values: CreateOrUpdateUserFormValues): Promise<void> {
    return this.userApi.create(CreateUserConverter.fromFormToModel(values));
  }

  public async update(id: string, values: CreateOrUpdateUserFormValues): Promise<void> {
    return this.userApi.update(id, CreateUserConverter.fromFormToModel(values));
  }

  public getUserByNameFullTextSearch(query: string) {
    return this.userApi.getUserByNameFullTextSearch(query);
  }
  public getUsersByPrescriberOrIntermediaryFromCurrentUser() {
    return this.userApi.getUsersByPrescriberOrIntermediaryFromCurrentUser();
  }
}
