import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

interface ReleaseAssignmentDialogProps {
  open: boolean;
  onAgree: () => void;
  onDismiss: () => void;
}

const ReleaseAssignmentDialog: React.FC<ReleaseAssignmentDialogProps> = ({ open, onAgree, onDismiss }) => {
  return (
    <div>
      <Dialog open={open} onClose={onDismiss} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Vorgang Freigeben?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Den aktuellen Vorgang für die Bearbeitung von anderen Kollegen Freigeben?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDismiss} data-testid="button-releaseassignment-dismiss">
            Abbrechen
          </Button>
          <Button onClick={onAgree} data-testid="button-releaseassignment-agree" color="primary" autoFocus>
            Vorgang freigeben
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ReleaseAssignmentDialog;
