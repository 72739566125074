import RecruitmentApi from "../../data/RecruitmentApi";
import B2CRecruitment from "../entities/B2CRecruitment";

class RecruitmentService {
  constructor(private recruitmentApi: RecruitmentApi) {}

  public async getEnduserRecruitmentsByEmployees(year: number, month: number): Promise<B2CRecruitment[]> {
    return this.recruitmentApi.getEnduserRecruitmentsByEmployees(year, month);
  }
}

export default RecruitmentService;
