import React, { useCallback, useState } from "react";
import { useQuery } from "react-query";
import { useService } from "core/di";
import { RouteComponentProps, useParams } from "react-router-dom";
import UIRoute from "core/presentation/components/UIRoute";
import B2BService from "features/b2b/domain/services/B2BService";
import ClientService from "features/clients/domain/services/ClientService";
import ManageAreaForm, { DeleteAreaData } from "../components/ManageAreaForm";
import AddAreaRequestModel from "../../domain/components/AddAreaRequestModel";
import { BadRequestError, UnauthorizedError, UnknownValidationError } from "core/data/errors";

export interface ManageAreaRouteProps extends RouteComponentProps {}

const ManageAreaRoute = (props: ManageAreaRouteProps) => {
  const { id } = useParams<{ id: string }>();
  const b2bService = useService(B2BService);
  const clientService = useService(ClientService);

  const [error, setError] = useState<string | null>(null);

  const { data: careHomeData } = useQuery(["client", id], () => clientService.getById(id));
  const { data: areasData, refetch: areasDataRefetch } = useQuery(
    ["area", careHomeData?.clientNumber],
    () => careHomeData && b2bService.getAreasByB2BClientNumber(careHomeData.clientNumber),
    {
      enabled: careHomeData,
    }
  );

  const onAddAreaSubmit = useCallback(
    (data: AddAreaRequestModel) => {
      return b2bService
        .createAreaInCareHome(data)
        .then(() => {
          setError(null);
          areasDataRefetch();
        })
        .catch((e) => {
          if (e instanceof UnknownValidationError) {
            setError("Ein unbekannter Fehler ist aufgetreten");
          } else if (e instanceof BadRequestError) {
            setError(e.message);
          } else if (e instanceof UnauthorizedError) {
            setError(e.message);
          }
        });
    },
    [areasDataRefetch, b2bService]
  );

  const onDeleteArea = (data: DeleteAreaData) => {
    return b2bService
      .deleteArea(data.areaNumber, data.clientNumber)
      .then(() => {
        areasDataRefetch();
      })
      .catch((e) => {
        if (e instanceof BadRequestError || e instanceof UnauthorizedError) {
          setError(e.message);
        } else {
          setError("Ein unbekannter Fehler ist aufgetreten");
        }
      });
  };

  return (
    <UIRoute {...props} title={careHomeData ? `Wohnbereich Management (${careHomeData.firstname})` : "Wohnbereich Management"}>
      {careHomeData ? (
        <ManageAreaForm
          careHomeData={careHomeData}
          onAddAreaSubmit={onAddAreaSubmit}
          error={error}
          onDeleteArea={onDeleteArea}
          areasData={areasData}
        />
      ) : (
        <div />
      )}
    </UIRoute>
  );
};

export default ManageAreaRoute;
