import React, { useCallback, useEffect, useMemo, useState } from "react";
import { queryCache, useQuery } from "react-query";
import useService from "core/di/useService";
import { RouteComponentProps, useParams } from "react-router-dom";
import UIRoute from "core/presentation/components/UIRoute";
import PoolService from "features/pools/domain/services/PoolService";
import PoolAssignmentDetails from "../components/PoolAssignmentDetails";
import {
  BadRequestError,
  NetworkError,
  NotAcceptableError,
  UnauthorizedError,
  ValidationMessageError,
  WrongMimeTypeError,
} from "core/data/errors";
import PoolUserModel from "features/pools/data/PoolUserModel";
import { useCurrentUserInGroup } from "features/login/presentation/hooks/useUser";
import ClientService from "features/clients/domain/services/ClientService";
import { SelectOption } from "features/pools/domain/entities/PoolEntity";
import UserService from "features/users/domain/services/UserService";
import ClassificationService from "features/classifications/domain/services/ClassificationService";
import AssignmentService, { AssignmentType, TranslatedAssignmentTypeMap } from "features/assignments/domain/services/AssignmentService";
import CaseService from "features/cases/domain/services/CaseService";
import { renderClientLabel, renderUserName } from "features/clients/presentation/utils";
import B2BService from "features/b2b/domain/services/B2BService";
import { UpdateSigingRequestFormProps } from "../components/detail/UpdateSigningRequestDialog";
import MessageService from "features/messages/domain/services/MessageService";
import { Alert, AlertTitle } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";

export interface PoolAssignmentDetailsRouteProps extends RouteComponentProps {}

export default function PoolAssignmentDetailsRoute(props: PoolAssignmentDetailsRouteProps) {
  const { id: assignmentId } = useParams<{ id: string }>();

  const poolService = useService(PoolService);
  const clientService = useService(ClientService);
  const userService = useService(UserService);
  const classificationService = useService(ClassificationService);
  const caseService = useService(CaseService);
  const assignmentService = useService(AssignmentService);
  const b2bService = useService(B2BService);
  const messageService = useService(MessageService);

  const [assignmentClaimed, setAssignmentClaimed] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<string | null>(null);
  const [actionError, setActionError] = useState<string | null>(null);
  const [pool, setPool] = useState<string>("");
  const [caseNumberQuery, setCaseNumberQuery] = useState<string>("");
  const [caseOptionSelected, setCaseOptionSelected] = useState<string>("");
  const [clientQuery, setClientQuery] = useState<string>("");
  const [updateError, setUpdateError] = useState<string | null>(null);

  const [isAdmin, isNotAdmin] = useCurrentUserInGroup("admin");
  const [isB2BBackoffice, isNotB2BBackoffice] = useCurrentUserInGroup("b2bBackoffice");
  const [isBackoffice] = useCurrentUserInGroup("backoffice");

  const { data: assignment, isLoading: assignmentLoading } = useQuery(
    ["/pools/assignments/", assignmentId, "/claim"],
    () => poolService.getAssignment(assignmentId),
    {
      enabled: (isAdmin || assignmentClaimed === true) && assignmentId,
    }
  );

  const historyGoBack = useCallback(() => {
    props.history.goBack();
  }, [props.history]);

  const onFinish = useCallback(
    (assignmentId: string) => {
      poolService
        .finishAssignment(assignmentId)
        .then(() => queryCache.invalidateQueries(["/pools/assignments/", assignmentId]))
        .then(() => props.history.goBack())
        .catch((e) => {
          if (e instanceof ValidationMessageError) {
            switch (e.code) {
              case "ASSIGNMENT_DOES_NOT_EXIST":
                setActionError(`Vorgang mit ID ${assignmentId} existiert nicht`);
                return;
              case "ASSIGNMENT_ALREADY_FINISHED":
                setActionError(`Vorgang mit ID ${assignmentId} wurde bereits abgeschlossen`);
                return;
              default:
                setActionError(`Ein unbekannter Fehler ist aufgetreten. Code: ${e.code || "VMSG400"}`);
                return;
            }
          }
        });
    },
    [poolService, props.history]
  );

  const onAssignCase = useCallback(() => {
    assignmentService
      .assignCaseAndClient(assignmentId, caseNumberQuery)
      .then(() => queryCache.invalidateQueries(["/pools/assignments/", assignmentId]))
      .catch(() => setActionError(`Vorgang ${caseNumberQuery} existiert nicht`));
  }, [assignmentService, assignmentId, caseNumberQuery]);

  const { data: connectedCase } = useQuery(
    ["caseByNumber", assignment?.relatedCaseNumber],
    () => (assignment?.relatedCaseNumber ? caseService.getByNumber(assignment?.relatedCaseNumber) : undefined),
    { enabled: assignment?.relatedCaseNumber }
  );

  const { data: branchOptions } = useQuery(
    ["branchOptions", pool],
    () =>
      poolService.getBranchesExceptAllocatedByPool(pool).then((r) => {
        if (r.length === 0) {
          return undefined;
        }
        return r.map(({ name, code }) => ({
          value: code,
          label: `${name} (${code})`,
        }));
      }),
    { enabled: pool && (isAdmin || isB2BBackoffice || isBackoffice) }
  );

  const { data: userOptions } = useQuery(
    ["userDataEdit", pool],
    () => {
      return poolService.getEmployeesInPool(pool).then((r) => {
        if (r.length === 0) {
          return undefined;
        }
        return r.map(({ employeeNumber, firstname, lastname, _id }: PoolUserModel) => ({
          value: employeeNumber ? employeeNumber : _id,
          label: employeeNumber ? `${firstname} ${lastname} (${employeeNumber})` : `${firstname} ${lastname}`,
        }));
      });
    },
    { enabled: pool }
  );
  const { data: classificationOptionsForBranch } = useQuery(
    ["classificationsForBranchData", pool],
    () => {
      return poolService.getClassificationsForBranchByPool(pool).then((r) => {
        if (r.length === 0) {
          return undefined;
        }
        return r.map(({ name, number }) => ({
          value: number,
          label: `${name} (${number})`,
        }));
      });
    },
    { enabled: pool && isNotB2BBackoffice }
  );
  const { data: allClassificationOptions } = useQuery(["allClassifications"], () =>
    classificationService.get().then((classifications) => {
      return classifications.map((classification) => ({
        value: classification.number,
        label: `${classification.name} (${classification.number} - ${
          TranslatedAssignmentTypeMap[classification.assignmentMapping as AssignmentType] || "Unbekannte Klassifizierung"
        })`,
      }));
    })
  );
  const { data: b2bPoolOptions } = useQuery(
    ["b2bPoolOptions"],
    () => poolService.getAll().then((pools) => pools.map((pool) => ({ value: pool.id, label: pool.name }))),
    { enabled: pool && isB2BBackoffice }
  );
  const { data: b2bPartner } = useQuery("B2BPartnerWithPools", () => b2bService.getB2BPartnerWithPools(), {
    enabled: pool,
  });

  const { data: employeeOptions } = useQuery(["allAvailableEmployeesForAssignment"], () =>
    userService.getEmployees({ groups: ["employee", "backoffice"] }).then((users) =>
      users.data.map((user) => ({
        value: user.employeeNumber || "",
        label: `${renderUserName(user)} (${user.employeeNumber})`,
      }))
    )
  );

  const { data: caseOptions, isLoading: casesLoading } = useQuery(
    ["cases", { caseNumberQuery, pageSize: 10 }],
    () => caseService.get({ caseNumberQuery: caseNumberQuery || undefined, pageSize: 10 }).then(({ data }) => data),
    { enabled: caseNumberQuery && caseNumberQuery.length >= 1 }
  );

  const { data: clientOptions, isLoading: clientOptionsLoading } = useQuery(
    ["ClientSearch", { clientQuery }],
    () => {
      return clientService.get({ fields: { clientNumber: clientQuery }, group: "", page: 1, pageSize: 10 }).then((r) => {
        return r.data.map((client) => {
          return {
            label: renderClientLabel(client),
            value: client.clientNumber,
            number: client.clientNumber,
          };
        });
      });
    },
    { enabled: clientQuery && clientQuery.length > 1 }
  );

  const queriedCaseOptions = useMemo(() => {
    if (caseOptions) {
      return caseOptions.map((kase) => ({
        value: kase.caseNumber,
        label: kase.caseNumber,
      }));
    }
    return [];
  }, [caseOptions]);

  useEffect(() => {
    if (assignment && assignment.poolId) {
      setPool(assignment.poolId);
    }
  }, [assignment]);

  useEffect(() => {
    if (isNotAdmin) {
      poolService
        .claimAssignment(assignmentId)
        .then((claimed) => {
          setLoadingError(null);
          setAssignmentClaimed(claimed);
        })
        .catch((e) => {
          if (e instanceof ValidationMessageError) {
            switch (e.code) {
              case "ASSIGNMENT_DOES_NOT_EXIST":
                setLoadingError(`Vorgang mit ID ${assignmentId} existiert nicht`);
                break;
              case "ASSIGNMENT_ALREADY_CLAIMED":
                setLoadingError(`Vorgang mit ID ${assignmentId} wird bereits bearbeitet`);
                break;
              default:
                setLoadingError("Unbekannter Validierungsfehler");
                break;
            }
          } else {
            setLoadingError("Ein unbekannter Fehler ist aufgetreten");
          }
        });
    }
  }, [assignmentId, poolService, setAssignmentClaimed, isNotAdmin]);

  const caseDetails = useMemo(() => {
    if (caseOptionSelected && caseOptions) {
      const caseOption = caseOptions.find((option) => option.caseNumber === caseOptionSelected);
      if (caseOption) {
        return (
          <div>
            <p style={{ fontWeight: "bold" }}>Vorgangsdetails</p>
            <p>
              Klassifizierung:{" "}
              <span data-testid="connectedcase-classification">{`${
                caseOption.classification ? caseOption.classification.name + " (" + caseOption.classification.number + ")" : "-"
              }`}</span>
            </p>
            <p>
              Kunde: <span data-testid="connectedcase-client">{caseOption.client ? renderClientLabel(caseOption.client) : "-"}</span>
            </p>
            <p>
              Betreff: <span data-testid="connectedcase-subject">{caseOption.subject}</span>
            </p>
          </div>
        );
      }
      return null;
    }
  }, [caseOptionSelected, caseOptions]);

  const poolOptionsWithoutCurrent: SelectOption[] = useMemo(() => {
    if (b2bPoolOptions && pool !== "") {
      return b2bPoolOptions.filter((option) => option.value !== pool);
    }
    return [];
  }, [b2bPoolOptions, pool]);

  const b2bPartnerOptions: SelectOption[] = useMemo(() => {
    if (b2bPartner) {
      return b2bPartner.map((partner) => ({
        value: partner.id,
        label: `${partner.name} (${partner.b2bNumber})`,
        number: partner.b2bNumber,
      }));
    }
    return [];
  }, [b2bPartner]);

  const { data: prescriber } = useQuery([assignment?.assignmentId, "prescriberByAssignment"], () =>
    assignmentService.getPrescriberByAssignment(assignment?.assignmentId)
  );

  const updateSigningRequest = useCallback(
    async (model: UpdateSigingRequestFormProps): Promise<any> => {
      return new Promise((res) => {
        if (assignment) {
          assignmentService
            .updateSigningRequest(assignment.assignmentId, model)
            .then((x) => {
              queryCache.invalidateQueries(["/pools/assignments/", assignmentId]);
              res(x);
            })
            .catch((e: any) => {
              if (e instanceof UnauthorizedError) {
                setUpdateError("Sie haben nicht notwendige Berechtigung diesen Auftrag zu starten.");
              } else if (e instanceof NetworkError) {
                setUpdateError("Netzwerkfehler.");
              } else if (e instanceof WrongMimeTypeError) {
                setUpdateError("Falscher Dateityp.");
              } else if (e instanceof ValidationMessageError) {
                setUpdateError(e.message);
              } else if (e instanceof BadRequestError) {
                setUpdateError(e.message);
              } else if (e instanceof NotAcceptableError) {
                setUpdateError(e.message);
              } else {
                setUpdateError("Ein unerwarteter Fehler ist aufgetreten");
              }
            });
        }
      });
    },
    [assignment, assignmentService, assignmentId]
  );

  const sendSignatureNotification = useCallback(
    async (data: any, assignment: string) => {
      if (data) {
        messageService.sendSigningNotification(data, assignment);
      }
    },
    [messageService]
  );

  return (
    <UIRoute {...props} title="Pool-Auftrag">
      {assignment ? (
        <PoolAssignmentDetails
          b2bPartnerOptions={b2bPartnerOptions}
          b2bPoolOptions={poolOptionsWithoutCurrent}
          assignment={assignment}
          loadingError={loadingError}
          actionError={actionError}
          classificationOptions={classificationOptionsForBranch}
          allClassificationOptions={allClassificationOptions}
          userOptions={userOptions}
          branchOptions={branchOptions}
          historyGoBack={historyGoBack}
          onFinish={onFinish}
          setActionError={setActionError}
          employeeOptionsForCase={employeeOptions}
          connectedCase={connectedCase}
          caseQuery={{
            caseOptions: queriedCaseOptions || [],
            caseOptionsLoading: casesLoading,
            onAssignCase: onAssignCase,
            setCaseNumberQuery: setCaseNumberQuery,
            setCaseOptionSelected: setCaseOptionSelected,
          }}
          selectedCaseDetails={caseDetails}
          clientSelection={{
            clientOptions: clientOptions || [],
            setClientQuery,
            clientOptionsLoading,
          }}
          prescriberInformation={prescriber || undefined}
          onSigningRequestUpdate={updateSigningRequest}
          onUpdateError={updateError}
          sendSignatureNotification={sendSignatureNotification}
        />
      ) : assignmentLoading ? (
        <>
          <Alert data-testid="pool-assignment-loading" severity="info">
            <div style={{ display: "flex" }}>
              <CircularProgress size={20} color="inherit" />
              <AlertTitle style={{ marginLeft: 10 }}>Auftrag wird geladen</AlertTitle>
            </div>
          </Alert>
        </>
      ) : (
        <Alert data-testid="pool-invalid-assignment-error" severity="error">
          {"Ungültiger Auftragstyp."}
        </Alert>
      )}
    </UIRoute>
  );
}
