import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import { makeStyles } from "@material-ui/core";
import { endOfDay, format } from "date-fns";
import SelectOption from "core/interfaces/SelectOption";
import { CreateCaseForPoolAssignmentModel } from "features/pools/data/CreateCaseForPoolAssignmentModel";
import ControlledAutocomplete from "template/presentation/components/form/ControlledAutocomplete";
import ComboBox from "template/presentation/components/form/ComboBox";
import { Alert } from "@material-ui/lab";

type InputCase = Partial<CreateCaseForPoolAssignmentModel>;
type NewCase = CreateCaseForPoolAssignmentModel;

interface ModifyNewCaseDialogProps {
  open: boolean;
  onDismiss: () => void;
  inputCase: InputCase;
  onSubmit: (newCase: NewCase) => void;
  classifications: SelectOption[];
  employees: SelectOption[];
  error?: string;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: "500px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "left",
    "& *": {
      marginBottom: 5,
    },
  },
  warning: {
    marginTop: 10,
  },
  field: {
    marginTop: 5,
  },
}));

const validationSchema = Yup.object().shape({
  clientNumber: Yup.string().required("Benötigt"),
  employeeNumber: Yup.string().required("Benötigt"),
  subject: Yup.string().required("Benötigt"),
  appointmentFrom: Yup.string().optional(),
  appointmentUntil: Yup.string().when("appointmentFrom", {
    is: (date) => date,
    then: Yup.string().required("Benötigt"),
    otherwise: Yup.string().optional(),
  }),
  agreement: Yup.string().required("Benötigt"),
  classificationNumber: Yup.string().required("Benötigt"),
});

const ModifyNewCaseDialog: React.FC<ModifyNewCaseDialogProps> = ({
  open,
  onDismiss,
  inputCase,
  onSubmit,
  classifications,
  employees,
  error,
}) => {
  const classes = useStyles();

  return (
    <Formik<NewCase>
      initialValues={{
        branchNumber: inputCase.branchNumber || "",
        clientNumber: inputCase.clientNumber || "",
        appointmentFrom: inputCase.appointmentFrom || "",
        appointmentUntil: inputCase.appointmentUntil || "",
        agreement: inputCase.agreement || "",
        classificationNumber: inputCase.classificationNumber || "",
        employeeNumber: inputCase.employeeNumber || "",
        subject: inputCase.subject || "",
      }}
      onSubmit={(values) => onSubmit(values)}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ submitForm, isSubmitting, values, handleChange }) => {
        return (
          <Dialog
            open={open}
            onClose={onDismiss}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ paper: classes.paper }}
          >
            <DialogTitle id="alert-dialog-title">Daten des neuen Vorgangs überprüfen und vervollständigen</DialogTitle>
            <DialogContent className={classes.content}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Field
                  data-testid="field-time-from"
                  style={{ flex: "0 0 49.5%", marginRight: "1%" }}
                  className={classes.field}
                  component={TextField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type={"datetime-local"}
                  variant={"outlined"}
                  name={"appointmentFrom"}
                  label={"Kundentermin Von"}
                />
                <Field
                  data-testid="field-time-until"
                  style={{ flex: "0 0 49.5%" }}
                  className={classes.field}
                  component={TextField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type={"datetime-local"}
                  variant={"outlined"}
                  name={"appointmentUntil"}
                  label={"Bis*"}
                  inputProps={{
                    min: values.appointmentFrom && format(new Date(values.appointmentFrom), "yyyy-MM-dd'T'HH:mm"),
                    max: values.appointmentFrom && format(endOfDay(new Date(values.appointmentFrom)), "yyyy-MM-dd'T'HH:mm"),
                  }}
                  disabled={!values.appointmentFrom}
                />
              </div>
              <Field
                data-testid="field-subject"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"subject"}
                label={"Problem/Betreff*"}
              />
              <Field
                data-testid="field-agreement"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"agreement"}
                label={"Vereinbarung*"}
              />
              <ControlledAutocomplete
                data-testid="field-classification"
                label="Klassifizierung wählen"
                name="classificationNumber"
                initialValue={values.classificationNumber}
                options={classifications || []}
                onSelectOption={handleChange("classificationNumber")}
              />
              <Field
                data-testid="field-client"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"clientNumber"}
                label={"Kundennummer*"}
              />
              <Field
                data-testid={"field-user"}
                className={classes.field}
                component={ComboBox}
                options={employees || []}
                getTestIdForOption={(option: any) => option.value}
                variant={"outlined"}
                legend={"Mitarbeiter wählen*"}
                name={"employeeNumber"}
              />
              {error && (
                <Alert className={classes.field} data-testid="case-form-error" severity="error">
                  {error}
                </Alert>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={onDismiss}>Abbrechen</Button>
              <Button onClick={submitForm} variant="outlined" color="primary" data-testid="modify-new-client" disabled={isSubmitting}>
                Vorgang anlegen
              </Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default ModifyNewCaseDialog;
