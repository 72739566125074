import { CurrentPasswordValidationError } from "core/data/errors";
import { useService } from "core/di";
import UIRoute from "core/presentation/components/UIRoute";
import { ChangePasswordModel } from "features/profile/data/ProfileApi";
import ProfileService from "features/profile/domain/ProfileService";
import React, { useCallback, useState } from "react";
import { RouteComponentProps } from "react-router";
import ChangePasswordForm from "../components/ChangePasswordForm";

interface ChangePasswordProps extends RouteComponentProps {}

const ChangePasswordRoute = (props: ChangePasswordProps) => {
  const profileService = useService(ProfileService);
  const [error, setError] = useState<string | null>(null);

  const onSubmit = useCallback(
    (model: ChangePasswordModel, callback?: () => void) => {
      profileService
        .changePassword(model)
        .then(() => {
          setError(null);
          props.history.goBack();
        })
        .catch((e) => {
          if (e instanceof CurrentPasswordValidationError) {
            setError("Das aktuelle Passwort ist nicht richtig.");
            return;
          } else {
            setError("Ein unbekannter Fehler ist aufgetreten");
          }
        })
        .finally(callback);
    },
    [profileService, props.history]
  );

  return (
    <UIRoute {...props} title={"Passwort ändern"}>
      <ChangePasswordForm onSubmit={onSubmit} error={error} />
    </UIRoute>
  );
};

export default ChangePasswordRoute;
