import { Button, makeStyles } from "@material-ui/core";
import { useCurrentUserInGroup } from "features/login/presentation/hooks/useUser";
import { HandOverModel, SelectOption } from "features/pools/domain/entities/PoolEntity";
import { Field, Formik } from "formik";
import React, { useCallback, useState } from "react";
import SelectField from "template/presentation/components/form/SelectField";
import ReleaseAssignmentDialog from "./ReleaseAssignmentDialog";

interface Props {
  assignmentId: string;
  userOptions: SelectOption[] | undefined;
  onHandOver: (values: HandOverModel) => void;
  onRelease: (assignmentId: string) => void;
  canChangeEmployee: boolean;
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  textField: {
    width: "100%",
    textAlign: "center",
    fontSize: theme.typography.pxToRem(15),
  },
}));

export const HandOverAssignmentSection: React.FC<Props> = ({ assignmentId, userOptions, canChangeEmployee, onHandOver, onRelease }) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isAdmin] = useCurrentUserInGroup("admin");

  const allowChangeEmployee = (isAdmin: boolean, canChangeEmployee: boolean, userOptions: SelectOption[] | undefined): boolean => {
    if (!userOptions) {
      return false;
    } else if (isAdmin) {
      return true;
    } else if (canChangeEmployee) {
      return true;
    }
    return false;
  };

  const handleRelease = useCallback(() => {
    setDialogOpen(true);
  }, [setDialogOpen]);

  const onDismiss = useCallback(() => {
    setDialogOpen(false);
  }, [setDialogOpen]);

  const onAgree = useCallback(() => {
    onRelease(assignmentId);
    setDialogOpen(false);
  }, [setDialogOpen, onRelease, assignmentId]);

  return (
    <div style={{ marginTop: "15px" }}>
      {allowChangeEmployee(isAdmin, canChangeEmployee, userOptions) ? (
        <Formik
          initialValues={{ assignmentId, employeeNumber: "" }}
          onSubmit={async (values: HandOverModel) => {
            await onHandOver(values);
          }}
        >
          {({ submitForm }) => {
            return (
              <div className={classes.container}>
                <Field
                  data-testid="field-handoverassignment"
                  name="employeeNumber"
                  legend="Kollegen auswählen"
                  component={SelectField}
                  options={userOptions}
                />
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  data-testid="button-handoverassignment_colleagues"
                  onClick={submitForm}
                >
                  Vorgang an bestimmten Kollegen abgeben
                </Button>
              </div>
            );
          }}
        </Formik>
      ) : (
        <div data-testid="no-handover" />
      )}
      {allowChangeEmployee(isAdmin, canChangeEmployee, userOptions) && <p className={classes.textField}>oder</p>}
      <Button
        className={classes.button}
        color="primary"
        variant="contained"
        data-testid="button-releaseassignment"
        onClick={() => {
          handleRelease();
        }}
      >
        Vorgang für alle freigeben
      </Button>
      <ReleaseAssignmentDialog open={dialogOpen} onDismiss={onDismiss} onAgree={onAgree} />
    </div>
  );
};
