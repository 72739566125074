import { IconButton, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { TextField } from "formik-material-ui";
import React, { useState } from "react";

interface PasswordFieldProps {
  field: any;
  form: any;
  meta: any;
  label: string;
}

const PasswordField: React.FC<PasswordFieldProps> = ({ label, ...props }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <TextField
      variant="outlined"
      type={showPassword ? "text" : "password"}
      label={label}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              data-testid="button-togglepw"
              onClick={() => setShowPassword((s) => !s)}
              onMouseDown={() => setShowPassword((s) => !s)}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default PasswordField;
