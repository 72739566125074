import React from "react";
import SubTabHeader from "template/presentation/components/nav/SubTabHeader";

interface Props {
  path: string;
  onTabChange: (path: string) => void;
}

const CasesSubHeader: React.FC<Props> = ({ path, onTabChange }) => (
  <SubTabHeader
    currentPath={path}
    onTabChange={onTabChange}
    tabs={[
      { value: "/cases", label: "Alle" },
      { value: "/cases/active", label: "Aktiv" },
      { value: "/cases/inactive", label: "Abgeschlossen/Archiviert" },
    ]}
  />
);

export default CasesSubHeader;
