import ShopApi from "features/shop/data/ShopApi";

export default class ShopService {
  constructor(private shopApi: ShopApi) {}

  public async getShopEnabled(): Promise<string> {
    return await this.shopApi.getShopEnabled();
  }

  public async getShopUrl(): Promise<string | null> {
    return await this.shopApi.getShopUrl();
  }
}
