import React, { useCallback, useState } from "react";
import { queryCache } from "react-query";
import useService from "core/di/useService";
import { RouteComponentProps } from "react-router-dom";
import UIRoute from "core/presentation/components/UIRoute";
import ProductService from "features/products/domain/services/ProductService";
import ImportProductsForm, { ImportProductsFormValues } from "../components/ImportProductsForm";

export interface EditAssignmentRouteProps extends RouteComponentProps {}

const ImportProductsRoute = (props: EditAssignmentRouteProps) => {
  const productService = useService(ProductService);
  const [error, setError] = useState<string | null>(null);

  const onSubmit = useCallback(
    (values: ImportProductsFormValues) => {
      return productService
        .import(values)
        .then(() => {
          setError(null);
          return queryCache.invalidateQueries("productList");
        })
        .then(() => {
          props.history.goBack();
        })
        .catch((e: any) => {
          setError("Ein unbekannter Fehler ist aufgetreten");
        });
    },
    [productService, props.history]
  );

  return (
    <UIRoute {...props} title={"Produktliste importieren"}>
      <ImportProductsForm error={error} onSubmit={onSubmit} />
    </UIRoute>
  );
};

export default ImportProductsRoute;
