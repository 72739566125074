import { RehaItem } from "../entities/RehaItem";
import RehaItemApi from "./data/RehaItemApi";

class RehaItemService {
  constructor(private rehaItemApi: RehaItemApi) {}

  public async getByClientNumber(clientNumber: string): Promise<RehaItem[] | undefined> {
    return this.rehaItemApi.getByClientNumber(clientNumber);
  }
}

export default RehaItemService;
