import React, { useState } from "react";
import ContentSection from "template/presentation/components/content/ContentSection";
import ValueGrid from "template/presentation/components/content/ValueGrid";
import LabeledValue from "template/presentation/components/content/LabeledValue";
import { Button, makeStyles } from "@material-ui/core";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import Client, { NewClient } from "features/clients/domain/entities/Client";
import format from "date-fns/format";
import { PoolAssignmentStatusTypes } from "features/pools/domain/entities/PoolAssignment";
import useBooleanStateToggle from "core/presentation/hooks/useBooleanStateToggle";
import ModifyNewClientDialog from "./ModifyNewClientDialog";
import SelectOption from "core/interfaces/SelectOption";
import ComboBox from "template/presentation/components/form/ComboBox";
import { Field, Formik } from "formik";

interface Props {
  client?: Client | NewClient;
  isNewClient: boolean;
  assignmentStatus: PoolAssignmentStatusTypes;
  onCreateNewClient: () => Promise<any>;
  onModifyNewClient: (newClient: Partial<NewClient>) => Promise<void>;
  clientSelection: {
    clientOptions: SelectOption[];
    setClientQuery: (value: string) => void;
    clientOptionsLoading: boolean;
  };
  onConnectToExistingClient: (clientNumber: string) => void;
  internalPool: boolean;
  assignmentType: string;
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  textField: {
    width: "100%",
    textAlign: "center",
    fontSize: theme.typography.pxToRem(15),
  },
}));

export const ClientInfoSection: React.FC<Props> = ({
  client,
  isNewClient,
  assignmentStatus,
  onCreateNewClient,
  onModifyNewClient,
  clientSelection,
  onConnectToExistingClient,
  assignmentType,
}) => {
  const { clientOptions, setClientQuery } = clientSelection;

  const classes = useStyles();
  const [newClientIsBeingCreated, setNewClientIsBeingCreated] = useState<boolean>(false);
  const [modifyNewClientDialogOpen, openModifyNewClientDialog, closeModifyNewClientDialog] = useBooleanStateToggle();

  return (
    (client && (
      <ContentSection
        label={assignmentType === "SIGNATURE_REQUEST" ? "Kundeninformationen" : `Kundendetails${isNewClient ? " (Neuer Kunde)" : ""}`}
      >
        <ValueGrid>
          {/* @ts-ignore */}
          {client.clientNumber && (
            <LabeledValue
              data-testid="clientNumber"
              label={"Kundennummer"}
              /* @ts-ignore */
              value={client.clientNumber}
            />
          )}
          <LabeledValue data-testid="lastname" label="Nachname" value={client.lastname} />
          <LabeledValue data-testid="firstname" label="Vorname" value={client.firstname} />
          {client.street && client.zipcode && client.city && (
            <LabeledValue data-testid="address" label="Adresse" value={client.street + ", " + client.zipcode + " " + client.city} />
          )}
          {client.birthdate && (
            <LabeledValue data-testid="birthdayDate" label="Geburtstag" value={format(new Date(client.birthdate), "dd.MM.yyyy")} />
          )}
          {client.email && <LabeledValue data-testid="email" label="Email" value={client.email} />}
          {client.phone && <LabeledValue data-testid="phoneNumber" label="Telefon" value={client.phone} />}
        </ValueGrid>
        {isNewClient && assignmentType !== "SIGNATURE_REQUEST" && (
          <div style={{ maxWidth: "35%" }}>
            <div className={classes.container}>
              <Formik<{ clientNumber: string }>
                initialValues={{ clientNumber: "" }}
                onSubmit={({ clientNumber }) => onConnectToExistingClient(clientNumber)}
              >
                {({ submitForm }) => {
                  return (
                    <>
                      <Field
                        data-testid={"field-user"}
                        component={ComboBox}
                        options={clientOptions || []}
                        getTestIdForOption={(option: any) => option.number}
                        variant={"outlined"}
                        legend="Kundennummer"
                        name="clientNumber"
                        onSelectOption={setClientQuery ? (value: string) => setClientQuery(value) : undefined}
                        onChangeText={setClientQuery ? (value: string) => setClientQuery(value) : undefined}
                      />
                      <Button
                        data-testid="button-connectwithClient"
                        startIcon={<SyncAltIcon />}
                        disabled={assignmentStatus === "DONE" || newClientIsBeingCreated}
                        color="primary"
                        variant="contained"
                        onClick={submitForm}
                      >
                        Mit Kunden verknüpfen
                      </Button>
                    </>
                  );
                }}
              </Formik>
            </div>
            <p className={classes.textField}>oder</p>
            <Button
              style={{ width: "100%" }}
              data-testid="button-newClient"
              startIcon={<SyncAltIcon />}
              disabled={assignmentStatus === "DONE" || newClientIsBeingCreated}
              color="primary"
              variant="contained"
              onClick={() => openModifyNewClientDialog()}
            >
              Neuen Kunden erstellen
            </Button>
            <ModifyNewClientDialog
              open={modifyNewClientDialogOpen}
              newClient={client as NewClient}
              onDismiss={closeModifyNewClientDialog}
              onSubmit={async (newClient) => {
                try {
                  await onModifyNewClient(newClient);
                  closeModifyNewClientDialog();
                  setNewClientIsBeingCreated(true);
                  await onCreateNewClient();
                } catch (err) {
                  setNewClientIsBeingCreated(false);
                  throw err;
                } finally {
                  setNewClientIsBeingCreated(false);
                }
              }}
            />
          </div>
        )}
      </ContentSection>
    )) ||
    null
  );
};
