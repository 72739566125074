import React from "react";
import useService from "core/di/useService";
import { useParams } from "react-router-dom";
import SignatureformsService from "../../domain/services/SignatureformsService";
import { useQuery } from "react-query";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import FormContainer from "template/presentation/components/form/FormContainer";
import FormSection from "template/presentation/components/form/FormSection";
import { SignatureAssignmentFormData, SignatureAssignmentRequest } from "features/signatureforms/domain/entities/Signatureform";
import { TextField } from "formik-material-ui";
import { makeStyles } from "@material-ui/core";
import LabeledValue from "template/presentation/components/content/LabeledValue";
import FormSubmitButton from "template/presentation/components/form/FormSubmitButton";
import { Alert } from "@material-ui/lab";
import ContentSection from "template/presentation/components/content/ContentSection";

interface CreateSignatureAssignmentProps {
  onSubmit: (signatureAssignment: SignatureAssignmentRequest) => void;
  error: string | null;
}

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(1),
  },
}));

const importDocumentSchema = Yup.object().shape({
  comment: Yup.string().optional(),
  firstname: Yup.string().required("Benötigt"),
  lastname: Yup.string().required("Benötigt"),
});

const CreateSignatureAssignmentForm: React.FC<CreateSignatureAssignmentProps> = (props) => {
  const { onSubmit, error } = props;

  const signatureformsService = useService(SignatureformsService);
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();

  const { data: signatureform } = useQuery(["signatureform", id], () => signatureformsService.getById(id));
  const { data: path } = useQuery(["signatureformDocument", id], () => signatureformsService.getDocument(id));

  const initValues = { comment: "", firstname: "", lastname: "" };

  return (
    <Formik<SignatureAssignmentFormData>
      initialValues={initValues}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          if (signatureform && path) {
            await onSubmit({ id: signatureform.id, comment: values.comment, firstname: values.firstname, lastname: values.lastname });
          }
        } finally {
          setSubmitting(false);
        }
      }}
      validationSchema={importDocumentSchema}
    >
      {({ submitForm, isSubmitting, values, errors }) => {
        return (
          <FormContainer>
            <FormSection label={"Dokumentbezeichnung"}>
              {signatureform && path && <LabeledValue data-testid="title" label="" value={signatureform.title} />}
            </FormSection>
            <FormSection label={"Kundendaten"}>
              <Field
                data-testid="signatureforms-firstname"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"firstname"}
                label={"Vorname"}
              />
              <Field
                data-testid="signatureforms-lastname"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"lastname"}
                label={"Nachname"}
              />
            </FormSection>
            <FormSection label={"Kommentar"}>
              <Field
                data-testid="signatureforms-title"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"comment"}
                label={"Kommentar"}
              />
              {error && (
                <Alert className={classes.field} data-testid="signature-assignment-form-error" severity="error">
                  {error}
                </Alert>
              )}
              <FormSubmitButton data-testid="button-createsignatureassignmentform" disabled={isSubmitting} onClick={submitForm}>
                {"Auftrag erstellen"}
              </FormSubmitButton>
            </FormSection>

            {signatureform && path && (
              <ContentSection label={"Dokumentvorschau"}>
                <iframe
                  title={signatureform.title}
                  key={signatureform.title}
                  src={path}
                  id="pdf"
                  width="900px"
                  height="1200px"
                  style={{ overflow: "auto" }}
                />
              </ContentSection>
            )}
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default CreateSignatureAssignmentForm;
