import React, { useCallback, useState } from "react";
import { queryCache, useQuery } from "react-query";
import useService from "core/di/useService";
import { RouteComponentProps, useParams } from "react-router-dom";
import UIRoute from "core/presentation/components/UIRoute";
import ClientService from "../../domain/services/ClientService";
import CreateOrUpdateClientForm, { CreateOrUpdateClientFormValues } from "../components/CreateOrUpdateClientForm";
import ClientConverter from "../../domain/converter/ClientConverter";
import B2BSpace from "../../domain/services/B2BSpace";
import { ValidationMessageError } from "core/data/errors";
import AreaResponseModel from "../../../b2b/domain/components/AreaResponseModel";
import B2BService from "../../../b2b/domain/services/B2BService";
import { AreasForCareHomeOptions } from "../../domain/entities/Client";
import BranchService from "features/branches/domain/services/BranchService";
import UserService from "features/users/domain/services/UserService";
import SelectOption from "core/interfaces/SelectOption";
import User from "features/users/domain/entities/UserEntity";
import { renderUserB2BClient, renderUserName } from "../utils";

export interface EditClientRouteProps extends RouteComponentProps {}

const EditClientRoute = (props: EditClientRouteProps) => {
  const { id, group } = useParams<{ id: string; group: string }>();
  const clientService = useService(ClientService);
  const b2bService = useService(B2BService);
  const branchService = useService(BranchService);
  const userService = useService(UserService);

  const [error, setError] = useState<string | null>(null);
  const [areasForCareHome, setAreasForCareHome] = useState<AreasForCareHomeOptions[] | []>([]);
  const [searchedForCareHome, setSearchedForCareHome] = useState<boolean>(false);

  const { data: client } = useQuery(["client", id], () => clientService.getById(id));
  const { data: b2bUserOptions } = useQuery(["b2bUserOptions"], () =>
    userService.get(undefined, "b2b", 1, 1000, { field: "firstname", order: "asc" })
  );
  const { data: prescriberOptions } = useQuery("prescriberOptions", () => clientService.getPrescriberOptions());
  const { data: careHomeOptions } = useQuery("careHomeOptions", () => clientService.getIntermediaryOptions());
  const { data: internalBranchOptions } = useQuery("internalBranchOptions", () => branchService.getBranchOptions());

  const b2bClientNumber = careHomeOptions && careHomeOptions.find((c) => client && c.value === client.memberOfCareHome)?.b2bNumber;
  const { data: initialAreasForCareHomeData } = useQuery(
    ["initialAreasForCareHomeData", { b2bClientNumber }],
    (key, { b2bClientNumber }) => {
      return (
        b2bClientNumber &&
        b2bService.getAreasByClientNumber(b2bClientNumber).then((r) => {
          setSearchedForCareHome(false);
          return r.map(({ id, areaNumber, name }: AreaResponseModel) => ({
            value: id,
            areaNumber,
            label: `${name} (${areaNumber})`,
          }));
        })
      );
    }
  );

  const onSubmit = useCallback(
    (values: CreateOrUpdateClientFormValues) => {
      const clientRequest = ClientConverter.fromClientFormToRequestModel(values, group);
      return clientService
        .update(id, clientRequest)
        .then(() => {
          setError(null);
          return queryCache.invalidateQueries("clientList");
        })
        .then(() => {
          return queryCache.invalidateQueries(["client", id]);
        })
        .then(() => {
          return queryCache.invalidateQueries("careHomeOptions");
        })
        .then(() => {
          props.history.goBack();
        })
        .catch((e) => {
          if (e instanceof ValidationMessageError) {
            switch (e.code) {
              case "DUPLICATE_CLIENT_NUMBER":
                setError("Die Kundennummer ist bereits vergeben");
                return;
              default:
                setError(`Ein unbekannter Fehler ist aufgetreten. Code: ${e.code}`);
                return;
            }
          } else {
            setError("Ein unbekannter Fehler ist aufgetreten");
          }
        });
    },
    [group, clientService, id, props.history]
  );

  const onGetAreasOfCareHome = useCallback(
    (clientNumber: string) => {
      return b2bService.getAreasByClientNumber(clientNumber).then((r) => {
        const areas = r.map(({ id, areaNumber, name }: AreaResponseModel) => ({ value: id, areaNumber, label: `${name} (${areaNumber})` }));
        setAreasForCareHome(areas);
        setSearchedForCareHome(true);
      });
    },
    [b2bService]
  );

  const convertB2BUserToSelection = useCallback(
    async (data: User[]): Promise<SelectOption[]> => {
      const hierarchy: string[] = [];

      if (!client) {
        return [];
      }

      if (client.enterprise) {
        if (client.enterprise?.role === "root") {
          //eslint-disable-next-line
          client.enterprise.children?.forEach((x) => {
            hierarchy.push(x.id);
          });
          hierarchy.push(client.id);
        } else if (client.enterprise.root) {
          hierarchy.push(client.enterprise.root.id);
          const root_client = await clientService.getById(client.enterprise.root!.id);
          if (root_client) {
            //eslint-disable-next-line
            root_client.enterprise?.children?.forEach((x) => {
              return hierarchy.push(x.id);
            });
          }
        }
      }

      return data.map((x) => {
        return { label: `${renderUserName(x)}  (${renderUserB2BClient(x)}) `, value: x.id };
      });
    },
    [client, clientService]
  );

  const { data: b2bUser } = useQuery(["b2bUserFiltered", client?.id], () => convertB2BUserToSelection(b2bUserOptions!.data), {
    enabled: !!b2bUserOptions,
  });

  return (
    <UIRoute {...props} title={group === B2BSpace.INTERMEDIARY ? "Pflegeheim bearbeiten" : "Kunden bearbeiten"}>
      {client && careHomeOptions && b2bUser ? (
        <CreateOrUpdateClientForm
          error={error}
          onSubmit={onSubmit}
          client={client}
          group={group}
          careHomeOptions={careHomeOptions}
          getAreasForCareHome={onGetAreasOfCareHome}
          areasForCareHome={areasForCareHome.length === 0 && !searchedForCareHome ? initialAreasForCareHomeData : areasForCareHome}
          internalBranchOptions={internalBranchOptions}
          prescriberOptions={prescriberOptions}
          b2bUserOptions={b2bUser}
        />
      ) : (
        <div />
      )}
    </UIRoute>
  );
};

export default EditClientRoute;
