import React, { useMemo } from "react";
import useService from "core/di/useService";
import { RouteComponentProps, useParams } from "react-router-dom";
import UIRoute from "core/presentation/components/UIRoute";
import { useQuery } from "react-query";
import RehaItemService from "features/rehaItems/domain/services/RehaItemService";
import RehaItemsTable from "../components/RehItemsTable";
import ClientService from "features/clients/domain/services/ClientService";

export interface Props extends RouteComponentProps {}

const RehaItemsRoute = (props: Props) => {
  const { id: clientNumber } = useParams<{ id: string }>();
  const rehaItemService = useService(RehaItemService);
  const { data: rehaItems, isLoading } = useQuery(["rehaItems", clientNumber], () => rehaItemService.getByClientNumber(clientNumber));

  const clientService = useService(ClientService);
  const { data: clients } = useQuery(["client", clientNumber], () =>
    clientService.get({ fields: { clientNumber: clientNumber }, group: "client", page: 1, pageSize: 1 })
  );

  const relatedClient = useMemo(() => {
    return clients?.data.find((c) => c.clientNumber === clientNumber);
  }, [clients, clientNumber]);

  return relatedClient ? (
    <UIRoute {...props} title={`Rehamittel für ${relatedClient.firstname} ${relatedClient.lastname} (${relatedClient.clientNumber})`}>
      <RehaItemsTable rehaItems={rehaItems} isLoading={isLoading} />
    </UIRoute>
  ) : (
    <div></div>
  );
};

export default RehaItemsRoute;
