import { PoolAssignmentReportVideoConsulting } from "features/pools/domain/entities/PoolAssignment";
import { RehaItem } from "features/rehaItems/domain/entities/RehaItem";
import React, { useMemo } from "react";
import ContentContainer from "template/presentation/components/content/ContentContainer";
import ContentSection from "template/presentation/components/content/ContentSection";
import LabeledValue from "template/presentation/components/content/LabeledValue";
import ValueGrid from "template/presentation/components/content/ValueGrid";
import { RehaItemsPoolView } from "../RehaItemsPoolView";
import { AnnotationSubSection } from "./AnnotationSubSection";

interface Props {
  report: PoolAssignmentReportVideoConsulting;
  rehaItems?: RehaItem[];
}

export const VideoConsultingInfoSection: React.FC<Props> = ({ report, rehaItems }) => {
  const status = useMemo(() => {
    switch (report.result) {
      case "delivered":
        return "erfolgreich durchgeführt";
      case "notEncountered":
        return "Niemanden angetroffen";
      case "unfeasible":
        return "Nicht durchführbar";
      default:
        return "Unbekannter Status";
    }
  }, [report.result]);

  return (
    <ContentSection label="Details">
      <ContentContainer>{report.annotation && <AnnotationSubSection annotation={report.annotation} />}</ContentContainer>
      <ValueGrid>
        <LabeledValue data-testid="status" label="Status" value={status} />
      </ValueGrid>
      <RehaItemsPoolView rehaItems={rehaItems} />
    </ContentSection>
  );
};
