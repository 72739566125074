import { PagedResponse } from "core/utils";
import CaseConverter from "features/cases/converters/CaseConverter";
import CaseApi from "features/cases/data/CaseApi";
import { CreateOrUpdateCaseFormValues } from "features/cases/presentation/components/CreateOrUpdateCaseForm";
import Case from "../entities/Case";

export default class CaseService {
  constructor(private caseApi: CaseApi) {}

  public async get(query?: {
    caseNumberQuery?: string;
    clientIdQuery?: string;
    firstname?: string;
    lastname?: string;
    page?: number;
    pageSize?: number;
    sortedBy?: { field: string; order: "asc" | "desc" };
    closed?: boolean;
  }): Promise<PagedResponse<Case>> {
    const {
      caseNumberQuery,
      clientIdQuery,
      firstname,
      lastname,
      page = 1,
      pageSize = 0,
      sortedBy = { field: "caseNumber", order: "asc" },
      closed,
    } = query || {};

    const params = {
      caseNumber__ire: caseNumberQuery,
      client: clientIdQuery,
      closed__eq: closed,
      firstname__ire: firstname ? `^${firstname}` : undefined,
      lastname__ire: lastname ? `^${lastname}` : undefined,
      __skip: (page - 1) * pageSize,
      __limit: pageSize,
      __sort: (sortedBy.order === "asc" ? "" : "-") + sortedBy.field,
    };

    return this.caseApi.get(params).then((caseModelPaged) => ({
      ...caseModelPaged,
      data: caseModelPaged.data.map((kase) => CaseConverter.fromResponseModelToEntity(kase)),
    }));
  }

  public async getTypeAheadOptions(searchQuery: string): Promise<Case[]> {
    const params = {
      caseNumber__ire: searchQuery,
    };

    return this.caseApi.getTypeAheadOptions(params);
  }

  public async create(model: CreateOrUpdateCaseFormValues) {
    return this.caseApi.create(model);
  }

  public async getByNumber(caseNumber: string) {
    const kase = await this.caseApi.getByNumber(caseNumber);
    return CaseConverter.fromResponseModelToEntity(kase);
  }

  public async update(id: string, caseFormInput: CreateOrUpdateCaseFormValues, genAssignment: boolean) {
    return this.caseApi.update(id, CaseConverter.fromCreateOrUpdateFormToModel(caseFormInput), genAssignment);
  }
}
