import React, { useCallback, useMemo } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { endOfDay, format, isSameDay, isWithinInterval } from "date-fns";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import { makeStyles } from "@material-ui/core";
import { PoolAssignmentAppointment } from "features/pools/domain/entities/PoolAssignment";
import { mergeDateAndTime } from "core/utils";
import { renderWantedAppointmentTimeText } from "../../helpers/renderWantedAppointmentTimeText";
import ComboBox from "template/presentation/components/form/ComboBox";
import SelectOption from "core/interfaces/SelectOption";
import { useCurrentUser } from "features/login/presentation/hooks/useUser";

interface AppointmentReservationForm {
  appointmentFrom: Date;
  appointmentUntil: Date;
  employee: string;
  b2bEmployeeNumber?: string;
}

interface NegotiateAppointmentDialogProps {
  wantedAppointment: PoolAssignmentAppointment;
  open: boolean;
  employees: SelectOption[];
  onReserveInWantedTimeRange: (appointment: AppointmentReservationForm) => void;
  onReserveInUnwantedTimeRange: (appointment: AppointmentReservationForm) => void;
  onDismiss: () => void;
  needsConfirmationIfNotInWantedTimeRange: boolean;
  isB2B?: boolean;
  onEmployeeQuery?: (value: string) => void;
}

interface FormProps {
  appointmentFrom: string;
  appointmentUntil: string;
  employee: string;
  b2bEmployeeNumber?: string;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: "500px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "left",
  },
  warning: {
    marginTop: 10,
  },
  field: {
    marginTop: 10,
  },
}));

const NegotiateAppointmentDialog: React.FC<NegotiateAppointmentDialogProps> = ({
  open,
  wantedAppointment,
  employees,
  onReserveInWantedTimeRange,
  onReserveInUnwantedTimeRange,
  onDismiss,
  needsConfirmationIfNotInWantedTimeRange,
  isB2B,
}) => {
  const classes = useStyles();
  const wantedAppointmentAsText = useMemo(() => renderWantedAppointmentTimeText(wantedAppointment), [wantedAppointment]);

  const validationSchema = useMemo(() => {
    if (isB2B) {
      return Yup.object().shape({
        appointmentFrom: Yup.string().required("Sie müssen ein Datum mit Uhrzeit auswählen"),
        appointmentUntil: Yup.string().required("Sie müssen ein Datum mit Uhrzeit auswählen"),
      });
    }
    return Yup.object().shape({
      appointmentFrom: Yup.string().required("Sie müssen ein Datum mit Uhrzeit auswählen"),
      appointmentUntil: Yup.string().required("Sie müssen ein Datum mit Uhrzeit auswählen"),
      employee: Yup.string()
        .required("Sie müssen einen Außendienstmitarbeiter auswählen")
        .min(1, "Sie müssen einen Außendienstmitarbeiter auswählen"),
    });
  }, [isB2B]);

  const isAppointmentInWantedTimeRange = useCallback(
    (appointment: Date): boolean => {
      const start = mergeDateAndTime(wantedAppointment.date, wantedAppointment.timeFrom);
      const end = mergeDateAndTime(wantedAppointment.date, wantedAppointment.timeTo);

      if (wantedAppointment.availableOnAnyDate) {
        if (wantedAppointment.availableAnyTime) {
          return true;
        }
      }
      if (wantedAppointment.availableAnyTime) {
        return isSameDay(appointment, wantedAppointment.date);
      }
      return isWithinInterval(appointment, { start, end });
    },
    [wantedAppointment]
  );
  const { data: currentUser } = useCurrentUser();

  return (
    <Formik<FormProps>
      initialValues={{
        appointmentFrom: "",
        appointmentUntil: "",
        employee: "",
        b2bEmployeeNumber: isB2B ? currentUser?.id : undefined,
      }}
      onSubmit={async (values) => {
        if (values.appointmentFrom === undefined) {
          return;
        }

        const appointmentFrom = new Date(values.appointmentFrom);
        const appointmentUntil = new Date(values.appointmentUntil);
        if (isAppointmentInWantedTimeRange(appointmentFrom) && isAppointmentInWantedTimeRange(appointmentUntil)) {
          await onReserveInWantedTimeRange({
            appointmentFrom,
            appointmentUntil,
            employee: values.employee,
            b2bEmployeeNumber: values.b2bEmployeeNumber,
          });
        } else {
          await onReserveInUnwantedTimeRange({
            appointmentFrom,
            appointmentUntil,
            employee: values.employee,
            b2bEmployeeNumber: values.b2bEmployeeNumber,
          });
        }
      }}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting, values }) => {
        return (
          <Dialog
            open={open}
            onClose={onDismiss}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ paper: classes.paper }}
          >
            <DialogTitle id="alert-dialog-title">Terminwahl</DialogTitle>
            <DialogContent className={classes.content}>
              <p>Terminwunsch: {wantedAppointmentAsText}</p>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Field
                  style={{ flex: "0 0 49.5%", marginRight: "1%" }}
                  id="datetime-local"
                  label="Von"
                  component={TextField}
                  type="datetime-local"
                  name="appointmentFrom"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ min: format(new Date(), "yyyy-MM-dd'T'hh:mm") }}
                  variant="outlined"
                  data-testid="field-appointment-from"
                />
                <Field
                  style={{ flex: "0 0 49.5%" }}
                  id="datetime-local"
                  label="Bis"
                  component={TextField}
                  type="datetime-local"
                  name="appointmentUntil"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: format(values.appointmentFrom ? new Date(values.appointmentFrom) : new Date(), "yyyy-MM-dd'T'hh:mm"),
                    max: format(
                      values.appointmentFrom ? endOfDay(new Date(values.appointmentFrom)) : endOfDay(new Date()),
                      "yyyy-MM-dd'T'hh:mm"
                    ),
                  }}
                  variant="outlined"
                  data-testid="field-appointment-until"
                  disabled={!values.appointmentFrom}
                />
              </div>
              {values.appointmentFrom &&
                values.appointmentUntil &&
                (!isAppointmentInWantedTimeRange(new Date(values.appointmentFrom)) ||
                  !isAppointmentInWantedTimeRange(new Date(values.appointmentUntil))) && (
                  <p className={classes.warning}>
                    <strong>Der gewählte Termin liegt nicht im gewünschten Terminzeitraum des Kunden.</strong>{" "}
                    {needsConfirmationIfNotInWantedTimeRange
                      ? "Wenn Sie diesen Termin reservieren, muss der Kunde diesen zusätzlich akzeptieren."
                      : ""}
                  </p>
                )}
              {!isB2B && (
                <Field
                  data-testid={"field-user"}
                  className={classes.field}
                  component={ComboBox}
                  options={employees || []}
                  getTestIdForOption={(option: any) => option.number}
                  variant={"outlined"}
                  legend={"Mitarbeiter wählen*"}
                  name={"employee"}
                />
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={onDismiss}>Abbrechen</Button>
              <Button onClick={submitForm} variant="outlined" color="primary" data-testid="reserve-appointment" disabled={isSubmitting}>
                Reservieren
              </Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default NegotiateAppointmentDialog;
