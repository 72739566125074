import { Chip, FormControl, FormHelperText, TextField, Typography } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import React, { useState } from "react";
import { FixedSizeList, ListChildComponentProps } from "react-window";

//region Virtualized List
function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + 8,
    },
  });
}

const OuterElementContext = React.createContext({});
const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          itemData={itemData}
          height={Math.min(8, itemData.length) * 36 + 16}
          width="100%"
          outerElementType={OuterElementType}
          itemSize={36}
          overscanCount={5}
          itemCount={itemData.length}
        >
          {renderRow}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

//endregion

interface MultiSelectFieldProps {
  field: any;
  form: any;
  legend: string;
  options: { value: string; label: string }[];
  "data-testid"?: string;
  getTestIdForOption?: (option: any) => string;
  onChangeText?: (x: string) => void;
  onClick?: () => void;
  single?: boolean;
  isServerSideFilter?: boolean;
  loading?: boolean;
  maxDisplayedItems?: number;
}

const MultiSelectField: React.FC<MultiSelectFieldProps> = ({
  field,
  form,
  legend,
  options,
  getTestIdForOption,
  onChangeText,
  onClick,
  isServerSideFilter,
  loading,
  maxDisplayedItems = 75,
  single,
  ...props
}) => {
  const testId = props["data-testid"];

  return (
    <FormControl error={form.touched[field.name] && !!form.errors[field.name]}>
      <Autocomplete
        data-testid={testId}
        onBlur={() => onChangeText?.("")}
        onInputChange={(e, value) => onChangeText?.(value)}
        multiple={!single}
        options={loading ? [] : options}
        loading={loading}
        loadingText={"Searching ..."}
        value={field.value}
        onChange={(e, x) => {
          form.setFieldValue(field.name, x === null ? [] : x);
        }}
        getOptionLabel={(option: any) => option.label}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              key={option.value}
              data-type={`${testId}-chip`}
              data-label={option.label}
              data-testid={`${testId}-chip-${option.value}`}
              label={option.label}
              {...getTagProps({ index })}
            />
          ))
        }
        filterSelectedOptions
        filterOptions={isServerSideFilter ? (e) => e : createFilterOptions({ limit: maxDisplayedItems })}
        ListboxComponent={ListboxComponent}
        renderOption={(option) => (
          <Typography data-testid={`${testId}-${getTestIdForOption ? getTestIdForOption(option) : option.value}`} noWrap>
            {option.label}
          </Typography>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={legend}
            placeholder={legend}
            error={form.touched[field.name] && !!form.errors[field.name]}
            onClick={onClick ? () => onClick() : undefined}
          />
        )}
        noOptionsText="Keine weiteren Optionen verfügbar"
        {...props}
      />
      <FormHelperText>{form.touched[field.name] && form.errors[field.name]}</FormHelperText>
    </FormControl>
  );
};

export default MultiSelectField;
