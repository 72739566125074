import React, { useMemo, useState } from "react";
import DataContainer from "template/presentation/components/table/DataContainer";
import DataTable, { DataTableCell } from "template/presentation/components/table/DataTable";
import SignatureRequestModel from "../../data/SignatureformsRequestModel";
import { EmptyState } from "core/presentation/components/EmptyState";
import { useCurrentUserInGroup } from "features/login/presentation/hooks/useUser";
import ActionMenu from "template/presentation/components/menues/ActionMenu";
import { useHistory } from "react-router-dom";
import { SignatureformDeleteDialog } from "./SignatureformDeleteDialog";

export interface SignatureformTableProps {
  signatureforms: any | SignatureRequestModel[] | null;
  isLoading: boolean;
  onReload: () => void;
  onAdd: () => void;
  onDelete: (id: string) => void;
}

const defaultColumns = [{ name: "Bezeichnung", sortField: "title" }, { name: "" }];

export default function SignatureformsTable(props: SignatureformTableProps) {
  const { signatureforms, isLoading, onReload, onAdd, onDelete } = props;
  const history = useHistory();

  const [, isNotBackoffice] = useCurrentUserInGroup("backoffice");
  const [deleteId, setDeleteId] = useState<string | undefined>(undefined);

  const emptyState = useMemo(() => {
    if (signatureforms)
      return (
        <EmptyState
          title="Noch keine Formulare vorhanden"
          description="Erstellen Sie jetzt Ihr erstes Unterschriftenformular, um Aufträge einfach und digital von unterwegs erledigen zu können."
          buttonLabel="Neues Formular"
          onButtonClick={onAdd}
        />
      );
  }, [signatureforms, onAdd]);

  return (
    <DataContainer data-testid="newForm" loading={isLoading} addButton="Neues Formular" onAdd={onAdd} onReload={onReload}>
      <SignatureformDeleteDialog
        open={deleteId !== undefined}
        onDismiss={() => setDeleteId(undefined)}
        onAgree={
          deleteId
            ? () => {
                onDelete(deleteId);
                setDeleteId(undefined);
              }
            : () => {
                return;
              }
        }
      />
      <DataTable<SignatureRequestModel>
        data-testid={`signatureformtable`}
        loading={isLoading}
        columns={defaultColumns}
        rows={signatureforms}
        keyExtractor={(data) => data.id}
        renderRow={(data, index) => {
          return (
            <>
              <DataTableCell data-testid={"title"}>{data.title}</DataTableCell>
              {isNotBackoffice && (
                <DataTableCell>
                  <div
                    style={{
                      float: "right",
                    }}
                  >
                    <ActionMenu
                      data-testid={`signatureforms-action-`}
                      options={[
                        {
                          type: "MENU_ENTRY",
                          label: "Formular anzeigen",
                          "data-testid": "read-form",
                          onSelect: () => history.push(`/signatureforms/${data.id}`),
                        },
                        {
                          type: "MENU_ENTRY",
                          label: "Formular löschen",
                          "data-testid": "delete-form",
                          onSelect: () => setDeleteId(data.id),
                        },
                        {
                          type: "MENU_ENTRY",
                          label: "Auftrag erstellen",
                          "data-testid": "create-assignment-form",
                          onSelect: () => history.push(`/signatureforms/assignment/${data.id}`),
                        },
                      ]}
                    />
                  </div>
                </DataTableCell>
              )}
            </>
          );
        }}
        renderEmpty={() => emptyState}
      />
    </DataContainer>
  );
}
