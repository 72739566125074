import useService from "core/di/useService";
import UIRoute from "core/presentation/components/UIRoute";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CaseService from "features/cases/domain/services/CaseService";
import AssignmentService from "features/assignments/domain/services/AssignmentService";
import ClientService from "features/clients/domain/services/ClientService";
import FormularService from "features/formular-center/domain/services/FormularService";
import { useQuery } from "react-query";
import { RouteComponentProps } from "react-router-dom";
import CreateOrUpdateFormularForm, { CreateOrUpdateFormularFormValues } from "../components/CreateOrUpdateFormularForm";

export interface CreateFormularRouteProps extends RouteComponentProps {}

const CreateFormularRoute = (props: CreateFormularRouteProps) => {
  const caseService = useService(CaseService);
  const assignmentService = useService(AssignmentService);
  const clientService = useService(ClientService);
  const formularService = useService(FormularService);

  const [caseNumberQuery, setCaseNumberQuery] = useState<string | undefined>(undefined);
  const [caseOptionSelected, setCaseOptionSelected] = useState<string | undefined>(undefined);
  const [assignmentNumberQuery, setAssignmentNumberQuery] = useState<string | undefined>(undefined);
  const [assignmentOptionSelected, setAssignmentOptionSelected] = useState<string | undefined>(undefined);
  const [clientNumberQuery, setClientNumberQuery] = useState<string | undefined>(undefined);
  const [clientOptionSelected, setClientOptionSelected] = useState<string>("");

  const { data: caseOptions, isLoading: casesLoading } = useQuery(
    ["cases", { caseNumberQuery, pageSize: 10 }],
    () => caseService.getTypeAheadOptions(caseNumberQuery || ""),
    { enabled: caseNumberQuery && caseNumberQuery.length >= 1 }
  );

  const { data: assignmentOptions, isLoading: assignmentsLoading } = useQuery(
    ["assignments", { assignmentNumberQuery, pageSize: 10 }, caseOptionSelected],
    () =>
      assignmentService
        .getAssignmentList({ assignmentNumber: assignmentNumberQuery || "", relatedCaseNumber: caseOptionSelected }, 1, 10, {
          field: "sortedBy",
          order: "asc",
        })
        .then(({ data }) => data)
  );

  const { data: clientOptions, isLoading: clientsLoading } = useQuery(
    ["clients", { clientNumberQuery, assignmentOptionSelected }],
    () => clientService.getClientTypeAheadOptions((clientNumberQuery ?? "") as string, "", assignmentOptionSelected),
    { enabled: (clientNumberQuery?.length ?? 0) > 0 || !!assignmentOptionSelected }
  );

  const queriedCaseOptions = useMemo(() => {
    if (caseOptions) {
      return caseOptions.map((kase) => ({
        value: kase.caseNumber,
        label: kase.caseNumber,
      }));
    }
    return [];
  }, [caseOptions]);

  const queriedAssignmentOptions = useMemo(() => {
    if (assignmentOptions) {
      return assignmentOptions.map((assignment) => ({
        value: assignment._id,
        label: assignment.assignmentNumber,
      }));
    }
    return [];
  }, [assignmentOptions]);

  const queriedClientOptions = useMemo(() => {
    if (clientOptions) {
      return clientOptions.map((client) => ({
        value: client.clientNumber,
        label: `${client.firstname ?? ""} ${client.lastname ?? ""} (${client.clientNumber})`,
      }));
    }
    return [];
  }, [clientOptions]);

  const onSubmit = useCallback(
    (values: CreateOrUpdateFormularFormValues) => {
      return formularService
        .create({
          ...values,
          assignmentId: assignmentOptionSelected,
          caseNumber: caseOptionSelected,
          clientNumber: clientOptionSelected,
        })
        .then(() => {
          props.history.goBack();
        });
    },
    [assignmentOptionSelected, caseOptionSelected, clientOptionSelected, formularService, props.history]
  );

  return (
    <UIRoute {...props} title={"Neues Formular erstellen"}>
      <CreateOrUpdateFormularForm
        onSubmit={onSubmit}
        caseQuery={{
          caseOptions: queriedCaseOptions || [],
          caseOptionsLoading: casesLoading,
          setCaseNumberQuery: setCaseNumberQuery,
          setCaseOptionSelected: setCaseOptionSelected,
        }}
        assignmentQuery={{
          assignmentOptions: queriedAssignmentOptions || [],
          assignmentOptionsLoading: assignmentsLoading,
          setAssignmentNumberQuery: setAssignmentNumberQuery,
          setAssignmentOptionSelected: setAssignmentOptionSelected,
        }}
        clientQuery={{
          clientOptions: queriedClientOptions || [],
          clientOptionsLoading: clientsLoading,
          setClientNumberQuery: setClientNumberQuery,
          setClientOptionSelected: setClientOptionSelected,
        }}
      />
    </UIRoute>
  );
};

export default CreateFormularRoute;
