import React, { useMemo, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import RefreshIcon from "@material-ui/icons/Refresh";
import TypeAheadField from "../form/TypeAheadField";
import { Autocomplete } from "@material-ui/lab";
import { Checkbox, FormControlLabel } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      maxWidth: 1048,
      margin: "auto",
      overflow: "hidden",
    },
    searchBar: {},
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    block: {
      display: "block",
    },
    addUser: {
      marginRight: theme.spacing(1),
    },
    contentWrapper: {
      margin: "0px 0px",
    },
    toolbar: {
      paddingRight: 8,
    },
  });

interface SelectOptions {
  value: string;
  label: string;
}

export interface FilterItem {
  name: string;
  initialValue?: { label: string; value: string };
  searchField?: string | undefined;
  toggleField?: string | undefined;
  setTypeAheadQuery?: (query: string) => void | undefined;
  typeAheadOptions?: SelectOptions[] | undefined;
  typeAheadTestProperty?: string;
  selectOptions?: SelectOptions[] | undefined;
  isLoading?: boolean;
}

export interface DataContainerProps extends WithStyles<typeof styles> {
  loading?: boolean;
  addButton?: string;
  onAdd?: () => void;
  onReload?: () => void;
  showSearchbar?: boolean;
  onSearchSubmit?: (queries: any) => void;
  filterItems?: FilterItem[];
  singleSearch?: boolean;
}

const DataContainer: React.FC<DataContainerProps> = ({
  classes,
  loading,
  addButton,
  onAdd,
  onReload,
  children,
  showSearchbar = true,
  onSearchSubmit,
  filterItems,
  singleSearch,
}) => {
  const [searchObject, setSearchObject] = useState<undefined | any>(undefined);

  const displayToggles = useMemo(() => filterItems && filterItems.findIndex((filterItem) => "toggleField" in filterItem) !== -1, [
    filterItems,
  ]);

  return (
    <Paper className={classes.paper}>
      {showSearchbar && (
        <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
          <Toolbar className={classes.toolbar}>
            <Grid container spacing={2} alignItems="center" justify="flex-end">
              <Grid item>
                {addButton && (
                  <Button
                    data-testid="datatable-add"
                    onClick={onAdd}
                    variant="contained"
                    color="primary"
                    disabled={onAdd === undefined || loading === true}
                    className={classes.addUser}
                  >
                    {addButton}
                  </Button>
                )}
                {onReload && (
                  <Tooltip title="Reload">
                    <span>
                      <IconButton data-testid="datatable-reload" onClick={onReload} disabled={loading === true}>
                        <RefreshIcon className={classes.block} color="inherit" />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </Toolbar>
          <Toolbar>
            <Grid container spacing={1} alignItems="center">
              {filterItems &&
                filterItems.map((filterItem: FilterItem, index: number) => {
                  if (filterItem.searchField) {
                    if (filterItem.setTypeAheadQuery) {
                      return (
                        <Grid item xs key={index}>
                          <TypeAheadField
                            value={filterItem.initialValue}
                            isLoading={filterItem.isLoading}
                            data-testid={`searchField-${filterItem.searchField}`}
                            legend={`${filterItem.name}`}
                            typeAheadOptions={filterItem.typeAheadOptions || []}
                            setTypeAheadQuery={filterItem.setTypeAheadQuery}
                            typeAheadChange={(value: string) => {
                              if (filterItem.searchField) {
                                singleSearch
                                  ? setSearchObject({ [filterItem.searchField]: value })
                                  : setSearchObject({ ...searchObject, [filterItem.searchField]: value });
                              }
                            }}
                            getTestIdForOption={(option) => {
                              return filterItem.typeAheadTestProperty && option[filterItem.typeAheadTestProperty];
                            }}
                          />
                        </Grid>
                      );
                    } else if (filterItem.selectOptions) {
                      return (
                        <Grid item xs key={index}>
                          <Autocomplete
                            value={filterItem.initialValue}
                            data-testid={`searchField-${filterItem.searchField}`}
                            options={filterItem.selectOptions}
                            getOptionLabel={(option: any) => option.label}
                            onChange={(e: any, x: any) => {
                              if (filterItem.searchField) {
                                if (x) {
                                  setSearchObject({ ...searchObject, [filterItem.searchField]: x.value });
                                } else {
                                  setSearchObject({ ...searchObject, [filterItem.searchField]: "" });
                                }
                              }
                            }}
                            renderInput={(params) => <TextField {...params} label={filterItem.name} />}
                          />
                        </Grid>
                      );
                    } else {
                      const searchObjectKey = filterItem.searchField;
                      const fieldValue = !searchObject ? filterItem.initialValue?.label : searchObject[searchObjectKey];
                      return (
                        <Grid item xs key={index}>
                          <TextField
                            value={fieldValue}
                            fullWidth
                            data-testid={`searchField-${filterItem.searchField}`}
                            label={`${filterItem.name}`}
                            onChange={(e: any) => {
                              if (filterItem.searchField) {
                                setSearchObject({ ...searchObject, [filterItem.searchField]: e.target.value });
                              }
                            }}
                            name={filterItem.searchField}
                            onKeyDown={(e: any) => {
                              if (e.key === "Enter" && onSearchSubmit) {
                                onSearchSubmit(searchObject);
                              }
                            }}
                          />
                        </Grid>
                      );
                    }
                  }
                  return null;
                })}
            </Grid>
          </Toolbar>
          {displayToggles && (
            <Toolbar>
              <Grid container spacing={1} alignItems="center">
                {filterItems &&
                  filterItems.map((filterItem: FilterItem, index: number) => {
                    if (filterItem.toggleField) {
                      return (
                        <Grid item xs key={index}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e: any) => {
                                  if (filterItem.toggleField) {
                                    setSearchObject({ ...searchObject, [filterItem.toggleField]: e.target.checked });
                                  }
                                }}
                                color="primary"
                              />
                            }
                            label={filterItem.name}
                          />
                        </Grid>
                      );
                    }
                    return null;
                  })}
              </Grid>
            </Toolbar>
          )}
          <Toolbar>
            <Button
              onClick={() => {
                if (onSearchSubmit) {
                  onSearchSubmit(searchObject);
                }
              }}
              variant="contained"
              color="primary"
              disabled={loading === true}
              className={classes.addUser}
              data-testid="button-search"
            >
              Suchen
            </Button>
          </Toolbar>
        </AppBar>
      )}
      <div className={classes.contentWrapper}>
        {/*
        <Typography color="textSecondary" align="center">
          No users for this project yet
        </Typography>
        */}
        {children}
      </div>
    </Paper>
  );
};

export default withStyles(styles)(DataContainer);
