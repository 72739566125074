import format from "date-fns/format";
import de from "date-fns/locale/de";
import { PoolAssignmentAppointment } from "features/pools/domain/entities/PoolAssignment";

export const renderWantedAppointmentTimeText = (wantedAppointmentDetails: PoolAssignmentAppointment) => {
  let appointmentText = "";

  if (wantedAppointmentDetails.availableOnAnyDate) {
    appointmentText += "Jeden Tag";
  } else {
    appointmentText += format(wantedAppointmentDetails.date, "EEEE, dd. LLLL", {
      locale: de,
    });
  }

  if (wantedAppointmentDetails.availableAnyTime) {
    appointmentText += ", beliebige Uhrzeit";
  } else {
    appointmentText += ", zwischen ";

    appointmentText += format(wantedAppointmentDetails.timeFrom, "HH:mm") + " - " + format(wantedAppointmentDetails.timeTo, "HH:mm");

    appointmentText += " Uhr";
  }

  return appointmentText;
};
