import container from "./container";
import { authorizedClient, unauthorizedClient } from "core/data/clients";
import LoginService from "features/login/domain/services/LoginService";
import UserService from "features/users/domain/services/UserService";
import LoginApi from "features/login/data/LoginApi";
import UserApi from "features/users/data/UserApi";
import ClientService from "features/clients/domain/services/ClientService";
import ClientApi from "features/clients/data/ClientApi";
import AssignmentsApi from "features/assignments/data/AssignmentsApi";
import B2BService from "features/b2b/domain/services/B2BService";
import AssignmentService from "features/assignments/domain/services/AssignmentService";
import B2BApi from "features/b2b/data/B2BApi";
import PoolApi from "features/pools/data/PoolApi";
import PoolService from "features/pools/domain/services/PoolService";
import BranchService from "features/branches/domain/services/BranchService";
import BranchApi from "features/branches/data/BranchApi";
import ClassificationService from "features/classifications/domain/services/ClassificationService";
import ClassificationApi from "features/classifications/data/ClassificationApi";
import AdapterService from "features/adapter/domain/AdapterService";
import AdapterApi from "features/adapter/data/AdapterApi";
import SignatureformsApi from "features/signatureforms/data/SignatureformsApi";
import MessageService from "features/messages/domain/services/MessageService";
import MessageApi from "features/messages/data/MessageApi";
import ProductService from "features/products/domain/services/ProductService";
import ProductsApi from "features/products/data/ProductsApi";
import RecruitmentService from "../../features/recruitment/domain/services/ProductService";
import RecruitmentApi from "../../features/recruitment/data/RecruitmentApi";
import CaseService from "features/cases/domain/services/CaseService";
import CaseApi from "features/cases/data/CaseApi";
import ProfileApi from "features/profile/data/ProfileApi";
import ProfileService from "features/profile/domain/ProfileService";
import SignatureformsService from "features/signatureforms/domain/services/SignatureformsService";
import ShopApi from "features/shop/data/ShopApi";
import ShopService from "features/shop/domain/services/ShopService";
import RehaItemService from "features/rehaItems/domain/services/RehaItemService";
import RehaItemApi from "features/rehaItems/domain/services/data/RehaItemApi";
import FormularApi from "features/formular-center/data/FormularApi";
import FormularService from "features/formular-center/domain/services/FormularService";

export default function initDi() {
  const loginService = new LoginService(new LoginApi(unauthorizedClient, authorizedClient));
  container.bind(LoginService).toConstantValue(loginService);

  const userService = new UserService(new UserApi(authorizedClient));
  container.bind(UserService).toConstantValue(userService);

  const clientService = new ClientService(new ClientApi(authorizedClient));
  container.bind(ClientService).toConstantValue(clientService);

  const b2bService = new B2BService(new B2BApi(authorizedClient));
  container.bind(B2BService).toConstantValue(b2bService);

  const messageService = new MessageService(new MessageApi(authorizedClient));
  container.bind(MessageService).toConstantValue(messageService);

  const assignmentService = new AssignmentService(new AssignmentsApi(authorizedClient));
  container.bind(AssignmentService).toConstantValue(assignmentService);

  const classificationService = new ClassificationService(new ClassificationApi(authorizedClient));
  container.bind(ClassificationService).toConstantValue(classificationService);

  const branchService = new BranchService(new BranchApi(authorizedClient));
  container.bind(BranchService).toConstantValue(branchService);

  const poolService = new PoolService(new PoolApi(authorizedClient));
  container.bind(PoolService).toConstantValue(poolService);

  const adapterService = new AdapterService(new AdapterApi(authorizedClient));
  container.bind(AdapterService).toConstantValue(adapterService);

  const productsService = new ProductService(new ProductsApi(authorizedClient));
  container.bind(ProductService).toConstantValue(productsService);

  const recruitmentService = new RecruitmentService(new RecruitmentApi(authorizedClient));
  container.bind(RecruitmentService).toConstantValue(recruitmentService);

  const caseService = new CaseService(new CaseApi(authorizedClient));
  container.bind(CaseService).toConstantValue(caseService);

  const profileService = new ProfileService(new ProfileApi(unauthorizedClient, authorizedClient));
  container.bind(ProfileService).toConstantValue(profileService);

  const signatureformsService = new SignatureformsService(new SignatureformsApi(authorizedClient));
  container.bind(SignatureformsService).toConstantValue(signatureformsService);

  const shopService = new ShopService(new ShopApi(authorizedClient));
  container.bind(ShopService).toConstantValue(shopService);

  const rehaItemService = new RehaItemService(new RehaItemApi(authorizedClient));
  container.bind(RehaItemService).toConstantValue(rehaItemService);

  const formularService = new FormularService(new FormularApi(authorizedClient));
  container.bind(FormularService).toConstantValue(formularService);
}
