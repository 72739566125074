import ClientRequestModel from "../../data/ClientRequestModel";
import ClientResponseModel from "../../data/ClientResponseModel";
import Client, { EmployeeEmailAddress, PrescriberOption } from "../entities/Client";
import { CreateOrUpdateClientFormValues } from "../../presentation/components/CreateOrUpdateClientForm";
import B2BSpace from "../services/B2BSpace";
import BranchConverter from "features/branches/domain/converters/BranchConverter";
import PrescriberResponseModel from "features/clients/data/PrescriberResponseModel";

interface EnterpriseRequestModel {
  role: "root" | "child";
  children?: { id: string }[];
  root?: { id: string };
  additionalAccess?: { id: string }[];
  exclusiveAccess?: { id: string }[];
  exclusiveFieldstaffAccess?: { id: string }[];
}
export default class ClientConverter {
  public static fromClientFormToRequestModel(formValues: CreateOrUpdateClientFormValues, group?: string): ClientRequestModel {
    if (group === B2BSpace.INTERMEDIARY) {
      delete formValues.lastname;
      delete formValues.birthdate;
      delete formValues.phone2;
    }
    if (formValues.isPartOfEnterprise === true) {
      if (formValues.enterpriseRole === "root") {
        let enterprise: EnterpriseRequestModel;
        enterprise = {
          role: formValues.enterpriseRole,
          children: formValues.enterpriseChildren?.map((x) => {
            return { id: x.value };
          }),
          additionalAccess: formValues.additionalAccess?.map((x) => {
            return { id: x.value };
          }),
          exclusiveAccess: formValues.exclusiveAccess?.map((x) => {
            return { id: x.value };
          }),
          exclusiveFieldstaffAccess: formValues.exclusiveFieldstaffAccess?.map((x) => {
            return { id: x.value };
          }),
        };

        delete formValues.enterpriseRole;
        delete formValues.isPartOfEnterprise;
        delete formValues.enterpriseChildren;
        delete formValues.exclusiveAccess;
        delete formValues.exclusiveFieldstaffAccess;
        delete formValues.additionalAccess;

        return {
          ...formValues,
          isCareHome: group === B2BSpace.INTERMEDIARY,
          enterprise: enterprise,
          prescribers: undefined,
        };
      } else if (formValues.enterpriseRole === "child" && formValues.enterpriseChildren) {
        let enterprise: EnterpriseRequestModel;
        const root = formValues.enterpriseChildren[0];
        enterprise = {
          role: formValues.enterpriseRole,
          root: { id: root.value },
          additionalAccess: formValues.additionalAccess?.map((x) => {
            return { id: x.value };
          }),
        };
        delete formValues.enterpriseRole;
        delete formValues.isPartOfEnterprise;
        delete formValues.enterpriseChildren;
        delete formValues.exclusiveAccess;
        delete formValues.additionalAccess;

        return {
          ...formValues,
          isCareHome: group === B2BSpace.INTERMEDIARY,
          enterprise: enterprise,
          prescribers: formValues.prescribers?.map((x) => x.value),
        };
      }
    } else {
      delete formValues.enterpriseRole;
      delete formValues.isPartOfEnterprise;
      delete formValues.enterpriseChildren;
      delete formValues.exclusiveAccess;
      delete formValues.additionalAccess;
    }

    return {
      ...formValues,
      isCareHome: group === B2BSpace.INTERMEDIARY,
      prescribers: formValues.prescribers?.map((x) => {
        return x.value;
      }),
    };
  }

  public static fromResponseModelToClient(data: ClientResponseModel): Client {
    const { _id, ...fields } = data;
    const clientData = {
      ...fields,
      id: _id,
      branch:
        data.branch !== undefined && data.branch !== null && typeof data.branch === "object"
          ? BranchConverter.fromResponseModelToEntity(data.branch)
          : data.branch,
      isPrescriber: !!data.isPrescriber,
      prescribers: data.prescribers?.map((p) => ({ value: p._id, label: `${p.name} (${p.prescriberNumber})` })),
    };
    return clientData;
  }

  public static fromPrescriberResponseModelToOption(data: ClientResponseModel): PrescriberOption {
    return {
      value: data._id,
      label: data.firstname + (data.lastname && data.lastname !== "" ? " " + data.lastname : "") + " (" + data.clientNumber + ")",
    };
  }

  public static fromPrescriberResponseModelToPrescriberOption(data: PrescriberResponseModel): PrescriberOption {
    return {
      value: data._id,
      number: data.prescriberNumber,
      label: `${data.name} (${data.prescriberNumber})`,
    };
  }

  public static convertStringToArrayEmails(data: string): EmployeeEmailAddress[] {
    return data && data.length > 0 ? data.split(",").map((d) => ({ mail: d.trim() })) : [];
  }

  public static convertArrayToStringEmails(data?: EmployeeEmailAddress[]): string {
    return data ? data.map((d) => d.mail).join(", ") : "";
  }
}
