import AdapterLogEntryModel from "features/adapter/data/AdapterLogEntryModel";
import AdapterLogEntry from "features/adapter/domain/entities/AdapterLogEntry";

export default class AdapterLogEntryConverter {
  public static fromModelListToEntityList(models: AdapterLogEntryModel[]): AdapterLogEntry[] {
    return models.map((x) => AdapterLogEntryConverter.fromModelToEntity(x));
  }

  public static fromModelToEntity(model: AdapterLogEntryModel): AdapterLogEntry {
    return {
      id: model.id,
      level: model.level,
      action: model.action,
      direction: model.direction,
      date: new Date(model.date),
      data: model.data,
    };
  }
}
