import React from "react";
import BaseRoute, { BaseRouteProps } from "template/presentation/components/routes/BaseRoute";
import { AvatarMenu } from "./AvatarMenu";

export interface UIRouteProps extends BaseRouteProps {}

function UIRoute(props: UIRouteProps) {
  return (
    <BaseRoute {...props} renderIcons={() => [<AvatarMenu key="avatar" handleRouteChange={props.history.replace} />]}>
      {props.children}
    </BaseRoute>
  );
}

export default UIRoute;
