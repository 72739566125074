import React from "react";
import { useQuery } from "react-query";
import useService from "core/di/useService";
import { RouteComponentProps } from "react-router-dom";
import UIRoute from "core/presentation/components/UIRoute";
import AdapterService from "features/adapter/domain/AdapterService";
import AdapterTable from "../components/AdapterTable";

export interface AdaptersRouteProps extends RouteComponentProps {}

export default function AdaptersRoute(props: AdaptersRouteProps) {
  const adapterService = useService(AdapterService);

  const { data, isLoading, refetch, isFetching } = useQuery("adapterList", () => adapterService.get());

  return (
    <UIRoute {...props} title="Adapter">
      <AdapterTable adapter={data || null} isLoading={isLoading || isFetching} onReload={refetch} />
    </UIRoute>
  );
}
