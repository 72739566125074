import { Grid, makeStyles } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import { Alert } from "@material-ui/lab";
import { useService } from "core/di";
import ProfileService from "features/profile/domain/ProfileService";
import { Field, Formik } from "formik";
import React, { useState } from "react";
import FormContainer from "template/presentation/components/form/FormContainer";
import FormSection from "template/presentation/components/form/FormSection";
import FormSubmitButton from "template/presentation/components/form/FormSubmitButton";
import PasswordField from "template/presentation/components/form/PasswordField";
import * as Yup from "yup";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

export default function ResetPasswordScreen() {
  const useStyles = makeStyles((theme) => ({
    field: {
      marginTop: theme.spacing(1),
    },
    form: {
      marginTop: theme.spacing(5),
      maxWidth: 936,
      margin: "auto",
      overflow: "hidden",
      marginBottom: theme.spacing(5),
    },
    backLink: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
  }));
  const classes = useStyles();
  const history = useHistory();

  const profileService = useService(ProfileService);

  const [errorRequest, setErrorRequest] = useState<null | string>(null);
  const [successRequest, setSuccessRequest] = useState<{ msg: string; state: boolean }>({ msg: "", state: false });

  const [errorSetPassword, setErrorSetPassword] = useState<null | string>(null);
  const [successSetPassword, setSuccessSetPassword] = useState<null | string>(null);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Keine gültige E-Mail Adresse").required("Benötigt"),
    resetCode: Yup.string().when("email", {
      is: (val) => {
        return val !== "" && successRequest.state;
      },
      then: Yup.string().required("Benötigt").length(6, "Der Code den wir Ihnen gesendet haben besteht aus 6 Zahlen"),
      otherwise: Yup.string().optional(),
    }),
    password: Yup.string()
      .when("resetCode", {
        is: (val) => {
          return val !== "" && successRequest.state;
        },
        then: Yup.string().required("Benötigt").min(8, "Benötigt mindestens 8 Zeichen"),
        otherwise: Yup.string().optional(),
      })
      .oneOf([Yup.ref("passwordRepetition")], "Passwörter müssen gleich sein"),
    passwordRepetition: Yup.string()
      .when("password", {
        is: (val) => {
          return val !== "" && successRequest.state;
        },
        then: Yup.string().required("Benötigt").min(8, "Benötigt mindestens 8 Zeichen"),
        otherwise: Yup.string().optional(),
      })
      .oneOf([Yup.ref("password")], "Passwörter müssen gleich sein"),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={{ email: "", resetCode: "", password: "", passwordRepetition: "" }}
      onSubmit={async (values) => {
        if (!successRequest?.state) {
          profileService
            .getResetCode(values.email)
            .then(() => {
              setSuccessRequest({
                msg: "Ein Code zum zurücksetzen des Passwortes ist an die E-Mail-Adresse gesendet worden.",
                state: true,
              });
            })
            .catch(() => {
              setErrorRequest("Fehler beim senden des Codes.");
            });
        }
        //prevent an executition after rerender and codeRequested is already true
        if (values.password !== "" && values.resetCode !== "" && values.email !== "") {
          profileService
            .resetPassword(values.resetCode, values.password, values.email)
            .then(() => {
              setSuccessSetPassword("Das Passwort wurde geändert.");
              setTimeout(() => {
                history.push("/");
              }, 1000);
            })
            .catch(() => {
              setErrorSetPassword("Fehler beim Zurücksetzen des Passwortes.");
            });
        }
      }}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting }) => {
        return (
          <div className={classes.form}>
            <h3>Passwort vergessen</h3>
            <FormContainer>
              <FormSection label={"Code anfordern"}>
                <Field
                  component={TextField}
                  className={classes.field}
                  name="email"
                  label="E-Mail"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  autoComplete="email"
                />
                <FormSubmitButton
                  data-testid="button-requestpasswordcode"
                  disabled={isSubmitting || successRequest.state}
                  onClick={submitForm}
                >
                  {"Code anfordern"}
                </FormSubmitButton>
                {successRequest.state && (
                  <Alert className={classes.field} data-testid="resetpassword-success" severity="success">
                    {successRequest.msg}
                  </Alert>
                )}
                {errorRequest && (
                  <Alert className={classes.field} data-testid="resetpassword-error" severity="error">
                    {errorRequest}
                  </Alert>
                )}
              </FormSection>

              <FormSection label={"Passwort setzen"}>
                <Field
                  component={TextField}
                  className={classes.field}
                  name="resetCode"
                  label="Code"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                />
                <Field
                  component={PasswordField}
                  className={classes.field}
                  name="password"
                  label="Passwort"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                />
                <Field
                  component={PasswordField}
                  className={classes.field}
                  name="passwordRepetition"
                  label="Passwort wiederholen"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                />
                {errorSetPassword && (
                  <Alert className={classes.field} data-testid="setpassword-error" severity="error">
                    {errorSetPassword}
                  </Alert>
                )}
                {successSetPassword && (
                  <Alert className={classes.field} data-testid="setpassword-success" severity="success">
                    {successSetPassword}
                  </Alert>
                )}
                <FormSubmitButton
                  data-testid="button-requestpasswordcode"
                  disabled={isSubmitting || !successRequest.state}
                  onClick={submitForm}
                >
                  {"Password setzen"}
                </FormSubmitButton>
              </FormSection>
            </FormContainer>
            <Grid container className={classes.backLink}>
              <Grid item xs>
                <Link href="#" to={"/"} style={{ textDecoration: "none" }}>
                  Zurück zum Login
                </Link>
              </Grid>
            </Grid>
          </div>
        );
      }}
    </Formik>
  );
}
