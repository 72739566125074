import { PoolAssignmentReportB2CAttachment } from "features/pools/domain/entities/PoolAssignment";
import React from "react";
import ContentSection from "template/presentation/components/content/ContentSection";
import LabeledValue from "template/presentation/components/content/LabeledValue";
import ValueGrid from "template/presentation/components/content/ValueGrid";

interface Props {
  report: PoolAssignmentReportB2CAttachment;
}

export const AttachmentB2CInfoSection: React.FC<Props> = ({ report }) => {
  return (
    <ContentSection label="Details">
      <ValueGrid>
        <LabeledValue data-testid="reportText" label="Anliegen" value={report.text} />
        <LabeledValue
          data-testid="reportForWhom"
          label="Anfrage für andere Person?"
          value={report.forWhom === "myself" ? "Nein" : "Ja: " + report.otherPerson}
        />
        <LabeledValue
          data-testid="reportStreetZipcodeCity"
          label="Adresse für Lieferungen/Termine"
          value={
            report.street == "" && report.zipcode == "" && report.city == ""
              ? "-"
              : report.street + ", " + report.zipcode + " " + report.city
          }
        />
        <LabeledValue
          data-testid="reportPreferredCommunication"
          label="Rückfragen bevorzugt per"
          value={report.preferredCommunication === "email" ? "E-Mail" : "Telefon"}
        />
        <LabeledValue data-testid="reportEmail" label="E-Mail Rückfragen" value={report.email} />
        <LabeledValue data-testid="reportPhone" label="Tel. Rückfragen" value={report.phone} />
      </ValueGrid>
    </ContentSection>
  );
};
