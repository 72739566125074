import React from "react";
import { Calendar, dateFnsLocalizer, Event } from "react-big-calendar";
import de from "date-fns/locale/de";
import { format, parse, startOfWeek, getDay } from "date-fns";

import "react-big-calendar/lib/css/react-big-calendar.css";

const locales = {
  de,
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

export interface CalendarProps {
  className: string;
  events: Event[];
  minDate?: Date;
  maxDate?: Date;
}

const onEventPress = (event: Event) => {
  if (event.start && event.end && event.title) {
    let text = `Auftrag für den ${format(event.start, "dd.MM.yyyy")}: ${format(event.start, "HH:mm")} - ${format(
      event.end,
      "HH:mm"
    )} Uhr\n${event.title}`;
    window.alert(text);
  }
};

const eventWrapper = (props: any) => {
  return <div data-testid={`${props.event.clientNumber}-${props.event.type}`}>{props.children}</div>;
};

const AssignmentsCalendar: React.FC<CalendarProps> = ({ className, events, minDate, maxDate }) => {
  return (
    <div className={className}>
      <Calendar
        events={events}
        culture="de"
        defaultDate={new Date()}
        localizer={localizer}
        messages={{
          allDay: "Ganzer Tag",
          previous: "Vorherig",
          next: "Nachfolgend",
          today: "Heute",
          month: "Monat",
          week: "Woche",
          day: "Tag",
          agenda: "Agenda",
          date: "Datum",
          time: "Zeit",
          event: "Ereignis",
          showMore: (total) => `+${total} mehr`,
        }}
        min={minDate}
        max={maxDate}
        onSelectEvent={onEventPress}
        components={{
          eventWrapper: eventWrapper,
        }}
      />
    </div>
  );
};

export default AssignmentsCalendar;
