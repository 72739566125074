import { AxiosInstance } from "axios";
import B2CRecruitment from "../domain/entities/B2CRecruitment";

export default class RecruitmentApi {
  constructor(private authClient: AxiosInstance) {}

  public async getEnduserRecruitmentsByEmployees(year: number, month: number): Promise<B2CRecruitment[]> {
    return this.authClient
      .get<B2CRecruitment[]>("/b2c/recruitments", { params: { year, month } })
      .then((r) => {
        return r.data;
      });
  }
}
