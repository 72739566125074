import React, { useMemo } from "react";
import Client from "../../domain/entities/Client";
import DataContainer from "template/presentation/components/table/DataContainerExtendedSearch";
import { DataTableCell } from "template/presentation/components/table/DataTable";
import { Link, useHistory } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import B2BSpace from "../../domain/services/B2BSpace";
import CheckIcon from "@material-ui/icons/Check";
import DataTablePaginatedAndSortable from "template/presentation/components/table/DataTablePaginatedAndSortable";
import ActionMenu from "template/presentation/components/menues/ActionMenu";
import { EmptyState } from "core/presentation/components/EmptyState";
import { EILSEQ } from "constants";
import format from "date-fns/format";

export interface StandingDataTableProps {
  group?: string;
  clients: Client[] | null;
  isLoading: boolean;
  onReload: () => void;
  onAdd: () => void;
  onSearch: (query: string | undefined) => void;
  page: number;
  pageSize: number;
  totalCount?: number;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
  onSortChange: (sortBy: string, sortOrder: "asc" | "desc") => void;
  sortedBy: { field: string; order: "asc" | "desc" };
}

const renderIsMemberOfCareHome = (memberOfCareHome?: string) => {
  if (memberOfCareHome) {
    return <CheckIcon style={{ color: "green", fontSize: "16px" }} />;
  }

  return "-";
};

const renderLocation = ({ city, street, zipcode }: Client) => {
  if (city && street && zipcode) {
    return (
      <div>
        <span>{street}</span> <br />
        <span>
          {zipcode} {city}
        </span>
      </div>
    );
  }
  return <div>-</div>;
};

const renderFullName = ({ firstname, lastname }: Client) => {
  if (firstname && lastname) {
    return `${firstname} ${lastname}`;
  }

  if (firstname && !lastname) {
    return firstname;
  }

  if (!firstname && lastname) {
    return lastname;
  }

  return "-";
};

const StandingDataTable = (props: StandingDataTableProps) => {
  const {
    clients,
    isLoading,
    onReload,
    onAdd,
    onSearch,
    page,
    pageSize,
    totalCount,
    onPageChange,
    onPageSizeChange,
    onSortChange,
    sortedBy,
    group,
  } = props;

  const history = useHistory();
  const columns = useMemo(() => {
    switch (group) {
      case B2BSpace.INTERMEDIARY:
        return [
          { name: "Name", sortField: "firstname" },
          { name: "Kundennummer" },
          { name: "Ort", sortField: "city" },
          { name: "Aktion" },
          { name: "Wohnbereiche" },
        ];
      case B2BSpace.PRESCRIBER:
        return [{ name: "Name", sortField: "firstname" }, { name: "Verordnernummer" }, { name: "Adresse" }];
      default:
        return [
          { name: "Name", sortField: "firstname" },
          { name: "Kundennummer" },
          { name: "Geburtsdatum" },
          { name: "Ort", sortField: "city" },
          { name: "Telefon" },
          { name: "Vermittler" },
          { name: "Aktion" },
        ];
    }
  }, [group]);

  const filterItems = useMemo(() => {
    if (group === B2BSpace.INTERMEDIARY) {
      return [
        { name: "Name", searchField: "firstname" },
        { name: "Kundennummer", searchField: "clientNumber" },
      ];
    } else if (group === B2BSpace.PRESCRIBER) {
      return [
        { name: "Name", searchField: "firstname" },
        { name: "Verordnernummer", searchField: "clientNumber" },
      ];
    }
    return [
      { name: "Nachname", searchField: "lastname" },
      { name: "Vorname", searchField: "firstname" },
      { name: "Kundennummer", searchField: "clientNumber" },
      { name: "Geburtsdatum", searchField: "birthdate" },
    ];
  }, [group]);

  const addButton = useMemo(() => {
    if (group === B2BSpace.INTERMEDIARY) {
      return "Neuer Vermittler";
    } else if (group === B2BSpace.PRESCRIBER) {
      return undefined;
    }
    return "Neuer Kunde";
  }, [group]);

  const emptyState = useMemo(() => {
    switch (group) {
      case B2BSpace.INTERMEDIARY:
        return (
          <EmptyState
            title="Noch keine Vermittler vorhanden"
            description="Erstellen Sie jetzt Ihren ersten Vermittler, um Aufträge mit Business Partnern teilen zu können."
            buttonLabel="Neuer Vermittler"
            onButtonClick={onAdd}
          />
        );

      case B2BSpace.PRESCRIBER:
        return (
          <EmptyState
            title="Noch keine Verordner vorhanden"
            description="Verbinden Sie das System mit Ihrer Branchensoftware, um Zugriff auf die hinterlegten Verordner zu erhalten."
          />
        );
    }

    return (
      <EmptyState
        title="Noch keine Kunde vorhanden"
        description="Erstellen Sie jetzt Ihren ersten Kunden, um für diese Aufträge einfach und digital von unterwegs zu erledigen."
        buttonLabel="Neuer Kunde"
        onButtonClick={onAdd}
      />
    );
  }, [group]);
  return (
    <DataContainer
      onSearchSubmit={onSearch}
      loading={isLoading}
      addButton={addButton}
      onAdd={onAdd}
      onReload={onReload}
      filterItems={filterItems}
      singleSearch={true}
    >
      <DataTablePaginatedAndSortable<Client>
        data-testid={`clienttable-${!group ? "patients" : group}`}
        loading={isLoading}
        columns={columns}
        rows={clients}
        page={page}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        totalCount={totalCount}
        keyExtractor={(data) => data.id}
        onSortChange={onSortChange}
        sortedBy={sortedBy}
        renderRow={(data, index) => {
          switch (group) {
            case B2BSpace.INTERMEDIARY:
              return (
                <>
                  <DataTableCell data-testid={`intermediary-name`}>{data.firstname || "-"}</DataTableCell>
                  <DataTableCell data-testid={`intermediary-clientNumber`}>{data.clientNumber || "-"}</DataTableCell>
                  <DataTableCell data-testid={`intermediary-location`}>{renderLocation(data)}</DataTableCell>
                  <DataTableCell>
                    <Link data-testid={`link-edit-row-${index}`} to={`/clients/edit/${data.id}/${B2BSpace.INTERMEDIARY}`}>
                      <EditIcon color={"primary"} />
                    </Link>
                  </DataTableCell>
                  <DataTableCell>
                    <Link data-testid={`link-iconLivingArea-row-${index}`} to={`/area/manage/${data.id}`}>
                      <VisibilityIcon color={"primary"} />
                    </Link>
                  </DataTableCell>
                </>
              );
            case B2BSpace.PRESCRIBER:
              return (
                <>
                  <DataTableCell data-testid={`prescriber-name`}>{data.firstname || "-"}</DataTableCell>
                  <DataTableCell data-testid={`prescriber-clientNumber`}>{data.clientNumber || "-"}</DataTableCell>
                  <DataTableCell data-testid={`prescriber-location`}>{renderLocation(data)}</DataTableCell>
                  {/* <DataTableCell>
                    {<Link data-testid={`link-edit-row-${index}`} to={`/clients/edit/${data.id}/${B2BSpace.INTERMEDIARY}`}>
                      <EditIcon color={"primary"} />
                    </Link> }
                  </DataTableCell>
                  <DataTableCell>
                    <Link data-testid={`link-iconLivingArea-row-${index}`} to={`/area/manage/${data.id}`}>
                      <VisibilityIcon color={"primary"} />
                    </Link>
                  </DataTableCell> */}
                </>
              );
            default:
              return (
                <>
                  <DataTableCell data-testid={"name"}>{renderFullName(data)}</DataTableCell>
                  <DataTableCell data-testid={"clientNumber"}>{data.clientNumber || "-"}</DataTableCell>
                  <DataTableCell data-testid={"birthdate"}>
                    {data.birthdate ? format(new Date(data.birthdate), "dd.MM.yyyy") : "-"}
                  </DataTableCell>
                  <DataTableCell data-testid={"location"}>{renderLocation(data)}</DataTableCell>
                  <DataTableCell data-testid={"phone"}>{data.phone || "-"}</DataTableCell>

                  <DataTableCell data-testid={"carehome"}>{renderIsMemberOfCareHome(data.memberOfCareHome)}</DataTableCell>
                  <DataTableCell>
                    <ActionMenu
                      data-testid={`client-action-`}
                      options={[
                        {
                          type: "MENU_ENTRY",
                          label: "Bearbeiten",
                          "data-testid": "edit",
                          onSelect: () => history.push(`/clients/edit/${data.id}`),
                        },
                        {
                          type: "SEPARATOR",
                          "data-testid": "edit-client-separator",
                        },
                        {
                          type: "MENU_ENTRY",
                          label: "Vorgang erstellen für Kunde",
                          "data-testid": "create-case",
                          onSelect: () => history.push(`/cases/create?clientNumber=${data.clientNumber}`),
                        },
                        {
                          type: "MENU_ENTRY",
                          label: "Auftrag erstellen für Kunde",
                          "data-testid": "create-assignment",
                          onSelect: () => history.push(`/assignments/create?clientNumber=${data.clientNumber}`),
                        },
                        {
                          type: "SEPARATOR",
                          "data-testid": "rehaItem-separator",
                        },
                        {
                          type: "MENU_ENTRY",
                          label: "Rehamittel anzeigen",
                          "data-testid": "show-rehaItems",
                          onSelect: () => history.push(`/rehaItems/${data.clientNumber}`),
                        },
                      ]}
                    />
                  </DataTableCell>
                </>
              );
          }
        }}
        renderEmpty={() => emptyState}
      />
    </DataContainer>
  );
};

export default StandingDataTable;
