import { FormControl, FormHelperText, MenuItem, Select } from "@material-ui/core";
import React from "react";

interface SelectFieldProps {
  field: any;
  form: any;
  legend?: string;
  options: { value: string; label: string }[];
  "data-testid"?: string;
  getTestIdForOption?: (option: any) => string;
}

const SelectField: React.FC<SelectFieldProps> = ({ field, form, legend, options, getTestIdForOption, ...props }) => {
  return (
    <FormControl error={form.touched[field.name] && !!form.errors[field.name]}>
      <Select
        value={field.value || ""}
        onChange={(e) => form.setFieldValue(field.name, e.target.value)}
        variant="outlined"
        displayEmpty
        {...props}
      >
        {legend && (
          <MenuItem value="" disabled>
            {legend}
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem
            data-testid={
              props["data-testid"] && `${props["data-testid"]}-${getTestIdForOption ? getTestIdForOption(option) : option.value}`
            }
            key={option.value}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{form.touched[field.name] && form.errors[field.name]}</FormHelperText>
    </FormControl>
  );
};

export default SelectField;
