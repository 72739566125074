import React, { useMemo, useCallback, useState } from "react";
import DataContainer, { FilterItem } from "template/presentation/components/table/DataContainerExtendedSearch";
import { DataTableCell } from "template/presentation/components/table/DataTable";
import { Link } from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";
import MailIcon from "@material-ui/icons/Mail";
import DeleteIcon from "@material-ui/icons/Delete";
import Message from "../../domain/entities/Message";
import MessageService from "../../domain/services/MessageService";
import DeleteMessageDialog from "./DeleteMessageDialog";
import { useService } from "core/di";
import { format } from "date-fns";
import { Alert } from "@material-ui/lab";
import DataTablePaginatedAndSortable from "template/presentation/components/table/DataTablePaginatedAndSortable";
import { SelectOption } from "features/assignments/domain/entities/Assignment";
import { EmptyState } from "core/presentation/components/EmptyState";
import { useCurrentUserInGroup } from "features/login/presentation/hooks/useUser";

export interface MessagesTableProps {
  messages: Message[] | null;
  isLoading: boolean;
  onReload: () => void;
  onAdd: () => void;
  onSearch: (query: string | undefined) => void;
  page: number;
  pageSize: number;
  totalCount?: number;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
  onDeleteMessage: (id: string) => void;
  onSortChange: (sortBy: string, sortOrder: "asc" | "desc") => void;
  sortedBy: { field: string; order: "asc" | "desc" };
  recipientTypeAhead: { options: SelectOption[]; query: (query: string) => void };
  assignmentTypeAhead: { options: SelectOption[]; query: (query: string) => void };
  error: string | null;
}

const MessagesTable = (props: MessagesTableProps) => {
  const messageService = useService(MessageService);
  const {
    messages,
    isLoading,
    onReload,
    onAdd,
    onSearch,
    page,
    pageSize,
    onPageChange,
    onPageSizeChange,
    totalCount,
    onDeleteMessage,
    onSortChange,
    sortedBy,
    recipientTypeAhead,
    assignmentTypeAhead,
    error,
  } = props;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [messageToDelete, setMessageToDelete] = useState<undefined | string>(undefined);
  const [isAdmin] = useCurrentUserInGroup("admin");
  const [isBackoffice] = useCurrentUserInGroup("backoffice");
  const [isB2BBackoffice, isNotB2BBackoffice] = useCurrentUserInGroup("b2bBackoffice");

  const handleDelete = useCallback(
    (id: string) => {
      setDialogOpen(true);
      setMessageToDelete(id);
    },
    [setDialogOpen, setMessageToDelete]
  );

  const onDismiss = useCallback(() => {
    setDialogOpen(false);
  }, [setDialogOpen]);

  const onAgree = useCallback(() => {
    setDialogOpen(false);
    if (messageToDelete) {
      onDeleteMessage(messageToDelete);
      setMessageToDelete(undefined);
    }
  }, [messageToDelete, onDeleteMessage]);

  const columns = useMemo(() => {
    return [
      { name: "Empfänger" },
      { name: "Nachrichten Typ" },
      isNotB2BBackoffice && { name: "Zugehöriger Auftrag" },
      { name: "Betreff" },
      { name: "Eingang", sortField: "date" },
      { name: "Gelesen" },
      { name: "" },
    ].filter((e) => !!e) as Array<{ name: string; sortField?: string }>;
  }, [isNotB2BBackoffice]);

  const filterItems = useMemo(() => {
    return [
      {
        name: "Empfänger",
        searchField: "recipient",
        setTypeAheadQuery: recipientTypeAhead.query,
        typeAheadOptions: recipientTypeAhead.options,
        typeAheadTestProperty: "number",
      },
      isNotB2BBackoffice && {
        name: "Zugehöriger Auftrag",
        searchField: "relatedAssignment",
        setTypeAheadQuery: assignmentTypeAhead.query,
        typeAheadOptions: assignmentTypeAhead.options,
        typeAheadTestProperty: "label",
      },
      { name: "Betreff", searchField: "subject" },
    ].filter((e: any) => e) as FilterItem[];
  }, [isNotB2BBackoffice, recipientTypeAhead.query, recipientTypeAhead.options, assignmentTypeAhead.query, assignmentTypeAhead.options]);

  const renderRead = useCallback((unread: boolean) => {
    if (unread) {
      return "-";
    }

    return <CheckIcon style={{ color: "green", fontSize: "16px" }} />;
  }, []);

  return (
    <>
      <DeleteMessageDialog open={dialogOpen} onDismiss={onDismiss} onAgree={onAgree} />
      <DataContainer
        onSearchSubmit={onSearch}
        loading={isLoading}
        addButton={isAdmin || isBackoffice || isB2BBackoffice ? "Neue Nachricht" : undefined}
        onAdd={isAdmin || isBackoffice || isB2BBackoffice ? onAdd : undefined}
        onReload={onReload}
        filterItems={filterItems}
      >
        {error && (
          <Alert data-testid="add-message-error" severity="error">
            {error}
          </Alert>
        )}
        <DataTablePaginatedAndSortable<Message>
          data-testid={`messagetable`}
          loading={isLoading}
          columns={columns}
          rows={messages}
          page={page}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          totalCount={totalCount}
          keyExtractor={(data) => data.id}
          onSortChange={onSortChange}
          sortedBy={sortedBy}
          renderRow={(data, index) => {
            return (
              <>
                <DataTableCell data-testid="name">{data.recipient ? messageService.getFullName(data.recipient) : "-"}</DataTableCell>
                <DataTableCell data-testid="message_type">{messageService.translateType(data.type) || "-"}</DataTableCell>
                {isNotB2BBackoffice && (
                  <DataTableCell data-testid="associated_order_number">
                    {data.relatedAssignment ? data.relatedAssignment.assignmentNumber : "-"}
                  </DataTableCell>
                )}
                <DataTableCell data-testid="subject">{data.subject || "-"}</DataTableCell>
                <DataTableCell data-testid="time_date_received">{format(new Date(data.date), "dd.MM.yyyy HH:mm") || "-"}</DataTableCell>
                <DataTableCell>{renderRead(data.unread)}</DataTableCell>
                <DataTableCell>
                  <Link data-testid={`link-read-row-${index}`} to={`/message/${data.id}`}>
                    <MailIcon color={"primary"} />
                  </Link>
                  {isAdmin && (
                    <DeleteIcon
                      data-testid={`field-message-row-${index}-delete`}
                      onClick={() => handleDelete(`${data.id}`)}
                      style={{ cursor: "pointer" }}
                      color={"secondary"}
                    />
                  )}
                </DataTableCell>
              </>
            );
          }}
          renderEmpty={() => (
            <EmptyState
              title="Noch keine Nachrichten vorhanden"
              description="Erstellen Sie jetzt Ihre erste Mitteilung, um alle Beteiligten schnell über Änderungen oder Neuigkeiten zu informieren."
              buttonLabel="Neue Nachricht"
              onButtonClick={onAdd}
            />
          )}
        />
        {error && (
          <Alert data-testid="add-message-error" severity="error">
            {error}
          </Alert>
        )}
      </DataContainer>
    </>
  );
};

export default MessagesTable;
