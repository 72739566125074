import { AxiosInstance } from "axios";
import handleAxiosError from "axios-handle-error";
import { CurrentPasswordValidationError, ElementNotFoundError, NetworkError } from "core/data/errors";

export interface ChangePasswordModel {
  newPassword: string;
  oldPassword: string;
}
interface PasswordResetModel {
  passwordResetToken: string;
  newPassword: string;
  email: string;
}

export default class ProfileApi {
  constructor(private unauthorizedClient: AxiosInstance, private authorizedClient: AxiosInstance) {}

  public async resetPassword(code: string, password: string, email: string) {
    return this.unauthorizedClient.post<PasswordResetModel>("/auth/password-reset", {
      passwordResetToken: code,
      email: email,
      newPassword: password,
    });
  }

  public async getResetCode(email: string) {
    return this.unauthorizedClient
      .get(`/auth/password-reset?email=${email}`)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          500: () => new Error(),
          NO_RESPONSE: () => new NetworkError(),
          404: () => new ElementNotFoundError(),
        });
      });
  }
  public async changePasword(model: ChangePasswordModel): Promise<void> {
    return this.authorizedClient
      .post("/auth/change-password", model)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          403: () => new CurrentPasswordValidationError(),
          NO_RESPONSE: () => new NetworkError(),
          404: () => new ElementNotFoundError(),
          500: () => new Error(),
        });
      });
  }
}
