import { AxiosInstance } from "axios";
import handleAxiosError from "axios-handle-error";
import Signatureform, { ResponseSignatureform, SignatureAssignmentRequest } from "../domain/entities/Signatureform";
import {
  BadRequestError,
  convertAxiosErrorToValidationError,
  ElementNotFoundError,
  NotAcceptableError,
  ServiceUnavailableError,
  UnauthorizedError,
  ValidationMessageError,
  WrongMimeTypeError,
} from "core/data/errors";
import { NetworkError } from "core/data/errors";
import { PagedResponse } from "core/utils";

export default class SignatureformsApi {
  constructor(private authClient: AxiosInstance) {}

  public async get(): Promise<PagedResponse<Signatureform>> {
    return this.authClient.get("/signatureforms").then((x) => x.data);
  }

  public async getById(id: string): Promise<ResponseSignatureform> {
    return this.authClient.get(`/signatureforms/${id}`).then((x) => x.data);
  }

  public async getDocument(id: string) {
    const res = await this.authClient.get(`/signatureforms/getDocument/${id}`, { responseType: "blob" }).then((x) => x.data);
    const fileBlob = window.URL.createObjectURL(new Blob([res], { type: "application/pdf" }));
    return fileBlob;
  }

  public async delete(id: string) {
    return this.authClient
      .delete(`/signatureforms/${id}`)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          401: () => new UnauthorizedError("Nicht Autorisiert", "401"),
          NO_RESPONSE: () => new NetworkError(),
        });
      });
  }

  public async create(formValues: Signatureform) {
    const body = new FormData();
    body.append("file", formValues.file!);
    body.append("title", formValues.title);
    return this.authClient
      .post(`/signatureforms/create`, body)
      .then((x) => {
        return;
      })
      .catch((e) => {
        throw handleAxiosError(e, {
          401: () => new UnauthorizedError("Nicht Autorisiert.", "401"),
          404: () => new WrongMimeTypeError(),
          NO_RESPONSE: () => new NetworkError(),
          500: () => new Error("Ein unerwarteteter Fehler ist aufgetreten."),
          403: () => new ValidationMessageError("Nutzer muss entweder einen Vermittler oder einem Verodner zugewiesen sein."),
          406: () => new NotAcceptableError("Die Datei ist möglicherweise mit Schadsoftware infiziert und kann nicht akzeptiert werden."),
          503: () => new ServiceUnavailableError("Keine Verbindung zum Virenscanner."),
          400: () => new BadRequestError("Die Datei darf nicht größer als 10MB sein."),
        });
      });
  }

  public async createAssignment(formValues: SignatureAssignmentRequest) {
    return this.authClient
      .post(`/signatureforms/createSignatureRequest`, formValues)
      .then((x) => {
        return;
      })
      .catch((e) => {
        throw handleAxiosError(e, {
          401: () => new UnauthorizedError("Nicht Autorisiert", "401"),
          NO_RESPONSE: () => new NetworkError(),
          404: () => new ElementNotFoundError(),
        });
      });
  }
}
