import { AppBar, Collapse, FormGroup, IconButton, makeStyles } from "@material-ui/core";
import React from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const useStyles = makeStyles((theme) => ({
  formSection: {
    flex: 1,
    padding: theme.spacing(2),
  },
  sectionHeader: {
    backgroundColor: "#f5f5f5",
    color: "rgba(0,0,0,0.54)",
    fontWeight: 600,
    fontSize: 12,
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    padding: "10px 15px",
  },
}));

interface FormSectionProps {
  label: string;
  collapsed: boolean;
  onClick: () => void;
}

const CollapseableFormSection: React.FC<FormSectionProps> = ({ label, children, onClick, collapsed }) => {
  const classes = useStyles();

  return (
    <>
      <AppBar className={classes.sectionHeader} position="static" color="default" elevation={0}>
        <div style={{ flexDirection: "row" }}>
          <IconButton aria-label="expand row" size="small" onClick={() => onClick()}>
            {collapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <span>{label}</span>
        </div>
      </AppBar>
      <Collapse in={collapsed} timeout={300}>
        <FormGroup className={classes.formSection}>{children}</FormGroup>
      </Collapse>
    </>
  );
};
export default CollapseableFormSection;
