import React from "react";
import { FormControlLabel, makeStyles, Radio, RadioGroup } from "@material-ui/core";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import FormContainer from "template/presentation/components/form/FormContainer";
import FormSection from "template/presentation/components/form/FormSection";
import { TextField } from "formik-material-ui";
import { Alert } from "@material-ui/lab";
import FormSubmitButton from "template/presentation/components/form/FormSubmitButton";

export interface CreateClientOnIndustrySoftwareFormValues {
  firstname: string;
  lastname: string;
  birthdate: string;
  street: string;
  zipcode: string;
  city: string;
  phone: string;
  email: string;
  gender: "M" | "F";
}

interface ICreateClientOnIndustrySoftwareFormProps {
  onSubmit: (values: CreateClientOnIndustrySoftwareFormValues) => void;
  error: string | null;
}

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(1),
  },
}));

const createClientSchema = Yup.object().shape({
  firstname: Yup.string().required("Benötigt"),
  lastname: Yup.string().required("Benötigt"),
  birthdate: Yup.string().required("Benötigt"),
  street: Yup.string().required("Benötigt"),
  zipcode: Yup.string().required("Benötigt").min(4, "Mindestens 4 Zeichen"),
  city: Yup.string().required("Benötigt"),
  phone: Yup.string().optional(),
  email: Yup.string().email("Ungültige Email Adresse").optional(),
});

const CreateClientOnIndustrySoftwareForm: React.FC<ICreateClientOnIndustrySoftwareFormProps> = (props) => {
  const { onSubmit, error } = props;

  const classes = useStyles();

  return (
    <Formik<CreateClientOnIndustrySoftwareFormValues>
      initialValues={{
        firstname: "",
        lastname: "",
        birthdate: "",
        street: "",
        zipcode: "",
        city: "",
        email: "",
        phone: "",
        gender: "F",
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
        } finally {
          setSubmitting(false);
        }
      }}
      validationSchema={createClientSchema}
    >
      {({ values, submitForm, isSubmitting, setFieldValue }) => {
        return (
          <FormContainer>
            <FormSection label={"Persönliche Informationen"}>
              <RadioGroup
                row
                aria-labelledby="salutation-group-label"
                name="gender"
                value={values.gender}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue("gender", (event.target as HTMLInputElement).value);
                }}
              >
                <FormControlLabel value="F" control={<Radio />} label="Frau" />
                <FormControlLabel value="M" control={<Radio />} label="Mann" />
              </RadioGroup>
              <Field
                data-testid="field-firstname"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"firstname"}
                label={"Vorname*"}
              />
              <Field
                data-testid="field-lastname"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"lastname"}
                label={"Nachname*"}
              />

              <Field
                data-testid="field-birthday"
                className={classes.field}
                component={TextField}
                type={"date"}
                InputLabelProps={{
                  shrink: true,
                }}
                variant={"outlined"}
                name={"birthdate"}
                label={"Geburtstag*"}
              />
            </FormSection>
            <FormSection label="Adresse">
              <Field
                data-testid="field-Street"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"street"}
                label={"Straße*"}
              />
              <Field
                data-testid="field-Zipcode"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"zipcode"}
                label={"Postleitzahl*"}
              />
              <Field
                data-testid="field-City"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"city"}
                label={"Stadt*"}
              />
            </FormSection>
            <FormSection label="Kontakt">
              <Field
                data-testid="field-email"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"email"}
                label={"Email"}
              />
              <Field
                data-testid="field-Phone"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"phone"}
                label={"Telefon"}
              />
            </FormSection>
            <FormSection>
              {error && (
                <Alert className={classes.field} data-testid="create-patient-error" severity="error">
                  {error}
                </Alert>
              )}

              <FormSubmitButton data-testid="button-createclient" disabled={isSubmitting} onClick={submitForm}>
                Erstellen
              </FormSubmitButton>
            </FormSection>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default CreateClientOnIndustrySoftwareForm;
