import ProfileApi, { ChangePasswordModel } from "../data/ProfileApi";

class ProfileService {
  constructor(private profileApi: ProfileApi) {}

  public changePassword(model: ChangePasswordModel): Promise<void> {
    return this.profileApi.changePasword(model);
  }

  public getResetCode(email: string) {
    return this.profileApi.getResetCode(email);
  }

  public resetPassword(code: string, password: string, email: string) {
    return this.profileApi.resetPassword(code, password, email);
  }
}

export default ProfileService;
