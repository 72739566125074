import { AxiosError } from "axios";

export class ElementNotFoundError extends Error {}
export class NetworkError extends Error {}
export class UnknownValidationError extends Error {}
export class CurrentPasswordValidationError extends Error {}
export class ClientNotFound extends Error {}

export class BadRequestError extends Error {
  constructor(message: string, public code?: string) {
    super(message);
  }
}

export class UnauthorizedError extends Error {
  constructor(message: string, public code?: string) {
    super(message);
  }
}
export class ValidationMessageError extends Error {
  constructor(message: string, public code?: string, public data?: any) {
    super(message);
  }
}
export class NotAcceptableError extends Error {
  constructor(message: string, public code?: string) {
    super(message);
  }
}

export class ServiceUnavailableError extends Error {
  constructor(message: string, public code?: string) {
    super(message);
  }
}

export class WrongMimeTypeError extends Error {}

export function convertAxiosErrorToValidationError(e: any) {
  const axiosError = e as AxiosError;

  const errorList: any[] | undefined = axiosError.response ? axiosError.response.data.errors : [];
  if (errorList === undefined || errorList.length < 1) {
    return new UnknownValidationError();
  }

  const firstError = errorList[0] ?? errorList;

  if (firstError.name === "ValidationMessage") {
    return new ValidationMessageError(firstError.message, firstError.code, firstError?.data);
  }

  return new UnknownValidationError();
}
