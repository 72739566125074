import AssignmentApi from "../../data/AssignmentsApi";
import AssignmentRequestModel, { PatchAssignmentModel } from "../../data/AssignmentRequestModel";
import Assignment, { CalendarAssignment } from "../entities/Assignment";
import { endOfDay, format, startOfDay } from "date-fns";
import { SelectOption } from "features/pools/domain/entities/PoolEntity";
import { addDays } from "date-fns/esm";
import { UpdateSigingRequestFormProps } from "features/pools/presentation/components/detail/UpdateSigningRequestDialog";
import { StartSignatureRequestDialogFormProps } from "features/pools/presentation/components/detail/StartSignatureRequestDialog";

export const assignmentTypes = [
  "WOUNDCARE",
  "DELIVERY",
  "ATTACHMENT",
  "COLLECTION",
  "CONSULTING",
  "REPAIR",
  "VISIT",
  "OTHER",
  "ATTACHMENT_B2C",
  "ATTACHMENT_B2C_WITH_APPOINTMENT",
  "ATTACHMENT_B2C_TO_B2B_WITH_APPOINTMENT",
  "CONSULTATION_REQUEST_B2C",
  "CONSULTATION_REQUEST_B2B",
  "CONSULTATION_REQUEST_B2C_TO_B2B",
  "VIDEO_CONSULTATION",
  "ON_SITE_CONSULTATION",
  "RENT_EXTENSION",
  "BUSINESS_INQUIRY",
  "EDIT_PROFILE",
  "ORDER",
] as const;
export const createUpdateAssignmentTypes = [
  "DELIVERY",
  "COLLECTION",
  "CONSULTING",
  "VIDEO_CONSULTATION",
  "ON_SITE_CONSULTATION",
  "REPAIR",
  "VISIT",
  "RENT_EXTENSION",
  "OTHER",
];

export type AssignmentType = typeof assignmentTypes[number];
export type CreateUpdateAssignmentType = typeof createUpdateAssignmentTypes[number];

export const TranslatedAssignmentTypeMap: { [key in AssignmentType]: string } = {
  WOUNDCARE: "Wundversorgung",
  DELIVERY: "Lieferung",
  COLLECTION: "Abholung",
  CONSULTING: "Beratung",
  VIDEO_CONSULTATION: "Videoberatung",
  CONSULTATION_REQUEST_B2C: "Videoberatungsanfrage B2C",
  CONSULTATION_REQUEST_B2C_TO_B2B: "Videoberatungsanfrage B2C->B2B",
  ATTACHMENT_B2C: "B2C Anhang",
  EDIT_PROFILE: "B2C Profiländerung",
  ATTACHMENT_B2C_TO_B2B_WITH_APPOINTMENT: "B2C Anhang mit Terminwunsch B2C-B2B",
  ATTACHMENT_B2C_WITH_APPOINTMENT: "B2C Anhang mit Terminwunsch",
  ATTACHMENT: "Anhang",
  REPAIR: "Reparatur",
  VISIT: "Heimbesuch",
  RENT_EXTENSION: "Mietverlängerung",
  BUSINESS_INQUIRY: "Geschäftsanfrage",
  CONSULTATION_REQUEST_B2B: "Videoberatungsanfrage B2B",
  ON_SITE_CONSULTATION: "Beratung vor Ort",
  ORDER: "Bestellung für Kunden",
  OTHER: "Andere",
};
export const TranslatedCreateUpdateAssignmentTypeMap: { [key in CreateUpdateAssignmentType]: string } = {
  DELIVERY: "Lieferung",
  COLLECTION: "Abholung",
  CONSULTING: "Beratung",
  VIDEO_CONSULTATION: "Videoberatung",
  REPAIR: "Reparatur",
  VISIT: "Heimbesuch",
  OTHER: "Sonstiges",
  RENT_EXTENSION: "Mietverlängerung",
};

export const assignmentStatus = ["TODO", "DONE", "SCHEDULING"] as const;

export type AssignmentStatus = typeof assignmentStatus[number];

const translationTableStatus: { [key in AssignmentStatus]: string } = {
  TODO: "Offen",
  DONE: "Erledigt",
  SCHEDULING: "Neuterminierung",
};

class AssignmentService {
  constructor(private assignmentApi: AssignmentApi) {}

  public async getById(id: string): Promise<Assignment | null> {
    return this.assignmentApi.getById(id);
  }

  public async getByNumber(assignmentNumber: string): Promise<Assignment | null> {
    return this.assignmentApi.getByNumber(assignmentNumber);
  }

  public async getAssignmentList(
    searchQueries: { assignmentNumber: string; client?: string; employee?: string; relatedCaseNumber?: string },
    page: number,
    pageSize: number,
    sortedBy: { field: string; order: "asc" | "desc" },
    status?: "todo" | "done"
  ) {
    const params = {
      assignmentNumber__ire: searchQueries.assignmentNumber,
      client: searchQueries.client,
      employee: searchQueries.employee,
      relatedCaseNumber: searchQueries.relatedCaseNumber,
      status: status ? status.toUpperCase() : undefined,
      __skip: (page - 1) * pageSize,
      __limit: pageSize,
      __sort: (sortedBy.order === "asc" ? "" : "-") + sortedBy.field,
    };
    return this.assignmentApi.get(params);
  }

  public async getTypeAheadOptions(searchQuery: string): Promise<Assignment[]> {
    const params = {
      assignmentNumber__ire: searchQuery,
    };

    return this.assignmentApi.getTypeAheadOptions(params);
  }

  public async create(data: AssignmentRequestModel): Promise<{ id: string }> {
    return this.assignmentApi.create(data);
  }

  public async update(id: string, data: AssignmentRequestModel) {
    return this.assignmentApi.update(id, data);
  }

  public async patch(id: string, model: PatchAssignmentModel): Promise<Assignment> {
    return this.assignmentApi.patch(id, model);
  }

  public getTypeOptions() {
    const options: SelectOption[] = [];

    for (const [key, values] of Object.entries(TranslatedCreateUpdateAssignmentTypeMap)) {
      options.push({ value: key, label: values });
    }

    return options;
  }

  public translateType(type: AssignmentType): string {
    return TranslatedAssignmentTypeMap[type];
  }

  public getStatusOptions() {
    const options: any = [];

    for (const [key, values] of Object.entries(translationTableStatus)) {
      options.push({ value: key, label: values });
    }

    return options;
  }

  public translateStatus(status: AssignmentStatus): string {
    return translationTableStatus[status];
  }

  public renderTime = ({ timeFrom, timeUntil, timeNote }: Assignment) => {
    if (timeFrom && timeUntil) {
      if (timeFrom === timeUntil) {
        return `um ${format(new Date(timeFrom), "HH:mm")} Uhr`;
      }
      return `${format(new Date(timeFrom), "HH:mm")} - ${format(new Date(timeUntil), "HH:mm")} Uhr`;
    }
    if (timeFrom && !timeUntil) {
      return `ab ${format(new Date(timeFrom), "HH:mm")} Uhr`;
    }
    if (!timeFrom && timeUntil) {
      return `bis ${format(new Date(timeUntil), "HH:mm")} Uhr`;
    }

    if (timeNote) {
      return timeNote === "kein" ? "keine Zeitangabe" : timeNote;
    }

    return "keine Zeitangabe";
  };

  public async cancelRentExtension(id: string): Promise<Assignment> {
    return this.assignmentApi.cancelRentExtension(id);
  }

  public async assignCaseAndClient(assignmentId: string, caseNumber: string): Promise<void> {
    return this.assignmentApi.assignCaseAndClient(assignmentId, caseNumber);
  }

  public async getCalendarAssignmentsForEmployeeInDatetimeRange(
    userId: string,
    // show next two weeks as default
    dateTimeRange: { start: Date; end: Date } = { start: startOfDay(new Date()), end: endOfDay(addDays(startOfDay(new Date()), 14)) }
  ): Promise<CalendarAssignment[]> {
    return this.assignmentApi.getCalendarAssignmentsForEmployeeInDatetimeRange(userId, dateTimeRange).then((assignments) => {
      return assignments.map((assignment) => {
        const transformedAssignment = { ...assignment };
        transformedAssignment.appointment.date = new Date(assignment.appointment.date);
        transformedAssignment.appointment.timeFrom = new Date(assignment.appointment.timeFrom);
        transformedAssignment.appointment.timeUntil = new Date(assignment.appointment.timeUntil);

        return transformedAssignment;
      });
    });
  }

  public async assignClient(assignmentId: string, clientNumber: string) {
    return this.assignmentApi.assignClient(assignmentId, clientNumber);
  }

  public async getPrescriberByAssignment(id?: string) {
    if (id) {
      return this.assignmentApi.getPrescriberByAssignment(id);
    }
    return undefined;
  }

  public async updateSigningRequest(id: string, model: UpdateSigingRequestFormProps) {
    return this.assignmentApi.updateSigningRequest(id, model);
  }

  public getSignatureRequestHistoryDocument(assignmentId: string, historyID: string) {
    return this.assignmentApi.getSignatureRequestHistoryDocument(assignmentId, historyID);
  }

  public startSignatureRequest(model: StartSignatureRequestDialogFormProps, id: string) {
    return this.assignmentApi.startSignatureRequest(model, id);
  }

  public deleteAssigment(id: string) {
    return this.assignmentApi.deleteAssigment(id);
  }
}

export default AssignmentService;
