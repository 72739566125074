import React from "react";
import ReadMessage from "../components/ReadMessage";
import MessageService from "../../domain/services/MessageService";
import { useQuery } from "react-query";
import { useService } from "core/di";
import UIRoute from "core/presentation/components/UIRoute";
import { RouteComponentProps, useParams } from "react-router-dom";

export interface ReadMessageRouteProps extends RouteComponentProps {}

const ReadMessageRoute = (props: ReadMessageRouteProps) => {
  const { id } = useParams<{ id: string }>();
  const messageService = useService(MessageService);
  const { data } = useQuery(["message", id], () => messageService.getById(id));

  return (
    <UIRoute {...props} title="Mitteilungen">
      {data ? <ReadMessage message={data} /> : <div />}
    </UIRoute>
  );
};

export default ReadMessageRoute;
