import { useCurrentUserInGroup } from "features/login/presentation/hooks/useUser";
import React, { useMemo } from "react";
import SubTabHeader from "template/presentation/components/nav/SubTabHeader";

interface Props {
  path: string;
  onTabChange: (path: string) => void;
}

const UsersSubHeader: React.FC<Props> = ({ path, onTabChange }) => {
  const [isAdmin] = useCurrentUserInGroup("admin");

  const tabs = useMemo(() => {
    if (isAdmin) {
      return [
        { value: "/users", label: "Alle" },
        { value: "/users/admin", label: "Admin" },
        { value: "/users/au", label: "Außendienst" },
        { value: "/users/b2b", label: "B2B" },
        { value: "/users/b2bBackoffice", label: "B2B-Innendienst" },
        { value: "/users/b2c", label: "B2C" },
        { value: "/users/backoffice", label: "Innendienst" },
      ];
    }
    return [{ value: "/users/b2c", label: "B2C" }];
  }, [isAdmin]);

  return <SubTabHeader currentPath={path} onTabChange={onTabChange} tabs={tabs} />;
};

export default UsersSubHeader;
