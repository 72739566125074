import { Box, Typography } from "@material-ui/core";
import { EmptyState } from "core/presentation/components/EmptyState";
import React, { useMemo } from "react";
import DataContainer from "template/presentation/components/table/DataContainer";
import DataTable, { DataTableCell } from "template/presentation/components/table/DataTable";
import B2CRecruitment from "../../domain/entities/B2CRecruitment";

export interface B2CRecruitmentTableProps {
  recruitments?: B2CRecruitment[] | null;
  isLoading: boolean;
  onReload: () => void;
}

const B2CRecruitmentTable = (props: B2CRecruitmentTableProps) => {
  const { recruitments, isLoading, onReload } = props;

  const columns = useMemo(() => {
    return [{ name: "Mitarbeiter" }, { name: "Anzahl" }];
  }, []);

  return (
    <DataContainer loading={isLoading} onReload={onReload}>
      <DataTable<B2CRecruitment>
        data-testid={"b2crecruitmenttable"}
        loading={isLoading}
        columns={columns}
        rows={recruitments}
        keyExtractor={(data) => data.employee._id}
        renderRow={(data) => {
          return (
            <>
              <DataTableCell>{`${data.employee.firstname} ${data.employee.lastname} (${data.employee.employeeNumber})`}</DataTableCell>
              <DataTableCell>{data.count}</DataTableCell>
            </>
          );
        }}
        renderEmpty={() => (
          <EmptyState title="Keine Anwerbungen vorhanden" description="In dem Zeitraum sind keine Anwerbungen durchgeführt worden." />
        )}
      />
    </DataContainer>
  );
};

export default B2CRecruitmentTable;
