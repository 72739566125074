import format from "date-fns/format";
import User from "features/users/domain/entities/UserEntity";
import React from "react";
import ContentSection from "template/presentation/components/content/ContentSection";
import DataTable, { DataTableCell } from "template/presentation/components/table/DataTable";

interface Props {
  assignmentHistory: any;
}

const columns = [
  { name: "Pool" },
  { name: "Bearbeitet durch" },
  { name: "Status" },
  { name: "Klassifizierung" },
  { name: "Geändert von" },
  { name: "Datum" },
];

export const AssignmentHistorySection: React.FC<Props> = ({ assignmentHistory }) => {
  const getEmployeeNumber = (user: User): string => {
    if (user.groups.includes("admin")) {
      return "Admin";
    } else if (user.groups.includes("b2c")) {
      return "Kunde";
    } else if (user.employeeNumber) {
      return user.employeeNumber;
    }
    return "";
  };

  return (
    <ContentSection label="Auftragshistorie">
      <DataTable<any>
        data-testid={`assignment-history`}
        columns={columns}
        rows={assignmentHistory}
        keyExtractor={(data) => data.added}
        renderRow={(data) => {
          return (
            <>
              <DataTableCell data-testid="pool name"> {data.poolName}</DataTableCell>
              <DataTableCell data-testid="edit by">
                {" "}
                {data.who ? `${data.who.firstname} ${data.who.lastname} (${getEmployeeNumber(data.who)})` : "-"}
              </DataTableCell>
              <DataTableCell data-testid="historyStatus"> {data.what}</DataTableCell>
              <DataTableCell data-testid="classification">
                {data.classification ? data.classification.name : "Unklassifiziert"}
              </DataTableCell>
              <DataTableCell data-testid="modified_by">
                {data.changedBy
                  ? `${data.changedBy.firstname} ${data.changedBy.lastname} (${getEmployeeNumber(data.changedBy)})`
                  : "Erstellung"}
              </DataTableCell>
              <DataTableCell data-testid="date">{format(new Date(data.added), "yyyy-MM-dd HH:mm:ss")}</DataTableCell>
            </>
          );
        }}
        renderEmpty={() => <div>Noch keine Historie verfügbar.</div>}
      />
    </ContentSection>
  );
};
