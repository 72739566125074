import React, { useCallback, useEffect, useState } from "react";
import PoolService from "features/pools/domain/services/PoolService";
import PoolAssignment, { PoolAssignmentDocument } from "features/pools/domain/entities/PoolAssignment";
import { ImagePreviewWithDialog } from "./ImagePreviewWithDialog";
import { useMemo } from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { ServiceUnavailableError } from "core/data/errors";

const renderDocument = (fileUrl: string, doc: PoolAssignmentDocument, testId?: string, signingRequest?: boolean) => {
  if (doc.type === "video") {
    return <video height="300px" key={doc.id} controls src={fileUrl} />;
  } else if (doc.type === "image") {
    return <ImagePreviewWithDialog testId={testId} key={doc.id} fileUri={fileUrl} />;
  } else if (doc.type === "document") {
    return (
      <div data-testid={testId}>
        <iframe
          title={doc.id}
          key={doc.id}
          src={fileUrl}
          id="pdf"
          width="800px"
          height={signingRequest && signingRequest === true ? "1200px" : "500px"}
          style={{ overflow: "auto" }}
        />
      </div>
    );
  }
};

const renderError = (e: any, doc?: PoolAssignmentDocument) => {
  if (e instanceof ServiceUnavailableError) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        Darstellungsfehler. Dienst nicht erreichbar.
      </Alert>
    );
  } else if (doc) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {doc.type === "document" ? "Dokument" : "Bild"} {doc.id} konnte nicht gefunden werden.
      </Alert>
    );
  } else {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        Unbekannter Fehler.
      </Alert>
    );
  }
};

export function useAttachedFiles(poolService: PoolService, assignment: PoolAssignment) {
  const [attachmentRecipe, setAttachmentRecipe] = useState<(void | undefined | JSX.Element)[] | undefined>();
  const [attachmentHealthInsuranceCard, setAttachmentHealthInsuranceCard] = useState<(void | undefined | JSX.Element)[] | undefined>();
  const [attachmentOther, setAttachmentOther] = useState<(void | undefined | JSX.Element)[] | undefined>();
  const [attachmentExemptionCertificate, setAttachmentExemptionCertificate] = useState<(void | undefined | JSX.Element)[] | undefined>();
  const [attachmentSignaturePage, setAttachmentSignaturePage] = useState<void | undefined | JSX.Element>();
  const [attachmentReceipt, setAttachmentReceipt] = useState<(void | undefined | JSX.Element)[] | undefined>();
  const [attachmentClarificationDocument, setAttachmentClarificationDocument] = useState<(void | undefined | JSX.Element)[] | undefined>();
  const [attachmentQuantityChange, setAttachmentQuantityChange] = useState<(void | undefined | JSX.Element)[] | undefined>();
  const [attachmentDeliveryNote, setAttachmentDeliveryNote] = useState<(void | undefined | JSX.Element)[] | undefined>();
  const [attachmentReport, setAttachmentReport] = useState<void | undefined | JSX.Element>();
  const [attachmentFeedback, setAttachmentFeedback] = useState<(void | undefined | JSX.Element)[] | undefined>();
  const [attachmentConsultationDocuments, setAttachmentConsultationDocuments] = useState<(void | undefined | JSX.Element)[] | undefined>();
  const [attachmentRentExtensionDocument, setAttachmentRentExtensionDocument] = useState<void | undefined | JSX.Element>();
  const [attachmentInboxDocument, setAttachmentInboxDocument] = useState<void | undefined | JSX.Element>();
  const [attachmentSignatureRequestDocument, setAttachmentSignatureRequestDocument] = useState<
    (void | undefined | JSX.Element)[] | undefined
  >();

  const getDocumentAndRender = useCallback(
    (assignmentId: string, doc: PoolAssignmentDocument, testId?: string, signingRequest?: boolean) => {
      return poolService
        .getDocByFilenameFromAssignment(assignmentId, doc.id, doc.type)
        .then((fileUrl) => renderDocument(fileUrl, doc, testId, signingRequest))
        .catch((e) => {
          return renderError(e, doc);
        });
    },
    [poolService]
  );

  const getNewDeliveryDocument = useCallback(
    async (assignmentId: string) => {
      return poolService
        .getDeliveryDocumentFromAssignment(assignmentId)
        .then((fileUrl) => [renderDocument(fileUrl, { id: "Lieferschein", type: "document" })])
        .catch((e) => {
          return renderError(e);
        });
    },
    [poolService]
  );

  useEffect(() => {
    const { assignmentId } = assignment;
    let deliveryDocument: any = undefined;

    if (
      assignment.type === "ATTACHMENT" ||
      assignment.type === "ATTACHMENT_B2C" ||
      assignment.type === "EDIT_PROFILE" ||
      assignment.type === "ATTACHMENT_B2C_WITH_APPOINTMENT" ||
      assignment.type === "CONSULTATION_REQUEST_B2C" ||
      assignment.type === "CONSULTATION_REQUEST_B2C_TO_B2B" ||
      assignment.type === "ON_SITE_CONSULTATION" ||
      assignment.type === "VIDEO_CONSULTATION" ||
      assignment.type === "CONSULTATION_REQUEST_B2B" ||
      assignment.type === "VIDEO_CONSULTATION_B2B"
    ) {
      Promise.all(assignment.report.recipe.map((doc) => getDocumentAndRender(assignmentId, doc))).then((recipeDocs) =>
        setAttachmentRecipe(recipeDocs)
      );
      Promise.all(
        assignment.report.healthInsuranceCard.map((doc, index) => getDocumentAndRender(assignmentId, doc, `healthInsuranceCard-${index}`))
      ).then((healthInsuranceCardDocs) => setAttachmentHealthInsuranceCard(healthInsuranceCardDocs));
      Promise.all(
        assignment.report.other.map((doc, index) => getDocumentAndRender(assignmentId, doc, `report-${index}`))
      ).then((otherDocs) => setAttachmentOther(otherDocs));

      if (assignment.report.exemptionCertificate) {
        Promise.all(
          assignment.report.exemptionCertificate.map((doc) => getDocumentAndRender(assignmentId, doc))
        ).then((exemptionCertificateDocs) => setAttachmentExemptionCertificate(exemptionCertificateDocs));
      }
      if (assignment.type === "ATTACHMENT") {
        if (assignment.report.attachments) {
          Promise.all(
            assignment.report.attachments.map((doc: any, index: number) =>
              getDocumentAndRender(assignmentId, { id: doc.path.split("/").pop(), type: "document" }, `enligtenment-${index}`)
            )
          ).then((enlightenmentDocs) => {
            setAttachmentClarificationDocument(enlightenmentDocs as JSX.Element[]);
          });
        }
      }
    } else if (assignment.type === "DELIVERY" || assignment.type === "UNPLANNED_DELIVERY") {
      Promise.all(
        assignment.report.recipe.map((doc, index) => getDocumentAndRender(assignmentId, doc, `recipe-${index}`))
      ).then((recipeDocs) => setAttachmentRecipe(recipeDocs));
      Promise.all(
        assignment.report.other.map((doc, index) => getDocumentAndRender(assignmentId, doc, `other-${index}`))
      ).then((otherDocs) => setAttachmentOther(otherDocs));
      Promise.all(
        assignment.report.receipt.map((doc, index) => getDocumentAndRender(assignmentId, doc, `receipt-${index}`))
      ).then((otherDocs) => setAttachmentReceipt(otherDocs));
      Promise.all(
        assignment.report.clarificationDocument.map((doc, index) =>
          getDocumentAndRender(assignmentId, doc, `clarification-document-${index}`)
        )
      ).then((otherDocs) => setAttachmentClarificationDocument(otherDocs));
      Promise.all(
        assignment.report.quantitiyChange.map((doc, index) => getDocumentAndRender(assignmentId, doc, `quantity-change-${index}`))
      ).then((otherDocs) => setAttachmentQuantityChange(otherDocs));
      Promise.all(
        assignment.report.deliveryNote.map((doc, index) => getDocumentAndRender(assignmentId, doc, `delivery-note-${index}`))
      ).then(async (otherDocs) => {
        if (otherDocs.length > 0) {
          setAttachmentDeliveryNote(otherDocs);
        } else if (otherDocs.length === 0) {
          deliveryDocument = await getNewDeliveryDocument(assignmentId);
          setAttachmentDeliveryNote(deliveryDocument);
        }
        if (otherDocs.length > 0 && assignment.report.signaturePage) {
          await getDocumentAndRender(assignmentId, assignment.report.signaturePage, `signature-page`).then((doc) => {
            setAttachmentSignaturePage(doc);
          });
        }
      });
      if (assignment.report.report) {
        getDocumentAndRender(assignmentId, assignment.report.report, "report").then((doc) => setAttachmentReport(doc));
      }
    } else if (assignment.type === "COLLECTION" || assignment.type === "REPAIR") {
      Promise.all(
        assignment.report.recipe.map((doc, index) => getDocumentAndRender(assignmentId, doc, `recipe-${index}`))
      ).then((recipeDocs) => setAttachmentRecipe(recipeDocs));
      Promise.all(
        assignment.report.other.map((doc, index) => getDocumentAndRender(assignmentId, doc, `other-${index}`))
      ).then((otherDocs) => setAttachmentOther(otherDocs));
      Promise.all(
        assignment.report.feedback.map((doc, index) => getDocumentAndRender(assignmentId, doc, `feedback-${index}`))
      ).then((docs) => setAttachmentFeedback(docs));

      if (assignment.report.report) {
        getDocumentAndRender(assignmentId, assignment.report.report, "report").then((doc) => setAttachmentReport(doc));
      }
    } else if (assignment.type === "CONSULTING") {
      Promise.all(
        assignment.report.recipe.map((doc, index) => getDocumentAndRender(assignmentId, doc, `recipe-${index}`))
      ).then((recipeDocs) => setAttachmentRecipe(recipeDocs));
      Promise.all(
        assignment.report.other.map((doc, index) => getDocumentAndRender(assignmentId, doc, `other-${index}`))
      ).then((otherDocs) => setAttachmentOther(otherDocs));
      Promise.all(
        assignment.report.consultationDocuments.map((doc, index) => getDocumentAndRender(assignmentId, doc, `consultation-${index}`))
      ).then((docs) => setAttachmentConsultationDocuments(docs));

      if (assignment.report.report) {
        getDocumentAndRender(assignmentId, assignment.report.report, "report").then((doc) => setAttachmentReport(doc));
      }
    } else if (assignment.type === "RENT_EXTENSION") {
      // getDocumentAndRender(assignmentId, assignment.report.signature, "img-signature").then((doc) => setAttachmentSignature(doc));
      getDocumentAndRender(assignmentId, assignment.report.rentExtensionDocument, "pdf-rentextension").then((doc) =>
        setAttachmentRentExtensionDocument(doc)
      );
    } else if (assignment.type === "INBOX") {
      if (assignment.report.report) {
        getDocumentAndRender(assignmentId, assignment.report.report, "report").then((doc) => setAttachmentInboxDocument(doc));
      }
    } else if (assignment.type === "WOUNDCARE") {
      if (assignment.report) {
        Promise.all(
          assignment.report.other.map((doc, index) => getDocumentAndRender(assignmentId, doc, `other-${index}`))
        ).then((otherDocs) => setAttachmentOther(otherDocs));
      }
    } else if (assignment.type === "SIGNATURE_REQUEST") {
      const signaturesDoc = assignment.report.signatureDocument;
      if (assignment.report) {
        Promise.all([
          getDocumentAndRender(assignmentId, assignment.report.document, "signatureRequest", signaturesDoc ? false : true),
          signaturesDoc && getDocumentAndRender(assignmentId, assignment.report!.signatureDocument!, "signatureRequest", false),
        ]).then((docs) => setAttachmentSignatureRequestDocument(docs));
      }
    }
  }, [poolService, getDocumentAndRender, assignment.type, assignment.report, assignment.assignmentId]);

  const result = useMemo(
    () => ({
      attachmentRecipe,
      attachmentHealthInsuranceCard,
      attachmentOther,
      attachmentExemptionCertificate,
      attachmentSignaturePage,
      attachmentReceipt,
      attachmentClarificationDocument,
      attachmentQuantityChange,
      attachmentDeliveryNote,
      attachmentReport,
      attachmentFeedback,
      attachmentConsultationDocuments,
      attachmentRentExtensionDocument,
      attachmentInboxDocument,
      attachmentSignatureRequestDocument,
    }),
    [
      attachmentClarificationDocument,
      attachmentDeliveryNote,
      attachmentExemptionCertificate,
      attachmentHealthInsuranceCard,
      attachmentOther,
      attachmentQuantityChange,
      attachmentReceipt,
      attachmentRecipe,
      attachmentSignaturePage,
      attachmentReport,
      attachmentFeedback,
      attachmentConsultationDocuments,
      attachmentRentExtensionDocument,
      attachmentInboxDocument,
      attachmentSignatureRequestDocument,
    ]
  );

  return result;
}
