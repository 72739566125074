import { PoolAssignmentReportDelivery } from "features/pools/domain/entities/PoolAssignment";
import { RehaItem } from "features/rehaItems/domain/entities/RehaItem";
import React, { useMemo } from "react";
import ContentContainer from "template/presentation/components/content/ContentContainer";
import ContentSection from "template/presentation/components/content/ContentSection";
import LabeledValue from "template/presentation/components/content/LabeledValue";
import ValueGrid from "template/presentation/components/content/ValueGrid";
import { RehaItemsPoolView } from "../RehaItemsPoolView";
import { AnnotationSubSection } from "./AnnotationSubSection";

interface Props {
  report: PoolAssignmentReportDelivery;
  rehaItems?: RehaItem[];
}

export const DeliveryInfoSection: React.FC<Props> = ({ report, rehaItems }) => {
  const status = useMemo(() => {
    switch (report.result) {
      case "delivered":
        return "Zugestellt";
      case "notEncountered":
        return "Niemanden angetroffen";
      case "unfeasible":
        return "Nicht möglich";
      default:
        return "Unbekannter Status";
    }
  }, [report.result]);
  const signatureFrom = useMemo(() => {
    switch (report.signatureFrom) {
      case "client":
        return "Kunde";
      case "neighbor":
        return "Nachbar";
      case "relative":
        return "Verwandter";
      case "staff":
        return "Außendienstmitarbeiter";
      case undefined:
      case null:
        return "-";
      default:
        return "Unbekannt";
    }
  }, [report.signatureFrom]);
  const payment = useMemo(() => {
    switch (report.payment) {
      case "cash":
        return "Bargeld";
      case "invoice":
        return "Rechnung";
      case "none":
        return "Keine Zahlung erfolgt";
      default:
        return "Unbekannt";
    }
  }, [report.payment]);
  return (
    <ContentSection label="Details">
      <ContentContainer>{report.annotation && <AnnotationSubSection annotation={report.annotation} />}</ContentContainer>
      <ValueGrid>
        <LabeledValue data-testid="status" label="Status" value={status} />
        <LabeledValue data-testid="comment" label="Kommentar" value={report.comment} />
        <LabeledValue data-testid="personSigning" label="Signiert von" value={signatureFrom} />
        <LabeledValue data-testid="sortPayment" label="Bezahlart" value={payment} />
      </ValueGrid>
      <RehaItemsPoolView rehaItems={rehaItems} />
    </ContentSection>
  );
};
