import handleAxiosError from "axios-handle-error";
import { PagedResponse } from "core/utils";
import FormularConverter from "features/formular-center/converters/FormularConverter";
import FormularApi from "features/formular-center/data/FormularApi";
import FormularResponseModel from "features/formular-center/data/FormularModel";
import FormularRequestModel from "features/formular-center/data/FormularRequestModel";
import { CreateOrUpdateFormularFormValues } from "features/formular-center/presentation/components/CreateOrUpdateFormularForm";
import Formular, { FormularTypes } from "../entities/Formular";

const translationTableType: { [key in FormularTypes]: string } = {
  DELIVERYNOTE: "Lieferschein",
};
export default class FormularService {
  constructor(private formularApi: FormularApi) {}

  public async get(query: any): Promise<PagedResponse<FormularResponseModel>> {
    return this.formularApi.get(query);
  }

  public async update(id: string, values: CreateOrUpdateFormularFormValues): Promise<any> {
    return this.formularApi.update(id, values);
  }

  public async getById(id: string): Promise<Formular> {
    return this.formularApi.getById(id).then((data) => FormularConverter.fromResponseModelToEntity(data));
  }

  public async create(formular: FormularRequestModel): Promise<any> {
    return this.formularApi.create(formular);
  }

  public translateType(type: FormularTypes): string {
    return translationTableType[type];
  }

  public async getFile(formularId: string) {
    return this.formularApi
      .getFile(formularId)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          "*": () => new Error("Fehler beim generieren des Dokuments"),
        });
      });
  }
}
