import { AxiosInstance } from "axios";
import AdapterEntitySizeEntryModel from "./AdapterEntitySizeEntryModel";
import AdapterLogEntryModel from "./AdapterLogEntryModel";
import AdapterModel from "./AdapterModel";

export default class AdapterApi {
  constructor(private authClient: AxiosInstance) {}

  public get(): Promise<AdapterModel[]> {
    return this.authClient.get<AdapterModel[]>(`/adapter`).then((x) => x.data);
  }
  public getAdapterStatus(): Promise<"OFFLINE" | "ONLINE" | null> {
    return this.authClient.get(`/adapter/defaultAdapterStatus`).then((x) => x.data);
  }

  public getById(id: string): Promise<AdapterModel> {
    return this.authClient.get<AdapterModel>(`/adapter/${id}`).then((x) => x.data);
  }

  public getLogsById(id: string): Promise<AdapterLogEntryModel[]> {
    return this.authClient.get<AdapterLogEntryModel[]>(`/adapter/${id}/logs`).then((x) => x.data);
  }

  public syncAllClients(id: string): Promise<any> {
    return this.authClient.post<any>(`/adapter/${id}/sync-clients`).then((x) => x.data);
  }

  public syncAllClassifications(id: string): Promise<any> {
    return this.authClient.post<any>(`/adapter/${id}/sync-classifications`).then((x) => x.data);
  }

  public syncAllBranches(id: string): Promise<any> {
    return this.authClient.post<any>(`/adapter/${id}/sync-branches`).then((x) => x.data);
  }

  public syncAllPrescribers(id: string): Promise<any> {
    return this.authClient.post<any>(`/adapter/${id}/sync-prescribers`).then((x) => x.data);
  }

  public syncAllIntermediaries(id: string): Promise<any> {
    return this.authClient.post<any>(`/adapter/${id}/sync-intermediaries`).then((x) => x.data);
  }
  public syncAllRehaItems(id: string): Promise<any> {
    return this.authClient.post<any>(`/adapter/${id}/sync-rehaItems`).then((x) => x.data);
  }
  public getTableSizesById(id: string): Promise<AdapterEntitySizeEntryModel[]> {
    return this.authClient.get<AdapterEntitySizeEntryModel[]>(`/adapter/${id}/tableSizes`).then((x) => x.data);
  }
}
