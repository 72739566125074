import format from "date-fns/format";
import { PoolAssignmentReportB2COnSiteAppointment } from "features/pools/domain/entities/PoolAssignment";
import { RehaItem } from "features/rehaItems/domain/entities/RehaItem";
import React from "react";
import ContentSection from "template/presentation/components/content/ContentSection";
import LabeledValue from "template/presentation/components/content/LabeledValue";
import ValueGrid from "template/presentation/components/content/ValueGrid";
import { RehaItemsPoolView } from "../RehaItemsPoolView";

interface Props {
  report: PoolAssignmentReportB2COnSiteAppointment;
  rehaItems?: RehaItem[];
}

export const OnSiteConsultationInfoSection: React.FC<Props> = ({ report, rehaItems }) => {
  return (
    <ContentSection label="Details">
      <ValueGrid>
        <LabeledValue data-testid="reportText" label="Anliegen" value={report.text} />
        <LabeledValue
          data-testid="reportFormWhom"
          label="Anfrage für andere Person?"
          value={report.forWhom === "myself" ? "Nein" : "Ja: " + report.otherPerson}
        />
        <LabeledValue
          data-testid="reportStreetZipcodeCity"
          label="Adresse für Lieferungen/Termine"
          value={report.street + ", " + report.zipcode + " " + report.city}
        />
        <LabeledValue
          data-testid="reportPreferredCommunication"
          label="Rückfragen bevorzugt per"
          value={report.preferredCommunication === "email" ? "E-Mail" : "Telefon"}
        />
        <LabeledValue data-testid="reportPhone" label="Telefon" value={report.phone} />
        <LabeledValue data-testid="reportEmail" label="E-Mail" value={report.email} />
        <LabeledValue
          data-testid="reportAppointmentDateAndTime"
          label="Termin"
          value={format(report.appointmentDateAndTime, "dd.MM.yyyy HH:mm")}
        />
        <LabeledValue
          data-testid="reportAppointmentStatus"
          label="Status"
          value={report.appointmentStatus === "ACCEPTED" ? "Akzeptiert" : "Storniert"}
        />
      </ValueGrid>
      <RehaItemsPoolView rehaItems={rehaItems} />
    </ContentSection>
  );
};
