import React from "react";
import ContentContainer from "template/presentation/components/content/ContentContainer";
import ContentSection from "template/presentation/components/content/ContentSection";
import ValueGrid from "template/presentation/components/content/ValueGrid";

interface Props {
  sections: {
    attachmentDeliveryNote: (void | undefined | JSX.Element)[] | undefined;
    attachmentQuantityChange: (void | undefined | JSX.Element)[] | undefined;
    attachmentClarificationDocument: (void | undefined | JSX.Element)[] | undefined;
    attachmentReceipt: (void | undefined | JSX.Element)[] | undefined;
    attachmentReport: void | undefined | JSX.Element;
    attachmentSignaturePage: void | undefined | JSX.Element;
    attachmentRecipe: (void | undefined | JSX.Element)[] | undefined;
    attachmentHealthInsuranceCard: (void | undefined | JSX.Element)[] | undefined;
    attachmentOther: (void | undefined | JSX.Element)[] | undefined;
    attachmentExemptionCertificate: (void | undefined | JSX.Element)[] | undefined;
    attachmentFeedback: (void | undefined | JSX.Element)[] | undefined;
    attachmentConsultationDocuments: (void | undefined | JSX.Element)[] | undefined;
    attachmentRentExtensionDocument: void | undefined | JSX.Element;
    attachmentInboxDocument: void | undefined | JSX.Element;
    attachmentSignatureRequestDocument: (void | undefined | JSX.Element)[] | undefined;
  };
}

export const AttachedFilesSection: React.FC<Props> = ({ sections }) => {
  const {
    attachmentDeliveryNote,
    attachmentQuantityChange,
    attachmentClarificationDocument,
    attachmentReceipt,
    attachmentReport,
    attachmentSignaturePage,
    attachmentRecipe,
    attachmentHealthInsuranceCard,
    attachmentOther,
    attachmentExemptionCertificate,
    attachmentFeedback,
    attachmentConsultationDocuments,
    attachmentRentExtensionDocument,
    attachmentInboxDocument,
    attachmentSignatureRequestDocument,
  } = sections;

  return (
    <ContentContainer style={{ margin: 16 }}>
      {attachmentDeliveryNote && (
        <ContentSection label="Lieferschein">
          <ValueGrid>{attachmentDeliveryNote}</ValueGrid>
        </ContentSection>
      )}
      {attachmentSignaturePage && (
        <ContentSection label="Unterschriftenblatt">
          <ValueGrid>{attachmentSignaturePage}</ValueGrid>
        </ContentSection>
      )}
      {attachmentQuantityChange && (
        <ContentSection label="Mengenänderungsdokumente">
          <ValueGrid>{attachmentQuantityChange}</ValueGrid>
        </ContentSection>
      )}
      {attachmentClarificationDocument && (
        <ContentSection label="Beratungsaufklärungsdokumente">
          <ValueGrid itemSize={12}>{attachmentClarificationDocument}</ValueGrid>
        </ContentSection>
      )}
      {attachmentReceipt && (
        <ContentSection label="Quittung">
          <ValueGrid>{attachmentReceipt}</ValueGrid>
        </ContentSection>
      )}
      {attachmentFeedback && (
        <ContentSection label="Feedback">
          <ValueGrid>{attachmentFeedback}</ValueGrid>
        </ContentSection>
      )}
      {attachmentConsultationDocuments && (
        <ContentSection label="Beratungsdokumente">
          <ValueGrid>{attachmentConsultationDocuments}</ValueGrid>
        </ContentSection>
      )}
      {attachmentRentExtensionDocument && (
        <ContentSection label="Mietverlängerungsdokument">
          <ValueGrid>{attachmentRentExtensionDocument}</ValueGrid>
        </ContentSection>
      )}
      {attachmentRecipe && (
        <ContentSection label="Rezept">
          <ValueGrid>{attachmentRecipe}</ValueGrid>
        </ContentSection>
      )}
      {attachmentHealthInsuranceCard && (
        <ContentSection label="Krankenkasse">
          <ValueGrid>{attachmentHealthInsuranceCard}</ValueGrid>
        </ContentSection>
      )}
      {attachmentExemptionCertificate && (
        <ContentSection label="Befreiungsnachweis">
          <ValueGrid>{attachmentExemptionCertificate}</ValueGrid>
        </ContentSection>
      )}
      {attachmentOther && (
        <ContentSection label="Andere Anhänge">
          <ValueGrid>{attachmentOther}</ValueGrid>
        </ContentSection>
      )}
      {attachmentReport && (
        <ContentSection label="Bericht">
          <ValueGrid>{attachmentReport}</ValueGrid>
        </ContentSection>
      )}
      {attachmentInboxDocument && (
        <ContentSection label="PDF Scan">
          <ValueGrid>{attachmentInboxDocument}</ValueGrid>
        </ContentSection>
      )}
      {attachmentSignatureRequestDocument && (
        <ContentSection label="Unterschriftenanforderung">
          <ValueGrid>{attachmentSignatureRequestDocument}</ValueGrid>
        </ContentSection>
      )}
    </ContentContainer>
  );
};
