import React from "react";
import PeopleIcon from "@material-ui/icons/People";
import DnsRoundedIcon from "@material-ui/icons/DnsRounded";
import TimerIcon from "@material-ui/icons/Timer";
import SettingsIcon from "@material-ui/icons/Settings";
import PoolIcon from "@material-ui/icons/Pool";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import ClassIcon from "@material-ui/icons/Class";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import CalendarIcon from "@material-ui/icons/Event";
import B2BSpace from "features/clients/domain/services/B2BSpace";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import BallotIcon from "@material-ui/icons/Ballot";
import PersonIcon from "@material-ui/icons/Person";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import AssignmentIcon from "@material-ui/icons/Assignment";

export default function adminMenu(shopEnabled?: boolean, shopUrl?: string) {
  return [
    {
      id: "Allgemein",
      children: [
        { id: "Benutzer", icon: <PersonIcon />, path: "/users" },
        { id: "Kunden", icon: <PeopleIcon />, path: "/clients/b2c" },
        { id: "Mitteilungen", icon: <DnsRoundedIcon />, path: "/messages/" },
        { id: "Produkte Wundversorgung", icon: <ShoppingCartIcon />, path: "/products/" },
        { id: "Shop", icon: <ShoppingCartIcon />, path: `${shopUrl}/admin`, external: true, hide: !shopEnabled },
        { id: "Formular Center", icon: <BallotIcon />, path: "/formular-center/" },
      ],
    },
    {
      id: "Außendienst",
      children: [
        { id: "Mitarbeiter", icon: <PersonIcon />, path: "/users/au" },
        { id: "Aufträge", icon: <LocalShippingIcon />, path: "/assignments" },
        { id: "Anwerbungen (B2C)", icon: <GroupAddIcon />, path: "/b2c-recruitments" },
        { id: "Kalender", icon: <CalendarIcon />, path: "/assignments-calendar" },
      ],
    },
    {
      id: "Innendienst",
      children: [
        { id: "Vorgänge", icon: <AssignmentIcon />, path: "/cases" },
        {
          id: "Neuen Endkunden in Branchensoftware anlegen",
          icon: <PersonAddIcon />,
          path: "/clients/createOnIndustrySoftware",
        },
      ],
    },
    {
      id: "B2B",
      children: [
        { id: "Vermittler", icon: <BusinessCenterIcon />, path: `/clients/${B2BSpace.INTERMEDIARY}` },
        { id: "Verordner", icon: <LocalHospitalIcon />, path: `/clients/${B2BSpace.PRESCRIBER}` },
        { id: "Mitarbeiter", icon: <PersonIcon />, path: "/users/b2b" },
      ],
    },
    {
      id: "B2C",
      children: [
        { id: "Accounts", icon: <AccountBoxIcon />, path: "/users/b2c" },
        { id: "Kunden", icon: <PeopleIcon />, path: "/clients/b2c" },
      ],
    },
    {
      id: "Niederlassungen",
      children: [
        { id: "Interne Niederlassungen", icon: <HomeWorkIcon />, path: "/branches/internal" },
        { id: "B2B-Niederlassungen", icon: <HomeWorkIcon />, path: "/branches/b2b" },
      ],
    },
    {
      id: "Pools",
      children: [
        { id: "Interne Pools", icon: <BallotIcon />, path: "/pools/internal" },
        { id: "B2B-Pools", icon: <BallotIcon />, path: "/pools/b2b" },
      ],
    },
    {
      id: "Klassifizierungen",
      children: [{ id: "Klassifizierungen", icon: <ClassIcon />, path: "/classifications" }],
    },
    {
      id: "System",
      children: [{ id: "Adapter", icon: <SettingsIcon />, path: "/adapter" }],
    },
  ];
}
