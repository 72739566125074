import AdapterApi from "../data/AdapterApi";
import Adapter from "./entities/Adapter";
import AdapterConverter from "./converter/AdapterConverter";
import AdapterLogEntry from "./entities/AdapterLogEntry";
import AdapterLogEntryConverter from "./converter/AdapterLogEntryConverter";
import AdapterEntitySizeEntry from "./entities/AdapterEntitySizeEntry";
import AdapterEntitySizeEntryConverter from "./converter/AdapterEntitySizeEntryConverter";

export default class AdapterService {
  constructor(private adapterApi: AdapterApi) {}

  public async get(): Promise<Adapter[]> {
    return this.adapterApi.get().then((adapterModels) => adapterModels.map((x) => AdapterConverter.fromModelToEntity(x)));
  }
  public async getAdapterStatus(): Promise<"OFFLINE" | "ONLINE" | null> {
    return this.adapterApi.getAdapterStatus();
  }

  public async getById(id: string): Promise<Adapter> {
    return this.adapterApi.getById(id).then((adapterModel) => AdapterConverter.fromModelToEntity(adapterModel));
  }

  public async getLogsById(id: string): Promise<AdapterLogEntry[]> {
    return this.adapterApi.getLogsById(id).then((logEntries) => logEntries.map((x) => AdapterLogEntryConverter.fromModelToEntity(x)));
  }

  public async syncAllClients(adapterId: string): Promise<void> {
    return this.adapterApi.syncAllClients(adapterId);
  }

  public async syncAllClassifications(adapterId: string): Promise<void> {
    return this.adapterApi.syncAllClassifications(adapterId);
  }

  public async syncAllBranches(adapterId: string): Promise<void> {
    return this.adapterApi.syncAllBranches(adapterId);
  }

  public async syncAllPrescribers(adapterId: string): Promise<void> {
    return this.adapterApi.syncAllPrescribers(adapterId);
  }

  public async syncAllIntermediaries(adapterId: string): Promise<void> {
    return this.adapterApi.syncAllIntermediaries(adapterId);
  }
  public async syncAllRehaItems(adapterId: string): Promise<void> {
    return this.adapterApi.syncAllRehaItems(adapterId);
  }
  public async getTableSizesById(adapterId: string): Promise<AdapterEntitySizeEntry[]> {
    return this.adapterApi
      .getTableSizesById(adapterId)
      .then((tableSizeEntries) => tableSizeEntries.map((x) => AdapterEntitySizeEntryConverter.fromModelToEntity(x)));
  }
}
