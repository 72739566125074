import { RehaItem } from "features/rehaItems/domain/entities/RehaItem";
import React from "react";
import ContentSection from "template/presentation/components/content/ContentSection";
import DataTable, { DataTableCell } from "template/presentation/components/table/DataTable";

interface Props {
  rehaItems?: RehaItem[];
}

export const RehaItemsPoolView: React.FC<Props> = ({ rehaItems }) => {
  return rehaItems && rehaItems.length > 0 ? (
    <ContentSection label="Auftragsbezogene Rehamittel">
      <DataTable<RehaItem>
        data-testid={"rehaItemtable"}
        columns={[
          { name: "Rehamittelnummer" },
          { name: "Hersteller" },
          { name: "Modell" },
          { name: "Seriennummer" },
          { name: "Himi-Nummer" },
          { name: "Beschreibung" },
        ]}
        rows={rehaItems}
        keyExtractor={(data) => data.id}
        renderRow={(data, idx) => {
          return (
            <>
              <DataTableCell data-testid={`rehaitem-number-${idx}`}>{data.number ?? "-"}</DataTableCell>
              <DataTableCell data-testid={`rehaitem-manufacturer-${idx}`}>{data.manufacturer ?? "-"}</DataTableCell>
              <DataTableCell data-testid={`rehaitem-model-${idx}`}>{data.model ?? "-"}</DataTableCell>
              <DataTableCell data-testid={`rehaitem-serialNumber-${idx}`}>{data.serialNumber ?? "-"}</DataTableCell>
              <DataTableCell data-testid={`rehaitem-helpingRessourcesNumber-${idx}`}>{data.himiNumber ?? "-"}</DataTableCell>
              <DataTableCell data-testid={`rehaitem-description-${idx}`}>{data.description ?? "-"}</DataTableCell>
            </>
          );
        }}
        renderEmpty={() => <div> Keine Rehamittel betroffen.</div>}
      />
    </ContentSection>
  ) : (
    <div></div>
  );
};
