import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import FormContainer from "template/presentation/components/form/FormContainer";
import FormSection from "template/presentation/components/form/FormSection";
import { CheckboxWithLabel, SimpleFileUpload } from "formik-material-ui";
import { Alert } from "@material-ui/lab";
import FormSubmitButton from "template/presentation/components/form/FormSubmitButton";

export interface ImportProductsFormValues {
  file: Blob | null;
  replace: boolean;
}

interface IUpdateAssignmentFormProps {
  onSubmit: (values: ImportProductsFormValues) => void;
  error: string | null;
}

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(1),
  },
}));

const importProductsSchema = Yup.object().shape({
  file: Yup.string().required("Benötigt"),
  replace: Yup.boolean().required("Benötigt"),
});

const ImportProductsForm: React.FC<IUpdateAssignmentFormProps> = (props) => {
  const { onSubmit, error } = props;
  const classes = useStyles();

  const initValues = useMemo((): ImportProductsFormValues => {
    return {
      file: null,
      replace: false,
    };
  }, []);

  return (
    <Formik<ImportProductsFormValues>
      initialValues={initValues}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
        } finally {
          setSubmitting(false);
        }
      }}
      validationSchema={importProductsSchema}
    >
      {({ submitForm, isSubmitting, values }) => {
        return (
          <FormContainer>
            <FormSection label={"Datei"}>
              <Field component={SimpleFileUpload} name="file" label="CSV-Datei" accept=".csv" />
            </FormSection>

            <FormSection label={"Operation"}>
              <Field
                data-testid="field-replace"
                component={CheckboxWithLabel}
                type="checkbox"
                name="replace"
                Label={{ label: "Bestehende Liste ersetzen" }}
              />
            </FormSection>

            <FormSection>
              {error && (
                <Alert className={classes.field} data-testid="productsimport-form-error" severity="error">
                  {error}
                </Alert>
              )}

              <FormSubmitButton data-testid="button-createassignment" disabled={isSubmitting} onClick={submitForm}>
                {"Importieren"}
              </FormSubmitButton>
            </FormSection>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default ImportProductsForm;
