import { makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { ChangePasswordModel } from "features/profile/data/ProfileApi";
import { Field, Formik } from "formik";
import React from "react";
import FormContainer from "template/presentation/components/form/FormContainer";
import FormSection from "template/presentation/components/form/FormSection";
import FormSubmitButton from "template/presentation/components/form/FormSubmitButton";
import PasswordField from "template/presentation/components/form/PasswordField";
import * as Yup from "yup";

interface Props {
  onSubmit: (model: ChangePasswordModel, callback: () => void) => void;
  error: string | null;
}

interface ChangePasswordFormValues {
  newPassword: string;
  newPasswordRepetition: string;
  currentPassword: string;
}

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required("Benötigt"),
  newPasswordRepetition: Yup.string()
    .required("Benötigt")
    .min(8, "Mindestens 8 Zeichen")
    .oneOf([Yup.ref("newPassword")], "Passwörter müssen gleich sein"),
  newPassword: Yup.string()
    .required("Benötigt")
    .min(8, "Mindestens 8 Zeichen")
    .oneOf([Yup.ref("newPasswordRepetition")], "Passwörter müssen gleich sein"),
});

const ChangePasswordForm: React.FC<Props> = ({ onSubmit, error }) => {
  const useStyles = makeStyles((theme) => ({
    field: {
      marginTop: theme.spacing(1),
    },
  }));
  const classes = useStyles();
  return (
    <Formik<ChangePasswordFormValues>
      initialValues={{ newPassword: "", newPasswordRepetition: "", currentPassword: "" }}
      onSubmit={(values, { setSubmitting }) =>
        onSubmit(
          {
            newPassword: values.newPassword,
            oldPassword: values.currentPassword,
          },
          () => setSubmitting(false)
        )
      }
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting }) => {
        return (
          <FormContainer>
            <FormSection label={"Passwort ändern"}>
              <Field
                data-testid="field-name"
                className={classes.field}
                component={PasswordField}
                variant={"outlined"}
                name={"currentPassword"}
                label={"Aktuelles Passwort"}
              />
              <Field
                data-testid="field-name"
                className={classes.field}
                component={PasswordField}
                variant={"outlined"}
                name={"newPassword"}
                label={"Neues Passwort"}
              />
              <Field
                data-testid="field-name"
                className={classes.field}
                component={PasswordField}
                variant={"outlined"}
                name={"newPasswordRepetition"}
                label={"Neues Passwort wiederholen"}
              />
              <FormSubmitButton data-testid="button-chnagepassword" disabled={isSubmitting} onClick={submitForm}>
                {"Passwort ändern"}
              </FormSubmitButton>
            </FormSection>

            {error && (
              <Alert className={classes.field} data-testid="changepassword-error" severity="error">
                {error}
              </Alert>
            )}
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default ChangePasswordForm;
