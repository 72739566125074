import React, { useState } from "react";
import { useQuery } from "react-query";
import useService from "core/di/useService";
import { RouteComponentProps } from "react-router-dom";
import UIRoute from "core/presentation/components/UIRoute";
import PoolService from "features/pools/domain/services/PoolService";
import PoolsTable from "../components/PoolsTable";
import BranchService from "features/branches/domain/services/BranchService";
import B2BService from "features/b2b/domain/services/B2BService";

interface PoolsRouteProps extends RouteComponentProps {}

export default function B2BPoolsRoute(props: PoolsRouteProps) {
  const poolService = useService(PoolService);
  const branchService = useService(BranchService);
  const b2bService = useService(B2BService);

  const [currentB2BPartner, setCurrentB2BPartner] = useState<string>("");
  const [currentBranch, setCurrentBranch] = useState<string>("");

  const { data: b2bPartnerOptions } = useQuery("b2bPartner", () => b2bService.getB2BPartnerWithPools());

  const { data: branches, isLoading: branchesLoading } = useQuery(
    ["b2bBranchsList", { currentClient: currentB2BPartner }],
    (_key, { searchQuery }) => branchService.get(searchQuery, true, currentB2BPartner),
    { enabled: currentB2BPartner !== "" }
  );

  const { data: pools, isLoading: poolsLoading, refetch, isFetching: poolsFetching } = useQuery(
    ["b2b/pools?branch=" + currentBranch],
    () => {
      // refetch seem to be untouched from enabled property that we need an if here
      if (currentBranch !== "") {
        return poolService.getAllForBranch(currentBranch);
      }
    },
    {
      enabled: currentBranch !== "",
    }
  );

  return (
    <UIRoute {...props} title="Pools">
      <PoolsTable
        branches={branches || []}
        branchesLoading={branchesLoading}
        currentBranch={currentBranch}
        onCurrentBranchChange={setCurrentBranch}
        pools={pools || []}
        poolsLoading={poolsLoading || poolsFetching}
        onReload={refetch}
        onAddB2BPool={() => props.history.push("/pools/create/b2b")}
        createNewB2BPool="Neuer Pool (B2B)"
        b2bPartnerOptions={b2bPartnerOptions || []}
        currentB2BPartner={currentB2BPartner}
        onCurrentB2BPartnerChange={(partner) => setCurrentB2BPartner(partner)}
        type="b2b"
      />
    </UIRoute>
  );
}
