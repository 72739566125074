import React, { useCallback, useMemo } from "react";
import { Field, Formik } from "formik";
import FormContainer from "template/presentation/components/form/FormContainer";
import FormSection from "template/presentation/components/form/FormSection";
import { FormularTypes } from "features/formular-center/data/FormularModel";
import SelectField from "template/presentation/components/form/SelectField";
import DeliverynoteForm from "./DeliverynoteForm";
import Formular, { Item } from "features/formular-center/domain/entities/Formular";
import ControlledAutocomplete from "template/presentation/components/form/ControlledAutocomplete";
import FormSubmitButton from "template/presentation/components/form/FormSubmitButton";
import { makeStyles } from "@material-ui/core";
import SelectOption from "core/interfaces/SelectOption";
import { useCurrentUser } from "features/login/presentation/hooks/useUser";
import * as Yup from "yup";
export interface CreateOrUpdateFormularFormValues {
  type: FormularTypes;
  clientNumber: string;
  caseNumber?: string;
  assignmentId?: string;
  //email
  employee?: string;
  itemList?: Item[];
  ikNumber?: string;
  branchId?: string;
  groupKey?: string;
}

interface CreateOrUpdateFormularFormProps {
  onSubmit: (values: CreateOrUpdateFormularFormValues, id?: string) => void;
  initialFormularData?: Formular;
  formularId?: string;
  caseQuery: {
    caseOptions: SelectOption[];
    setCaseNumberQuery: (value: string) => void;
    setCaseOptionSelected?: (values: string) => void;
    caseOptionsLoading: boolean;
  };
  assignmentQuery: {
    assignmentOptions: SelectOption[];
    setAssignmentNumberQuery: (value: string) => void;
    setAssignmentOptionSelected?: (values: string) => void;
    assignmentOptionsLoading: boolean;
  };
  clientQuery: {
    clientOptions: SelectOption[];
    setClientNumberQuery: (value: string) => void;
    setClientOptionSelected?: (values: string) => void;
    clientOptionsLoading: boolean;
  };
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  textField: {
    width: "100%",
    textAlign: "center",
    fontSize: theme.typography.pxToRem(15),
  },
}));

const CreateOrUpdateFormularForm: React.FC<CreateOrUpdateFormularFormProps> = (props) => {
  const { onSubmit, initialFormularData, caseQuery, assignmentQuery, clientQuery, formularId } = props;

  const { caseOptions, setCaseNumberQuery, setCaseOptionSelected, caseOptionsLoading } = caseQuery;
  const { assignmentOptions, setAssignmentNumberQuery, setAssignmentOptionSelected, assignmentOptionsLoading } = assignmentQuery;
  const { clientOptions, setClientNumberQuery, setClientOptionSelected, clientOptionsLoading } = clientQuery;

  const classes = useStyles();
  const currentUser = useCurrentUser();

  const initialValuesFromProps = useMemo(() => {
    if (initialFormularData === undefined) {
      const values: CreateOrUpdateFormularFormValues = {
        type: "",
        clientNumber: "",
        assignmentId: "",
        employee: currentUser.data?.email ?? "",
        caseNumber: "",
        itemList: [],
        ikNumber: "",
        branchId: "",
        groupKey: "",
      };
      return values;
    } else {
      const values: CreateOrUpdateFormularFormValues = {
        type: initialFormularData.type,
        clientNumber: initialFormularData.client.clientNumber,
        caseNumber: initialFormularData.relatedCase?.caseNumber,
        assignmentId: initialFormularData.relatedAssignment?.id,
        itemList: initialFormularData.itemList,
        ikNumber: initialFormularData.ikNumber,
        branchId: initialFormularData.branch?.id,
        groupKey: initialFormularData.groupKey,
        employee: initialFormularData.employee.email,
      };
      return values;
    }
  }, [initialFormularData]);

  const selectForm = useCallback((values) => {
    switch (values.type) {
      case "DELIVERYNOTE":
        return <DeliverynoteForm values={values} />;
      default:
        return <FormSection label={"Noch kein Formulartyp ausgewählt"}>Bitte erst einen Formulartyp auswählen</FormSection>;
    }
  }, []);
  const validationSchema = Yup.object().shape({
    caseNumber: Yup.string().required("Wird benötigt"),
    assignmentId: Yup.string().required("Wird benötigt"),
    clientNumber: Yup.string().required("Wird benötigt"),
  });
  return (
    <Formik<CreateOrUpdateFormularFormValues>
      initialValues={initialValuesFromProps}
      validationSchema={validationSchema}
      onSubmit={(values: CreateOrUpdateFormularFormValues) => {
        onSubmit(
          {
            ...values,
          },
          initialFormularData ? formularId : undefined
        );
      }}
    >
      {({ submitForm, isSubmitting, values, setFieldValue, handleChange, errors }) => {
        return (
          <FormContainer>
            <FormSection label={"Formular für"}>
              <div style={{ maxWidth: "100%", marginTop: 10 }}>
                <div className={classes.container}>
                  <ControlledAutocomplete
                    data-testid="relatedCaseNumber-field"
                    label="Vorgangsnummer"
                    name="caseNumber"
                    initialValue={values.caseNumber}
                    options={caseOptions}
                    disabled={initialFormularData ? true : false}
                    onSelectOption={(val) => {
                      setFieldValue("caseNumber", val);
                      setCaseNumberQuery(val);
                      if (setCaseOptionSelected) {
                        setCaseOptionSelected(val);
                      }
                    }}
                    onChangeInput={setCaseNumberQuery}
                    loading={caseOptionsLoading}
                  />
                </div>
              </div>
              <div style={{ maxWidth: "100%", marginTop: 10 }}>
                <div className={classes.container}>
                  <ControlledAutocomplete
                    data-testid="relatedAssignment-field"
                    label="Auftrag"
                    name="assignmentId"
                    disabled={initialFormularData ? true : false}
                    initialValue={values.assignmentId}
                    options={assignmentOptions}
                    onSelectOption={(val) => {
                      setFieldValue("assignmentId", val);
                      setAssignmentNumberQuery(val);
                      if (setAssignmentOptionSelected) {
                        setAssignmentOptionSelected(val);
                      }
                    }}
                    onChangeInput={setAssignmentNumberQuery}
                    loading={assignmentOptionsLoading}
                  />
                </div>
              </div>
              <div style={{ maxWidth: "100%", marginTop: 10 }}>
                <div className={classes.container}>
                  <ControlledAutocomplete
                    data-testid="client-field"
                    label="Kunde"
                    name="clientNumber"
                    disabled={initialFormularData ? true : false}
                    initialValue={values.clientNumber}
                    options={clientOptions}
                    onSelectOption={(val) => {
                      setFieldValue("clientNumber", val);
                      setClientNumberQuery(val);
                      if (setClientOptionSelected) {
                        setClientOptionSelected(val);
                      }
                    }}
                    onChangeInput={setClientNumberQuery}
                    loading={clientOptionsLoading}
                  />
                </div>
              </div>
            </FormSection>
            <FormSection label={"Was für ein Dokument soll erstellt werden?"}>
              <Field
                data-testid={"field-client"}
                component={SelectField}
                options={[{ value: "DELIVERYNOTE", label: "Lieferschein" }]}
                getTestIdForOption={(option: any) => option.number}
                variant={"outlined"}
                legend="Dokumententyp"
                name="type"
              />
            </FormSection>
            <>{selectForm(values)}</>
            <FormSection>
              <FormSubmitButton style={{ width: 230 }} data-testid="button-createformular" disabled={isSubmitting} onClick={submitForm}>
                {initialFormularData ? "Bearbeiten" : "Formular erstellen"}
              </FormSubmitButton>
            </FormSection>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default CreateOrUpdateFormularForm;
