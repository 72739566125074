import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import { Field, Formik } from "formik";
import FormContainer from "template/presentation/components/form/FormContainer";
import FormSection from "template/presentation/components/form/FormSection";
import FormSubmitButton from "template/presentation/components/form/FormSubmitButton";
import SelectField from "template/presentation/components/form/SelectField";

export interface SelectDateFormValues {
  year: number;
  month: number;
}

interface ISelectDateFormProps {
  initialDate: SelectDateFormValues;
  onSubmit: (values: SelectDateFormValues) => void;
}

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(1),
  },
}));

const SelectDateForm: React.FC<ISelectDateFormProps> = (props) => {
  const { onSubmit, initialDate } = props;
  const classes = useStyles();

  const yearOptions = useMemo(() => {
    // do not change startYear
    const startYear = 2020;
    const currentYear = new Date().getFullYear();
    const pastYearsCount = Array.from(Array(currentYear - startYear + 1).keys());
    return pastYearsCount.map((year) => ({ value: year + startYear, label: (year + startYear).toString() }));
  }, []);

  return (
    <Formik<SelectDateFormValues>
      initialValues={initialDate}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ submitForm, isSubmitting, values }) => {
        return (
          <FormContainer>
            <FormSection label={"Zeitraum"}>
              <Field
                data-testid="field-year"
                className={classes.field}
                component={SelectField}
                options={yearOptions}
                variant={"outlined"}
                name={"year"}
                legend={"Jahr auswählen"}
              />
              <Field
                data-testid="field-month"
                className={classes.field}
                component={SelectField}
                options={[
                  { value: 1, label: "Januar" },
                  { value: 2, label: "Feburar" },
                  { value: 3, label: "März" },
                  { value: 4, label: "April" },
                  { value: 5, label: "Mai" },
                  { value: 6, label: "Juni" },
                  { value: 7, label: "Juli" },
                  { value: 8, label: "August" },
                  { value: 9, label: "September" },
                  { value: 10, label: "Oktober" },
                  { value: 11, label: "November" },
                  { value: 12, label: "Dezember" },
                ]}
                variant={"outlined"}
                name={"month"}
                legend={"Monat auswählen"}
              />
            </FormSection>

            <FormSection>
              <FormSubmitButton disabled={isSubmitting} onClick={submitForm}>
                {"Zeitraum auswählen"}
              </FormSubmitButton>
            </FormSection>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default SelectDateForm;
