import { PoolAssignmentReportAttachment } from "features/pools/domain/entities/PoolAssignment";
import { RehaItem } from "features/rehaItems/domain/entities/RehaItem";
import React from "react";
import ContentContainer from "template/presentation/components/content/ContentContainer";
import ContentSection from "template/presentation/components/content/ContentSection";
import LabeledValue from "template/presentation/components/content/LabeledValue";
import ValueGrid from "template/presentation/components/content/ValueGrid";
import { RehaItemsPoolView } from "../RehaItemsPoolView";
import { AnnotationSubSection } from "./AnnotationSubSection";

interface Props {
  report: PoolAssignmentReportAttachment;
  rehaItems?: RehaItem[];
}
export const AttachmentInfoSection: React.FC<Props> = ({ report, rehaItems }) => {
  return (
    <ContentSection label="Details">
      <ContentContainer>{report.annotation && <AnnotationSubSection annotation={report.annotation} />}</ContentContainer>
      <ValueGrid>
        <LabeledValue data-testid="freitext" label="Freitext" value={report.text} />
        <LabeledValue data-testid="assignment reference" label="Auftragsnummer Ref." value={report.assignmentNumber} />
        <LabeledValue data-testid="inquiry email" label="E-Mail Rückfragen" value={report.email} />
        <LabeledValue data-testid="inquiry telephone" label="Tel. Rückfragen" value={report.phone} />
      </ValueGrid>
      <RehaItemsPoolView rehaItems={rehaItems} />
    </ContentSection>
  );
};
