import React, { useMemo } from "react";
import { Field, Formik } from "formik";
import PoolAssignment from "features/pools/domain/entities/PoolAssignment";
import { TransferToInternalModel } from "features/pools/domain/entities/PoolEntity";
import { Button, makeStyles } from "@material-ui/core";
import { TextField } from "formik-material-ui";

interface Props {
  assignment: PoolAssignment;
  onTransferToInternal: (values: TransferToInternalModel) => Promise<void>;
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  textField: {
    marginTop: theme.spacing(1),
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

export const TransferToInternalSection: React.FC<Props> = ({ assignment, onTransferToInternal }) => {
  const classes = useStyles();

  const initValues = useMemo(() => {
    return { assignmentId: assignment.assignmentId, reason: "" };
  }, [assignment]);

  return (
    <div>
      <Formik<TransferToInternalModel>
        initialValues={initValues}
        onSubmit={async (values) => {
          await onTransferToInternal(values);
        }}
      >
        {({ submitForm }) => {
          return (
            <div data-testid="reason-submitt" className={classes.container}>
              <Field
                data-testid="field-reason"
                component={TextField}
                multiline
                rows={4}
                variant={"outlined"}
                name="reason"
                label={"Begründung"}
                className={classes.textField}
              />
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                data-testid="button-submittReason"
                onClick={submitForm}
              >
                An Sanitätshaus übergeben
              </Button>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};
