import React, { useState } from "react";
import { useQuery } from "react-query";
import useService from "core/di/useService";
import { RouteComponentProps } from "react-router-dom";
import UIRoute from "core/presentation/components/UIRoute";
import ClassificationService from "../../domain/services/ClassificationService";
import ClassificationsTable from "../components/ClassificationsTable";

export interface ClassificationsRouteProps extends RouteComponentProps {}

const ClassificationsRoute = (props: ClassificationsRouteProps) => {
  const classificationService = useService(ClassificationService);

  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);

  const { data, isLoading, refetch, isFetching } = useQuery(["classificationsList", { searchQuery }], (key, { searchQuery }) =>
    classificationService.get(searchQuery)
  );

  return (
    <UIRoute {...props} title="Klassifizierungen">
      <ClassificationsTable
        classifications={data || null}
        isLoading={isLoading || isFetching}
        onReload={refetch}
        onSearch={setSearchQuery}
        onAdd={() => {
          props.history.push("/classification/create");
        }}
      />
    </UIRoute>
  );
};

export default ClassificationsRoute;
