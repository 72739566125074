import { PoolAssignmentInbox } from "features/pools/domain/entities/PoolAssignment";
import React from "react";
import ContentSection from "template/presentation/components/content/ContentSection";
import LabeledValue from "template/presentation/components/content/LabeledValue";
import ValueGrid from "template/presentation/components/content/ValueGrid";

interface Props {
  report: PoolAssignmentInbox;
}

export const InboxSection: React.FC<Props> = ({ report }) => {
  return (
    <ContentSection label="Details">
      <ValueGrid>
        <LabeledValue data-testid="assignment info" label="Info" value={"PDF - Dokument aus Scanordner"} />
      </ValueGrid>
    </ContentSection>
  );
};
