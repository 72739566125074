import { AxiosInstance } from "axios";
import AddAreaRequestModel from "../domain/components/AddAreaRequestModel";
import handleAxiosError from "axios-handle-error";
import { BadRequestError, convertAxiosErrorToValidationError, NetworkError, UnauthorizedError } from "core/data/errors";
import AreaResponseModel from "../domain/components/AreaResponseModel";
import { B2BPartner, IAreaPostData } from "../../clients/domain/entities/Client";
import UpdateAreaRequestModel from "../domain/components/UpdateAreaRequestModel";

export default class B2BApi {
  constructor(private authClient: AxiosInstance) {}

  public async addAreaToCareHome(data: AddAreaRequestModel): Promise<void> {
    return this.authClient
      .post(`/b2b/areas?b2bClientNumber=${data.path.b2bClientNumber}`, data.body)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          401: () => new UnauthorizedError("Nicht Autorisiert", "401"),
          403: () => new BadRequestError("Fehlerhaftes Datenformat", "403"),
          NO_RESPONSE: () => new NetworkError(),
        });
      });
  }
  public async getAreasByClientNumber(clientNumber: string): Promise<AreaResponseModel[]> {
    return this.authClient
      .get(`/b2b/areas?b2bClientNumber=${clientNumber}`)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          NO_RESPONSE: () => new NetworkError(),
        });
      });
  }
  public async getAreasByClientAndAreaNumber(clientNumber: string, areaNumber: string): Promise<AreaResponseModel> {
    return this.authClient
      .get(`/b2b/areas/${areaNumber}?b2bClientNumber=${clientNumber}`)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          NO_RESPONSE: () => new NetworkError(),
        });
      });
  }

  public async postClientToArea({ areaNumber, clientNumber, b2bClientNumber }: IAreaPostData): Promise<void> {
    return this.authClient
      .post(`/b2b/areas/${areaNumber}/clients/${clientNumber}?b2bClientNumber=${b2bClientNumber}`)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          401: () => new UnauthorizedError("Nicht Autorisiert", "401"),
          403: () => new BadRequestError("Fehlerhaftes Datenformat", "403"),
          NO_RESPONSE: () => new NetworkError(),
        });
      });
  }

  public async updateArea(data: UpdateAreaRequestModel): Promise<void> {
    return this.authClient
      .put(`/b2b/areas/${data.path.areaId}`, data.body)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          401: () => new UnauthorizedError("Nicht Autorisiert", "401"),
          403: () => new BadRequestError("Fehlerhaftes Datenformat", "403"),
          NO_RESPONSE: () => new NetworkError(),
        });
      });
  }

  public async deleteClientInArea(areaNumber: string, clientNumber: string, b2bClientNumber: string): Promise<void> {
    return this.authClient
      .delete(`/b2b/areas/${areaNumber}/clients/${clientNumber}?b2bClientNumber=${b2bClientNumber}`)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          401: () => new UnauthorizedError("Nicht Autorisiert", "401"),
          NO_RESPONSE: () => new NetworkError(),
        });
      });
  }

  public async deleteUserInArea(areaNumber: string, clientNumber: string, b2bClientNumber: string): Promise<void> {
    return this.authClient
      .delete(`/b2b/areas/${areaNumber}/users/${clientNumber}?b2bClientNumber=${b2bClientNumber}`)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          401: () => new UnauthorizedError("Nicht Autorisiert", "401"),
          NO_RESPONSE: () => new NetworkError(),
        });
      });
  }

  public async deleteArea(areaNumber: string, clientNumber: string): Promise<void> {
    return this.authClient
      .delete(`/b2b/areas/${areaNumber}?b2bClientNumber=${clientNumber}`)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          401: () => new UnauthorizedError("Nicht Autorisiert", "401"),
          NO_RESPONSE: () => new NetworkError(),
        });
      });
  }

  public async addUserToArea(areaNumber: string, clientNumber: string, b2bClientNumber: string): Promise<void> {
    return this.authClient
      .post(`/b2b/areas/${areaNumber}/users/${clientNumber}?b2bClientNumber=${b2bClientNumber}`)
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          401: () => new UnauthorizedError("Nicht Autorisiert", "401"),
          NO_RESPONSE: () => new NetworkError(),
        });
      });
  }

  public async getB2BPartnerWithBranches(): Promise<B2BPartner[]> {
    return this.authClient
      .get("b2b/b2bPartner-with-branches")
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          401: () => new UnauthorizedError("Nicht Autorisiert", "401"),
          NO_RESPONSE: () => new NetworkError(),
        });
      });
  }
  public async getB2BPartnerWithPools(): Promise<B2BPartner[]> {
    return this.authClient
      .get("b2b/b2bPartner-with-pools")
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          401: () => new UnauthorizedError("Nicht Autorisiert", "401"),
          NO_RESPONSE: () => new NetworkError(),
        });
      });
  }

  public async getB2BPartners(): Promise<B2BPartner[]> {
    return this.authClient
      .get("b2b/b2bPartners")
      .then((x) => x.data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          401: () => new UnauthorizedError("Nicht Autorisiert", "401"),
          NO_RESPONSE: () => new NetworkError(),
        });
      });
  }
}
