import B2BApi from "../../data/B2BApi";
import AddAreaRequestModel from "../components/AddAreaRequestModel";
import AreaResponseModel from "../components/AreaResponseModel";
import { B2BPartner, IAreaPostData } from "../../../clients/domain/entities/Client";
import UpdateAreaRequestModel from "../components/UpdateAreaRequestModel";

class B2BService {
  constructor(private b2bApi: B2BApi) {}

  public async createAreaInCareHome(data: AddAreaRequestModel): Promise<void> {
    return await this.b2bApi.addAreaToCareHome(data);
  }

  public async getAreasByB2BClientNumber(clientNumber: string): Promise<AreaResponseModel[]> {
    return await this.b2bApi.getAreasByClientNumber(clientNumber);
  }
  public async getAreasByB2BClientAndAreaNumber(clientNumber: string, areaNumber: string): Promise<AreaResponseModel> {
    return await this.b2bApi.getAreasByClientAndAreaNumber(clientNumber, areaNumber);
  }

  public async getAreasByClientNumber(clientNumber: string): Promise<AreaResponseModel[]> {
    return await this.b2bApi.getAreasByClientNumber(clientNumber);
  }

  public async editArea(data: UpdateAreaRequestModel): Promise<void> {
    return await this.b2bApi.updateArea(data);
  }

  public async postClientToArea(data: IAreaPostData): Promise<void> {
    return await this.b2bApi.postClientToArea(data);
  }
  public async deleteClientInArea(areaNumber: string, clientNumber: string, b2bClientNumber: string): Promise<void> {
    return await this.b2bApi.deleteClientInArea(areaNumber, clientNumber, b2bClientNumber);
  }

  public async deleteUserInArea(areaNumber: string, clientNumber: string, b2bClientNumber: string): Promise<void> {
    return await this.b2bApi.deleteUserInArea(areaNumber, clientNumber, b2bClientNumber);
  }

  public async deleteArea(areaNumber: string, clientNumber: string): Promise<void> {
    return await this.b2bApi.deleteArea(areaNumber, clientNumber);
  }

  public async addUserToArea(areaNumber: string, clientNumber: string, b2bClientNumber: string): Promise<void> {
    return await this.b2bApi.addUserToArea(areaNumber, clientNumber, b2bClientNumber);
  }
  public async getB2BPartnerWithBranches(): Promise<B2BPartner[]> {
    return this.b2bApi.getB2BPartnerWithBranches();
  }
  public async getB2BPartnerWithPools(): Promise<B2BPartner[]> {
    return this.b2bApi.getB2BPartnerWithPools();
  }
  public async getB2BPartners(): Promise<B2BPartner[]> {
    return this.b2bApi.getB2BPartners();
  }
}

export default B2BService;
