import { AxiosInstance } from "axios";
import handleAxiosError from "axios-handle-error";
import { convertAxiosErrorToValidationError, ElementNotFoundError, NetworkError, ServiceUnavailableError } from "core/data/errors";
import { PagedResponse } from "core/utils";
import { Branch } from "features/branches/domain/entities/BranchEntity";
import { ClassificationResponseModel } from "features/classifications/data/ClassificationResponseModel";
import UserResponseModel from "features/users/data/UserResponseModel";
import { PoolAssignmentDocType } from "../domain/entities/PoolAssignment";
import {
  TransferToB2BPartnerModel,
  ChangeBranchModel,
  ChangePoolModel,
  ClassifyModel,
  HandOverModel,
  TransferToInternalModel,
} from "../domain/entities/PoolEntity";
import AppointmentReservationModel from "./AppointmentReservationModel";
import { CreateCaseForPoolAssignmentModel } from "./CreateCaseForPoolAssignmentModel";
import PoolAssignmentPreviewResponseModel from "./PoolAssignmentPreviewResponseModel";
import PoolAssignmentResponseModel from "./PoolAssignmentResponseModel";
import PoolCreateOrUpdateModel from "./PoolCreateOrUpdateModel";
import { PoolLeanResponseModel, PoolResponseModel } from "./PoolResponseModel";
import PoolUserModel from "./PoolUserModel";

export default class PoolApi {
  constructor(private authClient: AxiosInstance) {}

  public create(createPoolModel: PoolCreateOrUpdateModel) {
    return this.authClient.post("/pools", createPoolModel).then(({ data }) => data);
  }

  public update(id: string, updatePoolModel: PoolCreateOrUpdateModel) {
    return this.authClient.put("/pools/" + id, updatePoolModel).then(({ data }) => data);
  }

  public getAll(): Promise<PoolResponseModel[]> {
    return this.authClient.get<PoolResponseModel[]>("/pools").then(({ data }) => data);
  }

  public getAllForBranch(branchId: string): Promise<PoolResponseModel[]> {
    return this.authClient.get<PoolResponseModel[]>("/pools?branch=" + branchId).then(({ data }) => data);
  }

  public getById(poolId: string): Promise<PoolResponseModel> {
    return this.authClient.get<PoolResponseModel>("/pools/" + poolId).then(({ data }) => data);
  }

  public getAssignmentPreviewsForPool(poolId: string, params: any) {
    return this.authClient.get(`/pools/assignment-previews-for-pool/${poolId}`, { params }).then(({ data }) => data);
  }

  public getDefaultForBranch(branchId: string): Promise<PoolLeanResponseModel> {
    return this.authClient.get(`/pools/default-for-branch/${branchId}`).then(({ data }) => data);
  }

  public getMyAssignmentPreviews(params: any): Promise<PagedResponse<PoolAssignmentPreviewResponseModel>> {
    return this.authClient
      .get<PagedResponse<PoolAssignmentPreviewResponseModel>>("/pools/assignment-previews", { params })
      .then(({ data }) => data);
  }

  public getEmployeesInPool(poolId: string): Promise<PoolUserModel[]> {
    return this.authClient.get<PoolUserModel[]>(`/pools/employees-for-pool-except-self/${poolId}`).then(({ data }) => data);
  }

  public claimAssignment(assignmentId: string): Promise<boolean> {
    return this.authClient
      .post<{ claimed: boolean }>("/pools/assignments/" + assignmentId + "/claim")
      .then(({ data }) => data.claimed)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          NO_RESPONSE: () => new NetworkError(),
        });
      });
  }

  public getAssignment(assignmentId: string): Promise<PoolAssignmentResponseModel> {
    return this.authClient.get(`/pools/assignments/${assignmentId}`).then(({ data }) => data);
  }

  public async getBranchesExceptAllocatedByPool(poolId: string): Promise<Branch[]> {
    return this.authClient.get(`/pools/branches-except-allocated-by-pool/${poolId}`).then((x) => x.data);
  }

  public async getClassificationsForBranchByPool(poolId: string): Promise<ClassificationResponseModel[]> {
    return this.authClient.get(`/pools/classifications-for-branch-by-pool/${poolId}`).then((x) => x.data);
  }

  public async submitAssignmentWithNewClient(assignmentId: string) {
    return this.authClient
      .post(`/adapter/create-new-client-for-assignment/${assignmentId}`)
      .then(({ data }) => data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          NO_RESPONSE: () => new NetworkError(),
        });
      });
  }

  public async createCaseForAssignment(assignmentId: string, model: CreateCaseForPoolAssignmentModel) {
    return this.authClient
      .post(`/adapter/create-new-case-for-assignment/${assignmentId}`, model)
      .then(({ data }) => data)
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          NO_RESPONSE: () => new NetworkError(),
        });
      });
  }

  public async createCaseFilesForAssignment(assignmentId: string) {
    return this.authClient.post(`/adapter/create-casefiles-for-assignment/${assignmentId}`);
  }

  public async setClassification(model: ClassifyModel) {
    return this.authClient.post(`/pools/assignments/${model.assignmentId}/classify/${model.classificationNumber}`).catch((e) => {
      throw handleAxiosError(e, {
        400: () => convertAxiosErrorToValidationError(e),
        NO_RESPONSE: () => new NetworkError(),
      });
    });
  }

  public async changeBranch(model: ChangeBranchModel) {
    return this.authClient.post(`/pools/assignments/${model.assignmentId}/move-to-branch/${model.branchCode}`).catch((e) => {
      throw handleAxiosError(e, {
        400: () => convertAxiosErrorToValidationError(e),
        NO_RESPONSE: () => new NetworkError(),
      });
    });
  }

  // TODO extract this method to a document api
  public async getDocByFilenameFromAssignment(assignmentId: string, filename: string, doctype: PoolAssignmentDocType) {
    const url = `/docs/assignments/${assignmentId}/doc/${filename}`;

    return this.authClient
      .get(url, {
        responseType: "blob",
      })
      .then(({ data }) => {
        if (doctype === "document") {
          return window.URL.createObjectURL(new Blob([data], { type: "application/pdf" }));
        } else if (doctype === "image") {
          return window.URL.createObjectURL(new Blob([data], { type: "image/jpeg" }));
        }
        return window.URL.createObjectURL(new Blob([data], { type: "video/mp4" }));
      })
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          404: () => new ElementNotFoundError(),
          NO_RESPONSE: () => new NetworkError(),
        });
      });
  }

  public async getDeliveryDocumentFromAssignment(assignmentId: string) {
    const url = `/archive-entries/${assignmentId}`;
    return this.authClient
      .get(url, {
        responseType: "blob",
      })
      .then(({ data }) => {
        return window.URL.createObjectURL(new Blob([data], { type: "application/pdf" }));
      })
      .catch((e) => {
        throw handleAxiosError(e, {
          400: () => convertAxiosErrorToValidationError(e),
          404: () => new ElementNotFoundError(),
          503: () => new ServiceUnavailableError("Service"),
          NO_RESPONSE: () => new NetworkError(),
        });
      });
  }

  public async finishAssignment(assignmentId: string) {
    return this.authClient.post(`/pools/assignments/${assignmentId}/finish`).catch((e) => {
      throw handleAxiosError(e, {
        400: () => convertAxiosErrorToValidationError(e),
        NO_RESPONSE: () => new NetworkError(),
      });
    });
  }

  public async handOverAssignment(model: HandOverModel) {
    return this.authClient.post(`/pools/assignments/${model.assignmentId}/handover/${model.employeeNumber}`).catch((e) => {
      throw handleAxiosError(e, {
        400: () => convertAxiosErrorToValidationError(e),
        NO_RESPONSE: () => new NetworkError(),
      });
    });
  }

  public async releaseAssignment(assignmentId: string) {
    return this.authClient.post(`/pools/assignments/${assignmentId}/release`).catch((e) => {
      throw handleAxiosError(e, {
        400: () => convertAxiosErrorToValidationError(e),
        NO_RESPONSE: () => new NetworkError(),
      });
    });
  }

  public async acceptB2CAppointment(appointment: AppointmentReservationModel) {
    return this.authClient.post("/b2c/accept-consultation-appointment-request", appointment).catch((e) => {
      throw handleAxiosError(e, {
        400: () => convertAxiosErrorToValidationError(e),
        NO_RESPONSE: () => new NetworkError(),
      });
    });
  }

  public async proposeB2CAppointment(appointment: AppointmentReservationModel) {
    return this.authClient.post("/b2c/propose-other-date-for-consultation-appointment-request", appointment).catch((e) => {
      throw handleAxiosError(e, {
        400: () => convertAxiosErrorToValidationError(e),
        NO_RESPONSE: () => new NetworkError(),
      });
    });
  }

  public async acceptB2BAppointment(appointment: AppointmentReservationModel) {
    return this.authClient.post("/b2b/accept-consultation-appointment-request", appointment).catch((e) => {
      throw handleAxiosError(e, {
        400: () => convertAxiosErrorToValidationError(e),
        NO_RESPONSE: () => new NetworkError(),
      });
    });
  }

  public async proposeB2BAppointment(appointment: AppointmentReservationModel) {
    return this.authClient.post("/b2b/propose-other-date-for-consultation-appointment-request", appointment).catch((e) => {
      throw handleAxiosError(e, {
        400: () => convertAxiosErrorToValidationError(e),
        NO_RESPONSE: () => new NetworkError(),
      });
    });
  }

  public async getB2BEmployeesFromMyBranch(): Promise<UserResponseModel[]> {
    return this.authClient.get("/pools/b2b/employees").then(({ data }) => data);
  }

  public async getAvailableFieldstaffEmployeesFromBranch(branchId: string): Promise<UserResponseModel[]> {
    return this.authClient.get("/pools/available-fieldstaff-employees/" + branchId).then(({ data }) => data);
  }

  public async getAvailableFieldstaffEmployeesAndUsedForPool(poolId: string): Promise<UserResponseModel[]> {
    return this.authClient.get("/pools/available-fieldstaff-and-used-in-pool/" + poolId).then(({ data }) => data);
  }

  public async changePoolForAssignment(model: ChangePoolModel) {
    return this.authClient.patch("/pools/assignments/assign-to-pool", model).catch((e) => {
      throw handleAxiosError(e, {
        400: () => convertAxiosErrorToValidationError(e),
        NO_RESPONSE: () => new NetworkError(),
      });
    });
  }

  public async transferAssignmentToB2BClient(model: TransferToB2BPartnerModel) {
    return this.authClient.patch("/pools/assignments/transfer-to-b2bPartner", model).catch((e) => {
      throw handleAxiosError(e, {
        400: () => convertAxiosErrorToValidationError(e),
        NO_RESPONSE: () => new NetworkError(),
      });
    });
  }

  public async transferB2BAssignmentToInternal(model: TransferToInternalModel) {
    return this.authClient.patch("/pools/assignments/transfer-to-internal", model).catch((e) => {
      throw handleAxiosError(e, {
        400: () => convertAxiosErrorToValidationError(e),
        NO_RESPONSE: () => new NetworkError(),
      });
    });
  }
}
