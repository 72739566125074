import React, { useCallback, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useQueryCache } from "react-query";
import useService from "core/di/useService";
import UIRoute from "core/presentation/components/UIRoute";
import ClassificationService from "../../domain/services/ClassificationService";
import CreateOrUpdateClassificationForm, { CreateOrUpdateClassificationFormValues } from "../components/CreateOrUpdateClassificationForm";
import { ValidationMessageError } from "core/data/errors";

export interface CreateClassificationRouteProps extends RouteComponentProps {}

const CreateClassificationRoute = (props: CreateClassificationRouteProps) => {
  const classificationService = useService(ClassificationService);
  const queryCache = useQueryCache();
  const [error, setError] = useState<string | null>(null);

  const onSubmit = useCallback(
    (values: CreateOrUpdateClassificationFormValues) => {
      return classificationService
        .create(values)
        .then(() => {
          setError(null);
        })
        .then(() => {
          queryCache.invalidateQueries("classificationsList");
        })
        .then(() => {
          props.history.goBack();
        })
        .catch((e) => {
          if (e instanceof ValidationMessageError) {
            switch (e.code) {
              case "DUPLICATE_CLASSIFICATION_NUMBER":
                setError("Die Klassifizierung ist bereits Vorhanden.");
                return;
              default:
                setError(`Ein unbekannter Fehler ist aufgetreten. Code: ${e.code || "VMSG400"}`);
                return;
            }
          } else {
            setError(`Ein unbekannter Fehler ist aufgetreten`);
          }
        });
    },
    [props.history, queryCache, classificationService]
  );

  return (
    <UIRoute {...props} title="Klassifizierung bearbeiten">
      <CreateOrUpdateClassificationForm error={error} onSubmit={onSubmit} mappingOptions={classificationService.getMappingOptions()} />
    </UIRoute>
  );
};

export default CreateClassificationRoute;
