import { Button } from "@material-ui/core";
import { PoolAssignmentStatus } from "features/pools/domain/entities/PoolAssignment";
import React, { useCallback, useState } from "react";
import ContentSection from "template/presentation/components/content/ContentSection";
import FinishAssignmentDialog from "./FinishAssignmentDialog";

interface Props {
  assignmentId: string;
  assignmentState: PoolAssignmentStatus;
  onFinish: (assignmentId: string) => void;
}

export const FinishAssignmentSection: React.FC<Props> = ({ assignmentId, assignmentState, onFinish }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [assignmentToFinsih, setAssignmentToFinish] = useState<undefined | string>(undefined);

  const handleFinish = useCallback(
    (id: string) => {
      setDialogOpen(true);
      setAssignmentToFinish(id);
    },
    [setDialogOpen, setAssignmentToFinish]
  );

  const onDismiss = useCallback(() => {
    setDialogOpen(false);
  }, [setDialogOpen]);

  const onAgree = useCallback(() => {
    setDialogOpen(false);
    if (assignmentToFinsih) {
      onFinish(assignmentToFinsih);
      setAssignmentToFinish(undefined);
    }
  }, [assignmentToFinsih, onFinish]);

  return (
    <>
      <FinishAssignmentDialog open={dialogOpen} onDismiss={onDismiss} onAgree={onAgree} />
      <ContentSection>
        <Button
          data-testid="close-button"
          color="primary"
          variant="contained"
          onClick={() => {
            handleFinish(assignmentId);
          }}
          disabled={assignmentState.status === "DONE"}
        >
          {assignmentState.status !== "DONE" ? "Pool-Auftrag Abschließen" : "Pool-Auftrag wurde bereits Abgeschlossen"}
        </Button>
      </ContentSection>
    </>
  );
};
