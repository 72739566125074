import { EmptyState } from "core/presentation/components/EmptyState";
import { RehaItem } from "features/rehaItems/domain/entities/RehaItem";
import React, { useMemo } from "react";
import DataContainer from "template/presentation/components/table/DataContainer";
import DataTable, { DataTableCell } from "template/presentation/components/table/DataTable";

export interface RehaItemsTableProps {
  rehaItems?: RehaItem[];
  isLoading: boolean;
}

const RehaItemsTable = (props: RehaItemsTableProps) => {
  const { rehaItems, isLoading } = props;

  const columns = useMemo(() => {
    return [
      { name: "Nummer" },
      { name: "Hersteller" },
      { name: "Modell" },
      { name: "Serienmummer" },
      { name: "Hilfsmittelnummer" },
      { name: "Beschreibung" },
    ];
  }, []);

  return (
    <>
      <DataContainer loading={isLoading}>
        <DataTable<RehaItem>
          data-testid={"rehaItemtable"}
          loading={isLoading}
          columns={columns}
          rows={rehaItems}
          keyExtractor={(data) => data.id}
          renderRow={(data, index) => {
            return (
              <>
                <DataTableCell data-testid={`rehaitem-number-${index}`}>{data.number || "-"}</DataTableCell>
                <DataTableCell data-testid={`rehaitem-manufacturer-${index}`}>{data.manufacturer || "-"}</DataTableCell>
                <DataTableCell data-testid={`rehaitem-model-${index}`}>{data.model || "-"}</DataTableCell>
                <DataTableCell data-testid={`rehaitem-serialNumber-${index}`}>{data.serialNumber || "-"}</DataTableCell>
                <DataTableCell data-testid={`rehaitem-helpingRessourcesNumber-${index}`}>{data.himiNumber || "-"}</DataTableCell>
                <DataTableCell data-testid={`rehaitem-description-${index}`}>{data.description || "-"}</DataTableCell>
              </>
            );
          }}
          renderEmpty={() => (
            <EmptyState
              title="Keine Rehamittel für diesen Kunden vorhanden."
              description="Verwalten Sie die Rehamittel in ihrer Branchensoftware um diese auch hier nutzen zu können."
            />
          )}
        />
      </DataContainer>
    </>
  );
};

export default RehaItemsTable;
