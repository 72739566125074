import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import * as Yup from "yup";
import { Field, FieldArray, Formik } from "formik";
import FormContainer from "template/presentation/components/form/FormContainer";
import FormSection from "template/presentation/components/form/FormSection";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import CheckIcon from "@material-ui/icons/Check";
import RemoveIcon from "@material-ui/icons/Remove";
import { Alert } from "@material-ui/lab";
import FormSubmitButton from "template/presentation/components/form/FormSubmitButton";
import Client, {
  AreasForCareHomeOptions,
  B2BLinkOptions,
  EmployeeEmailAddress,
  Phone,
  PrescriberOption,
  PrescriberType,
  PrescriberTypeOption,
} from "../../domain/entities/Client";
import B2BSpace from "../../domain/services/B2BSpace";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import SelectField from "template/presentation/components/form/SelectField";
import AreaResponseModel from "../../../b2b/domain/components/AreaResponseModel";
import { BranchOption } from "features/branches/domain/entities/BranchEntity";
import MultiSelectField from "template/presentation/components/form/MutiSelectField";
import SelectOption from "core/interfaces/SelectOption";
import Typography from "@material-ui/core/Typography";
import { useQueryCache } from "react-query";
export interface CreateOrUpdateClientFormValues {
  firstname: string;
  lastname?: string;
  birthdate?: string;
  street: string;
  zipcode: string;
  city: string;
  phone: string;
  phone2?: Phone;
  clientNumber: string;
  employeeEmails?: EmployeeEmailAddress[];

  housenumber?: string;
  email?: string;
  country?: string;
  copaymentExempt?: boolean;
  isCareHome?: boolean;
  memberOfCareHome?: string;
  areaOfCareHome?: string;
  clientAreas?: AreaResponseModel[];
  branchCode?: string;
  isPrescriber?: boolean;
  prescriberType?: PrescriberType;
  prescriber?: string;
  isPartOfEnterprise?: boolean;
  enterpriseRole?: "root" | "child";
  enterpriseChildren?: B2BLinkOptions[];
  exclusiveAccess?: SelectOption[];
  additionalAccess?: SelectOption[];
  exclusiveFieldstaffAccess?: SelectOption[];
  prescribers?: SelectOption[];
}

interface ICreateOrUpdateClientFormProps {
  onSubmit: (values: CreateOrUpdateClientFormValues) => void;
  error: string | null;
  client?: Client;
  group?: string;
  careHomeOptions?: B2BLinkOptions[];
  areasForCareHome?: AreasForCareHomeOptions[];
  getAreasForCareHome?: (id: string) => void;
  internalBranchOptions?: BranchOption[];
  prescriberTypeOptions?: PrescriberTypeOption[];
  prescriberOptions?: PrescriberOption[];
  b2bUserOptions?: SelectOption[];
}
//duplicate please combine with createOrUpdateUserForm
const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(1),
  },
}));

const createOrUpdateClientSchema = Yup.object().shape({
  firstname: Yup.string().required("Benötigt"),
  lastname: Yup.string().required("Benötigt"),
  birthdate: Yup.string().required("Benötigt"),
  street: Yup.string().required("Benötigt"),
  zipcode: Yup.string().required("Benötigt").min(4, "Mindestens 4 Zeichen"),
  city: Yup.string().required("Benötigt"),
  clientNumber: Yup.string().required("Benötigt"),
  employeeEmails: Yup.array().of(
    Yup.object().shape({
      mail: Yup.string().email("Ungültige Email Adresse").required("Benötigt"),
      who: Yup.string().required("Benötigt"),
    })
  ),
});

const createOrUpdateCareHomeSchema = Yup.object().shape({
  firstname: Yup.string().required("Benötigt"),
  street: Yup.string().required("Benötigt"),
  zipcode: Yup.string().required("Benötigt").min(4, "Mindestens 4 Zeichen"),
  city: Yup.string().required("Benötigt"),
  clientNumber: Yup.string().required("Benötigt"),
  branchCode: Yup.string().required("Benötigt"),
  isPrescriber: Yup.boolean(),
  prescriberType: Yup.string().when("isPrescriber", {
    is: true,
    then: Yup.string().required("Benötigt"),
    otherwise: Yup.string().optional(),
  }),
});

const formatDate = (date: string) => {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

const CreateOrUpdateClientForm: React.FC<ICreateOrUpdateClientFormProps> = (props) => {
  const {
    onSubmit,
    error,
    client,
    group,
    careHomeOptions,
    areasForCareHome,
    getAreasForCareHome,
    internalBranchOptions,
    prescriberOptions,
    b2bUserOptions,
  } = props;
  const classes = useStyles();
  const queryCache = useQueryCache();
  const initValues = useMemo((): CreateOrUpdateClientFormValues => {
    if (client === undefined) {
      return {
        firstname: "",
        lastname: "",
        birthdate: "",
        street: "",
        zipcode: "",
        city: "",
        clientNumber: "",
        phone: "",
        memberOfCareHome: "",
        areaOfCareHome: "",
        copaymentExempt: false,
        phone2: {
          number: "",
          who: "",
        },
        employeeEmails: [],
        isPrescriber: false,
        isPartOfEnterprise: false,
        prescribers: [],
      };
    }

    let areaOfCareHome = "";
    if (client.clientAreas && client.clientAreas.length > 0) {
      areaOfCareHome = client.clientAreas[0].id;
    }

    let enterpriseChildren: B2BLinkOptions[] | undefined;
    if (client.enterprise) {
      if (client.enterprise.role === "root") {
        if (client.enterprise.children) {
          const children = careHomeOptions?.filter(
            (careHome) => client.enterprise!.children!.findIndex((child) => child.id === careHome.value) > -1
          );
          enterpriseChildren = children ? children : [];
        } else {
          enterpriseChildren = [];
        }
      } else {
        const root = careHomeOptions?.filter((careHome) => careHome.value === client.enterprise?.root?.id);
        enterpriseChildren = root ? root : [];
      }
    } else {
      enterpriseChildren = undefined;
    }

    return {
      firstname: client.firstname || "",
      lastname: client.lastname || "",
      birthdate: client.birthdate ? formatDate(client.birthdate) : "",
      email: client.email || "",
      street: client.street || "",
      zipcode: client.zipcode || "",
      city: client.city || "",
      clientNumber: client.clientNumber || "",
      phone: client.phone || "",
      memberOfCareHome: client.memberOfCareHome || "",
      areaOfCareHome,
      copaymentExempt: client.copaymentExempt || false,
      phone2: client.phone2 || {
        number: "",
        who: "",
      },
      employeeEmails: client.employeeEmails || [],
      branchCode: client.branch?.code,
      isPrescriber: client.isPrescriber || false,
      prescriberType: client.prescriberType,
      prescriber: client.prescriber,
      isPartOfEnterprise: client.enterprise !== undefined,
      enterpriseRole: client.enterprise !== undefined ? client.enterprise.role : "root",
      enterpriseChildren: enterpriseChildren,
      additionalAccess:
        client.enterprise && client.enterprise.additionalAccess
          ? b2bUserOptions?.filter((user) => client.enterprise!.additionalAccess!.findIndex((aa) => aa.id === user.value) > -1)
          : [],
      exclusiveAccess:
        client.enterprise && client.enterprise.role === "root" && client.enterprise.exclusiveAccess
          ? b2bUserOptions?.filter((user) => client.enterprise!.exclusiveAccess!.findIndex((ea) => ea.id === user.value) > -1)
          : [],
      prescribers: client.prescribers,
    };
  }, [client, careHomeOptions, b2bUserOptions]);

  const enterpriseRoleOptions = (values: CreateOrUpdateClientFormValues) => {
    if (values.enterpriseRole === "root" || !client) {
      return [{ label: "Hauptsitz", value: "root" }];
    } else if (client && values.enterpriseRole) {
      return [
        { label: "Hauptsitz", value: "root" },
        { label: "Niederlassung", value: "child" },
      ];
    }
  };

  return (
    <Formik<CreateOrUpdateClientFormValues>
      enableReinitialize
      initialValues={initValues}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
        } finally {
          setSubmitting(false);
        }
      }}
      validationSchema={group === B2BSpace.INTERMEDIARY ? createOrUpdateCareHomeSchema : createOrUpdateClientSchema}
    >
      {({ values, submitForm, isSubmitting, setFieldValue }) => {
        if (group === B2BSpace.INTERMEDIARY) {
          return (
            <FormContainer>
              <FormSection label={"Infos zum Pflegeheim"}>
                <Field
                  data-testid="field-name"
                  className={classes.field}
                  component={TextField}
                  variant={"outlined"}
                  name={"firstname"}
                  label={"Name*"}
                />
                <Field
                  data-testid="field-b2bClientNumber"
                  className={classes.field}
                  component={TextField}
                  variant={"outlined"}
                  name={"clientNumber"}
                  label={"Kundennummer*"}
                />
                <Field
                  data-testid="field-b2bPhone"
                  className={classes.field}
                  component={TextField}
                  variant={"outlined"}
                  name={"phone"}
                  label={"Telefon"}
                />
              </FormSection>

              <FormSection label={"Ort"}>
                <Field
                  data-testid="field-b2bStreet"
                  className={classes.field}
                  component={TextField}
                  variant={"outlined"}
                  name={"street"}
                  label={"Straße*"}
                />
                <Field
                  data-testid="field-b2bZipcode"
                  className={classes.field}
                  component={TextField}
                  variant={"outlined"}
                  name={"zipcode"}
                  label={"Postleitzahl*"}
                />
                <Field
                  data-testid="field-b2bCity"
                  className={classes.field}
                  component={TextField}
                  variant={"outlined"}
                  name={"city"}
                  label={"Stadt*"}
                />
              </FormSection>
              <FormSection label={"Zuständige Sanitätshausniederlassung"}>
                <Field
                  data-testid="field-branchCode"
                  className={classes.field}
                  component={SelectField}
                  options={internalBranchOptions || []}
                  name={"branchCode"}
                  legend={"Zuständige Niederlassung zuordnen*"}
                />
              </FormSection>
              <FormSection label={"Organisationdetails bearbeiten"}>
                <Field
                  data-testid="part-enterprise"
                  className={classes.field}
                  component={CheckboxWithLabel}
                  type="checkbox"
                  name="isPartOfEnterprise"
                  Label={{ label: "Ist Teil einer Organisation" }}
                  disabled={values.enterpriseRole === "child" ? true : false}
                />
                {values.isPartOfEnterprise && (
                  <Field
                    data-testid="enterprise-role"
                    className={classes.field}
                    component={SelectField}
                    options={enterpriseRoleOptions(values)}
                    name={"enterpriseRole"}
                    legend={"Art der Organisation"}
                    disabled={values.enterpriseRole === "child" ? true : false}
                  />
                )}
                {values.isPartOfEnterprise && (
                  <Field
                    data-testid={"enterprise-children"}
                    className={classes.field}
                    component={MultiSelectField}
                    options={careHomeOptions?.filter((x) => x.b2bNumber !== client?.clientNumber) || []}
                    getTestIdForOption={(option: any) => option.b2bNumber}
                    variant={"outlined"}
                    legend={values.enterpriseRole === "child" ? "Niederlassung von:" : "Niederlassungen"}
                    name={"enterpriseChildren"}
                    disabled={values.enterpriseRole === "child" ? true : false}
                  />
                )}
                {values.isPartOfEnterprise && (
                  <Field
                    data-testid={"test-additionalAccess"}
                    className={classes.field}
                    component={MultiSelectField}
                    options={b2bUserOptions || []}
                    getTestIdForOption={(option: any) => option.label}
                    variant={"outlined"}
                    legend={"B2B-Benutzer mit geändertem Zugriff"}
                    name="additionalAccess"
                  />
                )}
                {values.isPartOfEnterprise && values.enterpriseRole === "root" && (
                  <>
                    <Field
                      className={classes.field}
                      data-testid={"test-exclusiveAccess"}
                      component={MultiSelectField}
                      options={b2bUserOptions || []}
                      getTestIdForOption={(option: any) => option.label}
                      variant={"outlined"}
                      legend={"B2B-Benutzer mit exklusiven Zugriff"}
                      name="exclusiveAccess"
                    />
                  </>
                )}
              </FormSection>
              <FormSection label={"E-Mail Adressen für Wundversorgung"}>
                <FieldArray
                  name="employeeEmails"
                  render={(arrayHelpers) => (
                    <div>
                      {values &&
                        values.employeeEmails &&
                        values.employeeEmails.map((email, index) => (
                          <div key={index} style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                            <Field
                              data-testid={`field-employeeEmail-row-${index}-email`}
                              style={{ flex: "0 0 45%", marginRight: "5px" }}
                              component={TextField}
                              variant={"outlined"}
                              label={"Email"}
                              name={`employeeEmails.${index}.mail`}
                            />
                            <Field
                              data-testid={`field-employeeEmail-row-${index}-name`}
                              style={{ flex: "0 0 45%" }}
                              component={TextField}
                              variant={"outlined"}
                              label={"Name"}
                              name={`employeeEmails.${index}.who`}
                            />
                            <DeleteIcon
                              data-testid={`field-employeeEmail-row-${index}-delete`}
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                              style={{ flex: "0 0 10%", cursor: "pointer" }}
                              color={"secondary"}
                            />
                          </div>
                        ))}
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        data-testid={`field-employeeEmail-plus`}
                        onClick={() => {
                          arrayHelpers.push({ mail: "", who: "" });
                        }}
                      >
                        <AddCircleIcon color={"primary"} style={{ marginRight: "8px" }} />
                        <span>Füge eine weitere Email hinzu</span>
                      </div>
                    </div>
                  )}
                />
              </FormSection>
              <FormSection>
                {error && (
                  <Alert className={classes.field} data-testid="create-care-home-error" severity="error">
                    {error}
                  </Alert>
                )}

                <FormSubmitButton data-testid="button-createclient" disabled={isSubmitting} onClick={submitForm}>
                  {client ? "Bearbeiten" : "Erstellen"}
                </FormSubmitButton>
              </FormSection>
            </FormContainer>
          );
        }

        return (
          <FormContainer>
            <FormSection label={"Persönliche Informationen"}>
              <Field
                data-testid="field-firstname"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"firstname"}
                label={"Vorname*"}
              />
              <Field
                data-testid="field-lastname"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"lastname"}
                label={"Nachname*"}
              />
              <Field
                data-testid="field-email"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"email"}
                label={"Email"}
              />
              <Field
                data-testid="field-birthday"
                className={classes.field}
                component={TextField}
                type={"date"}
                InputLabelProps={{
                  shrink: true,
                }}
                variant={"outlined"}
                name={"birthdate"}
                label={"Geburtstag*"}
              />
              <Field
                data-testid="field-b2bStreet"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"street"}
                label={"Straße*"}
              />
              <Field
                data-testid="field-b2bZipcode"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"zipcode"}
                label={"Postleitzahl*"}
              />
              <Field
                data-testid="field-b2bCity"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"city"}
                label={"Stadt*"}
              />
              <Field
                data-testid="field-b2bPhone"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"phone"}
                label={"Telefon*"}
              />
            </FormSection>

            <FormSection label={"Allgemeine Informationen"}>
              <Field
                data-testid="field-clientNumber"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"clientNumber"}
                label={"Kundennummer*"}
              />
              <Field
                name={"memberOfCareHome"}
                className={classes.field}
                data-testid="field-memberOfCareHome"
                component={MultiSelectField}
                single={true}
                options={careHomeOptions || []}
                legend={"Vermittler zuordnen"}
                onChange={(e: any, obj?: B2BLinkOptions) => {
                  if (!obj) {
                    setFieldValue("memberOfCareHome", null);
                    setFieldValue("areaOfCareHome", null);
                    queryCache.invalidateQueries("b2bBranchOptions");
                    if (getAreasForCareHome) {
                      setFieldValue("areaOfCareHome", "");
                    }
                    return;
                  }
                  if (!!getAreasForCareHome && !!careHomeOptions) {
                    const clientNumber = careHomeOptions.find((c) => c.value === obj.value)?.b2bNumber;
                    if (clientNumber) {
                      getAreasForCareHome(clientNumber);
                      setFieldValue("areaOfCareHome", "");
                    }
                  }
                  setFieldValue("memberOfCareHome", obj.value);
                }}
                getOptionLabel={(value: B2BLinkOptions) => {
                  return typeof value === "object" ? value.label : careHomeOptions?.find((x) => x.value === value)?.label;
                }}
                getOptionSelected={(option: B2BLinkOptions, value: string) => option.value === value}
              />
              {values.memberOfCareHome && (
                <Field
                  className={classes.field}
                  component={SelectField}
                  name={"areaOfCareHome"}
                  legend={"Wohnbereich zuordnen"}
                  options={areasForCareHome || []}
                />
              )}
            </FormSection>

            <FormSection label="Sonstiges">
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 20 }}>
                {values.copaymentExempt ? (
                  <>
                    <CheckIcon data-testid="copaymentExempt-true" style={{ color: "green", fontSize: "22px", marginRight: 8 }} />
                    <Typography variant="body1" align="center">
                      {"Kunde ist Zuzahlungsbefreit"}
                    </Typography>
                  </>
                ) : (
                  <>
                    <RemoveIcon data-testid="copaymentExempt-false" style={{ color: "red", fontSize: "22px", marginRight: 5 }} />
                    <Typography variant="body1" align="center">
                      {"Kunde ist nicht Zuzahlungsbefreit"}
                    </Typography>
                  </>
                )}
              </div>
              <Field
                name={"prescriber"}
                className={classes.field}
                data-testid="field-prescriber"
                component={MultiSelectField}
                single={true}
                options={prescriberOptions || []}
                legend={"Hauptverordner"}
                onChange={(e: any, obj?: B2BLinkOptions) => {
                  if (!obj) {
                    setFieldValue("prescriber", null);
                    return;
                  }
                  setFieldValue("prescriber", obj.value);
                }}
                getOptionLabel={(value: B2BLinkOptions) => {
                  return typeof value === "object" ? value.label : prescriberOptions?.find((x) => x.value === value)?.label;
                }}
                getOptionSelected={(option: B2BLinkOptions, value: string) => option.value === value}
                getTestIdForOption={(option: any) => option.number}
              />
              <Field
                data-testid="field-extended-prescribers"
                name="prescribers"
                legend="Weitere Verordner"
                component={MultiSelectField}
                options={prescriberOptions || []}
                getTestIdForOption={(option: any) => option.number}
                style={{ marginTop: 10 }}
              />
            </FormSection>

            <FormSection label={"E-Mail Adressen für Wundversorgung"}>
              <FieldArray
                name="employeeEmails"
                render={(arrayHelpers) => (
                  <div>
                    {values &&
                      values.employeeEmails &&
                      values.employeeEmails.map((email, index) => (
                        <div key={index} style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                          <Field
                            data-testid={`field-employeeEmail-row-${index}-email`}
                            style={{ flex: "0 0 45%", marginRight: "5px" }}
                            component={TextField}
                            variant={"outlined"}
                            label={"Email*"}
                            name={`employeeEmails.${index}.mail`}
                          />
                          <Field
                            data-testid={`field-employeeEmail-row-${index}-name`}
                            style={{ flex: "0 0 45%" }}
                            component={TextField}
                            variant={"outlined"}
                            label={"Name*"}
                            name={`employeeEmails.${index}.who`}
                          />
                          <DeleteIcon
                            data-testid={`field-employeeEmail-row-${index}-delete`}
                            onClick={() => {
                              arrayHelpers.remove(index);
                            }}
                            style={{ flex: "0 0 10%", cursor: "pointer" }}
                            color={"secondary"}
                          />
                        </div>
                      ))}
                    <div
                      style={{
                        marginTop: "20px",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      data-testid={`field-employeeEmail-plus`}
                      onClick={() => {
                        arrayHelpers.push({ mail: "", who: "" });
                      }}
                    >
                      <AddCircleIcon color={"primary"} style={{ marginRight: "8px" }} />
                      <span>Füge eine weitere Email hinzu</span>
                    </div>
                  </div>
                )}
              />
            </FormSection>

            <FormSection label={"Zusätzliche Telefonnummer"}>
              <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                <Field
                  data-testid={`field-employeeNumber-row-number`}
                  style={{ flex: "0 0 45%", marginRight: "5px" }}
                  component={TextField}
                  variant={"outlined"}
                  label={"Telefonnummer"}
                  name={`phone2.number`}
                />
                <Field
                  data-testid={`field-employeeNumber-row-name`}
                  style={{ flex: "0 0 45%" }}
                  component={TextField}
                  variant={"outlined"}
                  label={"Name"}
                  name={`phone2.who`}
                />
              </div>
            </FormSection>

            <FormSection>
              {error && (
                <Alert className={classes.field} data-testid="create-patient-error" severity="error">
                  {error}
                </Alert>
              )}

              <FormSubmitButton data-testid="button-createclient" disabled={isSubmitting} onClick={submitForm}>
                {client ? "Bearbeiten" : "Erstellen"}
              </FormSubmitButton>
            </FormSection>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default CreateOrUpdateClientForm;
