import React, { useMemo } from "react";
import { Field, Formik } from "formik";
import PoolAssignment from "features/pools/domain/entities/PoolAssignment";
import SelectField from "template/presentation/components/form/SelectField";
import { ChangeBranchModel, SelectOption } from "features/pools/domain/entities/PoolEntity";
import { Button, makeStyles } from "@material-ui/core";

interface Props {
  assignment: PoolAssignment;
  branchOptions: SelectOption[] | undefined;
  onChangeBranch: (values: ChangeBranchModel) => Promise<void>;
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

export const ChangeBranchSection: React.FC<Props> = ({ assignment, branchOptions, onChangeBranch }) => {
  const classes = useStyles();

  const initValues = useMemo(() => {
    return { branchCode: "", assignmentId: assignment.assignmentId };
  }, [assignment]);

  return (
    <div>
      <Formik
        initialValues={initValues}
        onSubmit={async (values: ChangeBranchModel) => {
          await onChangeBranch(values);
        }}
      >
        {({ submitForm }) => {
          return branchOptions ? (
            <div className={classes.container}>
              <Field
                data-testid="field-changebranch"
                name="branchCode"
                legend="Niederlassung wählen"
                component={SelectField}
                options={branchOptions}
              />
              <Button className={classes.button} color="primary" variant="contained" data-testid="button-changebranch" onClick={submitForm}>
                Niederlassung zuweisen
              </Button>
            </div>
          ) : (
            <div>Keine andere Niederlassung vorhanden.</div>
          );
        }}
      </Formik>
    </div>
  );
};
