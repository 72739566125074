import React, { useCallback, useEffect, useState } from "react";
import { queryCache, useQuery } from "react-query";
import useService from "core/di/useService";
import { RouteComponentProps } from "react-router-dom";
import UIRoute from "core/presentation/components/UIRoute";
import { useCurrentUserInGroup } from "../../../login/presentation/hooks/useUser";
import MessageService from "../../domain/services/MessageService";
import CreateOrUpdateMessageForm, { CreateOrUpdateMessageFormValues } from "../components/CreateOrUpdateMessageForm";
import { ElementNotFoundError, ValidationMessageError } from "core/data/errors";
import AssignmentService from "features/assignments/domain/services/AssignmentService";
import UserService from "features/users/domain/services/UserService";
import UserConverter from "features/users/domain/converter/UserConverter";
import { useDebounce } from "use-debounce";

export interface CreateMessageRouteProps extends RouteComponentProps {}

const CreateMessageRoute = (props: CreateMessageRouteProps) => {
  const messageService = useService(MessageService);
  const assignmentService = useService(AssignmentService);
  const userService = useService(UserService);
  const [error, setError] = useState<string | null>(null);
  const [assignmentNumber, setAssignmentNumber] = useState<string>("");
  const [userQuery, setUserQuery] = useState<string>("");
  const [userAndClientQueryDebounced] = useDebounce(userQuery, 500);
  const [isB2BBackoffice] = useCurrentUserInGroup("b2bBackoffice");

  const { data: assignmentData, error: assignmentDataError } = useQuery(
    ["assignmentData", assignmentNumber],
    () => assignmentService.getByNumber(assignmentNumber),
    {
      enabled: assignmentNumber,
    }
  );

  const [isFetchingRecipients, setIsFetchingRecipients] = useState(false);
  const { data: recipientOptions, error: recipientOptionsError } = useQuery(["recipientOptions", userAndClientQueryDebounced], () =>
    userService.getUserByNameFullTextSearch(userAndClientQueryDebounced).then((res) => {
      setIsFetchingRecipients(false);
      return res;
    })
  );

  useEffect(() => {
    if (assignmentDataError instanceof ElementNotFoundError) {
      setError(`Auftrag nicht gefunden.`);
    } else if (assignmentDataError) {
      setError(`Unbekannter Fehler.`);
    } else {
      setError(null);
    }
  }, [assignmentDataError]);

  const onSubmit = useCallback(
    (values: CreateOrUpdateMessageFormValues) => {
      return messageService
        .sendMessage(values)
        .then(() => {
          setError(null);
          return queryCache.invalidateQueries("messageList");
        })
        .then(() => {
          props.history.goBack();
        })
        .catch((e) => {
          if (e instanceof ValidationMessageError) {
            switch (e.code) {
              default:
                setError(`Ein unbekannter Fehler ist aufgetreten. Code: ${e.code}`);
                return;
            }
          } else {
            setError("Ein unbekannter Fehler ist aufgetreten");
          }
        });
    },
    [props.history, messageService]
  );

  return (
    <UIRoute {...props} title={"Neue Nachricht"}>
      <CreateOrUpdateMessageForm
        error={error}
        onSubmit={onSubmit}
        typeOptions={messageService.getTypeOptions()}
        recipientTypeOptions={messageService.getRecipientTypeOptions()}
        getAssignmentData={(assignmentNumber) => {
          setAssignmentNumber(assignmentNumber);
        }}
        assignmentData={assignmentData}
        loadingRecipients={isFetchingRecipients}
        repicientOptions={UserConverter.toMessageRecipientOptions(recipientOptions || [])}
        repicientOptionsChange={(value) => {
          setIsFetchingRecipients(true);
          setUserQuery(value);
        }}
        hideAssignment={isB2BBackoffice}
        groupOptions={
          isB2BBackoffice
            ? [
                { label: "B2B Nutzer bei Vermittler/Verordner", value: "b2b-intermediary" },
                { label: "B2C Nutzer bei Vermittler/Verordner", value: "b2c-intermediary" },
              ]
            : undefined
        }
      />
    </UIRoute>
  );
};

export default CreateMessageRoute;
