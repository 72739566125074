import React, { useState } from "react";
import { useQuery } from "react-query";
import { useService } from "core/di";
import UIRoute from "core/presentation/components/UIRoute";
import { RouteComponentProps } from "react-router-dom";
import RecruitmentService from "features/recruitment/domain/services/ProductService";
import B2CRecruitmentTable from "../components/B2CRecruitmentTable";
import SelectDateForm, { SelectDateFormValues } from "../components/SelectDateForm";

export interface ListB2CRecruitmentsRouteProps extends RouteComponentProps {}

const ListB2CRecruitmentsRoute = (props: ListB2CRecruitmentsRouteProps) => {
  const recruitmentService = useService(RecruitmentService);

  const [date, setDate] = useState<SelectDateFormValues>({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  });

  const { data, isLoading, refetch, isFetching } = useQuery(["b2cRecruitmentsList", { ...date }], () =>
    recruitmentService.getEnduserRecruitmentsByEmployees(date.year, date.month)
  );

  return (
    <UIRoute {...props} title="Kundenanwerbungen für die B2C-App">
      <>
        <div style={{ marginBottom: 15 }}>
          <SelectDateForm initialDate={date} onSubmit={setDate} />
        </div>
        <B2CRecruitmentTable isLoading={isLoading || isFetching} onReload={refetch} recruitments={data} />
      </>
    </UIRoute>
  );
};

export default ListB2CRecruitmentsRoute;
