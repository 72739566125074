import React from "react";
import { Button, makeStyles } from "@material-ui/core";

interface FormSubmitButtonProps {
  disabled?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
  "data-testid"?: string;
}

const useStyles = makeStyles((theme) => ({
  submitButton: {
    width: "20%",
    alignSelf: "flex-end",
    marginTop: theme.spacing(2),
  },
}));

const FormSubmitButton: React.FC<FormSubmitButtonProps> = (props) => {
  const classes = useStyles();
  return (
    <Button data-testid={props["data-testid"]} className={classes.submitButton} color="primary" variant="contained" {...props}>
      {props.children}
    </Button>
  );
};

export default FormSubmitButton;
