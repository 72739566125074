import FormularResponseModel from "features/formular-center/data/FormularModel";
import Formular from "../domain/entities/Formular";
import ClientConverter from "features/clients/domain/converter/ClientConverter";
import CaseConverter from "features/cases/converters/CaseConverter";
import UserConverter from "features/users/domain/converter/UserConverter";
import BranchConverter from "features/branches/domain/converters/BranchConverter";

export default class FormularConverter {
  public static fromResponseModelToEntity(model: FormularResponseModel): Formular {
    return {
      _id: model._id,
      type: model.type,
      client: ClientConverter.fromResponseModelToClient(model.client),
      relatedCase: model.relatedCase ? CaseConverter.fromResponseModelToEntity(model.relatedCase) : undefined,
      relatedAssignment: model.relatedAssignment,
      createdUser: UserConverter.fromModelToEntity(model.createdUser),
      employee: UserConverter.fromModelToEntity(model.employee),
      createdDate: model.createdDate,
      imported: model.imported,
      closed: model.closed,
      closedDate: model.closedDate,
      itemList: model.itemList,
      costUnit: model.costUnit,
      ikNumber: model.ikNumber,
      branch: model.branch ? BranchConverter.fromResponseModelToEntity(model.branch) : undefined,
      groupKey: model.groupKey,
      signaturePath: model.signaturePath,
      pdfFile: model.pdfFile,
    };
  }
}
