import React from "react";

interface Props {
  className?: string;
}

const WebUI: React.FC<Props> = (props) => {
  return (
    <svg width="741" height="486" viewBox="0 0 741 486" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="741" height="486" rx="44" fill="#ECECEC" />
      <path d="M0 40H741V442C741 466.301 721.301 486 697 486H44C19.6995 486 0 466.301 0 442V40Z" fill="white" />
      <path
        d="M44.2283 20.5854C44.1774 21.7343 43.7903 22.8429 43.1153 23.7737C42.4402 24.7045 41.5068 25.4164 40.4309 25.8212C39.3551 26.226 38.184 26.3058 37.0632 26.0507C35.9424 25.7957 34.9211 25.2169 34.1261 24.3863C33.3312 23.5557 32.7975 22.5098 32.5913 21.3785C32.3851 20.2471 32.5155 19.0801 32.9664 18.0223C33.4172 16.9644 34.1686 16.0623 35.1273 15.4279C36.086 14.7935 37.2099 14.4547 38.3593 14.4535C39.9489 14.4925 41.4583 15.1593 42.558 16.3083C43.6577 17.4572 44.2582 18.9949 44.2283 20.5854Z"
        fill="white"
      />
      <path
        d="M67.6931 20.566C67.6459 21.7167 67.2616 22.8281 66.5879 23.7618C65.9142 24.6956 64.981 25.4106 63.9043 25.8177C62.8276 26.2249 61.655 26.3063 60.5325 26.0517C59.4099 25.7972 58.3869 25.218 57.5909 24.3862C56.7948 23.5544 56.2608 22.5068 56.0553 21.3736C55.8497 20.2405 55.9818 19.0719 56.435 18.0133C56.8882 16.9547 57.6425 16.0527 58.6041 15.4198C59.5657 14.7868 60.6921 14.4508 61.8431 14.4535C63.4275 14.4923 64.9321 15.157 66.0283 16.3024C67.1244 17.4477 67.723 18.9805 67.6931 20.566Z"
        fill="white"
      />
      <path
        d="M90.2594 20.566C90.2158 21.7167 89.861 22.8281 89.2392 23.7618C88.6173 24.6956 87.7559 25.4106 86.762 25.8177C85.7681 26.2249 84.6858 26.3063 83.6495 26.0517C82.6133 25.7972 81.6691 25.218 80.9342 24.3862C80.1994 23.5544 79.7065 22.5068 79.5167 21.3736C79.327 20.2405 79.4489 19.0719 79.8673 18.0133C80.2856 16.9547 80.9819 16.0527 81.8695 15.4198C82.7571 14.7868 83.7969 14.4508 84.8594 14.4535C86.3219 14.4923 87.7108 15.157 88.7226 16.3024C89.7344 17.4477 90.2869 18.9805 90.2594 20.566Z"
        fill="white"
      />
      <rect x="61.375" y="139.115" width="113.722" height="7.22677" rx="3.61338" fill="#F1F1F1" />
      <rect x="61.375" y="169.829" width="113.722" height="7.22677" rx="3.61338" fill="#F1F1F1" />
      <rect x="90.2539" y="243.903" width="84.8404" height="7.22677" rx="3.61338" fill="#F1F1F1" />
      <rect x="61.375" y="316.171" width="113.722" height="7.22677" rx="3.61338" fill="#F1F1F1" />
      <rect x="61.375" y="200.543" width="113.722" height="7.22677" rx="3.61338" fill="#F1F1F1" />
      <rect x="90.2539" y="274.617" width="84.8404" height="7.22677" rx="3.61338" fill="#F1F1F1" />
      <rect x="61.375" y="346.885" width="113.722" height="7.22677" rx="3.61338" fill="#F1F1F1" />
      <ellipse cx="40.6191" cy="142.729" rx="8.12302" ry="8.13011" fill="#F1F1F1" />
      <ellipse cx="103.797" cy="89.4312" rx="16.246" ry="16.2602" fill="#F1F1F1" />
      <ellipse cx="40.6191" cy="173.442" rx="8.12302" ry="8.13011" fill="#F1F1F1" />
      <ellipse cx="69.498" cy="247.517" rx="8.12302" ry="8.13011" fill="#F1F1F1" />
      <ellipse cx="40.6191" cy="319.784" rx="8.12302" ry="8.13011" fill="#F1F1F1" />
      <ellipse cx="40.6191" cy="204.156" rx="8.12302" ry="8.13011" fill="#F1F1F1" />
      <ellipse cx="69.498" cy="278.231" rx="8.12302" ry="8.13011" fill="#F1F1F1" />
      <ellipse cx="40.6191" cy="350.498" rx="8.12302" ry="8.13011" fill="#F1F1F1" />
      <rect x="199.316" y="110.958" width="479.563" height="303.831" rx="5.25" fill="#FCFCFC" stroke="#EEF2F6" strokeWidth="1.5" />
      <rect x="250.012" y="212.286" width="92.0609" height="9.03346" rx="4.51673" fill="#F1F1F1" />
      <rect x="250.012" y="293.587" width="102.892" height="9.03346" rx="4.51673" fill="#F1F1F1" />
      <rect x="393.52" y="212.286" width="207.588" height="9.03346" rx="4.51673" fill="#F1F1F1" />
      <rect x="393.52" y="293.587" width="207.588" height="9.03346" rx="4.51673" fill="#F1F1F1" />
      <rect x="250.012" y="132.792" width="113.722" height="22.5836" rx="10" fill="#F1F1F1" />
      <rect x="250.012" y="239.387" width="102.892" height="9.03346" rx="4.51673" fill="#F1F1F1" />
      <rect x="250.012" y="320.688" width="92.0609" height="9.03346" rx="4.51673" fill="#F1F1F1" />
      <rect x="393.52" y="239.387" width="207.588" height="9.03346" rx="4.51673" fill="#F1F1F1" />
      <rect x="393.52" y="320.688" width="207.588" height="9.03346" rx="4.51673" fill="#F1F1F1" />
      <rect x="250.012" y="266.487" width="108.307" height="9.03346" rx="4.51673" fill="#009BE4" />
      <rect x="393.52" y="266.487" width="207.588" height="9.03346" rx="4.51673" fill="#009BE4" />
      <line x1="229.254" y1="172.442" x2="646.236" y2="172.442" stroke="#EEF2F6" strokeWidth="2" />
    </svg>
  );
};

export default WebUI;
