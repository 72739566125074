import { Button, Divider } from "@material-ui/core";
import { ValidationMessageError } from "core/data/errors";
import useService from "core/di/useService";
import useBooleanStateToggle from "core/presentation/hooks/useBooleanStateToggle";
import { renderUserName } from "features/clients/presentation/utils";
import { PoolAssignmentReportB2CAttachmentWithAppointment, PoolAssignmentStatusTypes } from "features/pools/domain/entities/PoolAssignment";
import PoolService from "features/pools/domain/services/PoolService";
import React, { useMemo } from "react";
import { useQuery, useQueryCache } from "react-query";
import ContentSection from "template/presentation/components/content/ContentSection";
import LabeledValue from "template/presentation/components/content/LabeledValue";
import ValueGrid from "template/presentation/components/content/ValueGrid";
import { renderWantedAppointmentTimeText } from "../../helpers/renderWantedAppointmentTimeText";
import NegotiateAppointmentDialog from "./NegotiateAppointmentDialog";

interface Props {
  report: PoolAssignmentReportB2CAttachmentWithAppointment;
  assignmentId: string;
  setActionError: (error: string) => void;
  assignmentStatus: PoolAssignmentStatusTypes;
}

export const AttachmentB2CtoB2BAppointmentInfoSection: React.FC<Props> = ({ report, assignmentId, setActionError, assignmentStatus }) => {
  const [appointmentDialogOpen, openAppointmentDialog, closeAppointmentDialog] = useBooleanStateToggle(false);
  const poolService = useService(PoolService);

  const { data: employees } = useQuery(
    ["/users", { group: "b2bBackoffice" }],
    () =>
      poolService.getB2BEmployeesFromMyBranch().then((users) =>
        users.map((user) => ({
          value: user.employeeNumber || "",
          label: `${renderUserName(user)} (${user.b2bNumber})`,
        }))
      ),
    {
      enabled: report.appointmentStatus === undefined,
    }
  );
  const employeeOptionsWithLabelAsValue = useMemo(() => {
    return employees
      ? employees.map((employee) => ({
          value: employee.label,
          label: employee.label,
          number: employee.value,
        }))
      : [];
  }, [employees]);

  const queryCache = useQueryCache();

  const appointmentRequestText = useMemo(() => {
    let text = renderWantedAppointmentTimeText(report.appointment);
    if (report.appointmentStatus !== undefined) {
      return text + " - (es wurde bereits ein Termin reserviert)";
    }
    return text;
  }, [report.appointment, report.appointmentStatus]);

  return (
    <ContentSection label="Details">
      <ValueGrid>
        <LabeledValue data-testid="reportText" label="Anliegen" value={report.text} />
        <LabeledValue label="Anfrage für andere Person?" value={report.forWhom === "myself" ? "Nein" : "Ja: " + report.otherPerson} />
        <LabeledValue
          data-testid="reportPreferredCommunication"
          label="Rückfragen bevorzugt per"
          value={report.preferredCommunication === "email" ? "E-Mail" : "Telefon"}
        />
        <LabeledValue data-testid="reportEmail" label="E-Mail Rückfragen" value={report.email} />
        <LabeledValue data-testid="reportPhone" label="Tel. Rückfragen" value={report.phone} />
      </ValueGrid>
      <Divider style={{ marginBottom: "10px" }} />
      <ValueGrid>
        <LabeledValue data-testid="desired-appointment" label="Terminwunsch" value={appointmentRequestText} />
        {assignmentStatus !== "DONE" && report.appointmentStatus === undefined && (
          <>
            <Button data-testid="book-appointment" color="primary" variant="contained" onClick={openAppointmentDialog}>
              Termin reservieren
            </Button>
            <NegotiateAppointmentDialog
              wantedAppointment={report.appointment}
              open={appointmentDialogOpen}
              onReserveInWantedTimeRange={async (appointment) => {
                try {
                  await poolService.reserveB2CAppointmentInWantedTimeRange({
                    appointmentFrom: appointment.appointmentFrom,
                    appointmentUntil: appointment.appointmentUntil,
                    assignmentId,
                    assignmentNumber: assignmentId,
                    b2bEmployeeNumber: appointment.b2bEmployeeNumber,
                  });
                  queryCache.invalidateQueries(["/pools/assignments/", assignmentId, "/claim"]);
                  closeAppointmentDialog();
                } catch (e) {
                  if (e instanceof ValidationMessageError) {
                    setActionError(e.message);
                  } else {
                    setActionError("Unbekannter Fehler eingetreten");
                  }
                }
              }}
              onReserveInUnwantedTimeRange={async (appointment) => {
                try {
                  await poolService.reserveB2CAppointmentInWantedTimeRange({
                    appointmentFrom: appointment.appointmentFrom,
                    appointmentUntil: appointment.appointmentUntil,
                    assignmentId,
                    assignmentNumber: assignmentId,
                    b2bEmployeeNumber: appointment.b2bEmployeeNumber,
                  });
                  queryCache.invalidateQueries(["/pools/assignments/", assignmentId, "/claim"]);
                  closeAppointmentDialog();
                } catch (e) {
                  if (e instanceof ValidationMessageError) {
                    setActionError(e.message);
                  } else {
                    setActionError("Unbekannter Fehler eingetreten");
                  }
                }
              }}
              onDismiss={closeAppointmentDialog}
              employees={employeeOptionsWithLabelAsValue}
              needsConfirmationIfNotInWantedTimeRange={false}
              isB2B={true}
            />
          </>
        )}
      </ValueGrid>
    </ContentSection>
  );
};
