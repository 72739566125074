import React, { useCallback, useState } from "react";
import { RouteComponentProps, useParams } from "react-router-dom";
import { useQuery, useQueryCache } from "react-query";
import useService from "core/di/useService";
import UIRoute from "core/presentation/components/UIRoute";
import ClassificationService from "../../domain/services/ClassificationService";
import CreateOrUpdateClassificationForm, { CreateOrUpdateClassificationFormValues } from "../components/CreateOrUpdateClassificationForm";
import { ValidationMessageError } from "core/data/errors";

export interface EditClassificationRouteProps extends RouteComponentProps {}

const EditClassificationRoute = (props: EditClassificationRouteProps) => {
  const { id } = useParams<{ id: string }>();
  const classificationService = useService(ClassificationService);
  const queryCache = useQueryCache();
  const [error, setError] = useState<string | null>(null);
  const { data: classification } = useQuery(["classification", id], () => classificationService.getById(id));

  const onSubmit = useCallback(
    (values: CreateOrUpdateClassificationFormValues) => {
      return classificationService
        .update(id, values)
        .then(() => {
          setError(null);
        })
        .then(() => {
          queryCache.invalidateQueries("classificationsList");
        })
        .then(() => {
          queryCache.invalidateQueries(["classification", id]);
        })
        .then(() => {
          props.history.goBack();
        })
        .catch((e) => {
          //TODO: check correct instance and show correct error
          if (e instanceof ValidationMessageError) {
            switch (e.code) {
              case "DUPLICATE_CLASSIFICATION_NUMBER":
                setError("Die Klassifizierung ist bereits Vorhanden.");
                return;
              default:
                setError(`Ein unbekannter Fehler ist aufgetreten. Code: ${e.code || "VMSG400"}`);
                return;
            }
          } else {
            setError(`Ein unbekannter Fehler ist aufgetreten`);
          }
        });
    },
    [props.history, queryCache, classificationService, id]
  );

  return (
    <UIRoute {...props} title="Klassifikation bearbeiten">
      {classification ? (
        <CreateOrUpdateClassificationForm
          classification={classification}
          error={error}
          onSubmit={onSubmit}
          mappingOptions={classificationService.getMappingOptions()}
        />
      ) : (
        <div />
      )}
    </UIRoute>
  );
};

export default EditClassificationRoute;
