import React, { useCallback, useMemo, useState } from "react";
import PoolAssignment from "features/pools/domain/entities/PoolAssignment";
import { useService } from "core/di";
import PoolService from "features/pools/domain/services/PoolService";
import { useQueryCache } from "react-query";
import { Alert } from "@material-ui/lab";
import ContentContainer from "template/presentation/components/content/ContentContainer";
import { useAttachedFiles } from "./useAttachedFiles";
import { AssignmentInfoSection } from "./detail/AssignmentInfoSection";
import { ClientInfoSection } from "./detail/ClientInfoSection";
import { SenderInfoSection } from "./detail/SenderInfoSection";
import { AttachmentInfoSection } from "./detail/AttachmentInfoSection";
import { AttachmentB2CInfoSection } from "./detail/AttachmentB2CInfoSection";
import { AttachmentB2CWithAppointmentInfoSection } from "./detail/AttachmentB2CWithAppointmentInfoSection";
import { ConsultationRequestB2CInfoSection } from "./detail/ConsultationRequestB2CInfoSection";
import { VideoConsultationInfoSection } from "./detail/VideoConsultationInfoSection";
import { OnSiteConsultationInfoSection } from "./detail/OnSiteConsultationInfoSection";
import { AttachedFilesSection } from "./detail/AttachedFilesSection";
import { SelectOption } from "features/pools/domain/entities/PoolEntity";
import { FinishAssignmentSection } from "./detail/FinishAssignmentSection";
import { ActionSection } from "./detail/ActionSection";
import { useCurrentUserInGroup } from "features/login/presentation/hooks/useUser";
import { AssignmentHistorySection } from "./detail/AssignmentHistorySection";
import { DeliveryInfoSection } from "./detail/DeliveryInfoSection";
import { CollectionInfoSection } from "./detail/CollectionInfoSection";
import { RepairInfoSection } from "./detail/RepairInfoSection";
import { ConsultingInfoSection } from "./detail/ConsultingInfoSection";
import { VideoConsultingInfoSection } from "./detail/VideoConsultingInfoSection";
import { Button, Typography } from "@material-ui/core";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import { VideoConsultationRequestB2BInfoSection } from "./detail/VideoConsultationRequestB2BInfoSection";
import { VideoConsultationB2BInfoSection } from "./detail/VideoConsultationB2BInfoSection";
import { ConsultationRequestB2CtoB2BInfoSection } from "./detail/ConsultationRequestB2CtoB2BInfoSection";
import { AttachmentB2CtoB2BAppointmentInfoSection } from "./detail/AttachmentB2CtoB2BAppointmentInfoSection";
import { BusinessInquirySection } from "./detail/BusinessInquirySection";
import AssignmentService from "features/assignments/domain/services/AssignmentService";
import { formatISO, parse } from "date-fns";
import Case from "features/cases/domain/entities/Case";
import { VisitSection } from "./detail/VisitSection";
import { ValidationMessageError } from "core/data/errors";
import { InboxSection } from "./detail/InboxSection";
import { SignatureRequestSection } from "./detail/SignatureRequestSection";
import { UpdateSigingRequestFormProps } from "./detail/UpdateSigningRequestDialog";

export interface PoolAssignmentDetailsProps {
  assignment: PoolAssignment;
  loadingError: string | null;
  actionError: string | null;
  classificationOptions: SelectOption[] | undefined;
  allClassificationOptions?: SelectOption[];
  userOptions: SelectOption[] | undefined;
  branchOptions: SelectOption[] | undefined;
  historyGoBack: () => void;
  onFinish: (assignmentId: string) => void;
  setActionError: (error: string) => void;
  b2bPoolOptions?: SelectOption[];
  b2bPartnerOptions?: SelectOption[];
  employeeOptionsForCase?: SelectOption[];
  connectedCase?: Case;
  caseQuery: {
    caseOptions: SelectOption[];
    setCaseNumberQuery: (value: string) => void;
    setCaseOptionSelected: (value: string) => void;
    caseOptionsLoading: boolean;
    onAssignCase: () => void;
  };
  selectedCaseDetails: React.ReactNode | null;
  clientSelection: {
    clientOptions: SelectOption[];
    setClientQuery: (value: string) => void;
    clientOptionsLoading: boolean;
  };
  prescriberInformation: any;
  onSigningRequestUpdate: (model: UpdateSigingRequestFormProps) => Promise<any>;
  onUpdateError: string | null;
  sendSignatureNotification: (data: any, assignment: any) => void;
}

export default function PoolAssignmentDetails(props: PoolAssignmentDetailsProps) {
  const {
    assignment,
    loadingError,
    actionError,
    classificationOptions,
    allClassificationOptions,
    userOptions,
    branchOptions,
    historyGoBack,
    onFinish,
    setActionError,
    b2bPoolOptions,
    b2bPartnerOptions,
    employeeOptionsForCase,
    connectedCase,
    caseQuery,
    selectedCaseDetails,
    clientSelection,
    prescriberInformation,
    onSigningRequestUpdate,
    onUpdateError,
    sendSignatureNotification,
  } = props;
  const { caseOptions, caseOptionsLoading, onAssignCase, setCaseNumberQuery, setCaseOptionSelected } = caseQuery;
  const { clientOptions, setClientQuery, clientOptionsLoading } = clientSelection;
  const poolService = useService(PoolService);
  const assignmentService = useService(AssignmentService);
  const queryCache = useQueryCache();
  const [isAdmin, isNotAdmin] = useCurrentUserInGroup("admin");
  const [isB2BBackoffice, isNotB2BBackoffice] = useCurrentUserInGroup("b2bBackoffice");

  const attachedFiles = useAttachedFiles(poolService, assignment);

  const files = useMemo(() => <AttachedFilesSection sections={attachedFiles} />, [attachedFiles]);

  const [syncDocError, setSyncDocError] = useState(false);
  const [caseCreationError, setCaseCreationError] = useState<string>("");
  const [attachmentsAreSyncing, setAttachmentsAreSyncing] = useState<boolean>(false);
  const onSyncAttachments = useCallback(
    (assignmentId: string) => {
      poolService
        .createCaseFilesForAssignment(assignmentId)
        .then((x) => {
          setSyncDocError(false);
          setAttachmentsAreSyncing(false);
          queryCache.invalidateQueries(["/pools/assignments/", assignment.assignmentId, "/claim"]);
          return x.data;
        })
        .catch(() => {
          setSyncDocError(true);
        });
    },
    [assignment.assignmentId, poolService, queryCache]
  );

  const onConnectToExistingClient = useCallback(
    (clientNumber: string) => {
      assignmentService
        .assignClient(assignment.assignmentId, clientNumber)
        .then(() => queryCache.invalidateQueries(["/pools/assignments/", assignment.assignmentId, "/claim"]));
    },
    [assignmentService, assignment.assignmentId, queryCache]
  );

  const employeeOptionsWithLabelAsValue = useMemo(() => {
    return employeeOptionsForCase
      ? employeeOptionsForCase.map((employee) => ({
          value: employee.label,
          label: employee.label,
        }))
      : [];
  }, [employeeOptionsForCase]);
  if (!assignment) {
    return (
      <Alert data-testid="pool-assignment-loading-error" severity="error">
        {"Ungültiger Aiftragstyp."}
      </Alert>
    );
  }

  if (loadingError) {
    return (
      <Alert data-testid="pool-assignment-loading-error" severity="error">
        {loadingError}
      </Alert>
    );
  }
  const buttonStatus = !assignment.relatedCaseNumber || attachmentsAreSyncing;
  const docsSynced = assignment.syncedAttachmentsToCase && assignment.syncedAttachmentsToCase.length > 0;
  return (
    <ContentContainer>
      {actionError && (
        <Alert data-testid="pool-assignment-action-error" severity="error">
          {actionError}
        </Alert>
      )}
      <ActionSection
        assignment={assignment}
        classificationOptions={classificationOptions}
        userOptions={userOptions}
        branchOptions={branchOptions}
        onActionSuccessful={historyGoBack}
        setActionError={setActionError}
        b2bPoolOptions={b2bPoolOptions}
        b2bPartnerOptions={b2bPartnerOptions}
      />

      <AssignmentInfoSection
        assignment={assignment}
        onCreateNewCase={async (newCase) => {
          try {
            await poolService.createCaseForAssignment(assignment.assignmentId, {
              ...newCase,
              appointmentFrom: newCase.appointmentFrom && formatISO(parse(newCase.appointmentFrom, "yyyy-MM-dd'T'HH:mm", new Date())),
              appointmentUntil: newCase.appointmentUntil && formatISO(parse(newCase.appointmentUntil, "yyyy-MM-dd'T'HH:mm", new Date())),
              employeeNumber: employeeOptionsForCase?.find((employee) => newCase.employeeNumber === employee.label)?.value || "",
            });
          } catch (err) {
            if (err instanceof ValidationMessageError) {
              switch (err.code) {
                case "EMPLOYEE_NOT_FOUND":
                  setCaseCreationError("Unbekannten Mitarbeiter angegeben");
                  return;
                case "CLIENT_NOT_FOUND":
                  setCaseCreationError("Unbekannten Kunden angegeben");
                  return;
                case "CASE_NOT_FOUND":
                  setCaseCreationError("Unbekannten Vorgang angegeben");
                  return;
                case "CASE_CREATION_FAILED":
                  setCaseCreationError("Vorgang konnte auf Branchensoftware nicht erstellt werden.");
                  return;
                default:
                  setCaseCreationError(`Ein unbekannter Fehler ist aufgetreten. Vorgang konnte nicht erstellt werden Code: ${err.code}`);
                  return;
              }
            } else {
              setCaseCreationError("Ein unbekannter Fehler ist aufgetreten");
            }

            return;
          }
          queryCache.invalidateQueries(["/pools/assignments/", assignment.assignmentId, "/claim"]);
        }}
        isB2BBackoffice={isB2BBackoffice}
        classifications={allClassificationOptions}
        employees={employeeOptionsWithLabelAsValue}
        connectedCase={connectedCase}
        caseQuery={{
          caseOptions,
          caseOptionsLoading,
          setCaseNumberQuery,
          onAssignCase,
          setCaseOptionSelected,
        }}
        selectedCaseDetails={selectedCaseDetails}
        error={caseCreationError}
      />
      <SenderInfoSection user={assignment.employee} prescriberInformation={prescriberInformation} assignmentType={assignment.type} />
      {assignment.type !== "BUSINESS_INQUIRY" && (assignment.client || assignment.newClient) && (
        <ClientInfoSection
          client={assignment.client || assignment.newClient}
          isNewClient={assignment.newClient !== undefined}
          internalPool={isNotB2BBackoffice}
          onCreateNewClient={() => {
            return poolService
              .submitAssignmentWithNewClient(assignment.assignmentId)
              .then(async () => {
                await queryCache.invalidateQueries(["/pools/assignments/", assignment.assignmentId, "/claim"]);
              })
              .catch((err) => {
                if (err instanceof ValidationMessageError) {
                  switch (err.code) {
                    case "CLIENT_CREATION_FAILED":
                      setActionError("Kunde konnte auf Branchensoftware nicht angelegt werden.");
                      return;
                    default:
                      setActionError(`Ein unbekannter Fehler ist aufgetreten. Code: ${err.code}`);
                      return;
                  }
                } else {
                  setActionError("Ein unbekannter Fehler ist aufgetreten");
                }
              });
          }}
          assignmentStatus={assignment.assignmentState.status}
          onModifyNewClient={(newClient) =>
            assignmentService
              .patch(assignment.assignmentId, { newClient })
              .then(async () => {
                await queryCache.invalidateQueries(["/pools/assignments/", assignment.assignmentId, "/claim"]);
              })
              .catch((err) => setActionError(err.message))
          }
          clientSelection={{
            clientOptions,
            setClientQuery,
            clientOptionsLoading,
          }}
          onConnectToExistingClient={onConnectToExistingClient}
          assignmentType={assignment.type}
        />
      )}

      {assignment.type === "ATTACHMENT" && <AttachmentInfoSection report={assignment.report} rehaItems={assignment.relatedRehaItems} />}
      {assignment.type === "ATTACHMENT_B2C" && <AttachmentB2CInfoSection report={assignment.report} />}
      {assignment.type === "EDIT_PROFILE" && <AttachmentB2CInfoSection report={assignment.report} />}
      {assignment.type === "ATTACHMENT_B2C_WITH_APPOINTMENT" && (
        <AttachmentB2CWithAppointmentInfoSection
          report={assignment.report}
          assignmentId={assignment.assignmentId}
          assignmentNumber={assignment.assignmentNumber}
          relatedCaseNumber={assignment.relatedCaseNumber}
          setActionError={setActionError}
          assignmentStatus={assignment.assignmentState.status}
        />
      )}
      {assignment.type === "ATTACHMENT_B2C_TO_B2B_WITH_APPOINTMENT" && (
        <AttachmentB2CtoB2BAppointmentInfoSection
          report={assignment.report}
          assignmentId={assignment.assignmentId}
          setActionError={setActionError}
          assignmentStatus={assignment.assignmentState.status}
        />
      )}
      {assignment.type === "CONSULTATION_REQUEST_B2C" && (
        <ConsultationRequestB2CInfoSection
          report={assignment.report}
          assignmentId={assignment.assignmentId}
          assignmentNumber={assignment.assignmentNumber}
          relatedCaseNumber={assignment.relatedCaseNumber}
          setActionError={setActionError}
          assignmentStatus={assignment.assignmentState.status}
        />
      )}
      {assignment.type === "CONSULTATION_REQUEST_B2C_TO_B2B" && (
        <ConsultationRequestB2CtoB2BInfoSection
          report={assignment.report}
          assignmentId={assignment.assignmentId}
          setActionError={setActionError}
          assignmentStatus={assignment.assignmentState.status}
        />
      )}
      {assignment.type === "VIDEO_CONSULTATION" && (
        <VideoConsultationInfoSection report={assignment.report} rehaItems={assignment.relatedRehaItems} />
      )}
      {assignment.type === "ON_SITE_CONSULTATION" && (
        <OnSiteConsultationInfoSection report={assignment.report} rehaItems={assignment.relatedRehaItems} />
      )}
      {(assignment.type === "DELIVERY" || assignment.type === "UNPLANNED_DELIVERY") && (
        <DeliveryInfoSection report={assignment.report} rehaItems={assignment.relatedRehaItems} />
      )}
      {assignment.type === "COLLECTION" && <CollectionInfoSection report={assignment.report} rehaItems={assignment.relatedRehaItems} />}
      {assignment.type === "REPAIR" && <RepairInfoSection report={assignment.report} rehaItems={assignment.relatedRehaItems} />}
      {assignment.type === "CONSULTING" && <ConsultingInfoSection report={assignment.report} rehaItems={assignment.relatedRehaItems} />}
      {assignment.type === "VIDEO_CONSULTING" && (
        <VideoConsultingInfoSection report={assignment.report} rehaItems={assignment.relatedRehaItems} />
      )}
      {assignment.type === "VIDEO_CONSULTATION_B2B" && (
        <VideoConsultationB2BInfoSection report={assignment.report} rehaItems={assignment.relatedRehaItems} />
      )}
      {assignment.type === "CONSULTATION_REQUEST_B2B" && (
        <VideoConsultationRequestB2BInfoSection
          report={assignment.report}
          assignmentId={assignment.assignmentId}
          assignmentNumber={assignment.assignmentNumber}
          relatedCaseNumber={assignment.relatedCaseNumber}
          setActionError={setActionError}
          assignmentStatus={assignment.assignmentState.status}
        />
      )}

      {assignment.type === "BUSINESS_INQUIRY" && <BusinessInquirySection report={assignment.report} />}
      {assignment.type === "VISIT" && <VisitSection report={assignment.report} rehaItems={assignment.relatedRehaItems} />}
      {assignment.type === "INBOX" && <InboxSection report={assignment.report} />}
      {assignment.type === "SIGNATURE_REQUEST" && (
        <SignatureRequestSection
          assignmentId={assignment.assignmentId}
          client={assignment.client || assignment.newClient}
          status={assignment.status}
          onSigningRequestUpdate={onSigningRequestUpdate}
          onUpdateError={onUpdateError}
          report={assignment.report}
          sendSignatureNotification={sendSignatureNotification}
        />
      )}
      {isNotB2BBackoffice && (
        <div style={{ margin: 16 }}>
          <Button
            data-testid="upload-documentation"
            startIcon={<SyncAltIcon />}
            // sorry =/
            disabled={docsSynced ? isNotAdmin : !(!buttonStatus || isAdmin)}
            color={docsSynced ? "secondary" : "primary"}
            variant="contained"
            onClick={async () => {
              if (docsSynced) {
                if (window.confirm("Dokumente im Vorgang bereits hinterlegt, soll diese Aktion wiederholt werden?")) {
                  setAttachmentsAreSyncing(true);
                  onSyncAttachments(assignment.assignmentId);
                }
              } else {
                setAttachmentsAreSyncing(true);
                onSyncAttachments(assignment.assignmentId);
              }
            }}
          >
            Dokumentation in Vorgang hinterlegen
          </Button>
          {docsSynced && (
            <Typography data-testid="upload-documentation-alreadysynced" color="textSecondary">
              Dokumente bereits im Vorgang hinterlegt
            </Typography>
          )}
          {syncDocError && (
            <Alert data-testid="pool-assignment-sync-doc-error" severity="error">
              Fehler beim Übertragen der Dokumente
            </Alert>
          )}
        </div>
      )}
      {files}
      {(isAdmin || isB2BBackoffice) && assignment.poolHistory && <AssignmentHistorySection assignmentHistory={assignment.poolHistory} />}
      <FinishAssignmentSection assignmentId={assignment.assignmentId} assignmentState={assignment.assignmentState} onFinish={onFinish} />
    </ContentContainer>
  );
}
