import React from "react";
import useService from "core/di/useService";
import { RouteComponentProps, useParams } from "react-router-dom";
import UIRoute from "core/presentation/components/UIRoute";
import SignatureformsService from "../../domain/services/SignatureformsService";
import { useQuery } from "react-query";
import LabeledValue from "template/presentation/components/content/LabeledValue";
import ContentContainer from "template/presentation/components/content/ContentContainer";
import ContentSection from "template/presentation/components/content/ContentSection";

export interface EditSignatureformsRouteProps extends RouteComponentProps {}

const EditSignatureformsRoute = (props: EditSignatureformsRouteProps) => {
  const signatureformsService = useService(SignatureformsService);
  const { id } = useParams<{ id: string }>();

  const { data: signatureform } = useQuery(["signatureform", id], () => signatureformsService.getById(id));
  const { data: path } = useQuery(["signatureformDocument", id], () => signatureformsService.getDocument(id));

  return (
    <UIRoute {...props} title={"Formularvorschau"}>
      <ContentContainer>
        <div>
          {signatureform && path && (
            <ContentSection label={"Bezeichnung"}>
              <LabeledValue data-testid="title" label="Titel" value={signatureform.title} />
              <iframe
                title={signatureform.title}
                key={signatureform.title}
                src={path}
                id="pdf"
                width="900px"
                height="1200px"
                style={{ overflow: "auto" }}
              />
            </ContentSection>
          )}
        </div>
      </ContentContainer>
    </UIRoute>
  );
};

export default EditSignatureformsRoute;
