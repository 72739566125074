import React, { useCallback, useState } from "react";
import StandingDataTable from "../components/StandingDataTable";
import { useQuery } from "react-query";
import ClientService from "../../domain/services/ClientService";
import { useService } from "core/di";
import UIRoute from "core/presentation/components/UIRoute";
import { RouteComponentProps, useParams } from "react-router-dom";
import StandingDataSubHeader from "../components/StandingDataSubHeader";
import B2BSpace from "../../domain/services/B2BSpace";
import { usePageAndPageSizeAndAnyQueryFromRouteProps } from "core/utils";

export interface ClientsRouteProps extends RouteComponentProps {}

const ClientsRoute = (props: ClientsRouteProps) => {
  const { page, pageSize, searchQueries } = usePageAndPageSizeAndAnyQueryFromRouteProps(props);
  const [sortedBy, setSortedBy] = useState<{ field: string; order: "asc" | "desc" }>({ field: "firstname", order: "asc" });
  const { group } = useParams<any>();

  const clientService = useService(ClientService);
  let queryUri: string;
  if (group) {
    queryUri = `/clients/${group}?page=`;
  } else {
    queryUri = `/clients?page=`;
  }

  const onHandleQuery = useCallback(
    (page, pageSize, searchQuery) => {
      let query = queryUri + page;
      if (pageSize) {
        query += "&page_size=" + pageSize;
      }
      if (searchQuery) {
        for (const [key, value] of Object.entries(searchQuery)) {
          if (value) {
            query += `&${key}=${value}`;
          }
        }
      }
      props.history.replace(query);
    },
    [props.history, queryUri]
  );

  const onSortChange = useCallback((sortBy: string, sortOrder: "asc" | "desc") => {
    setSortedBy({ field: sortBy, order: sortOrder });
  }, []);

  const { data, isLoading, refetch, isFetching } = useQuery(["clientList", { searchQueries, page, pageSize, group, sortedBy }], () =>
    clientService.get({ fields: searchQueries, group, page, pageSize, sortedBy })
  );

  return (
    <UIRoute
      {...props}
      title="Stammdaten"
      renderSubHeader={() => <StandingDataSubHeader path={props.location.pathname} onTabChange={props.history.push} />}
    >
      <StandingDataTable
        group={group}
        isLoading={isLoading || isFetching}
        onReload={refetch}
        onSearch={(searchValue) => {
          onHandleQuery(1, pageSize, searchValue);
        }}
        page={page}
        pageSize={pageSize}
        totalCount={data?.totalCount}
        onPageChange={(newPage: number) => {
          onHandleQuery(newPage, pageSize, searchQueries);
        }}
        onPageSizeChange={(newPageSize: number) => {
          onHandleQuery(page, newPageSize, searchQueries);
        }}
        onAdd={() => {
          props.history.push(group === B2BSpace.INTERMEDIARY ? `/clients/create/${B2BSpace.INTERMEDIARY}` : "/clients/create");
        }}
        clients={data?.data || null}
        sortedBy={sortedBy}
        onSortChange={onSortChange}
      />
    </UIRoute>
  );
};

export default ClientsRoute;
