import React from "react";
import DnsRoundedIcon from "@material-ui/icons/DnsRounded";
import BallotIcon from "@material-ui/icons/Ballot";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";

export default [
  {
    id: "Allgemein",
    children: [{ id: "Mitteilungen", icon: <DnsRoundedIcon />, path: "/messages/" }],
  },
  { id: "Formular Center", children: [{ id: "Übersicht Formulare", icon: <LibraryBooksIcon />, path: "/signatureforms" }] },
  {
    id: "Pool-Auftragsübersicht",
    children: [{ id: "Vorgänge", icon: <BallotIcon />, path: "/pools/assignments/all" }],
  },
];
