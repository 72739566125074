import { useCurrentUser } from "features/login/presentation/hooks/useUser";
import adminMenu from "../menues/adminMenu";
import b2bBackofficeMenu from "../menues/b2bBackofficeMenu";
import backofficeMenu from "../menues/backofficeMenu";
import { useService } from "core/di";
import ShopService from "features/shop/domain/services/ShopService";
import { useQuery } from "react-query";
import { useMemo } from "react";

export function useNavigationCategories() {
  const aboutMeQuery = useCurrentUser();
  const groups = aboutMeQuery.data?.groups || [];
  const shopService = useService(ShopService);

  const { data: shopEnabled } = useQuery(["shopEnabled"], () => shopService.getShopEnabled());

  const { data: shopUrl } = useQuery(["shopUrl"], () => shopService.getShopUrl());

  const menuAdmin: any = useMemo(() => {
    return adminMenu(shopEnabled ? true : false, shopUrl ? shopUrl : undefined);
  }, [shopEnabled, shopUrl]);

  const menuBackoffice: any = useMemo(() => {
    return backofficeMenu(shopEnabled ? true : false, shopUrl ? shopUrl : undefined);
  }, [shopEnabled, shopUrl]);

  if (groups.includes("admin")) {
    return menuAdmin;
  } else if (groups.includes("b2bBackoffice")) {
    return b2bBackofficeMenu;
  } else {
    return menuBackoffice;
  }
}
