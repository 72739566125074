import React, { useMemo } from "react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import { makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import FormSection from "template/presentation/components/form/FormSection";
import FormContainer from "template/presentation/components/form/FormContainer";
import FormSubmitButton from "template/presentation/components/form/FormSubmitButton";
import { Classification, SelectMappingOptions } from "../../domain/entities/ClassificationEntity";
import SelectField from "template/presentation/components/form/SelectField";

export interface CreateOrUpdateClassificationFormValues {
  name: string;
  number: string;
  assignmentMapping: string;
}

const createOrUpdateUserSchema = Yup.object().shape({
  name: Yup.string().required("Benötigt"),
  number: Yup.string().required("Benötigt"),
});

export interface InstanceFormProps {
  onSubmit: (values: CreateOrUpdateClassificationFormValues) => Promise<void>;
  error: string | null;
  classification?: Classification;
  mappingOptions: SelectMappingOptions[];
}

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(1),
  },
}));

const CreateOrUpdateClassificationForm = (props: InstanceFormProps) => {
  const classes = useStyles();
  const { onSubmit, error, classification, mappingOptions } = props;

  const initialValuesFromProps: CreateOrUpdateClassificationFormValues = useMemo(() => {
    return {
      name: classification?.name || "",
      number: classification?.number || "",
      assignmentMapping: classification?.assignmentMapping || "",
    };
  }, [classification]);

  return (
    <Formik<CreateOrUpdateClassificationFormValues>
      enableReinitialize
      initialValues={initialValuesFromProps}
      validationSchema={createOrUpdateUserSchema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ values, submitForm, isSubmitting }) => (
        <FormContainer>
          <FormSection label="Allgemeine Informationen">
            <Field
              data-testid="field-code"
              className={classes.field}
              component={TextField}
              variant="outlined"
              name="number"
              type="text"
              label="Nummer*"
            />
            <Field
              data-testid="field-name"
              className={classes.field}
              component={TextField}
              variant="outlined"
              name="name"
              type="text"
              label="Name*"
            />
            <Field
              data-testid="field-assignmentmapping"
              name="assignmentMapping"
              legend="Auftragstyp"
              className={classes.field}
              component={SelectField}
              options={mappingOptions}
            />
          </FormSection>

          <FormSection>
            {error && (
              <Alert className={classes.field} data-testid="createclassification-error" severity="error">
                {error}
              </Alert>
            )}

            <FormSubmitButton data-testid={"create-button"} disabled={isSubmitting} onClick={submitForm}>
              {classification ? "Bearbeiten" : "Erstellen"}
            </FormSubmitButton>
          </FormSection>
        </FormContainer>
      )}
    </Formik>
  );
};

export default CreateOrUpdateClassificationForm;
