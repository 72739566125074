import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

interface Props {
  label: string;
  value?: string | number | null;
  "data-testid"?: string;
}

const useStyles = makeStyles((theme) => ({
  label: {
    lineHeight: 1,
  },
}));

const LabeledValue: React.FC<Props> = ({ label, value, ...props }) => {
  const testId = props["data-testid"];
  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.label} variant="overline" display="block">
        {label}
      </Typography>
      <Typography data-testid={testId} variant="body2" display="block" gutterBottom style={{ wordWrap: "break-word" }}>
        {(value && value) || "-"}
      </Typography>
    </div>
  );
};
export default LabeledValue;
