import React, { useState, useCallback } from "react";
import { Field, Formik } from "formik";
import { TextField } from "formik-material-ui";
import FormSection from "template/presentation/components/form/FormSection";
import FormContainer from "template/presentation/components/form/FormContainer";
import FormSubmitButton from "template/presentation/components/form/FormSubmitButton";
import Client from "features/clients/domain/entities/Client";
import { Button, makeStyles, Paper, Typography } from "@material-ui/core";
import * as Yup from "yup";
import AddAreaRequestModel from "../../domain/components/AddAreaRequestModel";
import { Alert } from "@material-ui/lab";
import DataTable, { DataTableCell } from "template/presentation/components/table/DataTable";
import AreaResponseModel from "features/b2b/domain/components/AreaResponseModel";
import CollapseableFormSection from "template/presentation/components/form/CollapsableFormSection";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DataContainer from "template/presentation/components/table/DataContainer";
import DeleteAreaDialog from "./DeleteAreaDialog";

interface AddAreaProps {
  careHomeData: Client;
  areasData: AreaResponseModel[] | null | undefined;
  onAddAreaSubmit: (data: AddAreaRequestModel) => void;
  onDeleteArea: (data: DeleteAreaData) => void;
  error: string | null;
}

interface AddAreaFormValues {
  b2bClientNumber: string;
  name: string;
  areaNumber: string;
}
interface CareHomeAreaDetails {
  id: string;
  name: string;
  areaNumber: string;
  noOfEmployees: string;
  noOfPatients: string;
}

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(1),
  },
}));

const getAreaTableContent = (input: AreaResponseModel[]): CareHomeAreaDetails[] => {
  return input.map((x) => {
    return {
      id: x.id,
      name: x.name,
      areaNumber: x.areaNumber,
      noOfPatients: x.members.length.toString(),
      noOfEmployees: x.users.length.toString(),
    };
  });
};

export interface DeleteAreaData {
  clientNumber: string;
  areaNumber: string;
}

export interface DeleteAreaDependencies {
  usersCount: string;
  membersCount: string;
}

const areaColumn = [
  { name: "Name des Wohnbereichs" },
  { name: "Nummer" },
  { name: "Mitarbeiter" },
  { name: "Bewohner" },
  { name: "Bearbeiten" },
  { name: "Entfernen" },
];

export default function ManageAreaForm(props: AddAreaProps) {
  const { onAddAreaSubmit: onSubmit, careHomeData, error, onDeleteArea, areasData } = props;
  const classes = useStyles();
  const [addFormOpen, setAddFormOpen] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [areaToDelete, setAreaToDelete] = useState<undefined | DeleteAreaData>(undefined);
  const [deleteAreaDependencies, setDeleteAreaDependencies] = useState<undefined | DeleteAreaDependencies>(undefined);

  const initVals = {
    b2bClientNumber: careHomeData.clientNumber || "",
    name: "",
    areaNumber: "",
  };

  const AddAreaValidationSchema = Yup.object().shape({
    b2bClientNumber: Yup.string().required("Benötigt"),
    name: Yup.string().required("Benötigt"),
    areaNumber: Yup.string().required("Benötigt"),
  });

  const setAddFormOpenCallback = useCallback(() => {
    setAddFormOpen(!addFormOpen);
  }, [addFormOpen, setAddFormOpen]);

  const submitFunction = async (values: AddAreaFormValues): Promise<void> => {
    const data: AddAreaRequestModel = {
      path: { b2bClientNumber: values.b2bClientNumber },
      body: { areaNumber: values.areaNumber, name: values.name },
    };
    onSubmit(data);
  };

  const handleDelete = useCallback((clientNumber: string, areaNumber: string, usersCount: string, membersCount: string) => {
    setDialogOpen(true);
    setAreaToDelete({ clientNumber, areaNumber });
    setDeleteAreaDependencies({
      usersCount,
      membersCount,
    });
  }, []);

  const onDismiss = useCallback(() => {
    setDialogOpen(false);
    setAreaToDelete(undefined);
    setDeleteAreaDependencies(undefined);
  }, [setDialogOpen]);

  const onAgree = useCallback(() => {
    setDialogOpen(false);
    if (areaToDelete) {
      onDeleteArea(areaToDelete);
      setAreaToDelete(undefined);
      setDeleteAreaDependencies(undefined);
    }
  }, [areaToDelete, onDeleteArea]);

  return (
    <>
      <DeleteAreaDialog open={dialogOpen} onDismiss={onDismiss} onAgree={onAgree} dependencies={deleteAreaDependencies} />
      <DataContainer loading={areasData === undefined} showSearchbar={false}>
        {areasData && areasData.length > 0 ? (
          <DataTable<CareHomeAreaDetails>
            columns={areaColumn}
            rows={getAreaTableContent(areasData)}
            keyExtractor={(data) => data.id}
            renderRow={(data, index) => {
              return (
                <>
                  <DataTableCell data-testid={`area-name-row-${index}`}>{data.name}</DataTableCell>
                  <DataTableCell data-testid={`area-areaNumber-row-${index}`}>{data.areaNumber}</DataTableCell>
                  <DataTableCell data-testid={`area-noOfEmployees-row-${index}`}>{data.noOfEmployees}</DataTableCell>
                  <DataTableCell data-testid={`area-noOfPatients-row-${index}`}>{data.noOfPatients}</DataTableCell>
                  <DataTableCell>
                    <Link
                      data-testid={`link-livingAreaEdit-row-${index}`}
                      to={`/area/editArea/${careHomeData.clientNumber}/${data.areaNumber}`}
                    >
                      <EditIcon />
                    </Link>
                  </DataTableCell>
                  <DataTableCell>
                    <Button onClick={() => handleDelete(careHomeData.clientNumber, data.areaNumber, data.noOfEmployees, data.noOfPatients)}>
                      <DeleteIcon />
                    </Button>
                  </DataTableCell>
                </>
              );
            }}
          />
        ) : (
          <Paper>
            <Typography color="textSecondary" align="center">
              Keine Wohnbereiche angelegt
            </Typography>
          </Paper>
        )}
      </DataContainer>
      <div style={{ marginTop: 50 }}>
        <Formik<AddAreaFormValues>
          initialValues={initVals}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              submitFunction(values);
              resetForm({});
            } finally {
              setSubmitting(false);
            }
          }}
          validationSchema={AddAreaValidationSchema}
        >
          {({ submitForm, isSubmitting }) => (
            <FormContainer>
              <CollapseableFormSection collapsed={addFormOpen} onClick={setAddFormOpenCallback} label={"Anlegen eines Wohnbereiches"}>
                <Field
                  data-testid="field-livingAreab2b"
                  className={classes.field}
                  component={TextField}
                  variant={"outlined"}
                  name={"b2bClientNumber"}
                  label={"Kundennummer*"}
                  disabled={true}
                />
                <Field
                  data-testid="field-livingAreaName"
                  className={classes.field}
                  component={TextField}
                  variant={"outlined"}
                  name={"name"}
                  label={"Name des Wohnbereichs*"}
                />
                <Field
                  data-testid="field-livingAreaNumber"
                  className={classes.field}
                  component={TextField}
                  variant={"outlined"}
                  name={"areaNumber"}
                  label={"Nummer des Wohnbereichs*"}
                />
                <FormSection>
                  {error && (
                    <Alert className={classes.field} data-testid="add-area-error" severity="error">
                      {error}
                    </Alert>
                  )}
                  <FormSubmitButton data-testid="button-createlivingArea" disabled={isSubmitting} onClick={submitForm}>
                    Erstellen
                  </FormSubmitButton>
                </FormSection>
              </CollapseableFormSection>
            </FormContainer>
          )}
        </Formik>
      </div>
    </>
  );
}
