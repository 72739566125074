import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Divider } from "@material-ui/core";

export interface MenuOption {
  type: "MENU_ENTRY";
  label: string;
  "data-testid"?: string;
  onSelect: () => void;
}
export interface MenuOptionSeparator {
  type: "SEPARATOR";
  "data-testid"?: string;
}
interface ActionMenuProps {
  options: (MenuOption | MenuOptionSeparator)[];
  "data-testid"?: string;
}

const ActionMenu: React.FC<ActionMenuProps> = ({ options, ...props }) => {
  const testId = props["data-testid"];
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {options.map((option) =>
          option.type === "MENU_ENTRY" ? (
            <MenuItem
              data-testid={testId && option["data-testid"] && testId + option["data-testid"]}
              key={option.label}
              onClick={option.onSelect}
            >
              {option.label}
            </MenuItem>
          ) : (
            <Divider key="1" />
          )
        )}
      </Menu>
    </div>
  );
};

export default ActionMenu;
