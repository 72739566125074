import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import FormContainer from "template/presentation/components/form/FormContainer";
import FormSection from "template/presentation/components/form/FormSection";
import { TextField } from "formik-material-ui";
import { Alert } from "@material-ui/lab";
import FormSubmitButton from "template/presentation/components/form/FormSubmitButton";
import Assignment, { SelectOption } from "../../domain/entities/Assignment";
import SelectField from "template/presentation/components/form/SelectField";
import { format, startOfDay } from "date-fns";
import { AssignmentType } from "features/assignments/domain/services/AssignmentService";
import ValueGrid from "template/presentation/components/content/ValueGrid";
import LabeledValue from "template/presentation/components/content/LabeledValue";
import { endOfDay } from "date-fns/esm";
import ComboBox from "template/presentation/components/form/ComboBox";
import MultiSelectField from "template/presentation/components/form/MutiSelectField";
import RehaItemConverter from "features/rehaItems/domain/converter/RehaItemConverter";

export interface CreateOrUpdateAssignmentFormValues {
  clientNumber?: string;
  date: string;
  employeeNumber?: string;
  note: string;
  status: string;
  timeFrom: string;
  timeUntil: string;
  type: AssignmentType | "";
  caseNumber?: string;
  rehaItems?: SelectOption[];
}

interface CreateOrUpdateAssignmentFormProps {
  onSubmit: (values: CreateOrUpdateAssignmentFormValues, setFieldErrors: (field: string, message: string) => void) => void;
  error: string | null;
  statusOptions: SelectOption[];
  typeOptions: SelectOption[];
  caseOptions: SelectOption[];
  employeeOptions: SelectOption[];
  caseOptionsLoading?: boolean;
  assignment?: Assignment;
  caseNumber?: string;
  clientNumber?: string;
  setCaseNumberQuery?: (query: string) => void;
  onLoadRehaItems: (clientNumber: string) => void;
  rehaItems?: SelectOption[];
}

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(1),
  },
}));

const updateAssignmentSchema = Yup.object().shape({
  clientNumber: Yup.string().required("Benötigt"),
  employeeNumber: Yup.string()
    .nullable()
    .when("type", {
      is: (val) => val !== "RENT_EXTENSION",
      then: Yup.string().required("Benötigt"),
      otherwise: Yup.string().optional(),
    }),
  date: Yup.string().required("Benötigt"),
  note: Yup.string().required("Benötigt"),
  status: Yup.string().required("Benötigt"),
  timeFrom: Yup.string()
    .nullable()
    .when("type", {
      is: (val) => val !== "RENT_EXTENSION",
      then: Yup.string().required("Benötigt"),
      otherwise: Yup.string().optional(),
    }),
  timeUntil: Yup.string()
    .nullable()
    .when("type", {
      is: (val) => val !== "RENT_EXTENSION",
      then: Yup.string().required("Benötigt"),
      otherwise: Yup.string().optional(),
    })
    .when("timeFrom", (timeFrom: Date) => {
      if (timeFrom) {
        return Yup.date().min(timeFrom, "Der Endzeitpunkt muss nach dem Startzeitpunkt liegen.");
      }
    }),
  caseNumber: Yup.string().optional(),
  type: Yup.string().optional(),
});

const CreateOrUpdateAssignmentForm: React.FC<CreateOrUpdateAssignmentFormProps> = (props) => {
  const {
    onSubmit,
    error,
    statusOptions,
    typeOptions,
    assignment,
    clientNumber,
    caseNumber,
    employeeOptions,
    onLoadRehaItems,
    rehaItems,
  } = props;
  const classes = useStyles();

  const employeeOptionsWithLabelAsValue = useMemo(() => {
    return employeeOptions.map((employee) => ({
      value: employee.label,
      label: employee.label,
    }));
  }, [employeeOptions]);

  const initValues = useMemo((): CreateOrUpdateAssignmentFormValues => {
    if (assignment === undefined) {
      return {
        clientNumber: clientNumber || "",
        date: "",
        employeeNumber: "",
        note: "",
        status: "",
        timeFrom: "",
        timeUntil: "",
        type: "",
        caseNumber: caseNumber || "",
        rehaItems: undefined,
      };
    }

    return {
      clientNumber: assignment.client ? assignment.client.clientNumber : "",
      date: assignment.date ? format(new Date(assignment.date), "yyyy-MM-dd") : "",
      employeeNumber: employeeOptions?.find((employee) => employee.value === assignment.employee.employeeNumber)?.label,
      note: assignment.note || "",
      status: assignment.status || "",
      timeFrom: assignment.timeFrom ? format(new Date(assignment.timeFrom), "yyyy-MM-dd'T'HH:mm") : "",
      timeUntil: assignment.timeUntil ? format(new Date(assignment.timeUntil), "yyyy-MM-dd'T'HH:mm") : "",
      type: assignment.type,
      caseNumber: assignment.relatedCaseNumber || "",
      rehaItems: RehaItemConverter.fromEntityToSelectOptions(assignment.relatedRehaItems),
    };
  }, [assignment, caseNumber, clientNumber, employeeOptions]);

  return (
    <Formik<CreateOrUpdateAssignmentFormValues>
      initialValues={initValues}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        try {
          await onSubmit(values, setFieldError);
        } finally {
          setSubmitting(false);
        }
      }}
      validationSchema={updateAssignmentSchema}
      enableReinitialize
    >
      {({ submitForm, isSubmitting, values, setFieldError }) => {
        return (
          <FormContainer>
            {assignment && (
              <FormSection label={"Identifikatoren"}>
                <ValueGrid>
                  <LabeledValue data-testid="AD-assignmentNumber" label="Interne Auftragsnummer" value={assignment.id} />
                  {assignment.assignmentNumber && (
                    <LabeledValue label="Auftragsnummer (Von Branchensoftware erstellt)" value={assignment.assignmentNumber} />
                  )}
                </ValueGrid>
              </FormSection>
            )}

            <FormSection label="Vorgang">
              <Field
                data-testid="field-relatedCaseNumber"
                className={classes.field}
                component={TextField}
                InputLabelProps={{
                  shrink: true,
                }}
                variant={"outlined"}
                name={"caseNumber"}
                label={"Vorgangsnummer"}
                disabled={assignment?.relatedCaseNumber !== undefined && assignment?.relatedCaseNumber !== ""}
              />
            </FormSection>
            <FormSection label={"Details"}>
              <Field
                data-testid="field-date"
                className={classes.field}
                component={TextField}
                type={"date"}
                InputLabelProps={{
                  shrink: true,
                }}
                variant={"outlined"}
                name={"date"}
                label={"Datum*"}
              />
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <Field
                  data-testid="field-time-from"
                  style={{ flex: "0 0 49.5%", marginRight: "1%" }}
                  className={classes.field}
                  component={TextField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type={"datetime-local"}
                  variant={"outlined"}
                  name={"timeFrom"}
                  label={"Von*"}
                  inputProps={{
                    min: values.date && format(startOfDay(new Date(values.date)), "yyyy-MM-dd'T'HH:mm"),
                    max: values.date && format(endOfDay(new Date(values.date)), "yyyy-MM-dd'T'HH:mm"),
                  }}
                  disabled={!values.date}
                />
                <Field
                  data-testid="field-time-until"
                  style={{ flex: "0 0 49.5%" }}
                  className={classes.field}
                  component={TextField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type={"datetime-local"}
                  variant={"outlined"}
                  name={"timeUntil"}
                  label={"Bis*"}
                  inputProps={{
                    min: values.date && format(startOfDay(new Date(values.date)), "yyyy-MM-dd'T'HH:mm"),
                    max: values.date && format(endOfDay(new Date(values.date)), "yyyy-MM-dd'T'HH:mm"),
                  }}
                  disabled={!values.date}
                />
              </div>
              <Field
                data-testid="field-time-note"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"note"}
                label={"Terminbeschreibung*"}
              />
              <Field
                data-testid="field-status"
                className={classes.field}
                component={SelectField}
                options={assignment?.status === "SCHEDULING" ? statusOptions : statusOptions.filter((x) => x.value !== "SCHEDULING") || []}
                variant={"outlined"}
                name={"status"}
                legend={"Status Auswählen*"}
                disabled={assignment?.status === "DONE"}
              />
              <Field
                data-testid="field-type"
                className={classes.field}
                component={SelectField}
                options={typeOptions || []}
                variant={"outlined"}
                name={"type"}
                legend={"Auftragstyp wählen*"}
                disabled={
                  values.status === "DONE" ||
                  assignment?.status === "SCHEDULING" ||
                  values.type === "ON_SITE_CONSULTATION" ||
                  values.type === "VIDEO_CONSULTATION"
                }
              />
            </FormSection>

            <FormSection label={"Um welchen Kunden handelt es sich?"}>
              <Field
                data-testid="field-client"
                className={classes.field}
                component={TextField}
                variant={"outlined"}
                name={"clientNumber"}
                label={"Kundennummer*"}
                disabled={clientNumber !== null && clientNumber !== ""}
                onBlur={() => {
                  if (values.clientNumber) onLoadRehaItems(values.clientNumber);
                }}
              />
              <Field
                data-testid="field-rehaItems"
                name="rehaItems"
                legend="Rehamittel auswählen"
                className={classes.field}
                component={MultiSelectField}
                options={rehaItems || []}
                getOptionSelected={(option: SelectOption) => {
                  return undefined !== assignment?.relatedRehaItems?.find((item) => item.id.toString() === option.value.toString());
                }}
              ></Field>
            </FormSection>

            <FormSection label={"Welcher Außendienstmitarbeiter ist zuständig?"}>
              <Field
                data-testid={"field-user"}
                className={classes.field}
                component={ComboBox}
                options={employeeOptionsWithLabelAsValue || []}
                getTestIdForOption={(option: any) => option.value}
                variant={"outlined"}
                legend={"Mitarbeiter*"}
                name={"employeeNumber"}
              />
            </FormSection>

            <FormSection>
              {error && (
                <Alert className={classes.field} data-testid="assignment-form-error" severity="error">
                  {error}
                </Alert>
              )}

              {values.type !== "RENT_EXTENSION" ? (
                <FormSubmitButton style={{ width: 230 }} data-testid="button-createassignment" disabled={isSubmitting} onClick={submitForm}>
                  {assignment ? "Änderungen übernehmen" : "Auftrag erstellen"}
                </FormSubmitButton>
              ) : (
                <FormSubmitButton
                  style={{ width: 230 }}
                  data-testid="button-cancelrentextension"
                  disabled={isSubmitting || values.status === "DONE"}
                  onClick={submitForm}
                >
                  {values.status === "DONE" ? "Mietverlängerung storniert" : "Mietverlängerung stornieren"}
                </FormSubmitButton>
              )}
            </FormSection>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default CreateOrUpdateAssignmentForm;
