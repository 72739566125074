import { AxiosInstance } from "axios";
import LoginToken from "../domain/entities/LoginToken";
import UserSessionInfo from "../domain/entities/UserSessionInfo";

export default class LoginApi {
  constructor(private unauthorizedClient: AxiosInstance, private authorizedClient: AxiosInstance) {}

  public async login(username: string, password: string): Promise<LoginToken> {
    return this.unauthorizedClient
      .post<LoginToken>("/auth/login", { username, password })
      .then((x) => x.data);
  }

  public async aboutMe(): Promise<UserSessionInfo> {
    return this.authorizedClient.get<UserSessionInfo>("/auth/me").then((x) => x.data);
  }
}
