import { ValidationMessageError } from "core/data/errors";
import BranchApi from "../../data/BranchApi";
import { BranchRequestModel } from "../../data/BranchRequestModel";
import { BranchResponseModel } from "features/branches/data/BranchResponseModel";
import BranchConverter from "../converters/BranchConverter";
import { AllocatedArea, Branch, BranchOption } from "../entities/BranchEntity";

export default class BranchService {
  constructor(private branchApi: BranchApi) {}

  private contains(range: string, value: string) {
    if (range.indexOf("-") >= 0) {
      if (parseInt(value) >= parseInt(range.split("-")[0]) && parseInt(value) <= parseInt(range.split("-")[1])) {
        return true;
      }
    } else if (range === value) {
      return true;
    }
  }

  public async getCityByZip(zip: string, allocatedAreas: AllocatedArea[]) {
    const allocated: string[] = allocatedAreas.map((val) => val.zip);
    let hit: boolean = false;

    if (allocated.length > 0) {
      if (zip.indexOf("-") >= 0) {
        const zipArea: string[] = zip.split("-");
        allocated.forEach((element) => {
          zipArea.forEach((zipInArea) => {
            if (this.contains(element, zipInArea)) {
              hit = true;
            }
          });
        });
      } else {
        allocated.forEach((element) => {
          if (this.contains(element, zip)) {
            hit = true;
          }
        });
      }
    }
    if (!hit) {
      return await this.branchApi.getCityByZip(zip);
    } else {
      throw new ValidationMessageError(
        "Diese Postleitzahl, oder Postleitzahlenbereich ist bereits dieser Niederlassung zugeordnet.",
        "ALREADY_ALLOCATED_TO_THIS_BRANCH"
      );
    }
  }

  public disableDefault(defaultBranch?: Branch, branch?: any) {
    if (defaultBranch?.default) {
      if (branch?.default) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }

  public async getDefault(forB2BClient?: string) {
    return this.branchApi.getDefault(forB2BClient).then((branch) => branch && BranchConverter.fromResponseModelToEntity(branch));
  }

  public async get(searchQuery?: string | undefined, isB2B?: boolean, b2bClientId?: string): Promise<Branch[]> {
    return this.branchApi.get(searchQuery, isB2B, b2bClientId).then((branches) => {
      return branches.map((branch) => BranchConverter.fromResponseModelToEntity(branch));
    });
  }

  public async getById(id: string): Promise<Branch> {
    return this.branchApi.getById(id).then((branch) => BranchConverter.fromResponseModelToEntity(branch));
  }

  public async getMine(): Promise<Branch | null> {
    return this.branchApi.getMine().then((branch) => (branch ? BranchConverter.fromResponseModelToEntity(branch) : null));
  }

  public async update(id: string, values: BranchRequestModel) {
    return this.branchApi.update(id, values);
  }

  public async create(values: BranchRequestModel) {
    return this.branchApi.create(values);
  }

  public async getBranchOptions(b2bClientId?: string): Promise<BranchOption[]> {
    const res = await this.branchApi.get(undefined, b2bClientId ? true : false, b2bClientId);

    return res.map(({ _id, code, name }: BranchResponseModel) => ({
      id: _id,
      value: code,
      label: `${name} (${code})`,
    }));
  }

  public async getB2BBackofficeUserBranchOptions(data: { id: string; type?: string }): Promise<BranchOption[]> {
    return await this.branchApi.getB2BBackofficeUserBranchOptions(data);
  }
}
