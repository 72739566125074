import { AppBar, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  section: {
    flex: 1,
    padding: theme.spacing(2),
  },
  sectionHeader: {
    backgroundColor: "#f5f5f5",
    color: "rgba(0,0,0,0.54)",
    fontWeight: 600,
    fontSize: 12,
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    padding: "10px 15px",
  },
}));

interface ContentSectionProps {
  label?: string;
}

const ContentSection: React.FC<ContentSectionProps> = ({ label, children }) => {
  const classes = useStyles();

  return (
    <>
      {label && (
        <AppBar className={classes.sectionHeader} position="static" color="default" elevation={0}>
          <span>{label}</span>
        </AppBar>
      )}
      <div className={classes.section}>{children}</div>
    </>
  );
};
export default ContentSection;
