import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Alert } from "@material-ui/lab";
import { useService } from "core/di";

import { useQuery } from "react-query";
import AssignmentService from "features/assignments/domain/services/AssignmentService";
import { CircularProgress } from "@material-ui/core";

interface ViewPDFDocumentDialogProps {
  assignmentId: string;
  documentHistoryId?: string;
  open: boolean;
  onDismiss: () => void;
}

const ViewPDFDocumentDialog: React.FC<ViewPDFDocumentDialogProps> = ({ assignmentId, documentHistoryId, onDismiss, open }) => {
  const assignmentService = useService(AssignmentService);

  const { data: doc, error, isFetching } = useQuery(
    ["signatureRequestDocumentHistory", assignmentId, documentHistoryId],
    () => assignmentService.getSignatureRequestHistoryDocument(assignmentId, documentHistoryId!),
    { enabled: !!documentHistoryId }
  );

  return (
    <Dialog open={open} onClose={onDismiss} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth="xl">
      <DialogTitle id="alert-dialog-pdfView-title">Dokument ansehen</DialogTitle>
      <DialogContent>
        {doc && (
          <div data-testid={"testId-doc-view"}>
            <iframe title={"Bal bla"} key={"0"} src={doc} id="pdf" width="800px" height={"1200px"} style={{ overflow: "auto" }} />
          </div>
        )}
        {isFetching && <CircularProgress size="1rem" color="primary" style={{ marginRight: 5 }} />}
        {error && (
          <Alert data-testid="signingRequest-dialog-form-error" severity="error">
            {"Fehler beim Laden des Dokuments"}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onDismiss}>Schließen</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewPDFDocumentDialog;
