import BranchConverter from "features/branches/domain/converters/BranchConverter";
import ClassificationConverter from "features/classifications/domain/converters/ClassificationConverter";
import Client from "features/clients/domain/entities/Client";
import PoolAssignmentPreviewResponseModel from "features/pools/data/PoolAssignmentPreviewResponseModel";
import PoolAssignmentResponseModel from "features/pools/data/PoolAssignmentResponseModel";
import PoolAssignment, { PoolAssignmentPreview, PoolAssignmentType } from "../entities/PoolAssignment";

export default class PoolAssignmentConverter {
  public static fromPreviewResponseModelToEntity(responseModel: PoolAssignmentPreviewResponseModel): PoolAssignmentPreview {
    const { client, ...responseFields } = responseModel;

    let clientEntity: Client | undefined = undefined;
    if (client) {
      const { _id, prescribers, ...clientFields } = client;
      clientEntity = {
        id: _id,
        ...clientFields,
        branch: client.branch ? BranchConverter.fromResponseModelToEntity(client.branch) : undefined,
      };
    }

    let classificationEntity = responseFields.classification
      ? ClassificationConverter.fromResponseModelToEntity(responseFields.classification)
      : undefined;

    // A delivery is spontaneous if there is no assignment associated with it
    const type: PoolAssignmentType =
      responseFields.type === "DELIVERY" && !responseFields.relatedCaseNumber && !responseFields.assignmentNumber
        ? "UNPLANNED_DELIVERY"
        : responseFields.type;

    return {
      client: clientEntity,
      ...responseFields,
      type,
      classification: classificationEntity,
      createdAt: new Date(responseFields.createdAt),
    };
  }

  public static fromResponseModelToEntity(responseModel: PoolAssignmentResponseModel): any | null {
    // has to be return type any becasue of ts limiting type unions to 25 see https://github.com/microsoft/TypeScript/issues/40803#:~:text=Limit%20of%2025%20members%20when,40803%20%C2%B7%20microsoft%2FTypeScript%20%C2%B7%20GitHub
    switch (responseModel.type) {
      case "ATTACHMENT":
        return {
          ...responseModel,
        };
      case "ATTACHMENT_B2C":
        return { ...responseModel };
      case "EDIT_PROFILE":
        return { ...responseModel };
      case "ATTACHMENT_B2C_WITH_APPOINTMENT":
      case "ATTACHMENT_B2C_TO_B2B_WITH_APPOINTMENT":
        return {
          ...responseModel,
          report: {
            ...responseModel.report,
            appointment: {
              ...responseModel.report.appointment,
              date: new Date(responseModel.report.appointment.date),
              timeFrom: new Date(responseModel.report.appointment.timeFrom),
              timeTo: new Date(responseModel.report.appointment.timeTo),
            },
          },
        };
      case "CONSULTATION_REQUEST_B2C":
      case "CONSULTATION_REQUEST_B2C_TO_B2B":
        return {
          ...responseModel,
          report: {
            ...responseModel.report,
            appointment: {
              ...responseModel.report.appointment,
              date: new Date(responseModel.report.appointment.date),
              timeFrom: new Date(responseModel.report.appointment.timeFrom),
              timeTo: new Date(responseModel.report.appointment.timeTo),
            },
          },
        };
      case "VIDEO_CONSULTATION":
        return {
          ...responseModel,
          report: {
            ...responseModel.report,
            appointmentDateAndTime: new Date(responseModel.report.appointmentDateAndTime),
          },
        };
      case "VIDEO_CONSULTING":
        return {
          ...responseModel,
        };
      case "ON_SITE_CONSULTATION":
        return {
          ...responseModel,
          report: {
            ...responseModel.report,
            appointmentDateAndTime: new Date(responseModel.report.appointmentDateAndTime),
          },
        };
      case "DELIVERY":
        return {
          ...responseModel,
          type: responseModel.relatedCaseNumber ? "DELIVERY" : "UNPLANNED_DELIVERY",
        };
      case "COLLECTION":
        return {
          ...responseModel,
        };
      case "REPAIR":
        return {
          ...responseModel,
        };
      case "CONSULTING":
        return {
          ...responseModel,
        };
      case "RENT_EXTENSION":
        return {
          ...responseModel,
        };
      case "CONSULTATION_REQUEST_B2B":
        return {
          ...responseModel,
          report: {
            ...responseModel.report,
            appointmentRequest: {
              ...responseModel.report.appointmentRequest,
              date: new Date(responseModel.report.appointmentRequest.date),
              timeFrom: new Date(responseModel.report.appointmentRequest.timeFrom),
              timeTo: new Date(responseModel.report.appointmentRequest.timeTo),
            },
          },
        };
      case "VIDEO_CONSULTATION_B2B":
        return {
          ...responseModel,
          report: {
            ...responseModel.report,
            appointmentDateAndTime: new Date(responseModel.report.appointmentDateAndTime),
          },
        };
      case "BUSINESS_INQUIRY":
        return {
          ...responseModel,
        };
      case "VISIT":
        return {
          ...responseModel,
        };
      case "CASE_CREATED":
        return {
          ...responseModel,
        };
      case "INBOX":
        return {
          ...responseModel,
        };
      case "WOUNDCARE":
        return {
          ...responseModel,
        };
      case "EVO":
        return {
          ...responseModel,
        };
      case "SIGNATURE_REQUEST":
        return { ...responseModel };
      case "CASE_CHANGED":
        return {
          ...responseModel,
        };
      default:
        return null;
    }
  }

  public static fromAssignmentTypeToString(type: PoolAssignmentType, userGroups: string[]): string {
    switch (type) {
      case "ATTACHMENT":
        if (userGroups.includes("employee")) {
          return "Anhang (AU)";
        } else if (userGroups.includes("b2b")) {
          return "Anhang (B2B)";
        } else {
          return "Anhang (AU/B2B)";
        }
      case "ATTACHMENT_B2C":
        return "Anhang (B2C)";
      case "ATTACHMENT_B2C_WITH_APPOINTMENT":
        return "Anhang (B2C) mit Terminwunsch";
      case "ATTACHMENT_B2C_TO_B2B_WITH_APPOINTMENT":
        return "Terminanfrage vor Ort";
      case "CONSULTATION_REQUEST_B2C":
        return "Videoberatungsanfrage (B2C)";
      case "CONSULTATION_REQUEST_B2C_TO_B2B":
        return "Videoterminanfrage";
      case "ON_SITE_CONSULTATION":
        return "Beratung vor Ort (B2C)";
      case "VIDEO_CONSULTATION":
        return "Videoberatung (B2C)";
      case "DELIVERY":
        return "Lieferung";
      case "UNPLANNED_DELIVERY":
        return "ungeplante Lieferung";
      case "COLLECTION":
        return "Abholung";
      case "REPAIR":
        return "Reparatur/Wartung";
      case "CONSULTING":
        return "Beratung";
      case "VIDEO_CONSULTING":
        return "Videoberatung Ergebnis";
      case "RENT_EXTENSION":
        return "Mietverlängerung";
      case "CONSULTATION_REQUEST_B2B":
        return "Videoberatungsanfrage (B2B)";
      case "VIDEO_CONSULTATION_B2B":
        return "Videoberatung (B2B)";
      case "BUSINESS_INQUIRY":
        return "Geschäftsanfrage";
      case "VISIT":
        return "Heimbesuch";
      case "CASE_CREATED":
        return "Neuer Vorgang erstellt";
      case "CASE_CHANGED":
        return "Vorgang bearbeitet";
      case "INBOX":
        return "Posteingang";
      case "EDIT_PROFILE":
        return "Profiländerung (B2C)";
      case "WOUNDCARE":
        return "Wundversorgung";
      case "EVO":
        return "Elektronische Verordnung";
      case "SIGNATURE_REQUEST":
        return "Unterschriftenanforderung";
      default:
        return "-";
    }
  }
}
