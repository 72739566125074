import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import DataContainer from "template/presentation/components/table/DataContainer";
import DataTable, { DataTableCell } from "template/presentation/components/table/DataTable";
import { Classification } from "../../domain/entities/ClassificationEntity";
import { EmptyState } from "core/presentation/components/EmptyState";

export interface ClassificationsTableProps {
  classifications: Classification[] | null;
  isLoading: boolean;
  onReload: () => void;
  onSearch: (query: string | undefined) => void;
  onAdd: () => void;
}

const ClassificationsTable = (props: ClassificationsTableProps) => {
  const { classifications, isLoading, onReload, onSearch, onAdd } = props;

  const columns = useMemo(() => {
    return [{ name: "Nummer" }, { name: "Name" }, { name: "Aktion" }];
  }, []);

  return (
    <DataContainer
      loading={isLoading}
      searchPlaceholder="Suche nach Nummer ..."
      onSearch={onSearch}
      addButton="Neue Klassifizierung"
      onAdd={onAdd}
      onReload={onReload}
    >
      <DataTable<Classification>
        data-testid={`classificationtable`}
        loading={isLoading}
        columns={columns}
        rows={classifications}
        keyExtractor={(data) => data.id}
        renderRow={(data, index) => {
          return (
            <>
              <DataTableCell data-testid={`classification-number`}>{data.number || "-"}</DataTableCell>
              <DataTableCell data-testid={`classification-name`}>{data.name || "-"}</DataTableCell>
              <DataTableCell>
                <Link data-testid={`link-edit-row-${index}`} to={`/classification/edit/${data.id}`}>
                  <EditIcon />
                </Link>
              </DataTableCell>
            </>
          );
        }}
        renderEmpty={() => (
          <EmptyState
            title="Noch keine Klassifizierung vorhanden"
            description="Erstellen Sie jetzt Ihre erste Klassifizierung, um Aufträge einer Kategorie zuweisen zu können."
            buttonLabel="Neue Klassifizierung"
            onButtonClick={onAdd}
          />
        )}
      />
    </DataContainer>
  );
};

export default ClassificationsTable;
